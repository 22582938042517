<template>
    <div :id="'defaultNested-'+ownId"
         :key="'defaultNested-'+ownId">
        <template v-if="!allFiltered && 1 < pages.length">
            <Pagination :page="displayedPage" :pages="pages"
                        :key="filterKey"
                        @pageChange="handlePageChange"/>
            <div class="clearfix"></div>
        </template>
        <div :class="'default-view-itemlist'+( galleryMode ? ' gallery' : '' )">
            <template v-for="item in itemList"
                      :key="'template-dvnl-'+item">

                <NestedTouchable v-if="undefined !== pages[displayedPage - 1]
                             && -1 < pages[displayedPage - 1].indexOf( item )
                             && ( -1 < filtered.indexOf( item ) || !viewIsFiltered )"
                                 :localId="item"
                                 :viewItem="'Nested'+$core.f().ucFirst( viewItem )"
                                 :selectMode
                                 :noShareIndicator
                                 :scope
                                 :singleItems
                                 :galleryMode
                                 :inListEditor
                                 @clicked="$emit( 'clicked', $event )"
                                 @findTab="$emit( 'findTab', $event )"
                                 @dragging="$emit( 'dragging', $event )"
                                 @selected="$emit( 'selected', $event )"/>

            </template>
            <template v-if="itemList.length > 0">
                <div class="clearfix"></div>
            </template>
            <template v-if="!allFiltered && 1 < pages.length">
                <Pagination :page="displayedPage" :pages="pages"
                            :key="filterKey"
                            @pageChange="handlePageChange"/>
                <div class="clearfix"></div>
            </template>
            <InlineMessage v-if="allFiltered"
                           :active="allFiltered"
                           :key="'message-inline-'+scope+'-'+viewItem"
                           classname="nothing"
                           :title="$core.t( 'caption-all-filtered' )"
                           :message="$core.t( 'message-all-filtered' )"/>
            <InlineMessage v-if="!allFiltered && 0 === itemList.length"
                           :active="!allFiltered && 0 === itemList.length"
                           :key="'message-inline-nothing-'+scope+'-'+viewItem"
                           classname="empty"
                           :title="$core.t( 'default-caption-empty' )"
                           :message="$core.t( 'message-empty-'+scope )"/>
        </div>
    </div>
</template>
<script>
/*eslint-disable*/
import InlineMessage   from "@/components/messages/InlineMessage";
import Pagination      from "@/components/elements/defaults/Pagination";
import MixinPagination from "@/components/defaults/defaultViewItemList/MixinPagination";
import NestedTouchable from "@/components/defaults/NestedTouchable.vue";

export default {

    name      : 'DefaultNestedItemList',
    components: { NestedTouchable, Pagination, InlineMessage },
    mixins    : [
        MixinPagination
    ],

    emits: [ 'clicked', 'selected', 'dragging', 'findTab' ],

    data()
    {
        return {
            ownId: this.$core.getUuid().generate()
        }
    },

    props: {
        itemList        : { Type: Array, required: true },
        viewKey         : { Type: String, required: true },
        filtered        : { Type: Array, required: false, default: [] },
        filterKey       : { Type: String, required: true },
        viewIsFiltered  : { Type: Boolean, required: false, default: false },
        viewItem        : { Type: String, required: true },
        scope           : { Type: String, required: false, default: '' },
        viewScope       : { Type: String, required: false, default: 'cache' },
        singleItems     : { Type: Boolean, required: false, default: false },
        hiddenVisible   : { Type: Boolean, required: false, default: false },
        selectMode      : { Type: Boolean, required: false, default: false },
        noShareIndicator: { Type: Boolean, required: false, default: false },
        emitClick       : { Type: Boolean, required: false, default: false },
        galleryMode     : { Type: Boolean, required: false, default: false },
        inListEditor    : { Type: Boolean, required: false, default: false }
    },

    computed: {
        allFiltered()
        {
            return this.$props.viewIsFiltered && 0 === this.$props.filtered.length
        }
    },

    created()
    {
        this.preparePagination()
    },

    methods: {}

}
</script>