<template>
    <transition appear mode="out-in" :name="$core.settings().getTransition( 'crossfade' )">
        <div v-if="ready">

            <template v-if="true === $core.settings().getSetting( 'studentFileShowOnlyFilled' )">
                <button type="button" class="styled auto" @click="toggleShowAll()">
                    {{ showAll ? 'leere Zeilen ausblenden' : 'alle einblenden' }}
                </button>
            </template>

            <TabbedContent :tabs="tabs" :active="tab" @switched="setTab" :inline="true"
                           v-if="1 < tabs.length"/>
            <div class="clearfix"></div>
            <template v-for="t in tabs">
                <div class="tabbed-content" v-if="t.id === tab" :key="'tab-vertlist-'+t.id">
                    <table class="list" :id="'printable-'+item.referenceKey">
                        <thead>
                        <tr class="list-header">
                            <th>
                                <Button v-if="0 < opened.length"
                                        type="list-detail-opener"
                                        @click="toggleAllRows( 'close' )"
                                        addClass="list-closer"/>
                                <Button v-if="0 === opened.length"
                                        type="list-detail-opener"
                                        @click="toggleAllRows( 'open' )"
                                        addClass="list-opener"/>
                                <div style="display: none;">
                                    <button :id="'open-first-'+item.localId" @click="toggleAllRows( 'open' )">force
                                    </button>
                                </div>
                                {{ $core.t( 'object-type-students-single' ) }}
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="( row, index ) in students"
                                  :key="'template-'+row.localId+'-'+index">
                            <template v-if="archiveMatch( row, t )">
                                <template v-for="( col, index ) in t.data.columns"
                                          :key="'tr-template-'+row.localId+'-'+index">
                                    <template v-if="( false === $core.settings().getSetting( 'studentFileShowOnlyFilled' )
                                              || ( true === $core.settings().getSetting( 'studentFileShowOnlyFilled' )
                                                    && 0 < totals.rowCounts[row.localId] )
                                              || showAll ) && true === isVisible.get( row.localId )">
                                        <tr v-if="0 === index" :id="'spacer-'+row.localId+'-'+index"
                                            @click="handleRowClick( t.data.listItem )">
                                            <td colspan="2" class="td-vert-header">
                                                <Button v-if="-1 < opened.indexOf( row.localId )"
                                                        type="list-detail-opener"
                                                        @click="toggleRowOpen( row.localId )"
                                                        addClass="list-closer"/>
                                                <Button v-if="-1 === opened.indexOf( row.localId )"
                                                        type="list-detail-opener"
                                                        @click="toggleRowOpen( row.localId )"
                                                        addClass="list-opener"/>
                                                <div
                                                    :class="'sensitive studentName'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )">
                                                    <span><strong>{{ row.lastname }}</strong>, {{
                                                            row.firstname
                                                        }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="-1 < opened.indexOf( row.localId )"
                                            :id="'info-'+row.localId+'-'+index"
                                            @click="handleRowClick( t.data.listItem )">
                                            <TableDetailSplitted
                                                :caption="(col.type !== 'studentData' ? prepareCaption(col.caption) : $core.getTranslation().translate( 'caption_'+col.caption ))"/>
                                            <td :class="'td-'+col.type">
                                                <ListElement :type="t.data.columns[ index ].type"
                                                             :value="$core.f().valid( t.data.listItem.values ) ? t.data.listItem.values[ row.localId + '___' + col.columnId + '___' + ( 1 + index ) ] : ''"
                                                             :row="row.localId+'-'+col.columnId"
                                                             :referenceObjectId="t.data.listItem.localId"/>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                            <tr :class="'printable-cutline cutline-'+item.localId" style="display: none;"
                                :id="'printable-cutline-'+index"
                                v-if="false === $core.settings().getSetting( 'studentFileShowOnlyFilled' )
                                              || ( true === $core.settings().getSetting( 'studentFileShowOnlyFilled' )
                                                    && 0 < totals.rowCounts[row.localId] )
                                              || showAll">
                                <td colspan="2" class="printable-cutline">
                                    <div class="printable-cutline"></div>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>
            </template>
        </div>
    </transition>
</template>

<script>
/* eslint-disable */
import ListElement           from '@/components/elements/viewItems/listViews/ListElement'
import ListElementDistincts  from '@/components/elements/viewItems/listViews/listElement-distincts/ListElementDistincts'
import TabbedContent         from '@/components/layout/tabs/TabbedContent'
import TableDetailSplitted   from '@/components/elements/viewItems/listViews/tableElements/TableDetailSplitted'
import ListFilterable        from "@/components/elements/viewItems/listViews/ListFilterable";
import MixinStructureKey     from "@/components/elements/viewItems/listViews/mixins/MixinStructureKey";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup.vue";

export default {

    name      : 'ListVertical',
    extends   : ListFilterable,
    components: { TableDetailSplitted, TabbedContent, ListElementDistincts, ListElement },
    mixins    : [ MixinStructureKey, MixinComponentCleanup ],
    emits     : [ 'rowClick', 'dataUpdate' ],
    props     : {
        elementKey  : { Type: String, required: false },
        item        : { type: Object, required: true },
        changeKey   : { Type: String, required: false },
        inListEditor: { type: Boolean, required: false, default: false }
    },

    watch: {
        elementKey: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( true !== this.$core.getState( 'listEditorOpen' )
                    && newValue !== oldValue
                    && undefined !== oldValue )
                {
                    this.$emit( 'dataUpdate' )
                }
                else if( false === this.registeredEvent
                         && true === this.$core.getState( 'listEditorOpen' )
                         && newValue !== oldValue
                         && this.ready )
                {
                    this.$core.getEventManager()
                        .append( 'on-listeditor-closed', () =>
                        {
                            this.$emit( 'dataUpdate' )
                        } )
                    this.registeredEvent = true
                }
            }
        },
        changeKey : {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( true !== this.$core.getState( 'listEditorOpen' )
                    && newValue !== oldValue
                    && this.ready )
                {
                    //this.reset()
                    this.$nextTick()
                        .then( () =>
                        {
                            this.prepare()
                        } )
                }
            }
        }
    },

    data()
    {
        return {
            registeredEvent : false,
            data            : false,
            totals          : false,
            ready           : false,
            firstcolumn     : [],
            columns         : false,
            students        : false,
            totalScore      : 0,
            opened          : [],
            counts          : {},
            rowCounts       : {},
            headTypes       : false,
            showAll         : false,
            tabs            : [],
            timestamps      : [],
            tab             : false,
            printableCaption: ''
        }
    },

    created()
    {
        this.prepare()
        this.$core.getEventManager()
            .add( 'on-toggle-show-all', () =>
            {
                this.toggleAllRows( 'open' )
            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-toggle-show-all' )
    },

    methods: {

        reset()
        {
            this.timestamps = []
            this.data = false
            this.totals = false
            this.ready = false
            this.headTypes = false
            this.firstcolumn = []
            this.students = false
            this.columns = false
            this.totalScore = 0
            this.counts = {}
            this.rowCounts = {}
            this.tabs = []
            this.showAll = false
        },

        fetchCompetenceHeader( captionHtml )
        {

            let temp    = captionHtml.split( /:/g ),
                localId = temp[ 1 ],
                item    = this.$core.getCompetenceCategoryTree().tree.byRoot[ localId ]

            return item.label

        },

        needsTransition( caption )
        {
            return ( 'competence:' === caption.substring( 0, 11 ) )
        },

        prepareCaption( captionHtml )
        {

            if( this.needsTransition( captionHtml ) )
            {
                captionHtml = this.fetchCompetenceHeader( captionHtml )
            }
            return captionHtml

        },

        setTab( which )
        {
            this.tab = which
            for( let t in this.tabs )
            {
                if( this.tabs[ t ].id === which )
                {
                    this.printableCaption = this.tabs[ t ].caption
                }
            }
        },

        toggleShowAll()
        {
            this.showAll = !this.showAll
        },

        appendToTab( tabId, data )
        {
            for( let t in this.tabs )
            {
                if( this.tabs[ t ].id === 'list-' + tabId )
                {
                    this.tabs[ t ].data = data
                }
            }
        },

        archiveMatch( student, t )
        {

            if( true !== student.archived )
            {
                return true
            }

            let temp  = student.archiveKey.split( /-/g ),
                temp2 = t.id.split( /-/g )

            temp.shift()
            temp2.shift()

            let archiveTimestamp = parseInt( temp.join( '' ) ),
                listTimestamp    = parseInt( temp2.join( '' ) )

            if( archiveTimestamp >= listTimestamp )
            {
                return true
            }

            return false

        },

        prepare()
        {

            let data      = [],
                sortRules = this.$core.settings().getSetting( 'sortingDirections' ),
                itemList  = this.$props.item.lists

            this.$core.sort().multiSortObjects( itemList, sortRules[ 'lists' ] )

            this.$core.getTableCalculation().getSummary( itemList, true )
                .then( calculation =>
                {

                    this.totals = calculation.totals
                    this.headTypes = calculation.headTypes

                    let scope       = this.$props.item.archived === true ? 'archive' : 'cache',
                        temp        = calculation.result[ 0 ],
                        studentList = temp.listItem.columns[ 0 ].filterBy === 'all' ? this.$core.getBaseClassHelper().get( 'student' ).getCache( scope )
                                                                                    : this.$core.getBaseClassHelper().getObjectById( temp.listItem.columns[ 0 ].filterBy ).students

                    if( temp.listItem.columns[ 0 ].filterBy === 'all' )
                    {
                        let studentListNew = []
                        for( const [ localId, student ] of studentList )
                        {
                            studentListNew.push( student )
                        }
                        studentList = studentListNew
                    }

                    for( let l in calculation.result )
                    {

                        let list = calculation.result[ l ]
                        if( -1 === this.timestamps.indexOf( list.timestamp ) )
                        {
                            this.tabs.push(
                                {
                                    id     : 'list-' + list.timestamp,
                                    caption: this.$core.getFriendlyTimestamp().friendlyDate( list.timestamp )
                                }
                            )
                            if( !this.tab )
                            {
                                this.tab = 'list-' + list.timestamp
                                this.setTab( this.tab )
                            }
                            this.timestamps.push( list.timestamp )

                            data.push( list )
                            this.appendToTab( list.timestamp, list )
                            this.firstcolumn.push( this.$core.getFriendlyTimestamp().friendlyDate( list.timestamp ) )
                            this.columns = this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'columns' )

                            this.students = []

                            if( list.listItem.columns[ 0 ].filterBy === 'all' )
                            {
                                this.$core.sort().multiSortObjects( studentList, sortRules[ 'students' ] )
                                this.students = studentList
                                let newList = []
                                for( let s in this.students )
                                {
                                    newList.push( this.students[ s ].localId )
                                }
                            }
                            else
                            {
                                for( let s in studentList )
                                {
                                    let student = this.$core.getBaseClassHelper().get( 'student' ).getById( studentList[ s ] )
                                    if( undefined !== student )
                                    {
                                        this.students.push( student )
                                    }
                                }
                            }

                            this.$core.sort().multiSortObjects( this.students, sortRules[ 'students' ] )

                        }

                    }

                    this.data = data
                    this.ready = true

                } )

        },

        handleRowClick( row )
        {
            this.$emit( 'rowClick', row )
        },

        toggleRowOpen( id )
        {
            let index = this.opened.indexOf( id )
            if( -1 < index )
            {
                this.opened.splice( index, 1 )
            }
            else
            {
                this.opened.push( id )
            }
        },

        toggleAllRows( mode )
        {
            for( let s in this.students )
            {
                let id = this.students[ s ].localId
                let index = this.opened.indexOf( id )
                if( -1 < index )
                {
                    if( 'close' === mode )
                    {
                        this.toggleRowOpen( this.students[ s ].localId )
                    }
                }
                else
                {
                    if( 'open' === mode )
                    {
                        this.toggleRowOpen( this.students[ s ].localId )
                    }
                }
            }
        }
    }

}
</script>