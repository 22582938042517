<template>
    <div>
        <input type="hidden" :id="'form-element-'+id" :value="JSON.stringify( display )" />
        <TextBox :id="'textbox-'+id"
                 :value="display.value"
                 @update="handleText"/>
        <div class="select-wrapper" v-if="showOptional">
            <select name="height" @change="handleSize">
                <option value="30" :selected="display.height === 30">klein</option>
                <option value="60" :selected="display.height === 60">mittel</option>
                <option value="90" :selected="display.height === 90">groß</option>
            </select>
        </div>
    </div>
</template>

<script>
import TextBox from "@/components/form/elements/TextBox";

export default {

    name      : "SlotEditorBoxAdder",
    components: { TextBox },
    props     : {
        id          : { type: String, required: true },
        value       : { type: [ Object, String ], required: false },
        showOptional: { type: Boolean, required: false, default: false }
    },

    emits: [ 'update' ],

    data()
    {
        return {
            display: {
                value : '',
                height: 30,
                type  : 'freetext'
            }
        }
    },

    created()
    {
        if( undefined !== this.$props.value
            && null !== this.$props.value )
        {
            try
            {
                this.display = JSON.parse( this.$props.value )
            }
            catch( e )
            {
                this.display = {
                    value : this.$props.value,
                    height: 30,
                    type  : 'freetext'
                }
            }
        }
    },

    methods: {

        emitUpdate()
        {
            this.$emit( 'update', this.$props.id, 'SlotEditorBoxAdder', 'update', this.$props.id, JSON.stringify( this.display ) )
        },

        handleText( id, type, method, ref, value )
        {
            this.display.value = value ? value.trim() : ''
            this.emitUpdate()
        },

        handleSize( event )
        {
            this.display.height = parseInt( event.target.value )
            this.emitUpdate()
        }

    }
}
</script>