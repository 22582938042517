<template>
    <transition appear :name="$core.settings().getTransition( 'next' )">
        <div v-show="visible" class="page-message message" @click="hide()">
            <span class="message-title">{{ message.title || 'Ohne Titel' }}</span><br/>
            <p class="message-body" v-html="message.message || 'leere Nachricht' "></p>
        </div>
    </transition>
</template>

<script>
export default {
    
    name  : 'DefaultMessageOverlay',
    props : {
        message: { type: Object, required: true }
    },
    
    data()
    {
        return {
            visible: true
        }
    },
    
    created()
    {
        this.$core.getEventManager()
            .add( 'hide-message-overlay', () =>
            {
                this.hide( true )
            } )
    },
    
    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'hide-message-overlay' )
    },
    
    methods: {
        
        hide( ignoreTarget )
        {
            if( !ignoreTarget )
            {
                if( this.$core.f().valid( this.$props.message.target ) )
                {
                    this.$router.push( { name: this.$props.message.target } )
                }
            }
            this.visible = false
        }
        
    }
    
}
</script>