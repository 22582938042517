function headline( doc, text, formattedTimestamp, color, maxWidth, options, punchMarks, landscape )
{

    let pmAddX = ( punchMarks && !landscape ? 10 : 0 ),
        pmAddY = ( punchMarks && landscape ? 10 : 0 ),
        y      = ( 15 + pmAddY )

    doc.setFont( 'quicksand', 'bold' )
    doc.setFontSize( 14 )
    doc.setTextColor( color[ 0 ], color[ 1 ], color[ 2 ] )
    doc.setDrawColor( color[ 0 ], color[ 1 ], color[ 2 ] )

    let appendTitle = undefined !== options.appendTitle ? options.appendTitle : undefined,
        dimensions  = doc.getTextDimensions( text ),
        timestampX  = ( 10 + pmAddX ) + dimensions.w,
        textY       = y

    y += ( Math.ceil( dimensions.h ) - 2 )

    let result = {
        headline : {
            x: ( 10 + pmAddX ), y: textY, color: color, text: text
        },
        timestamp: {
            x    : timestampX,
            y    : textY,
            color: color,
            text : ( undefined !== appendTitle ? appendTitle + ' | ' : '' ) + formattedTimestamp
        },
        lines    : [
            { x: ( 10 + pmAddX ), y: y, x2: ( maxWidth + pmAddX ), y2: y }
        ],
        y        : ( y + 3 )
    }

    return result

}

export default headline