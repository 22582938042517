<template>
    <div v-if="ready">
        <input type="hidden" :id="'form-element-'+refName" />
        <div v-for="( row, index ) in rows"
             :key="'lcs-idx-'+index"
             class="spaced-adder-wrap">
            <div class="row-adder-wrapper">
                <div class="left">
                    <SingleLinkedColumnsSelector :refName="row.refName"
                                                 :exclude="excludeList"
                                                 :value="row.values"
                                                 :index="index"
                                                 @update="handleUpdate"/>
                </div>
                <div class="right">
                    <transition appear :name="$core.settings().getTransition( 'zoom' )">
                        <Button v-if="row.valid"
                                :type="'row-'+row.mode"
                                @click="$core.f().skip"
                                @clicked="handleClick( row.mode, index )"/>
                    </transition>
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import SingleLinkedColumnsSelector from "@/components/form/elements/SingleLinkedColumnsSelector";

export default {

    name      : 'LinkedColumnsSelector',
    components: { SingleLinkedColumnsSelector },
    emits     : [ 'extendedFilterSelection', 'enterPressed', 'backspacePressed', 'update', 'selected', 'adderValid',
                  'adderInvalid' ],

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false },

    },

    data()
    {
        return {
            ready         : false,
            formElm       : false,
            rows          : [],
            columns       : [],
            selectedColumn: false,
            excludeList   : [],
            setup         : {}
        }
    },

    created()
    {
    },

    mounted()
    {
        if( undefined === this.$props.value )
        {
            this.rows.push( { valid: false, mode: 'add', refName: this.$props.refName + '-0' } )
        }
        else
        {
            this.prepareValues()
        }
        this.$nextTick()
            .then( () => {
                this.ready = true
            })
    },

    methods: {

        prepareValues()
        {

            if( !Array.isArray( this.$props.value ) )
            {
                this.rows.push( {
                    valid  : true,
                    mode   : 'remove',
                    refName: this.$props.refName + '-0',
                    values : this.$props.value
                } )
            }
            else
            {
                let idx = 0
                for( let v in this.$props.value )
                {
                    this.rows.push( {
                        valid  : true,
                        mode   : idx < ( this.$props.value.length -1 ) ? 'remove' : 'add',
                        refName: this.$props.refName + '-' + idx,
                        values : this.$props.value[ v ]
                    } )
                    idx++
                }
            }
        },

        handleClick( mode, index )
        {
            switch( mode )
            {
                case 'add':
                    for( let r in this.rows )
                    {
                        this.rows[ r ].mode = 'remove'
                    }
                    this.rows.push( {
                        valid  : false,
                        mode   : 'add',
                        refName: this.$props.refName + '-' + ( index + 1 ),
                        values : {}
                    } )
                    break
                case 'remove':
                    this.rows.splice( index, 1 )
                    this.resort()
                    break
            }
        },

        resort()
        {
            let newRows = []
            for( let i = 0; i < this.rows.length; i++ )
            {
                newRows.push( this.rows[ i ] )
            }
            this.rows = newRows
            this.exclude()
            this.performEmit()
        },

        exclude()
        {
            let excludeList = []
            for( let r in this.rows )
            {
                let row = this.rows[ r ]
                if( undefined !== row.values
                    && false !== row.values.linkTo )
                {
                    excludeList.push( row.values.linkTo )
                }
            }
            this.excludeList = excludeList
        },

        handleUpdate( index, type, component, call, value )
        {

            if( undefined !== value.linked
                && Array.isArray( value.linked ) )
            {

                if( !isNaN( index ) )
                {
                    this.rows[ index ].values = value
                    this.rows[ index ].valid = 0 < value.linked.length
                }

            }

            this.exclude()
            this.performEmit()

        },

        performEmit()
        {
            let emittable = []
            for( let r in this.rows )
            {
                emittable.push( this.rows[r].values )
            }

            let elm = document.querySelector( '#form-element-'+this.$props.refName )
            if( null !== elm )
            {
                elm.value = JSON.stringify( emittable )
            }

            this.$emit( 'update', this.$props.refName, 'linkedColumnSelector', 'linkedColumns', 'form-element-' + this.$props.refName, emittable )
        }



    }

}
</script>