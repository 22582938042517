<template>
    <table class="list" :key="'access-active-'+active+'-'+viewKey">
        <tbody>
        <tr>
            <td><strong>Login</strong></td>
            <td class="font-courier"><strong>{{ localAccess.username }}</strong></td>
        </tr>
        <tr v-if="!active">
            <td>initiales <strong>Passwort</strong></td>
            <td class="font-courier"><strong>{{ localAccess.passphrase }}</strong></td>
        </tr>
        <tr v-if="!active">
            <td class="align-top"><strong>Login via QR-Code</strong></td>
            <td class="has-image">
                <StudentAccessQr :localAccess="localAccess"/>
            </td>
        </tr>
        <tr v-if="!active">
            <td><strong>PUK</strong> zur Wiederherstellung eines vergessenen Passworts</td>
            <td class="font-courier"><strong>{{ localAccess.keys[ 0 ].backupKey }}</strong></td>
        </tr>
        <tr>
            <td v-if="!active"><strong>gültig ab</strong></td>
            <td v-if="active"><strong>gültig seit</strong></td>
            <td><strong>{{
                    $core.getReformatter().dateFromTimestamp( localAccess.timestamp )
                }}</strong></td>
        </tr>
        <tr v-if="!active">
            <td><strong>Adresse</strong></td>
            <td><strong>https://app.entzettelt.de</strong></td>
        </tr>
        <tr v-if="!active">
            <td><strong>Hinweise</strong></td>
            <td>Das Passwort bitte nach der ersten Anmeldung ändern und dieses<br/>
                Informationsblatt an einem sicheren Ort aufbewahren!<br/><br/>
                Nur mit dem PUK kann das Passwort wiederhergestellt werden!<br/><br/>
                Den QR-Code kannst du auf der Login-Seite der App scannen<br/>
            </td>
        </tr>
        <tr v-if="active">
            <td class="align-top"><strong>Einrichtungsdokument</strong></td>
            <td class="min-100">
                <iframe :src="localAccess.accessDocument" :class="docHidden ? 'hidden' : ''"></iframe>
                <!--<button @click="regenerateDocument()">neu erzeugen</button>-->
                <button @click="docHidden = !docHidden" v-html="docHidden ? 'anzeigen' : 'ausblenden'"></button>
                <button class="submit" @click="download">herunterladen</button>
            </td>
        </tr>
        </tbody>
    </table>
</template>

<script>
import StudentAccessQr from "@/components/routes/secretary/studentAccess/StudentAccessQr";

export default {
    name      : "StudentAccessDataTable",
    components: { StudentAccessQr },
    props     : {
        localAccess     : { type: Object, required: true },
        isActive        : { type: Boolean, required: false, default: false },
        studentReference: { type: Object, required: true }
    },

    data()
    {
        return {
            docHidden: true,
            active   : true,
            viewKey  : this.$core.getUuid().generate()
        }
    },

    created()
    {
        this.active = this.$props.isActive === true
    },

    methods: {

        download()
        {
            let a = document.createElement( 'a' )
            a.download = 'entzettelt-Zugang für '
                         + this.$props.studentReference.firstname + ' '
                         + this.$props.studentReference.lastname + ' - '
                         + 'vom ' + this.$core.getReformatter().dateFromTimestamp( this.$props.localAccess.timestamp ) + '.pdf'
            a.href = this.$props.localAccess.accessDocument
            this.$nextTick()
                .then( () =>
                {
                    a.click()
                } )
        },

        regenerateDocument()
        {
            this.$core.getUi().showBlocker( 'Augenblick bitte...', 'Ein neues Einrichtungsdokument wird erzeugt' )
            this.active = false
            this.$nextTick()
                .then( () =>
                {

                    this.$core.getRegisterStudentAccessHelper()
                        .regeneratePdf( this.$props.studentReference, this.$props.localAccess )
                        .then( blob =>
                        {

                            let accessClass = this.$core.baseClass( 'studentAccess' ),
                                accessClone = JSON.parse( JSON.stringify( this.$props.localAccess ) )

                            accessClone.accessDocument = blob

                            accessClass.update(
                                accessClone,
                                accessClone.localId,
                                accessClone.remoteId,
                                accessClone.timestamp,
                                accessClone.localKey )

                            this.$core.getEventManager()
                                .append( 'storable-after-updated-' + accessClone.localId, () =>
                                {

                                    this.$core.getUi()
                                        .delay( () =>
                                        {
                                            this.active = true
                                            this.viewKey = this.$core.getUuid().generate()
                                            this.$core.getUi().hideBlocker()
                                        }, 500 )

                                } )

                        } )

                } )

        }
    }
}
</script>

<style scoped lang="css">

td.align-top {
    vertical-align: top;
}

td.min-100 {
    width: 100%;
}

iframe {
    width: 100%;
    height: 500px;
    border: 0;
}

</style>