<template>
    <transition appear :name="$core.settings().getTransition( 'next' )">
        <div v-if="false !== tutorial" id="tutorial-root" class="tutorial-overlay">
            <TutorialCloser @close="tutorial = false"/>
            <component :is="component"
                       @close="tutorial = false"/>
        </div>
    </transition>
</template>

<script>
import TutorialCloser    from '@/components/elements/tutorial/TutorialCloser'
import TutorialDashboard from '@/components/elements/tutorial/tutorials/TutorialDashboard'
import TutorialNotFound  from '@/components/elements/tutorial/tutorials/TutorialNotFound'
import TutorialStudents  from '@/components/elements/tutorial/tutorials/TutorialStudents'
import TutorialClasses   from '@/components/elements/tutorial/tutorials/TutorialClasses'
import TutorialGroups    from '@/components/elements/tutorial/tutorials/TutorialGroups'
import TutorialNotes     from '@/components/elements/tutorial/tutorials/TutorialNotes'
import TutorialTodos     from '@/components/elements/tutorial/tutorials/TutorialTodos'
import TutorialOffice    from '@/components/elements/tutorial/tutorials/TutorialOffice'

export default {

    name      : 'Tutorial',
    components: {
        TutorialStudents,
        TutorialClasses,
        TutorialGroups,
        TutorialNotes,
        TutorialTodos,
        TutorialNotFound,
        TutorialDashboard,
        TutorialOffice,
        TutorialCloser
    },
    data()
    {
        return {
            tutorial : false,
            component: false
        }
    },

    watch: {
        tutorial: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.spacer( newValue )
                    if( false === newValue )
                    {
                        this.reset()
                    }
                }
            }
        }
    },

    created()
    {
        this.$core.getEventManager().add( 'start-tutorial', ( scope ) =>
        {
            this.startTutorial( scope )
        } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'start-tutorial' )
    },

    methods: {

        reset()
        {
            this.$core.getUi().hideForm()
            this.$core.getEventManager().dispatch( 'ui-reset-form-overlay' )
        },

        spacer( state )
        {
            let spacer = document.querySelector( '#tutorial-spacer' )
            spacer.style.height = state ? '150px' : '0px'
        },

        startTutorial( scope )
        {

            switch( scope )
            {
                case 'dashboard':
                case 'students':
                case 'classes':
                case 'groups':
                case 'notes':
                case 'todos':
                case 'office':
                    this.component = 'Tutorial' + this.$core.f().ucFirst( scope )
                    break
                default:
                    this.component = 'TutorialNotFound'
                    break
            }
            this.tutorial = scope

        }

    }

}
</script>