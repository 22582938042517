<template>
    <div :class="'list-element checkmark'+( value === 1 ? ' checked' : '')">
        <span v-if="value === 1">&check;</span>
    </div>
</template>

<script>
export default {
    name : 'listElement-checkbox',
    props: {
        value: { type: Number, required: false, default: undefined }
    }
}
</script>
