export default class TutorialElementsHelper
{

    /**
     * constructor
     * @param core
     * @returns {Functions}
     */
    constructor( core )
    {

        if( !TutorialElementsHelper.instance )
        {

            this.store = core.getStore()
            this.translation = core.getTranslation()
            this.baseClassHelper = core.getBaseClassHelper()
            this.eventManager = core.getEventManager()
            this.uuid = core.getUuid()

            this.getState = ( key ) =>
            {
                return core.getState( key )
            }
            this.setState = ( key, value ) =>
            {
                core.setState( key, value )
            }

            this.localId = false
            this.type = false
            this.caches = {}

            TutorialElementsHelper.instance = this

        }

        return TutorialElementsHelper.instance

    }

    prepare( fakeElement )
    {

        fakeElement.localId = this.uuid.generate()
        fakeElement.referenceKey = fakeElement.localId

        this.localId = fakeElement.localId
        this.type = fakeElement.type

        this.baseClassHelper.get( fakeElement.type )
            .registry
            .cache
            .set( fakeElement.localId, fakeElement )

    }

    cleanup()
    {

        this.baseClassHelper.get( this.type )
            .registry
            .cache
            .delete( this.localId )

        this.localId = false
        this.type = false

    }

}