<script>
export default {

    name: "MixinOrganizerResolver",
    /*eslint-disable*/
    methods: {

        resolveOrganizerTitle( organizer )
        {

            let resolvers = [ 'class', 'group', 'yeargroup' ]
            organizer.displayTitle = '<strong>' + organizer.title + '</strong>'

            for( let r in resolvers )
            {
                if( undefined !== organizer[ resolvers[ r ] + 'Reference' ] )
                {

                    let element = this.$core
                                      .getBaseClassHelper()
                                      .getObjectById( organizer[ resolvers[ r ] + 'Reference' ] ),
                        append  = undefined !== element && undefined !== element[ resolvers[ r ] + 'name' ] ? ' (' + element[ resolvers[ r ] + 'name' ] + ')' : ''

                    organizer.displayTitle = '<strong>' + organizer.title + '</strong>' + append

                }
            }

        },

        resolveStartDate( content )
        {
            let start = 999999999999
            for( let s in content.slotDisplay )
            {
                if( content.slotDisplay[ s ].date !== undefined
                    && start > content.slotDisplay[ s ].date )
                {
                    start = content.slotDisplay[ s ].date
                }
            }
            return start
        },

        resolveEndDate( content )
        {
            let start = 0
            for( let s in content.slotDisplay )
            {
                if( content.slotDisplay[ s ].date !== undefined
                    && start < content.slotDisplay[ s ].date )
                {
                    start = content.slotDisplay[ s ].date
                }
            }
            return start
        },

        targetHasSameLabel( sourceDate, sourceIndex, targetDate, targetIndex )
        {

            let labelsSource = this.getLabels( new Date( sourceDate ).getDay(), sourceIndex ),
                labelsTarget = this.getLabels( new Date( targetDate ).getDay(), targetIndex )

            return labelsSource[ 0 ].localId === labelsTarget[ 0 ].localId ? labelsTarget[ 0 ].localId : false

        },

        labelIndex( date, index, labelId )
        {

            let labels = this.getLabels( new Date( date ).getDay(), index )
        },

        getAllLabelPositions( labelId )
        {
            let slotMatrix = []
            for( let i = 0; i < 6; i++ )
            {
                for( let s = 0; s < this.$props.slots.length; s++ )
                {
                    let labels = this.getLabels( i, s )
                    if( Array.isArray( labels )
                        && 0 < labels.length
                        && labels[ 0 ].localId === labelId )
                    {
                        slotMatrix.push( { dayIndex: i, slotIndex: s } )
                    }
                }
            }
            return slotMatrix
        },

        resolveFieldDistance( sourceDate, sourceIndex, targetDate, targetIndex, labelPositions, labelId )
        {

            let direction   = ( sourceDate - targetDate ) > 0 ? -1 : 1,
                checkDate   = sourceDate,
                checkSlot   = sourceIndex,
                steps       = 0,
                overflowMax = 0

            while( ( checkDate !== targetDate
                     || checkSlot !== targetIndex ) && 100 > overflowMax )
            {

                overflowMax++
                checkSlot = checkSlot + direction
                if( -1 === direction && 0 > checkSlot )
                {
                    checkDate = checkDate + ( this.$core.c.DAY_SECONDS * direction )
                    checkSlot = this.$props.slots.length - 1
                }
                else if( 1 === direction && checkSlot > this.$props.slots.length - 1 )
                {
                    checkDate = checkDate + ( this.$core.c.DAY_SECONDS * direction )
                    checkSlot = 0
                }

                for( let l in labelPositions )
                {
                    if( labelPositions[ l ].dayIndex === new Date( checkDate ).getDay()
                        && labelPositions[ l ].slotIndex === checkSlot )
                    {
                        steps++
                    }
                }

            }

            return steps * direction

        },

        finalizeSlotMove( content, sourceDate, sourceIndex, fieldDistance )
        {

            let direction = fieldDistance > 0 ? 1 : -1

        },

        resolveOrganizerSlotMove( reference, sourceDate, sourceIndex, targetDate, targetIndex )
        {

            let content = this.$core.getBaseClassHelper()
                              .get( 'organizercontent' )
                              .getById( reference )

            if( undefined !== content )
            {

                let contentStart = this.resolveStartDate( content ),
                    contentEnd   = this.resolveEndDate( content ),
                    labelId      = this.targetHasSameLabel( sourceDate, sourceIndex, targetDate, targetIndex ),
                    labels       = false //this.resolveCorrespondingLabels( content, sourceDate, sourceIndex, targetDate, targetIndex )

                if( false !== labelId )
                {

                    let labelPositions = this.getAllLabelPositions( labelId ),
                        fieldDistance  = this.resolveFieldDistance( sourceDate, sourceIndex, targetDate, targetIndex, labelPositions, labelId ),
                        gridPositions  = this.finalizeSlotMove( content, sourceDate, sourceIndex, fieldDistance )

                }

                return true

            }

            return null

        }

    }
}
</script>