<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible" class="default-form-overlay">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>

                    <h2>Bild speichern</h2>
                    <div class="form">
                        <input type="file" id="image-container" accept="image/*" @change="handleChange"/>
                        <!--<button id="startbutton" @click="takePicture">Bild speichern</button>-->
                        <img id="preview"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    name : 'DefaultImageOverlay',
    emits: [ 'close' ],
    props: {
        readyKey: { type: String, required: true }
    },

    data()
    {
        return {
            visible: true
        }
    },

    created()
    {

    },

    mounted()
    {

    },

    methods: {

        reduce( can, ctx )
        {

            let imageData = ctx.getImageData( 0, 0, can.width, can.height )
            let pixels = imageData.data;
            for( let i = 0; i < pixels.length; i += 4 )
            {

                let lightness = parseInt( ( pixels[ i ] + pixels[ i + 1 ] + pixels[ i + 2 ] ) / 3 );

                pixels[ i ] = lightness;
                pixels[ i + 1 ] = lightness;
                pixels[ i + 2 ] = lightness;

            }
            ctx.putImageData( imageData, 0, 0 )

        },

        resizeImage( image, quality )
        {

            quality = parseFloat( undefined !== quality ? quality : 0.5 )

            let canvas    = document.createElement( 'canvas' ),
                width     = image.width,
                height    = image.height,
                maxWidth  = 1920,
                maxHeight = 1080

            if( width < height )
            {
                maxWidth = 1080
                maxHeight = 1920
            }

            if( width > maxWidth )
            {
                height = Math.round( height *= ( maxWidth / width ) )
                width = maxWidth
            }

            if( height > maxHeight )
            {
                width = Math.round( width *= ( maxHeight / height ) )
                height = maxHeight
            }

            canvas.width = width
            canvas.height = height

            let ctx = canvas.getContext( '2d' )
            ctx.drawImage( image, 0, 0, width, height )

            this.reduce( canvas, ctx )

            let url = canvas.toDataURL( 'image/jpeg', quality )

            if( 100000 > url.length
                || 0.1 === parseFloat( quality.toPrecision( 1 ) ) )
            {
                return url
            }
            else
            {
                let newQuality = parseFloat( quality.toPrecision( 1 ) ) - 0.1
                return this.resizeImage( image, newQuality )
            }

        },

        handleChange()
        {

            let input = document.querySelector( '#image-container' )
            let preview = document.querySelector( '#preview' )
            if( 0 < input.files.length )
            {

                let file = input.files[ 0 ]
                if( !( /image/i ).test( file.type ) )
                {
                    return false
                }

                let reader = new FileReader()
                reader.readAsArrayBuffer( file )
                reader.onload = ( event ) =>
                {

                    let blob = new Blob( [ event.target.result ] )
                    window.URL = window.URL || window.webkitURL
                    let blobURL = window.URL.createObjectURL( blob )

                    let image = new Image()
                    image.src = blobURL

                    image.onload = () =>
                    {

                        preview.src = this.resizeImage( image )

                    }

                }

            }

        }

    }
}
</script>