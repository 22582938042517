<script>
export default {

    name: "MixinFilterState",

    data()
    {
        return {
            routeKey  : this.$route.name,
            filterType: this.$options.name
        }
    },

    mounted()
    {
        this.$core.getEventManager()
            .append( 'on-reset-filters', () => {

                this.resetFilters()

            })
    },

    methods: {

        storeFilterState( filters )
        {
            this.$core.setState( 'filters-' + this.filterType + '-' + this.routeKey, filters )
        },

        getStoredFilterState()
        {

            if( false === this.$core.settings().getSetting( 'persistFilterStates' )
                && true !== this.$core.getState( 'listEditorOpen' ) )
            {
                return []
            }

            return this.$core.getState( 'filters-' + this.filterType + '-' + this.routeKey ) || []

        }
    }

}
</script>