<template>
    <div>
        <div class="select-wrapper" v-if="initialized">
            <select v-bind:id="'form-element-'+refName" @change="checkStyleUpdate()" @click="$core.f().skip">
                <option v-for="quality in qualities"
                        v-bind:key="'key_option_'+quality"
                        :selected="parseInt( value ) === quality"
                        v-bind:value="quality">{{ quality }}: {{ qualityTrans[ quality ] }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

export default {
    name : 'RatingStarsSelector',
    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        id         : { Type: String, required: false },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        styleUpdate: { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },
    updated()
    {
        this.select()
    },
    mounted()
    {
        this.select()
    },
    data()
    {
        return {
            qualities   : [ 3, 4, 5, 6, 7, 8, 9, 10 ],
            qualityTrans: {
                3: '***',
                4: '****',
                5: '*****',
                6: '******',
                7: '*******',
                8: '********',
                9: '*********',
                10: '**********'
            },
            initialized : true
        }
    },
    methods: {
        select()
        {
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element
                && undefined !== this.$props.display )
            {
                element.value = this.$props.display
                this.checkStyleUpdate()
            }
        },

        checkStyleUpdate()
        {

            let element = document.querySelector( '#form-element-' + this.$props.refName )

            if( false !== this.$props.styleUpdate )
            {
                if( null !== element )
                {
                    this.$emit( 'update', this.$props.refName, 'ratingStarsSelector', 'update', undefined, element.value )
                }
            }

        }
    }

}
</script>
