<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="visible" class="default-form-overlay template-gallery">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    <h2>{{ $core.t( 'template-gallery' ) }}</h2>
                    <div class="form">
                        <TabbedContent :tabs="tabs" :active="tab" @switched="setTab"/>
                        <template v-for="( templates, key ) in setup.templates">
                            <div v-if="!processing
                                       && key === tab
                                       && 0 < templates.length"
                                 :key="'gallery-'+key">
                                <Pagination :noMargin="true" :page="page" :pages="pages[tab].set"
                                            @pageChange="page = $event"/>
                                <div class="clearfix"></div>
                                <div class="list-template-box">
                                    <div v-for="( template, index ) in templates"
                                         :key="'templates-'+key+'-'+index">
                                        <template v-if="-1 < pages[tab].set[( page - 1 )].indexOf( index )">
                                            <div class="list-template-selector gallery">
                                                <ListPreviewFrame :type='key'
                                                                  :key="'template-'+key+'-'+index"
                                                                  :template="getTemplate( key, index )"
                                                                  :caption="template.listname || template.caption"
                                                                  @click="handleClick( key, index )"/>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                                <Pagination :noMargin="true" :page="page" :pages="pages[tab].set"
                                            @pageChange="page = $event"/>
                                <div class="clearfix"></div>
                            </div>
                        </template>
                        <InlineMessage v-if="0 === setup.templates[tab].length
                                             && !processing"
                                       :title="$core.t( 'default-caption-empty' )"
                                       :message="$core.t( 'default-message-empty-templates' )"
                                       classname="nothing"
                                       :active="true"/>
                        <PreheatingIndicator v-if="processing"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import TabbedContent       from "@/components/layout/tabs/TabbedContent";
import ListPreviewFrame    from "@/components/form/elements/ListPreviewFrame";
import Pagination          from "@/components/elements/defaults/Pagination";
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";
import InlineMessage       from "@/components/messages/InlineMessage";

export default {

    name      : 'TemplateGalleryOverlay',
    components: { InlineMessage, PreheatingIndicator, Pagination, ListPreviewFrame, TabbedContent },
    emits     : [ 'close' ],

    props: {
        setup: { Type: Object, required: true }
    },

    data()
    {
        return {
            visible   : false,
            processing: false,
            tab       : 'vendor',
            tabs      : [
                {
                    id     : 'vendor',
                    caption: 'Vorlagen von entzettelt'
                },
                {
                    id     : 'own',
                    caption: 'Eigene Listen'
                },
                {
                    id     : 'archived',
                    caption: 'Archivierte Listen'
                },
                {
                    id     : 'foreign',
                    caption: 'Listen aus dem Kollegium'
                }
            ],
            pages     : {},
            page      : 1
        }
    },

    mounted()
    {
    },

    created()
    {
        this.preparePagination()
        this.$nextTick()
            .then( () =>
            {

                this.visible = true
                setTimeout( () =>
                {

                    this.$core.getUi()
                        .hideBlocker()

                }, 500 )

            } )
    },

    methods: {

        preparePagination()
        {
            for( let scope in this.$props.setup.templates )
            {
                if( 'all' !== scope )
                {
                    this.pages[ scope ] = {
                        total: this.$props.setup.templates[ scope ].length,
                        set  : []
                    }
                    let idx = 0,
                        set = []
                    for( let i in this.$props.setup.templates[ scope ] )
                    {
                        set.push( parseInt( i ) )
                        idx++
                        if( 9 === idx )
                        {
                            idx = 0
                            this.pages[ scope ].set.push( JSON.parse( JSON.stringify( set ) ) )
                            set = []
                        }
                    }
                    if( 0 < set.length )
                    {
                        this.pages[ scope ].set.push( JSON.parse( JSON.stringify( set ) ) )
                    }
                }
            }
        },

        handleClose()
        {
            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )
        },

        setTab( tab )
        {
            this.processing = true
            this.$nextTick()
                .then( () =>
                {

                    this.tab = tab
                    this.page = 1

                    setTimeout( () =>
                    {

                        this.processing = false

                    }, 500 )

                } )
        },

        getTemplate( scope, idx )
        {

            let template = this.$props.setup.templates[ scope ][ idx ],
                setup    = false

            switch( scope )
            {
                case 'vendor':
                    return template
                case 'own':
                case 'archived':
                case 'foreign':
                    setup = JSON.parse( JSON.stringify( template ) )
                    setup.value = setup.localId
                    setup.isOwn = true
                    return setup
            }

        },

        handleClick( key, index )
        {
            this.$core.getEventManager()
                .dispatch( 'on-template-gallery-select', { scope: key, index: index } )
            this.handleClose()
        }

    }
}
</script>