import CalculationHelper from "@/objects/stateMergers/list/helper/CalculationHelper";
import { reactive }      from "vue";

export default class CollectorClass
{

    constructor( core )
    {

        if( !CollectorClass.instance )
        {

            this.f = core.f()
            this.ftsmp = core.getFriendlyTimestamp()
            this.sanitizers = core.getSanitizers()
            this.logger = core.getLogger()
            this.tableCalculation = core.getTableCalculation()
            this.calculationHelper = new CalculationHelper( this.f, core.settings(), this.tableCalculation, core.getUuid() )
            this.eventManager = core.getEventManager()

            CollectorClass.instance = this

        }

        return CollectorClass.instance

    }

    /*eslint-disable*/
    _addColumns( idx, set, containerId, columns, totalcols )
    {

        for( let c in columns )
        {

            let col = columns[ c ]
            if( -1 < set.columns.indexOf( col.id ) )
            {
                totalcols.push( {
                    type      : col.type,
                    id        : idx + '_' + col.id,
                    originalId: col.id,
                    caption   : col.caption,
                    container : containerId
                } )
                idx++
            }
        }

        return idx

    }

    _getZeroTimeDates( dates )
    {

        let flattenedDates = []
        for( let d in dates )
        {

            let flatTime  = this.ftsmp.mysqlTimestamp( dates[ d ] ).split( / /g ),
                zeroed    = flatTime[ 0 ] + ' 00:00:00',
                flattened = this.ftsmp.timestampFromMysql( zeroed )

            if( -1 === flattenedDates.indexOf( flattened ) )
            {
                flattenedDates.push( flattened )
            }

        }
        return flattenedDates

    }

    _collectFromLists( original, dates, collection, totalcols, setup )
    {

        let listObjects   = [],
            zeroTimeDates = this._getZeroTimeDates( dates ),
            idx           = 0,
            nomerge       = [ 'columns', 'lists' ],
            listObject    = {}

        for( let k in Object.keys( original ) )
        {
            let key = Object.keys( original )[ k ]
            if( -1 === nomerge.indexOf( key ) )
            {
                listObject[ key ] = original[ key ]
            }
        }

        let timestamp = 0,
            update    = 0

        listObject.columns = []
        listObject.type = 'list'
        listObject.referenceKey = original.referenceKey
        listObject.masterListId = listObject.localId
        listObject._setup = setup

        if( timestamp < listObject.timestamp )
        {
            timestamp = listObject.timestamp
        }
        if( undefined !== listObject.update
            && update < listObject.update )
        {
            update = listObject.update
        }

        for( let c in totalcols )
        {
            listObject.columns.push( totalcols[ c ] )
        }

        for( let d in zeroTimeDates )
        {

            let list = JSON.parse( JSON.stringify( listObject ) )

            list.localId = idx + '-' + listObject.localId
            list.masterListId = listObject.localId
            list.referenceKey = listObject.referenceKey

            let tsmpMin = zeroTimeDates[ d ],
                tsmpMax = tsmpMin + ( 86400 * 1000 ),
                values  = {}

            list.timestamp = tsmpMin

            for( let c in collection )
            {

                let l = collection[ c ]
                if( l.list.timestamp >= tsmpMin && l.list.timestamp < tsmpMax )
                {

                    if( timestamp < l.list.timestamp )
                    {
                        timestamp = l.list.timestamp
                    }
                    if( undefined !== l.list.update
                        && update < l.list.update )
                    {
                        update = l.list.update
                    }

                    if( undefined !== l.list.scoreModel )
                    {
                        list.scoreModel = l.list.scoreModel
                        listObject.scoreModel = l.list.scoreModel
                    }

                    for( let cidx in totalcols )
                    {

                        if( undefined !== l.list.values && l.container === totalcols[ cidx ].container )
                        {

                            let original  = totalcols[ cidx ].originalId,
                                newId     = totalcols[ cidx ].id,
                                temp      = original.split( /_/g ),
                                nuIdx     = parseInt( temp.shift() ) + 1,
                                vals      = temp.join( '_' ),
                                temp2     = newId.split( /_/g ),
                                nuIdx2    = parseInt( temp2.shift() ) + 1,
                                vals2     = temp2.join( '_' ),
                                lookupKey = this.sanitizers.cleanId( vals + '___' + nuIdx ),
                                newKey    = this.sanitizers.cleanId( vals2 + '___' + nuIdx2 ),
                                keys      = Object.keys( l.list.values )

                            for( let k in keys )
                            {
                                let key = keys[ k ]

                                if( -1 < key.indexOf( lookupKey ) )
                                {

                                    let sub = key.split( /___/g )
                                    let studentId = sub.shift()
                                    values[ studentId + '___' + newKey ] = l.list.values[ key ]

                                }

                            }

                        }

                    }

                }

            }

            list.values = values
            listObjects.push( JSON.parse( JSON.stringify( list ) ) )

            idx++

        }

        listObject.timestamp = timestamp
        listObject.update = ( 0 < update ? update : undefined )
        listObject.lists = listObjects

        let listSummary = this.calculationHelper.calculateListSummary( listObject ),
            traversal   = [ 'averages', 'counts', 'defined', 'display', 'distincs', 'sums' ],
            idxCount    = 0

        if( null !== listSummary )
        {

            for( let h in listSummary.headTypes )
            {
                if( '___total' !== h )
                {
                    let combiKey = idxCount + '_' + h
                    listSummary.headTypes[ combiKey ] = listSummary.headTypes[ h ]
                    idxCount++
                }
            }

            for( let t in traversal )
            {
                for( let localId in listSummary.calculation )
                {
                    if( undefined !== listSummary.calculation[ localId ][ traversal[ t ] ] )
                    {
                        idxCount = 0
                        for( let c in listSummary.calculation[ localId ][ traversal[ t ] ] )
                        {
                            let combiKey = idxCount + '_' + c
                            listSummary.calculation[ localId ][ traversal[ t ] ][ combiKey ] = listSummary.calculation[ localId ][ traversal[ t ] ][ c ]
                            idxCount++
                        }
                    }
                }
            }


            let calculation = {
                summary: listSummary,
                columns: this.calculationHelper.calculateColumns( listObject ),
                details: {} //
            }

            listObject._tc = reactive( calculation )

        }

        return listObject

    }

    _referencedList( localId, cache, archive )
    {

        let cacheList   = cache.get( localId ),
            archiveList = archive.get( localId )

        if( cacheList || archiveList )
        {
            return cacheList || archiveList
        }

        /* perform deep lookup loop */
        for( const [ local, list ] of cache )
        {

            if( local === localId
                || localId === list.referenceKey )
            {
                return list
            }
            if( Array.isArray( list.lists )
                && 0 < list.lists.length )
            {
                for( let l in list.lists )
                {
                    if( list.lists[ l ].localId === localId || list.lists[ l ].referenceKey === localId )
                    {
                        return list
                    }
                }
            }
        }

        for( const [ local, list ] of archive )
        {
            if( local === localId
                || localId === list.referenceKey )
            {
                return list
            }
            if( Array.isArray( list.lists )
                && 0 < list.lists.length )
            {
                for( let l in list.lists )
                {
                    if( list.lists[ l ].localId === localId || list.lists[ l ].referenceKey === localId )
                    {
                        return list
                    }
                }
            }
        }

    }

    fromCollection( original, setup, cache, archive )
    {

        let idx       = 0,
            totalcols = [],
            dates     = [],
            matches   = []

        totalcols.push( original.columns[ 0 ] )

        for( let s in setup )
        {

            let set  = setup[ s ],
                test = this._referencedList( set.referenceKey, cache, archive )

            if( undefined !== test
                && 0 < test.lists.length )
            {

                idx = this._addColumns( idx, set, test.referenceKey, test.lists[ 0 ].columns, totalcols )
                for( let l in test.lists )
                {

                    let list = test.lists[ l ]
                    list._setup = setup

                    matches.push( {
                        container: test.referenceKey,
                        list     : list
                    } )
                    dates.push( list.timestamp )

                }

            }

        }

        let lists = this._collectFromLists( original, dates, matches, totalcols, setup )
        return lists

    }

    collect( localId, list, cache, archive )
    {

        let setup = []

        for( let c in list.columns )
        {
            let col = list.columns[ c ]
            if( 'setup' === col.type )
            {
                setup.push( {
                    localId     : col.setup.value.listId,
                    referenceKey: col.setup.value.listId,
                    columns     : col.setup.value.columns
                } )
            }
        }

        if( true !== list.collected )
        {
            list._setup = setup
        }

        let listObject = this.fromCollection( list, list._setup, cache, archive )
        listObject.collected = true

        for( let l in listObject.lists )
        {
            listObject.lists[ l ].archived = list.archived
            this.f.objectHash( listObject.lists[ l ] )
        }

        if( true === list.archived )
        {
            archive.set( localId, listObject )
        }
        else
        {
            cache.set( localId, listObject )
        }

        this.eventManager.dispatch( 'on-recollect-' + localId )

    }

}