export default class afterAllOrganizerContent
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
        this.f = core.f()
        this.store = core.getStore()
    }

    afterFetch( contents )
    {
        return new Promise( resolve =>
        {

            for( let c in contents )
            {

                for( let s in contents[ c ].slots )
                {
                    contents[ c ].slots[ s ].length = parseInt( contents[ c ].slots[ s ].length )
                }

                if( undefined === contents[ c ].slotDisplay
                    || 'create' === contents[ c ].slotDisplay )
                {

                    contents[ c ].slotDisplay = []
                    for( let s in contents[ c ].slots )
                    {
                        if( 1 === contents[ c ].slots[ s ].length )
                        {
                            let display = this.f.deref( contents[ c ].slots[ s ] )
                            display.displayTitle = contents[ c ].slots[ s ].title
                            contents[ c ].slotDisplay.push( display )
                        }
                        else
                        {
                            for( let i = 0; i < contents[ c ].slots[ s ].length; i++ )
                            {
                                let display = this.f.deref( contents[ c ].slots[ s ] )
                                display.displayTitle = contents[ c ].slots[ s ].title + ' <span class="organizer-content-count">(' + ( i + 1 ) + '/' + contents[ c ].slots[ s ].length + ')</span>'
                                contents[ c ].slotDisplay.push( display )
                            }
                        }
                    }

                }

            }

            return resolve( contents )

        } )
    }

    afterUpdate( contents, cache, skipArchived )
    {

        return new Promise( resolve =>
        {

            this.afterFetch( contents, skipArchived )
                .then( result =>
                {

                    return resolve( result )

                } )

        } )

    }

}