export default class ListSnapshotsClass
{

    constructor( core )
    {

        if( !ListSnapshotsClass.instance )
        {

            this.logger = core.getLogger()
            this.f = core.f()
            this.store = core.getStore()
            this.queueWorker = core.getQueueWorker()
            this.client = core.getClient()
            this.cryptoHelper = core.getCryptoHelper()
            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.eventManager = core.getEventManager()
            ListSnapshotsClass.instance = this

            this.logSign = 'Core::ListSnapshotsClass (LSNAP)'

            this.logger.cconstructed( this.logSign, 'list snapshots helper initialized' )

        }

        return ListSnapshotsClass.instance

    }

    createSnapshot( localId, values, keys )
    {

        let toEncrypt = {
            values: values
        }

        this.cryptoHelper
            .encryptElement( toEncrypt, undefined, keys )
            .then( result =>
            {

                if( false !== result
                    && null !== result )
                {

                    let message = {
                        method  : 'objects.createSnapshot',
                        id_local: localId,
                        object  : result.object,
                        keys    : JSON.stringify( result.keys )
                    }

                    let jobId = this.queueWorker.enqueueSocketMessage( message )
                    this.logger.clog( 'Core::ListSnapshotsClass', 'enqueued snapshot #' + jobId, 'for object #' + localId, 'encapsulating', keys.length, 'keys' )

                }

            } )


    }

    getAvailableSnapshots( localId )
    {

        return new Promise( resolve =>
        {

            let message = {
                method  : 'objects.getAvailableSnapshots',
                id_local: localId
            }

            this.client.request( message )
                .then( response =>
                {

                    let count = Array.isArray( response.result ) ? response.result.length : 0
                    this.logger.clog( this.logSign, 'got', count, 'snapshots for element...' )
                    if( 0 < count )
                    {
                        let list     = [],
                            promises = [],
                            hashes   = []

                        for( let r in response.result )
                        {

                            let pseudoElement = {
                                object: response.result[ r ].snapshot,
                                keys  : JSON.parse( response.result[ r ].snapshot_keys )
                            }
                            promises.push( () =>
                            {
                                return this.cryptoHelper.decrypt( pseudoElement )
                                           .then( result =>
                                           {
                                               if( false !== result )
                                               {

                                                   let snapshot = {
                                                           id               : response.result[ r ].id,
                                                           id_author        : response.result[ r ].id_author,
                                                           values           : result,
                                                           datetime_snapshot: this.friendlyTimestamp.convertServerTimestamp( response.result[ r ].datetime_snapshot )
                                                       },
                                                       hash     = this.f.objectHash( snapshot, true )

                                                   if( -1 === hashes.indexOf( hash ) )
                                                   {
                                                       hashes.push( hash )
                                                       list.push( this.f.deref( snapshot ) )
                                                   }

                                               }
                                           } )
                            } )

                            this.f.promiseRunner( promises )
                                .then( () =>
                                {

                                    return resolve( list )

                                } )

                        }

                    }
                    else
                    {
                        return resolve( [] )
                    }

                } )
                .catch( () =>
                {
                    return resolve( [] )
                } )

        } )
    }

}