<template>
    <div :class="'organizer-content hoverable '+( color || item.color )"
         :id="'printable-'+item.localId"
         :key="'organizer-content-'+item.localId">
        <div
            :class="'organizer-content-body '+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )"
            @click="handleClick()">
            <div class="title">
                {{ item.title }}
            </div>
            <div class="markerspace absolute right">
                <ReferenceMarkers :item="item"/>
            </div>
            <p :class="classList" v-html="$core.f().toHtml( item.description || 'keine Beschreibung vorhanden' )"></p>
            <div v-if="open" class="organizer-container" @click="$core.f().skip">
                <div class="line"></div>
                <h3>Planungseinheiten verteilen</h3>
                <p>Um die Planungseinheiten zu verteilen, klicke zunächst eine Einheit und danach die entsprechende
                    Position im Kalender an.<br/>
                    Du kannst die Planungseinheiten im Anschluss automatisch verteilen lassen, indem du einfach auf
                    die
                    Schaltfläche "Planungsreihe automatisch fortsetzen" klickst.</p>
                <div class="scrollablecontainer bordered inset height-200">
                    <!--<draggable v-model="slots"
                               group="draggery"
                               handle=".draggable">
                        <template #item="{ element, index }">
                            <div class="draggable">{{ element }} {{ index }}</div>
                        </template>
                    </draggable>-->
                    <div v-for="( slot, index ) in item.slotDisplay"
                         :class="'organizer-slot relative highlightable'+
                                     ( selected ? ( selected.index === index ? ' selected' : '' ) : '' )+
                                     ( null !== slot.slotIndex && undefined !== slot.slotIndex ? ' assigned' : '' )"
                         :key="item.localId+'-slot-'+index"
                         @mouseenter="highlighted = { reference: item.localId, slotIndex: slot.slotIndex, date: slot.date }"
                         @mouseleave="highlighted = false"
                         @click="handleSlotSelect( index )" v-html="slot.displayTitle"></div>
                </div>
                <div class="spacer"></div>
                <button v-if="!allAssigned" class="submit" @click="automaticPlacement">Planungsreihe automatisch
                    fortsetzen
                </button>
                <div class="line"></div>
                <div class="header">
                    <OrganizerFilter :className="undefined === item.organizerReference ? 'left' : 'hidden'"
                                     :value="item.organizerReference"
                                     @filter="setFilters"/>
                    <div class="clearfix"></div>
                </div>
                <template v-if="draggable">
                    <PlannerViewOrganizer :embedded="true"
                                          :slotSelection="selected"
                                          :changeKey="innerChangeKey"
                                          :filterList="filterList"
                                          :highlighted="highlighted"
                                          @markSlot="handleSlotMark"/>
                </template>
                <template v-else>
                    Wähle eine Planung aus, um deine Planungsreihe in deinen Planungskalender zu verschieben
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import draggable              from 'vuedraggable'
import ViewItemHandler        from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import LastEditor             from '@/components/elements/markers/LastEditor'
import ReferenceMarkers       from '@/components/elements/markers/ReferenceMarkers'
import MixinWatchElementKey   from "@/mixins/MixinWatchElementKey";
import MixinLivingTimestamps  from "@/mixins/MixinLivingTimestamps";
import MixinComponentCleanup  from "@/mixins/MixinComponentCleanup";
import PlannerViewOrganizer   from "@/components/elements/viewItems/plannerView/modes/PlannerViewOrganizer";
import OrganizerFilter        from "@/components/elements/filters/OrganizerFilter";
import MixinPlannerValidation from "@/components/elements/viewItems/plannerView/mixins/MixinPlannerValidation";
/*eslint-disable*/
export default {

    name      : 'OrganizerContent',
    components: { OrganizerFilter, PlannerViewOrganizer, ReferenceMarkers, LastEditor, draggable },
    extends   : ViewItemHandler,
    mixins    : [ MixinWatchElementKey, MixinLivingTimestamps, MixinComponentCleanup, MixinPlannerValidation ],

    props: {
        item          : { type: Object, required: true },
        changeKey     : { type: String, required: false },
        elementKey    : { type: String, required: false },
        selectedRemote: { type: Boolean, required: false, default: false }
    },

    emits: [ 'detailView', 'update' ],

    created()
    {

        this.eventIndex = this.$core.getEventManager().addIndexed( 'on-organizer-update', () =>
        {
            this.innerChangeKey = this.$core.getUuid().generate()
        } )
        this.openEventIndex = this.$core.getEventManager().addIndexed( 'on-organizer-content-open', ( localId ) =>
        {
            this.checkOpen( localId )
        } )

        this.prepare()

    },

    beforeUnmount()
    {
        this.$core.getEventManager().removeIndexedCallback( 'on-organizer-update', this.eventIndex )
        this.$core.getEventManager().removeIndexedCallback( 'on-organizer-content-open', this.openEventIndex )
    },

    watch: {
        changeKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== newValue )
                {
                    this.innerChangeKey = this.$core.getUuid().generate()
                    this.prepare()
                }
            }
        }
    },

    data()
    {
        return {
            innerChangeKey: false,
            highlighted   : false,
            filterList    : {},
            selected      : false,
            selectedBefore: false,
            unblurred     : false,
            open          : true,
            draggable     : false,
            color         : undefined,
            eventIndex    : false,
            openEventIndex: false,
            allAssigned   : false,
            slots         : [],
            mode          : 'manual'
        }
    },

    computed: {
        classList()
        {
            return ( !this.unblurred && this.$props.item.blurry ) ? 'blurred' : ''
        }
    },

    methods: {

        prepare()
        {

            this.slots = this.$core.f().deref( this.$props.item.slots )

            if( this.$core.f().isTruely( this.$props.item.useParentColor )
                && undefined !== this.$props.item.organizerReference )
            {
                let item = this.$core.getBaseClassHelper()
                               .get( 'organizer' )
                               .getById( this.$props.item.organizerReference )
                if( undefined !== item )
                {
                    this.color = item.color
                }
            }

            this.allAssigned = true

            for( let s in this.$props.item.slots )
            {
                if( null === this.$props.item.slots[ s ].slotIndex || undefined === this.$props.item.slots[ s ].slotIndex )
                {
                    this.allAssigned = false
                }
            }

        },

        reset()
        {
            this.selected = false
            this.highlighted = false
            this.innerChangeKey = this.$core.getUuid().generate()
        },

        handleClick()
        {
            this.$core.getEventManager().dispatchIndexed( 'on-organizer-content-open', this.$props.item.localId )
            this.$nextTick()
                .then( () =>
                {
                    this.open = !this.open
                } )
        },

        checkOpen( localId )
        {
            if( this.$props.item.localId !== localId )
            {
                this.open = false
            }
        },

        setFilters( type, filters )
        {

            if( undefined === filters )
            {
                delete this.filterList[ type ]
                this.filterList[ type ] = []
            }
            else
            {
                this.filterList[ type ] = filters
            }

            this.draggable = ( undefined !== this.filterList.organizer
                               && this.filterList.organizer.length === 1 )
            this.innerChangeKey = this.$core.getUuid().generate()

        },

        handleSlotSelect( index )
        {

            if( !this.selected
                || ( undefined !== this.selected && this.selected.index !== index ) )
            {
                this.selected = this.$core.f().deref( this.$props.item.slotDisplay[ index ] )
                this.selected.index = index
                this.selectedBefore = index
            }
            else
            {
                if( false !== this.selectedBefore )
                {
                    this.handleSlotMark( undefined, this.selectedBefore )
                    this.selectedBefore = false
                }
                this.selected = false
            }

        },

        storeClone( clone )
        {

            this.$core.getEventManager()
                .append( 'storable-after-updated-' + clone.localId, () =>
                {
                    this.reset()
                    this.$emit( 'update' )
                    this.$core.getEventManager().dispatchIndexed( 'on-organizer-update' )
                } )

            this.$core.getBaseClassHelper()
                .get( 'organizercontent' )
                .update( clone, clone.localId, clone.remoteId, clone.timestamp, clone.localKey, undefined, false, false )

            this.highlighted = false

        },

        handleSlotMark( date, slotIndex )
        {

            let clone = this.$core.f().deref( this.$props.item )
            if( undefined !== date )
            {
                clone.slotDisplay[ this.selected.index ].slotIndex = slotIndex
                clone.slotDisplay[ this.selected.index ].date = date.dateObject.getTime()
            }
            else
            {
                clone.slotDisplay[ this.selected.index ].slotIndex = null
                clone.slotDisplay[ this.selected.index ].date = undefined
            }
            clone.organizerReference = this.filterList.organizer[ 0 ].value

            this.storeClone( clone )

        },

        automaticPlacement()
        {

            let slots        = this.$props.item.slotDisplay,
                clone        = this.$core.f().deref( this.$props.item ),
                itemsPerWeek = 0,
                itemCount    = 0,
                starts       = [],
                indexes      = []

            for( let s in slots )
            {
                if( undefined !== slots[ s ].date && null !== slots[ s ].date )
                {
                    starts[ parseInt( s ) ] = slots[ s ].date
                    indexes[ parseInt( s ) ] = slots[ s ].slotIndex
                }
            }

            itemsPerWeek = starts.length

            for( let s in slots )
            {

                let i          = parseInt( s ),
                    weekNumber = 0

                while( i >= itemsPerWeek )
                {
                    i -= itemsPerWeek
                    weekNumber++
                }

                if( undefined === slots[ s ].date || null === slots[ s ].date )
                {

                    let newDate = new Date( starts[ i ] )
                    newDate.setDate( newDate.getDate() + ( weekNumber * 7 ) )

                    clone.slotDisplay[ s ].date = newDate.getTime()
                    clone.slotDisplay[ s ].slotIndex = indexes[ i ]

                }

            }

            this.storeClone( clone )

        }

    }

}
</script>