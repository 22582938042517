<template>
    <div :class="'group hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :key="'team-'+item.localId" @click="$emit( 'clicked' )">
        <div class="groupname">
            {{ item.teamname }}
        </div>
        <div v-if="ready" class="groupcount">
            <strong>{{ colleagueCount }}</strong> {{ $core.t( 'generic-colleagues' ) }}
        </div>
        <div v-else class="groupcount syncing">
            {{ $core.t( 'generic-colleagues' ) }} {{ $core.t( 'are-being-initialized' ) }}
        </div>
    </div>
</template>

<script>
import MixinWatchElementKey from "@/mixins/MixinWatchElementKey";
import MixinCachePreheater  from "@/mixins/MixinCachePreheater";

export default {

    name : 'Team',
    props: {
        item      : { type: Object, required: true },
        selected  : { type: Boolean, required: false, default: false },
        elementKey: { type: String, required: false, default: '' }
    },

    emits : [ 'clicked' ],
    mixins: [ MixinWatchElementKey, MixinCachePreheater ],

    data()
    {
        return {
            neededCaches  : [ 'colleague' ],
            colleagueCount: 0,
            ready         : false
        }
    },

    created()
    {

        this.awaitNeededCaches()
            .then( () => {
                this.prepare()
            })

    },

    watch: {
        '$core.getBaseClassHelper().get( "colleague" ).registry.cache.elementKey': {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.prepare()
                }
            }
        }
    },

    methods: {
        prepare()
        {

            let count      = 0,
                colleagues = this.$core.cc().getAll()

            for( let s in this.$props.item.colleagues )
            {
                let colleagueUuid = this.$props.item.colleagues[ s ]
                for( let c in colleagues )
                {
                    if( colleagues[ c ].uuid === colleagueUuid )
                    {
                        count++
                    }
                }
            }

            this.colleagueCount = count
            this.ready = true

        }
    }

}
</script>