import AbstractShadowCopyClass
    from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/abstract/AbstractShadowCopyClass";

export default class CopyList extends AbstractShadowCopyClass
{

    constructor( parent )
    {

        super( parent, false, 'list', 'CopyList' )
        return this

    }

    checkVersionDeletions( element, copyElement )
    {

        for( let l in copyElement.lists )
        {

            let found = false
            for( let e in element.lists )
            {
                if( element.lists[ e ].localId === copyElement.lists[ l ].shadowedId )
                {
                    found = true
                    break
                }
            }

            if( !found )
            {

                let copy = this.parent.baseClassHelper.get( 'shadowCopy' )
                               .getShadowCopyByReferenceLocalId( copyElement.lists[ l ].shadowedId )

                if( undefined !== copy )
                {

                    let deletion = copy
                    copy.shadowLocalId = copy.localId
                    deletion.action = 'delete'

                    return deletion

                }

            }

        }

        return null

    }

    checkVersionCreate( element, copyElement )
    {

        for( let e in element.lists )
        {

            let found     = false,
                reference = copyElement.lists[ 0 ].referenceKey

            for( let l in copyElement.lists )
            {
                if( element.lists[ e ].localId === copyElement.lists[ l ].shadowedId )
                {
                    found = true
                    break
                }
            }

            if( !found )
            {

                return {
                    action   : 'create-shadow',
                    element  : element.lists[ e ],
                    reference: reference
                }

            }

        }

        return null

    }

    checkListVersions( result, shadowCopy )
    {
        return new Promise( resolve =>
        {

            let element = this.parent.baseClassHelper.get( this.type )
                              .getById( shadowCopy.referenceLocalId )

            if( undefined !== element
                && undefined !== element.lists )
            {

                let copyElement = this.parent.baseClassHelper.get( this.type )
                                      .getShadowCopyById( 'shadowRef-' + shadowCopy.referenceLocalId )

                if( undefined !== copyElement
                    && undefined !== copyElement.lists )
                {
                    if( element.lists.length !== copyElement.lists.length )
                    {

                        let response = this.checkVersionDeletions( element, copyElement )
                        if( null !== response )
                        {
                            return resolve( response )
                        }

                        response = this.checkVersionCreate( element, copyElement )
                        if( null !== response )
                        {

                            return resolve( response )

                        }

                    }
                }

            }

            return resolve( result )

        } )
    }

    createStudentMap( studentList )
    {

        let studentMap = new Map()

        for( let s in studentList )
        {
            studentMap.set( 'shad-' + studentList[ s ] + '-' + this.parent.f.hashCyrB53( studentList[ s ] ), studentList[ s ] )
        }

        return studentMap

    }

    /*eslint-disable*/
    checkValueMerge( original, copyElement )
    {

        if( undefined === original
            || undefined === original.columns
            || null === copyElement
            || undefined === copyElement )
        {
            return false
        }

        let filterBy    = original.columns[ 0 ].filterBy,
            studentList = [],
            changes     = false

        if( 'all' === filterBy )
        {
            let tempList = this.parent.baseClassHelper.get( 'student' )
                               .getCache( 'cache' )
            for( const [ t, student ] of tempList )
            {
                studentList.push( student.localId )
            }
        }
        else
        {
            studentList = this.parent.baseClassHelper.getObjectById( filterBy ).students
        }

        let studentMap = this.createStudentMap( studentList )

        for( let v in copyElement.values )
        {

            let value          = copyElement.values[ v ],
                originalValue  = undefined,
                temp           = v.split( '___' ),
                studentLocalId = temp.shift(),
                valueKey       = temp.join( '___' ),
                originalId     = studentMap.get( studentLocalId )

            if( undefined !== originalId )
            {

                for( let vv in original.values )
                {
                    if( -1 < vv.indexOf( originalId )
                        && -1 < vv.indexOf( valueKey ) )
                    {

                        originalValue = original.values[ vv ]
                        break

                    }
                }

                if( value !== originalValue )
                {
                    original.values = original.values || {}
                    original.values[ originalId + '___' + valueKey ] = value
                    changes = true
                }

            }
        }

        return changes

    }

    performValueMerge( original )
    {
        this.parent.baseClassHelper.get( this.type )
            .update( original, original.localId, original.remoteId, original.timestamp, original.localKey )
    }

    _finalizeCheck( result, shadowCopy )
    {
        return new Promise( resolve =>
        {
            if( undefined === result.action )
            {
                this.checkListVersions( result, shadowCopy )
                    .then( checkResult =>
                    {
                        return resolve( checkResult )
                    } )
            }
            else
            {
                return resolve( result )
            }
        } )
    }

    /*eslint-disable*/
    check( shadowCopy )
    {

        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( this.type )
                .cacheHeatup()
                .then( () =>
                {

                    let shadowLocalId      = 'shad-'
                                             + shadowCopy.referenceLocalId + '-'
                                             + this.parent.f.hashCyrB53( shadowCopy.studentLocalId ),
                        copyElement        = null,
                        original           = this.parent.baseClassHelper.get( this.type ).findListById( shadowCopy.referenceLocalId, 'cache' ),
                        originalNeedsMerge = false

                    for( let [ key, list ] of this.parent.baseClassHelper.get( this.type ).registry.shadowCopies )
                    {

                        if( undefined !== key
                            && undefined !== list.lists )
                        {
                            for( let l in list.lists )
                            {
                                if( list.lists[ l ].localId === shadowLocalId )
                                {
                                    copyElement = list.lists[ l ]

                                }
                            }
                        }
                    }

                    if( true === shadowCopy.studentEditable )
                    {
                        originalNeedsMerge = this.checkValueMerge( original, copyElement )
                    }

                    let result = {
                        shadowLocalId: shadowLocalId,
                        localId      : shadowCopy.localId,
                        action       : undefined,
                        type         : this.type,
                        original     : original
                    }

                    if( undefined !== original )
                    {
                        if( null !== copyElement )
                        {
                            if( ( original.update > 0 && undefined === copyElement.update )
                                || original.update > copyElement.update )
                            {
                                result.action = 'update'
                                result.copy = shadowCopy
                                result.original = original
                            }
                        }
                        else
                        {
                            result.action = 'create'
                            result.copy = shadowCopy
                        }
                    }

                    if( true === originalNeedsMerge )
                    {
                        this.performValueMerge( original )
                        return resolve( this._finalizeCheck( result, shadowCopy ) )
                    }
                    else
                    {
                        return resolve( this._finalizeCheck( result, shadowCopy ) )
                    }

                } )

        } )

    }

    _patchValues( shadowCopy, access )
    {
        let values    = shadowCopy.values,
            newValues = {}

        for( let v in values )
        {
            if( v.substring( 0, 36 ) === access.studentLocalId )
            {
                newValues[ v ] = values[ v ]
            }

        }

        shadowCopy.values = newValues

    }

    create( job )
    {

        return new Promise( ( resolve, reject ) =>
        {

            if( undefined === job.access )
            {
                return reject( 'INVALID' )
            }

            this.prepareShadowCopyElement( job )
                .then( shadowCopy =>
                {

                    shadowCopy.referenceKey = 'shadowRef-' + shadowCopy.referenceKey

                    this._patchValues( shadowCopy, job.access )

                    this.encryptShadowCopyElement( job, shadowCopy )
                        .then( result =>
                        {

                            return resolve( { plain: shadowCopy, db: result } )

                        } )
                        .catch( () =>
                        {
                            return reject()
                        } )

                } )

        } )

    }

}

/***

   YIN-AÇI-J8EU8PPA
   )Zfou.lU.F=WQj5K

 */