<template>
    <th v-for="( head, index ) in headers"
        :class="( fixed ? 'fixed ' : '' )+color"
        :key="'th-calc-'+index+'-'+viewKey">
        <span class="rotated" v-html="head.label"></span>
        <template v-if="null !== colSymbols[index]">
            <br/><span class="score-heading" @click="$emit('table-head-clicked')" v-html="colSymbols[index]"></span>
        </template>
    </th>
</template>

<script>
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup.vue";

export default {

    name : "CalculationTableHeaders",
    props: {
        list   : { Type: Object, required: true },
        viewKey: { Type: String, required: true },
        fixed  : { Type: Boolean, required: false, default: false }
    },

    emits : [ 'table-head-clicked', 'count' ],
    mixins: [ MixinComponentCleanup ],

    data()
    {
        return {
            headers        : [],
            ready          : false,
            width          : 0,
            maxCaptionWidth: 10,
            colSymbols     : []
        }
    },

    computed: {
        color()
        {
            return this.$props.list.color
        }
    },

    mounted()
    {
        this.getMaxCaptionWidth()
        this.prepare()
    },

    watch: {
        viewKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.prepare()
                }
            }
        }
    },

    methods: {


        getMaxCaptionWidth()
        {

            let style = getComputedStyle( document.body )
                .getPropertyValue( '--max-column-header-height' )

            if( null !== style )
            {
                this.maxCaptionWidth = parseInt( style )
            }
            else
            {
                this.maxCaptionWidth = 20
            }

        },

        prepare()
        {

            this.headers = []
            let list = undefined !== this.$props.list.lists ? this.$props.list.lists[ 0 ] : this.$props.list
            if( this.$core.f().valid( list.calculatedColumns )
                && 'create' !== list.calculatedColumns )
            {

                for( let c in list.calculatedColumns )
                {
                    this.headers.push( {
                        type : list.calculatedColumns[ c ].calcType,
                        label: this.prepareCaption( list.calculatedColumns[ c ].label )
                    } )
                    this.colSymbols.push( this.getColumnSymbol( list.calculatedColumns[ c ].calcType ) )
                }

                this.$emit( 'count', list.calculatedColumns.length )

            }

        },

        getColumnSymbol( type )
        {
            switch( type )
            {
                case 'sum':
                    return '&nbsp;&nbsp;&nbsp;&sum;&nbsp;&nbsp;&nbsp;'
                case 'average':
                    return '&nbsp;&nbsp;&nbsp;&oslash;&nbsp;&nbsp;&nbsp;'
                case 'percentage':
                    return '&nbsp;&nbsp;&nbsp;%&nbsp;&nbsp;&nbsp;'
                default:
                    return null
            }
        },

        prepareCaption( captionHtml )
        {

            let w = this.getCaptionWidth( captionHtml )

            if( this.maxCaptionWidth > w )
            {
                return captionHtml
            }
            else
            {

                let parts = captionHtml.split( / /g ),
                    rows  = [],
                    row   = []

                while( 0 < parts.length )
                {

                    let field      = parts.shift(),
                        widthRow   = this.getCaptionWidth( row.join( ' ' ).trim() ),
                        widthField = this.getCaptionWidth( field.trim() )

                    if( this.maxCaptionWidth >= widthRow
                        && this.maxCaptionWidth >= ( widthRow + widthField ) )
                    {
                        row.push( field )
                    }
                    else if( this.maxCaptionWidth >= widthRow
                             && this.maxCaptionWidth < ( widthRow + widthField ) )
                    {
                        if( 0 < row.length )
                        {
                            rows.push( row.join( ' ' ) )
                            row = []
                            row.push( field )
                        }
                        else
                        {
                            rows.push( field )
                        }
                    }
                    else if( this.maxCaptionWidth <= widthRow )
                    {
                        rows.push( row.join( ' ' ) )
                        row = []
                    }

                }

                if( 0 < row.length )
                {
                    rows.push( row.join( ' ' ) )
                }

                return rows.reverse().join( '<br/>' )

            }

        },

        getCaptionWidth( captionHtml )
        {

            let span = document.createElement( 'span' )
            span.innerHTML = captionHtml
            return ( '' + span.innerText ).length

        }

    }

}
</script>