export default class TestScoreCalculation
{
    constructor( core )
    {

        if( !TestScoreCalculation.instance )
        {

            this.logger = core.getLogger()
            this.sanitizers = core.getSanitizers()
            this.translation = core.getTranslation()
            this.reformatter = core.getReformatter()
            this.f = core.f()

            this.getObjectById = ( which ) =>
            {
                return core.getBaseClassHelper().getObjectById( which )
            }

            TestScoreCalculation.instance = this

        }

        return TestScoreCalculation.instance

    }

    destruct()
    {
        delete TestScoreCalculation.instance
    }

    _modelIdxByVal( model, val )
    {

        for( let m in model )
        {
            if( model[ m ].number == val )
            {
                return m
            }
        }

        //FALLBACK
        for( let m in model )
        {
            if( parseInt( model[ m ].number ) === parseInt( val ) )
            {
                return m
            }
        }

    }

    /*eslint-disable*/
    calculateScoreByModel( points, distribution, model )
    {

        let result = 'N/A'

        if( undefined !== distribution )
        {

            let modelDefinition = this.translation.translate( 'scoreModels' )[ model ],
                dist            = distribution.split( ',' ),
                step            = 0,
                found           = false

            if( null !== points )
            {

                switch( this.translation.translate( 'scoreModelValidationDirection' )[ model ] )
                {
                    case 'ascending':
                        while( !found && step < dist.length )
                        {
                            if( parseFloat( dist[ step ] ) <= points )
                            {
                                found = true
                                result = modelDefinition[ step ]
                            }
                            if( !found )
                            {
                                step++
                            }
                        }
                        break
                    case 'descending':
                        while( step < dist.length )
                        {
                            if( points >= parseFloat( dist[ step ] ) )
                            {
                                result = modelDefinition[ '' + step ]
                            }
                            step++
                        }
                        break
                }

            }

        }

        return result

    }

    studentExists( localId )
    {
        return undefined !== this.getObjectById( localId )
    }

    calculate( list, items )
    {

        // TEST
        if( undefined !== list.distribution )
        {

            let dist         = list.distribution.split( ',' ),
                distribution = []

            for( let d in dist )
            {
                if( 'RETRIGGER' !== dist[ d ] )
                {
                    distribution.push( dist[ d ] )
                }
            }

            let selectedModel = list.scoreModel || 'basic',
                model         = this.translation.translate( 'scoreModels' )[ selectedModel ],
                avgByValue    = undefined !== model.calc && 'byValue' === model.calc.average

            if( undefined === items )
            {
                items = []
                for( let c in list.columns )
                {
                    if( 'testItem' === list.columns[ c ].type )
                    {
                        items.push( { idx: c, caption: list.columns[ c ].caption, score: list.columns[ c ].score } )
                    }
                }
            }

            let scores       = {},
                summary      = {},
                total        = 0,
                count        = 0,
                grandAverage = 0,
                grandTotal   = 0

            for( let v in list.values )
            {

                let temp = v.split( '___' )
                if( this.studentExists( temp[ 0 ] ) )
                {
                    scores[ temp[ 0 ] ] = {
                        count   : 0,
                        total   : parseFloat( '0' ),
                        complete: false
                    }
                }

            }

            for( let s in scores )
            {
                for( let i in items )
                {

                    let valueKey = s + '___' + this.sanitizers.cleanId( items[ i ].caption ) + '___' + items[ i ].idx
                    if( this.f.valid( list.values[ valueKey ] ) )
                    {
                        scores[ s ].count++
                        scores[ s ].total += this.reformatter.reformat( list.values[ valueKey ], 'float' )
                    }
                }

                scores[ s ].total = this.reformatter.reformat( scores[ s ].total, 'float' )
                scores[ s ].complete = scores[ s ].count === items.length

                if( scores[ s ].complete )
                {

                    count++
                    grandTotal += scores[ s ].total

                    let last = 0
                    let found = false

                    for( let d in distribution )
                    {

                        if( undefined === distribution[ d ] || '' === distribution[ d ] || null === distribution[ d ] )
                        {
                            distribution[ d ] = 0
                        }

                        if( scores[ s ].total >= parseFloat( distribution[ d ] )
                            && ( parseFloat( distribution[ d ] ) > last || !found ) )
                        {

                            last = parseFloat( distribution[ d ] )
                            found = true

                            scores[ s ].score = d
                            scores[ s ].number = model[ d ].number
                            scores[ s ].label = undefined !== model[ d ].short ? model[ d ].short : undefined
                            scores[ s ].translation = model[ d ].translation

                        }

                    }

                    if( avgByValue )
                    {
                        total += parseInt( undefined !== scores[ s ] ? scores[ s ].score : 0 )
                    }
                    else
                    {
                        total += parseFloat( undefined !== scores[ s ] ? scores[ s ].number : 0 )
                    }
                }
            }

            for( let d in distribution )
            {

                summary[ d ] = {
                    number     : model[ d ].number,
                    translation: model[ d ].translation,
                    count      : 0
                }

                for( let s in scores )
                {
                    if( scores[ s ].number === model[ d ].number )
                    {
                        summary[ d ].count++
                    }
                }

            }

            let averageOut = ( total === 0 && count === 0 ? 0 : ( total / count ) )

            if( avgByValue )
            {

                if( 0 < count )
                {

                    let avg = '' + averageOut.toFixed( 1 )
                    let tenTemp = avg.split( '.' )
                    let major = parseInt( tenTemp[ 0 ] )

                    averageOut = model[ major ].translation

                    let minor = parseInt( tenTemp[ 1 ] )
                    if( minor > 4 )
                    {
                        averageOut += ' - ' + model[ ( major + 1 ) ].translation
                    }
                }
                else
                {
                    averageOut = 'n/a'
                }

            }

            grandAverage = 0 < count ? ( grandTotal / count ) : 0

            return {
                summary     : summary,
                scores      : scores,
                average     : averageOut,
                averageScore: grandAverage,
                count       : count,
                avgByValue  : avgByValue
            }
        } // FLEX
        else
        {

            /*eslint-disable*/

            let selectedModel = list.scoreModel || 'basic'
            let model = this.translation.translate( 'scoreModels' )[ selectedModel ]

            let scores = {}
            let summary = {}
            let total = 0
            let count = 0
            let scoreColumn = false

            for( let c in list.columns )
            {
                if( list.columns[ c ].type.substr( 0, 8 ) === 'scoreBox'
                    && false === scoreColumn )
                {
                    scoreColumn = list.columns[ c ]
                    let temp = scoreColumn.originalId ? scoreColumn.originalId.split( '_' ) : scoreColumn.id.split( '_' )
                    let idx = parseInt( temp.shift() ) + 1
                    scoreColumn.key = temp.join( '_' ) + '___' + idx
                }
            }

            if( false !== scoreColumn )
            {
                for( let v in list.values )
                {
                    let val = list.values[ v ]

                    if( -1 < v.indexOf( scoreColumn.key ) )
                    {
                        count++
                        let modelIdx = this._modelIdxByVal( model, val )

                        if( undefined !== modelIdx )
                        {

                            let countVal = model[ modelIdx ].number

                            if( undefined === scores[ countVal ] )
                            {
                                scores[ countVal ] = {
                                    count   : 0,
                                    number  : countVal,
                                    total   : 0,
                                    complete: true
                                }
                            }

                            scores[ countVal ].count++
                            scores[ countVal ].total += parseFloat( undefined !== model[ modelIdx ].calc ? model[ modelIdx ].calc : parseInt( model[ modelIdx ].number ) )
                            total += parseFloat( undefined !== model[ modelIdx ].calc ? model[ modelIdx ].calc : parseInt( model[ modelIdx ].number ) )

                        }
                    }
                }
            }


            for( let d in model )
            {
                summary[ d ] = {
                    number     : model[ d ].number,
                    translation: model[ d ].translation,
                    count      : 0
                }
                for( let s in scores )
                {
                    if( scores[ s ].number == model[ d ].number )
                    {
                        summary[ d ].count = scores[ s ].count
                    }
                }
            }

            return {
                summary     : summary,
                scores      : scores,
                average     : ( total === 0 && count === 0 ? 0 : ( total / count ) ),
                averageScore: undefined,
                count       : count
            }

        }

    }

}