<template>
    <div>
        <template v-if="ready">
        <span v-if="$core.f().isset( refItem )"
              :class="'reference-marker group '+refItem.color+( additionalClass ? ' '+additionalClass : '' )"
              :key="'refmark-class-'+refItem.localId" @click="handleDetailViewAndSkip( $event, refItem )">
            {{ refItem.groupname }}
        </span>
        </template>
        <template v-else>
            <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
                <SyncingReferenceMarker/>
            </transition>
        </template>
    </div>
</template>

<script>

import SyncingReferenceMarker  from "@/components/elements/markers/SyncingReferencesMarker";
import MixinCacheStateReporter from "@/mixins/MixinCacheStateReporter";
import MixinDetailViews        from "@/mixins/MixinDetailViews";

export default {

    name      : 'GroupReferenceMarker',
    components: { SyncingReferenceMarker },
    mixins    : [ MixinCacheStateReporter, MixinDetailViews ],
    props     : {
        reference      : { Type: String, required: true },
        additionalClass: { Type: String, required: false }
    },

    data()
    {
        return {
            ready       : false,
            cacheState  : 'group',
            refItem     : false,
            clickBlocked: false
        }
    }

}
</script>