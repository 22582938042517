<script>
import TutorialElementsHelper from "@/classes/Helpers/TutorialElements/TutorialElementsHelper";

export default {

    name: "MixinTutorialElements",

    mounted()
    {
        this.tutorialHelper = new TutorialElementsHelper( this.$core )
        this.tutorialHelper.prepare( this.fake )
    },

    beforeUnmount()
    {
        this.tutorialHelper.cleanup()
        this.tutorialHelper = null
    }

}
</script>