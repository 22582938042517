export default class MoveToClassHelper
{

    constructor( parent )
    {
        this.parent = parent
    }

    execute( updates )
    {
        return new Promise( resolve =>
        {

            let removals   = {},
                additions = {}

            for( let u in updates )
            {
                if( undefined !== updates[ u ].element.classId )
                {
                    removals[ updates[ u ].element.classId ] = removals[ updates[ u ].element.classId ] || []
                    removals[ updates[ u ].element.classId ].push( updates[ u ].element.localId )
                }
                additions[ updates[ u ].value ] = additions[ updates[ u ].value ] || []
                additions[ updates[ u ].value ].push( updates[ u ].element.localId )
            }

            let baseClass = this.parent.getBaseClassHelper()
                                .get( 'class' )

            for( let r in removals )
            {

                let classElement = baseClass.getById( r ),
                    studentIds = removals[r]

                for( let s in studentIds )
                {
                    this.parent.f.removeFromArray( classElement.students, studentIds[s] )
                }

                baseClass.update( classElement )

            }

            for( let a in additions )
            {

                let classElement = baseClass.getById( a ),
                    studentIds = additions[a]

                for( let s in studentIds )
                {
                    classElement.students.push( studentIds[s] )
                }

                baseClass.update( classElement )

            }

            let studentBaseClass = this.parent.getBaseClassHelper()
                                       .get( 'student' )

            for( let a in additions )
            {
                let studentIds = additions[a]
                for( let s in studentIds )
                {
                    studentBaseClass.refreshCache( studentIds[s] )
                }
            }

            setTimeout( () => {

                return resolve()

            }, 500 )

        } )
    }

}