export default class ObjectTriggerHandler
{
    constructor( core, parent )
    {

        if( !ObjectTriggerHandler.instance )
        {

            this.core = core

            this.objectRegistryGetById = ( which ) =>
            {
                return this.core.objectRegistryGetById( which )
            }
            this.baseClass = ( which ) =>
            {
                return this.core.baseClass( which )
            }

            this.parent = parent

            this.f = core.f()
            this.core = core
            this.storeClass = core.getStore()
            this.eventManager = core.getEventManager()
            this.cryptoHelper = core.getCryptoHelper()
            this.logger = core.getLogger()
            this.database = core.getDatabase()
            this.notificationHelper = false //core.getNotificationHelper()

            this.triggerTimer = null
            this.triggerList = {}

            this.objectRegistry = null
            this.colleagues = {}

            this.ready = false
            this.initializeHelpers()
            this.storables = this.initializeStorables()

            ObjectTriggerHandler.instance = this

        }

        return ObjectTriggerHandler.instance

    }

    initializeStorables()
    {
        let create = {}

        return create
    }

    initializeHelpers()
    {

        if( !this.cryptoHelper )
        {
            this.cryptoHelper = this.core.getCryptoHelper()
        }

        this.ready = true

    }

    prepareKeys( keys )
    {

        let remoteKeys = []
        for( let k in keys )
        {
            remoteKeys.push( {
                uuid: keys[ k ].uuid,
                key : keys[ k ].secret
            } )
        }

        return remoteKeys

    }

    testDecrypt( rawObject, returnObject )
    {

        return new Promise( resolve =>
        {

            let crypted  = rawObject.object,
                localKey = this.cryptoHelper.getLocalKey( rawObject.keys )

            this.cryptoHelper.decryptElement( localKey, crypted )
                .then( object =>
                {

                    return resolve( !returnObject ? this.f.valid( object ) : object )

                } )

        } )

    }

    testDecryptAndNewer( rawObject, localObject )
    {

        return new Promise( resolve =>
        {

            let crypted  = rawObject.object,
                localKey = this.cryptoHelper.getLocalKey( rawObject.keys )

            this.cryptoHelper.decryptElement( localKey, crypted )
                .then( object =>
                {

                    if( this.f.valid( object )
                        && object.update > localObject.update )
                    {

                        return resolve( this.f.valid( object ) )

                    }
                    else
                    {
                        return resolve( false )
                    }

                } )

        } )

    }

    _afterStore( type, localId )
    {
        this.eventManager.dispatch( 'on-mxregistry-update', {
            type   : type,
            localId: localId
        } )
    }

    _handleTriggers()
    {

        this.core.objectRegistryQueueForType()
            .then( queueForType =>
            {

                const ordered = Object.keys( this.triggerList ).sort().reduce(
                    ( obj, key ) =>
                    {
                        obj[ key ] = this.triggerList[ key ]
                        return obj
                    },
                    {}
                )

                for( let k in ordered )
                {
                    this.eventManager.dispatch( 'on-mxregistry-full-refresh', queueForType[ k ] )
                }
                this.triggerTimer = null
                this.triggerList = {}

            } )

    }

    _mark( data )
    {
        if( undefined === this.triggerList[ data.payload.type ] )
        {
            this.triggerList[ data.payload.type ] = 0
        }
        this.triggerList[ data.payload.type ] += 1
        if( null !== this.triggerTimer )
        {
            clearTimeout( this.triggerTimer )
            this.triggerTimer = null
        }
        this.triggerTimer = setTimeout( () =>
        {
            this._handleTriggers()
        }, 5000 )
    }

    /*eslint-disable*/
    store( data )
    {

        return
        /*
        if( this.ready )
        {

            console.log( 'TRG', 'STILL WORKING' )

            this._mark( data )
            let rawObject = data.payload
            let localId = rawObject.id_local

            this.objectRegistryGetById( localId )
                .then( localObject =>
                {

                    if( undefined !== localId
                        && localId === this.core.getState( 'editing-object' ) )
                    {
                        this.logger.clog( 'TriggerHandlers::ObjectTriggerHandler:store', 'not dispatching store on object while editor is still open...' )
                        return
                    }

                    if( this.f.valid( localObject ) )
                    {
                        this.update( {
                            payload: localId
                        } )
                    }
                    else
                    {

                        this.testDecrypt( rawObject, true )
                            .then( decrypted =>
                            {

                                if( false !== decrypted )
                                {

                                    let remoteObject = {
                                        keys                 : this.prepareKeys( rawObject.keys ),
                                        remoteUpdateTimestamp: new Date( rawObject.datetime_updated ).getTime(),
                                        idOwner              : rawObject.id_owner,
                                        object               : rawObject.object,
                                        remoteId             : rawObject.id
                                    }

                                    this.database.writeObject( remoteObject, rawObject.id_local, rawObject.type )
                                        .then( () =>
                                        {

                                            if( undefined !== decrypted.referenceKey )
                                            {
                                                this.database.writeReference( localId, decrypted.referenceKey )
                                                    .then( () =>
                                                    {
                                                        this._afterStore( rawObject.type, localId )
                                                    } )
                                            }
                                            else
                                            {
                                                this._afterStore( rawObject.type, localId )
                                            }

                                        } )

                                }

                            } )

                    }

                } )

        }
        else
        {
            setTimeout( () =>
            {

                this.store( data )

            }, 500 )
        }
        */
    }

    updateKeySet( localObject, keys )
    {

        return new Promise( resolve =>
        {

            this.database.readObject( localObject.localId )
                .then( object =>
                {

                    let dbObject = object.object
                    let newKeys = []
                    for( let i in keys )
                    {
                        newKeys.push( {
                            uuid: keys[ i ].uuid,
                            key : keys[ i ].secret
                        } )
                    }

                    let remoteObject = {
                        remoteUpdateTimestamp: dbObject.remoteUpdateTimestamp,
                        keys                 : newKeys,
                        idOwner              : localObject.idOwner,
                        object               : dbObject.object,
                        remoteId             : dbObject.remoteId
                    }

                    this.database.writeObject( remoteObject, localObject.localId, localObject.type )
                        .then( () =>
                        {
                            this.eventManager.dispatch( 'on-mxregistry-update', {
                                type   : localObject.type,
                                localId: localObject.localId
                            } )

                        } )

                } )

        } )

    }

    performUpdate( localId, object, localObject )
    {

        if( object.update > localObject.update
            || ( object.update !== undefined && localObject.update === undefined ) )
        {

            for( let key in object )
            {
                localObject[ key ] = object[ key ]
            }

            let storable = this.baseClass( localObject.type )
            this.eventManager.append( 'on-queue-done-' + localId, () =>
            {

                this.eventManager.dispatch( 'on-mxregistry-update', {
                    type   : localObject.type,
                    localId: localId
                } )
            } )

            storable.localUpdate( localObject, localId, localObject.remoteId, localObject.timestamp, localObject.localKey )

        }

    }

    performLocalDelete( localObject, localId )
    {

        let storable = this.baseClass( localObject.type )
        storable.localDelete( localId )
                .then( () =>
                {
                    this.eventManager.dispatch( 'on-mxregistry-delete', {
                        type   : localObject.type,
                        localId: localId
                    } )
                } )

    }

    updateList( localId, localObject, remoteResult )
    {

        if( !this.f.valid( remoteResult.result )
            || Object.keys( remoteResult.result ).length === 0 )
        {
            this.performLocalDelete( localObject, localId )
        }
        else
        {

            let promises = []

            for( let r in remoteResult.result )
            {

                let rawObject = remoteResult.result[ r ]
                promises.push( () =>
                {
                    return new Promise( resolve =>
                    {

                        this.testDecryptAndNewer( rawObject, localObject )
                            .then( testResult =>
                            {

                                if( false !== testResult )
                                {

                                    let remoteObject = {
                                        keys                 : this.prepareKeys( rawObject.keys ),
                                        idOwner              : localObject.idOwner,
                                        object               : rawObject.object,
                                        remoteUpdateTimestamp: new Date( rawObject.datetime_updated ).getTime(),
                                        remoteId             : rawObject.id
                                    }

                                    this.database.writeObject( remoteObject, localId, localObject.type )
                                        .then( () =>
                                        {
                                            this.eventManager.dispatch( 'on-mxregistry-update', {
                                                type   : localObject.type,
                                                localId: localId
                                            } )
                                        } )

                                }

                                return resolve()

                            } )

                    } )

                } )

            }

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    this.logger.cdebug( 'WebSocketClient::ObjectTriggerHandler::updateList', 'done.' )

                } )

        }

    }

    getLocalObject( localId )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.database.readType( localId )
                .then( type =>
                {

                    let storable = this.storables[ type ]
                    storable.loadForRegistry( localId )
                            .then( object =>
                            {
                                return resolve( object )
                            } )
                            .catch( () =>
                            {
                                return reject()
                            } )

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )

    }

    update( payload )
    {

        return
        /*
        let start = Date.now()

        let localId = payload.payload
        if( localId === this.core.getState( 'editing-object' ) )
        {
            this.logger.clog( 'TriggerHandlers::ObjectTriggerHandler:update', 'not dispatching update on object while editor is still open...' )
            return
        }

        this.getLocalObject( localId )
            .then( localObject =>
            {

                let params = {
                    method   : 'objects.getObjectById',
                    since    : '2020-01-01 01:00:00',
                    id_local : localId,
                    waitRetry: true
                }

                this.parent.request( params )
                    .then( result =>
                    {

                        if( !this.f.valid( localObject ) )
                        {
                            return
                        }
                        if( localObject.type === 'list' )
                        {
                            this.updateList( localId, localObject, result )
                        }
                        else
                        {

                            if( 0 < Object.keys( result.result ).length )
                            {

                                for( let rId in result.result )
                                {

                                    let crypted  = result.result[ rId ].object,
                                        localKey = this.cryptoHelper.getLocalKey( result.result[ rId ].keys )

                                    this.cryptoHelper.decryptElement( localKey, crypted )
                                        .then( object =>
                                        {

                                            if( result.result[ rId ].keys.length !== localObject._keys.length )
                                            {
                                                this.updateKeySet( localObject, result.result[ rId ].keys )
                                            }
                                            else
                                            {
                                                this.performUpdate( localId, object, localObject )
                                            }

                                        } )

                                }

                            }
                            else
                            {

                                this.performLocalDelete( localObject, localId )

                            }

                        }

                    } )

            } )
            .catch( () =>
            {
                this.logger.clog( 'TriggerHandlers::ObjectTriggerHandler:update', 'not dispatching update on object as object is unknown to database...' )
            } )*/

    }

}