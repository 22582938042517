export default class DemoObjects
{
    constructor( parent )
    {

        this.parent = parent

        this.parent.eventManager
            .append( 'on-prepare-demo-objects', () => {
                if( this.parent.store.getters.authorized === true )
                {
                    this.prepareDemoObjects()
                }
            })
        this.parent.eventManager
            .append( 'on-remove-demo-objects', () => {
                if( this.parent.store.getters.authorized === true )
                {
                    this.removeDemoObjects()
                }
            })

        return this

    }

    prepareDemoObjects()
    {

        let demoStudents = []

        for( let i = 0; i < 24; i++ )
        {

            let lastName    = this.parent.f().ucFirst( this.parent.getTranslation().numberWord( '' + ( i + 1 ) ) ),
                firstName   = i % 2 === 0 ? 'Fritz' : 'Susi',
                gender      = i % 2 === 0 ? 'male' : 'female',
                demoStudent = {
                    type     : 'student',
                    firstname: firstName,
                    lastname : lastName,
                    gender   : gender,
                    isDemo   : true,
                    localId  : 'demo-student-object-' + i,
                    idOwner  : this.parent.store.getters.idUser,
                    classname: '2a',
                    archived : false
                }

            this.parent.getBaseClassHelper()
                .get( 'student' )
                .registry
                .cache
                .set( demoStudent.localId, demoStudent )

            demoStudents.push( demoStudent.localId )

        }

        let demoClass = {
            localId  : 'demo-class-object',
            type     : 'class',
            classname: '2a',
            color    : 'mint',
            isDemo   : true,
            students : demoStudents,
            idOwner  : this.parent.store.getters.idUser,
            archived : false
        }

        this.parent.getBaseClassHelper()
            .get( 'class' )
            .registry
            .cache
            .set( demoClass.localId, demoClass )

    }

    removeDemoObjects()
    {

        for( let i = 0; i < 24; i++ )
        {

            this.parent.getBaseClassHelper()
                .get( 'student' )
                .registry
                .cache
                .delete( 'demo-student-object-' + i )

        }

        this.parent.getBaseClassHelper()
            .get( 'class' )
            .registry
            .cache
            .delete( 'demo-class-object' )

    }

}