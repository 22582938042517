import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class DatesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !DatesObjectClass.instance )
        {

            let refList = [ 'title',
                            'color',
                            'studentReference',
                            'classReference',
                            'groupReference',
                            'yeargroupReference',
                            'date',
                            'enddate',
                            'start',
                            'end',
                            'pinned',
                            'description',
                            'timestamp',
                            'update',
                            'organizerSlot' ]

            let personalAttributesStatesList = [
                {
                    storage: 'pinning',
                    state  : 'pinned',
                    list   : 'pinLists'
                }
            ]


            super( core, 'date', refList, personalAttributesStatesList )

            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.objectType = 'dates'

            DatesObjectClass.instance = this
        }

        return DatesObjectClass.instance

    }

    getTabs()
    {
        return []
    }

    getFunctions()
    {
        return [ 'pin', 'edit', 'share', 'delete' ]
    }

    getFormFields()
    {
        return [
            {
                ref        : 'title',
                caption    : 'Bezeichnung',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref          : 'studentReference',
                caption      : 'zu Schüler:in',
                type         : 'RowAdder',
                component    : 'StudentSelector',
                values       : [],
                allowForeign : true,
                reformatter  : null,
                optional     : true,
                validator    : null,
                styleUpdate  : false,
                extended     : true,
                asPlainList  : true,
                dragBlocked  : true,
                unique       : true,
                ignoreClassId: true
            },
            {
                ref         : 'classReference',
                caption     : 'zu Klasse',
                type        : 'ClassSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true
            },
            {
                ref         : 'groupReference',
                caption     : 'zu Gruppe',
                type        : 'GroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_groups'
            },
            {
                ref         : 'yeargroupReference',
                caption     : 'zu Jahrgang',
                type        : 'YeargroupSelector',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false,
                extended    : true,
                acl         : 'page_yeargroups'
            },
            {
                ref        : 'date',
                caption    : 'Datum',
                type       : 'DateBox',
                reformatter: 'date',
                optional   : false,
                validator  : 'date',
                styleUpdate: false
            },
            {
                ref        : 'start',
                caption    : 'Beginn',
                type       : 'TextBox',
                reformatter: 'time',
                optional   : true,
                validator  : 'time',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'enddate',
                caption    : 'bis Datum',
                type       : 'DateBox',
                reformatter: 'date',
                optional   : true,
                validator  : 'date',
                styleUpdate: false,
                extended   : true
            },
            {
                ref        : 'end',
                caption    : 'Ende',
                type       : 'TextBox',
                reformatter: 'time',
                optional   : true,
                validator  : 'time',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'description',
                caption    : 'Details',
                type       : 'TextArea',
                reformatter: null,
                optional   : true,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'organizerSlot',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            }
        ]
    }

    create( values, additional, clone, forceTimestamp )
    {

        if( forceTimestamp === undefined )
        {
            let dateTime = values.date
            if( '' === values.start.trim() )
            {
                dateTime += ' 00:00:00'
            }
            else
            {
                dateTime += ' ' + values.start.trim() + ':00'
            }
            forceTimestamp = this.friendlyTimestamp.timestampForDateTime( dateTime )
        }

        return super.create( values, additional, clone, forceTimestamp )

    }

    update( values, localId, remoteId, timestamp, localKey, additional, noUpdateTimestamp, fromSync )
    {

        let dateTime = values.date
        if( '' === values.start.trim() )
        {
            dateTime += ' 00:00:00'
        }
        else
        {
            dateTime += ' ' + values.start.trim() + ':00'
        }

        timestamp = this.friendlyTimestamp.timestampForDateTime( dateTime )
        return super.update( values, localId, remoteId, timestamp, localKey, additional, noUpdateTimestamp, fromSync )

    }

}