<script>
export default {

    name: 'RowAdderFunctions',
    methods: {

        emitUpdate( value )
        {

            let adderIndex = this.$props.id.split( '-' ).pop()
            this.$emit( 'update', adderIndex, value )

        }

    }

}
</script>