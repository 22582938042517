<template>
    <footer id="footer">
        <div class="box left">
            Version <strong>{{ $core.getConfig().version }}</strong> | &copy;2019-{{ year }} Hill-Commerce GmbH<br/>
        </div>
        <div class="box right">
            <a target="_blank" class="footer" rel="noreferrer" href="https://www.entzettelt.de/hilfe/">Hilfe</a> |
            <a target="_blank" class="footer" rel="noreferrer"
               href="https://www.entzettelt.de/impressum/">Impressum</a> |
            <a class="footer" target="_blank" rel="noreferrer"
               href="https://www.entzettelt.de/datenschutzerklaerung-entzettelt-app/">Datenschutzerklärung</a>
            | <a target="_blank" class="footer" rel="noreferrer" href="https://www.entzettelt.de/agb/">AGB</a>
        </div>
    </footer>

</template>

<script>

export default {
    name: 'Footer',
    data()
    {
        return {
            year: new Date().getFullYear()
        }
    }
}
</script>