<template>
    <div>
        <template v-if="initialized">
            <template v-if="0 < filtered.length">
                <template v-for="list in filtered"
                          :key="'list-'+list.localId">
                    <div class="spacer"></div>
                    <div :class="'list '+list.color">
                        <div class="listname">{{ list.listname }}</div>
                        <strong>{{ $core.getTranslation().translate( 'list-type-' + list.listType ) }}</strong><br/>
                        <div class="list-summary-wrapper">
                            <template v-if="'test' !== list.listType">
                                <ListSummary :item="list"
                                             :listFilter="false"
                                             :skipSeparators="true"/>
                            </template>
                            <template v-else>
                                <ListScores :item="list"
                                            :listFilter="false"/>
                            </template>
                        </div>
                    </div>
                    <div class="spacer"></div>
                </template>
            </template>
            <template v-else>
                <template v-if="hasFilters">
                    <InlineMessage :active="true"
                                   key="message-inline"
                                   classname="nothing"
                                   :title="$core.t( 'caption-all-filtered' )"
                                   :message="$core.t( 'message-all-filtered' )"/>
                </template>
                <template v-else>
                    <InlineMessage :active="true"
                                   key="message-inline"
                                   classname="nothing"
                                   :title="$core.t( 'default-caption-empty' )"
                                   :message="$core.t( 'message-empty-lists' )"/>
                </template>
            </template>
        </template>
        <PreheatingIndicator v-else/>
    </div>
</template>

<script>
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator";
import InlineMessage       from "@/components/messages/InlineMessage";
import ListSummary         from "@/components/elements/viewItems/listViews/ListSummary";
import ListScores          from "@/components/elements/viewItems/listViews/ListScores";

export default {

    name      : "StudentDetailQuickview",
    components: { ListScores, ListSummary, InlineMessage, PreheatingIndicator },
    emits     : [ 'count' ],

    props: {
        reference : { type: Object, required: true },
        filterKey : { type: [ String, Boolean ], required: true },
        filterList: { type: Object, required: true }
    },

    watch: {
        filterKey: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined !== oldValue )
                {
                    this.filtered = this.filter( this.all )
                }
            }
        }
    },

    data()
    {
        return {
            initialized: false,
            hasFilters : false,
            filtered   : [],
            all        : []
        }
    },

    created()
    {
        this.$emit( 'count', 0 )
        this.prepare()
            .then( () =>
            {
                this.initialized = true
            } )
    },

    methods: {

        filter( list )
        {

            let filtered    = [],
                filterCount = 0

            for( let l in list )
            {

                let row         = list[ l ],
                    filterMatch = false

                for( let f in this.filterList )
                {
                    let filter = this.filterList[ f ],
                        group  = this.$core.getBaseClassHelper().getObjectById( filter.value )

                    switch( filter.attr )
                    {
                        case 'groupId':
                            filterCount++
                            if( -1 < group.students.indexOf( row.localId ) )
                            {
                                filterMatch = true
                            }
                            break
                        default:
                            for( let f in filter )
                            {
                                filterCount++
                                let flt = filter[ f ]
                                if( row[ flt.attr ] === flt.value )
                                {
                                    filterMatch = true
                                }
                            }
                            break
                    }

                }

                if( filterMatch )
                {
                    filtered.push( row )
                }

            }

            this.hasFilters = 0 < filterCount
            this.$emit( 'count', this.hasFilters ? filtered.length : this.all.length )
            return this.hasFilters ? filtered : this.all

        },

        rangeMatch( list )
        {
            if( this.$props.reference.archived !== true )
            {
                return true
            }

            let temp = this.$props.reference.archiveKey.split( /-/g )
            temp.shift()
            let timestamp = parseInt( temp[ 0 ] )

            if( list.timestamp < timestamp
                && list.timestamp > this.$props.reference.timestamp )
            {
                return true
            }
        },

        prepare()
        {
            return new Promise( resolve =>
            {

                let listsCache  = this.$core.getBaseClassHelper()
                                      .get( 'list' )
                                      .getCache( this.$props.reference.archived ? 'archive' : 'cache' ),
                    groupsCache = this.$core.getBaseClassHelper()
                                      .get( 'group' )
                                      .getCache( this.$props.reference.archived ? 'archive' : 'cache' ),
                    groups      = [],
                    lists       = []

                /*eslint-disable*/
                for( const [ g, group ] of groupsCache )
                {
                    if( -1 < group.students.indexOf( this.$props.reference.localId ) )
                    {
                        groups.push( group.localId )
                    }
                }

                for( const [ l, list ] of listsCache )
                {

                    let columns = this.$core.f().listColumns( list )
                    if( null !== columns )
                    {
                        let fixedColumn = columns[ 0 ],
                            add         = false
                        if( fixedColumn.type === 'fixed' )
                        {
                            switch( fixedColumn.filter )
                            {
                                case 'class':
                                    add = ( this.$props.reference.classId === fixedColumn.filterBy
                                            || ( 'all' === fixedColumn.filterBy && this.rangeMatch( list ) ) )
                                    break
                                case 'group':
                                    add = -1 < groups.indexOf( fixedColumn.filterBy )
                                    break
                                case 'yeargroup':
                                    add = this.$props.reference.yeargroupId === fixedColumn.filterBy
                                    break
                            }
                        }
                        if( add )
                        {
                            lists.push( list )
                        }
                    }
                }
                /*eslint-enable*/

                this.$emit( 'count', lists.length )
                let sortRules = this.$core.settings().getSetting( 'sortingDirections' )[ 'office' ]
                this.$core.getSorter().multiSortObjects( lists, sortRules, true )
                this.all = lists
                this.filtered = this.filter( this.all )
                return resolve()

            } )
        }

    }
}
</script>