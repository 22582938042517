<template>
    <div>
        <div>
            <input class="score"
                   :type="getFieldType()"
                   :value="elementValue"
                   placeholder=""
                   :disabled="disabled"
                   pattern="[1-6]{1}"
                   :ref="refName"
                   :id="'form-element-'+refName"
                   @focusin="$emit( 'focussed', refName )"
                   @focusout="$emit( 'blurred', refName )"
                   @keyup="handleValue"
                   @change="handleValue"
                   @click="skip"/>
        </div>
    </div>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {
    name : 'ScoreBox',

    mixins: [ MixinResettableComponent ],

    props: {
        id          : { Type: String, required: true },
        refName     : { Type: String, required: false },
        value       : { Type: String, required: false },
        validator   : { Type: String, required: false },
        disabled    : { Type: Boolean, required: false, default: false },
        singleScoreBox : { Type: Boolean, required: false }
    },

    emits: [ 'focussed', 'blurred', 'change', 'update' ],

    data()
    {
        return {
            elementValue: '',
            toggling    : false,
            initializing: true
        }
    },

    watch: {
        elementValue: function( newValue, oldValue )
        {
            if( !this.initializing
                && newValue !== oldValue )
            {
                this.handleValue()
            }
        }
    },

    mounted()
    {
        this.setValue()
        setTimeout( () =>
        {
            this.initializing = false
        }, 200 )

    },

    methods: {

        getFieldType()
        {

            return 'text'

        },

        setValue()
        {

            this.elementValue = this.$props.value
            document.querySelector( '#form-element-' + this.$props.refName ).value = this.elementValue

            if( !this.initializing )
            {
                this.handleValue()
            }

        },

        skip( event )
        {
            event.stopPropagation()
        },

        validate( value )
        {

            if( undefined === this.$props.singleScoreBox )
            {
                return value
            }

            if( '' !== value.trim() )
            {

                if( value < 1 )
                {
                    value = 1
                }
                if( value > 6 )
                {
                    value = 6
                }

            }

            return value

        },

        handleValue( event )
        {

            let value = this.validate( event && event.target ? event.target.value : this.$refs[this.$props.refName].value )

            this.elementValue = value.trim() !== '' ? value : undefined
            document.querySelector( '#form-element-' + this.$props.refName ).value = this.elementValue ? this.elementValue : ''

            this.$emit( 'change', this.$props.id, this.elementValue )

            if( !this.initializing )
            {
                this.$emit( 'update', this.$props.refName, 'scoreBox', 'update', undefined, this.elementValue )
            }

        }
    }

}
</script>
