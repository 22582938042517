<template>
    <div key="distri-basic" class="distribox">
        <div v-for="k in distributionKeys" :key="'key_distri_'+k"
             class="scorebox bremen">
            <div class="ratinglabel bremen">
                <strong>{{ trans[ ''+( parseInt( k ) ) ].short }}</strong>
            </div>
            <div class="scoreinput bremen">
                <input v-if="( k + 1 ) === distributionKeys.length" type="text" class="score"
                       placeholder="Pkt." :id="'ratio_'+k"
                       value="0" disabled="disabled" @keyup="checkKey( $event, k )"/>
                <input v-else type="text" class="score" placeholder="Pkt." :id="'ratio_'+k"
                       :value="distributionScores[k]" @keyup="checkKey( $event, k )"/>
            </div>
        </div>
        <div class="scorebox bremen scoretotal">
            <div class="ratinglabel scoretotal bremen">
                <strong>&#x2211;</strong>
            </div>
            <div class="scoreinput scoretotal">
                <strong>{{ $core.getReformatter().reformat( totalScore, 'localizedScore' ) }}</strong>
            </div>
        </div>
        <div class="clearfix"></div>
        <input type="hidden" :id="'form-element-'+refName" :value="value"/>
    </div>
</template>

<script>

import TestDistribution from '@/components/form/elements/tests/distributionKeys/TestDistribution'

export default {
    name   : 'TestDistributionBremen',
    extends: TestDistribution,

    props: [ 'value', 'refName', 'reference', 'validator', 'reformatter', 'display', 'editor', 'scores' ],

    data()
    {
        return {
            distributionKeys   : [],
            distributionScores : [],
            trans              : {},
            refValue           : '',
            totalScore         : 0,
            distributionChanged: false,
            changeTimer        : false
        }
    },

    created()
    {
        this.trans = this.$core.t( 'scoreModels' )[ 'bremen' ]
        this.initKeys()
        if( undefined !== this.$props.scores )
        {
            this.initScores()
        }

    },

    mounted()
    {
        this.update( this.$props.value )
    },

    beforeUnmount()
    {
        this.$emit( 'componentChanged' )
    },

    methods: {

        initKeys()
        {

            for( let i = 0; i < 4; i++ )
            {
                this.distributionKeys.push( i )
                this.distributionScores.push( i < 5 ? undefined : 0 )
            }

        },
        initScores()
        {

            for( let i = 0; i < 4; i++ )
            {
                this.distributionScores[ i ] = this.$props.scores[ i ]
            }

        }

    }

}
</script>