<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="visible" class="detail-view-wrapper">
            <div class="detail-view">
                <div class="headline">
                    <strong>{{ username }}</strong> | {{ studentReference.displayName }}
                    <Button type="close" @clicked="handleClose"/>
                </div>
                <div class="body">
                    <div class="spacer"></div>
                    <h2>Zugang für {{ studentReference.firstname }} {{ studentReference.lastname }}</h2>
                    <div class="spacer"></div>
                    <StudentAccessDataTable :localAccess="studentAccess.data"
                                            :isActive="true"
                                            :studentReference="studentReference"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import StudentAccessDataTable from "@/components/routes/secretary/studentAccess/StudentAccessDataTable";

export default {
    name      : "StudentAccessInfo",
    components: { StudentAccessDataTable },
    emits     : [ 'close' ],

    props: {
        studentAccess   : { type: Object, required: false },
        studentReference: { type: Object, required: true }
    },

    data()
    {
        return {
            colorModel  : false,
            visible     : true,
            username    : false
        }
    },

    created()
    {
        this.username = undefined !== this.$props.studentAccess ? this.$props.studentAccess.username : 'Neuen Zugang anlegen'
        this.colorModel = this.$core.settings().getSetting( 'studentColorModel' )
    },

    methods: {

        handleClose()
        {
            this.visible = false
            this.$core.getCoreTimer()
                .addTimeout( 'closing-studentaccess', 300, () =>
                {
                    this.$emit( 'close' )
                } )
        }

    }

}
</script>