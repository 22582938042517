export default class afterAllCompetence
{

    constructor( core )
    {

        this.f = core.f()
        this.sorter = core.getSorter()

    }

    /*eslint-disable-next-line*/
    afterFetch( competences, skipArchived )
    {

        return new Promise( resolve =>
        {

            let stack = {}

            for( let c in competences )
            {
                if( competences[ c ].archived && !skipArchived || !competences[ c ].archived )
                {
                    if( undefined === stack[ competences[ c ].idCompetenceCategory ] )
                    {
                        stack[ competences[ c ].idCompetenceCategory ] = []
                    }
                    stack[ competences[ c ].idCompetenceCategory ].push( competences[ c ] )
                }
            }

            for( let s in stack )
            {

                let minLevel    = 99999,
                    maxLevel    = -1,
                    defined     = {},
                    haveDoubles = false

                for( let c in stack[ s ] )
                {
                    if( undefined === stack[ s ][ c ].level
                        || false === stack[ s ][ c ].level
                        || 'create' === stack[ s ][ c ].level )
                    {
                        stack[ s ][ c ].level = -1
                    }
                    if( stack[ s ][ c ].level !== -1 && stack[ s ][ c ].level < minLevel )
                    {
                        minLevel = stack[ s ][ c ].level
                    }
                    if( stack[ s ][ c ].level !== -1 && stack[ s ][ c ].level > maxLevel )
                    {
                        maxLevel = stack[ s ][ c ].level
                    }

                    if( !defined[ stack[ s ][ c ].level ] )
                    {
                        defined[ stack[ s ][ c ].level ] = true
                    }
                    else
                    {
                        haveDoubles = true
                    }

                }

                if( !haveDoubles )
                {
                    for( let c in stack[ s ] )
                    {
                        if( stack[ s ][ c ].level === -1 )
                        {
                            maxLevel++
                            stack[ s ][ c ].level = maxLevel
                        }
                    }
                }
                else
                {
                    let level = 0
                    this.sorter.multiSortObjects( stack[ s ], [ [ 'title', 'ascending' ], [ 'level', 'ascending' ] ] )
                    for( let c in stack[ s ] )
                    {
                        stack[ s ][ c ].level = level
                        level++
                    }
                }

            }

            return resolve( competences )

        } )

    }

    afterUpdate( competences, skipArchived )
    {
        return new Promise( resolve =>
        {
            return resolve( this.afterFetch( competences, skipArchived ) )
        } )
    }

}