<template>
    <div>
        <div v-if="!isEditor()">
            <span v-html="display"></span>
        </div>
        <div v-if="isEditor()">
            <input type="text" class="zip" :placeholder="placeholders.zipcode" @change="updateValue()" :id="getRefName(0)" :value="zipcode"/>
            <input type="text" class="city" :placeholder="placeholders.city" @change="updateValue()" :id="getRefName(1)" :value="city"/>
        </div>
    </div>
</template>

<script>

export default {
    name : 'ZipCity',
    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        placeholder: { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'update' ],

    data()
    {
        return {
            zipcode: '',
            city   : '',
            placeholders: {
                zipcode: '',
                city: ''
            }
        }
    },

    created()
    {
        if( undefined !== this.$props.value )
        {
            this.zipcode = this.$props.value[ 'zipcode' ]
            this.city = this.$props.value[ 'city' ]
        }
        this.updateValue()
        this.setPlaceHolder()
    },

    methods: {

        setPlaceHolder()
        {
            if( undefined !== this.$props.placeholder )
            {
                let ph = this.$props.placeholder.split( /,/ )
                this.placeholders.zipcode = ph[ 0 ]
                this.placeholders.city = ph[ 1 ]
            }
        },

        updateValue()
        {
            let zipElm = document.querySelector( '#' + this.getRefName( 0 ) ),
                cityElm = document.querySelector( '#' + this.getRefName( 1 ) )

            if( null !== zipElm )
            {
                this.zipcode = zipElm.value
            }
            if( null !== cityElm )
            {
                this.city = cityElm.value
            }

            this.$emit( 'update', this.$props.refName, 'zipCity', 'update', undefined, {
                zipcode: this.zipcode,
                city   : this.city
            } )
        },
        getRefName( id )
        {
            let ref = this.$props.refName.split( ',' )
            return 'form-element-' + ref[ id ]
        },
        isEditor()
        {
            return ( undefined === this.$props.display || this.$props.editor === true )
        },
        skip( event )
        {
            event.stopPropagation()
        },
        reformatInput()
        {
        }
    }

}
</script>