export default class beforeDeleteStudentAccess
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
    }

    /*eslint-disable*/
    _getFromCache( registry, localId )
    {

        let access = registry.cache.get( localId )
        if( access )
        {
            return access
        }

        access = registry.archive.get( localId )
        if( access )
        {
            return access
        }

    }

    beforeDelete( localId, registry )
    {
        return new Promise( resolve =>
        {

            let access = this._getFromCache( registry, localId )
            if( access )
            {
                registry.byReferenceId.delete( access.studentLocalId )
            }

        } )
    }

}