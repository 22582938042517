<template>
    <div v-if="undefined === caption" style="float:left;">
        <div class="toggle_wrapper" v-bind:class="getOptionState()">
            <div class="toggle_inner" v-bind:class="getOptionState()">
                <div class="toggle_text" v-bind:class="getOptionState()" v-html="getOptionText()">
                </div>
            </div>
            <div class="toggle_outer" @click="toggleOption">
                <input type="hidden" :id="'tgl-hidden-'+id" :value="showValue" @change="updateSelf"
                       @changeRemote="updateRemote"/>
            </div>
        </div>
    </div>
    <div v-else style="float:left;">
        <div class="toggle_caption_wrapper">
            <div class="toggle_caption_left">
                <div class="toggle_wrapper" v-bind:class="getOptionState()">
                    <div class="toggle_inner" v-bind:class="getOptionState()">
                        <div class="toggle_text" v-bind:class="getOptionState()" v-html="getOptionText()">
                        </div>
                    </div>
                    <div class="toggle_outer" @click="toggleOption">
                        <input type="hidden" :id="'tgl-hidden-'+id" :value="showValue" @change="updateSelf"
                               @changeRemote="updateRemote"/>
                    </div>
                </div>
            </div>
            <div class="toggle_caption_right">
                <strong>{{ caption }}</strong>
            </div>
        </div>
    </div>
    <div class="toggle_disabled_hint"
         v-if="disabled"
         v-html="( disabledComment ? disabledComment : 'deaktiviert' )">
    </div>
</template>
<script>

export default {
    name: 'Toggle',

    props: {
        value          : { Type: String, required: false },
        refName        : { Type: String, required: true },
        id             : { Type: String, required: false },
        caption        : { Type: String, required: false },
        eventKey       : { Type: String, required: false },
        disabled       : { Type: Boolean, required: false },
        disabledComment: { Type: String, required: false }
    },

    emits: [ 'update', 'valueChange' ],

    data()
    {
        return {
            showValue: false
        }
    },
    created()
    {
        this.showValue = undefined !== this.$props.value ? this.$props.value : false
        if( '0' === this.showValue )
        {
            this.showValue = false
        }
    },
    watch  : {
        value: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.showValue = newValue
                }
            }
        }
    },
    methods: {
        updateSelf()
        {
        },
        updateRemote( event )
        {
            if( undefined !== event.detail.value )
            {
                this.showValue = event.detail.value
                this.emitValue()
            }
        },
        emitValue()
        {
            this.$emit( 'valueChange', this.showValue )
            this.$emit( 'update', this.$props.refName, 'toggle', 'update', undefined, this.showValue )
        },
        toggleOption()
        {
            if( !this.$props.disabled )
            {
                this.showValue = !this.showValue
                this.emitValue()
            }
        },
        getOptionState()
        {
            return ( this.showValue == true ? 'on' : 'off' ) + ( this.$props.disabled ? ' disabled' : '' )
        },
        getOptionText()
        {
            return this.showValue == true ? this.$core.getTranslation().translate( 'toggle-on' ) : this.$core.getTranslation().translate( 'toggle-off' )
        }
    }
}
</script>