<script>
export default {

    name: "MixinLumHooks",

    data()
    {
        return {
            lum         : null,
            openInEditor: {},
        }
    },

    watch: {
        'lum.hooks': {
            immediate: true,
            deep     : true,
            handler()
            {
                this.checkHooks()
                this.prepare()
            }
        },

        'lum.unhookQueue': {
            immediate: true,
            deep     : true,
            handler()
            {
                this.checkHooks()
                if( this.lum
                    && 0 === Object.keys( this.lum.hooks ).length )
                {
                    this.prepare()
                }
            }
        }
    },

    created()
    {
        this.lum = this.$core.getListUpdateManager()
    },

    beforeUnmount()
    {
        this.lum = null
    },

    methods: {
        checkHooks()
        {
            if( this.lum )
            {
                for( let h in this.lum.hooks )
                {
                    if( !this.lum.unhookQueue[ h ] )
                    {
                        this.openInEditor[ h ] = true
                    }
                    else
                    {
                        delete this.openInEditor[ h ]
                    }
                }
            }
        }
    }

}
</script>