export default class SyncStatistics
{

    constructor( parent )
    {

        this.parent = parent
        this.client = parent.client
        this.parent.logger.clog( 'SyncWorker::SyncStatistics', 'initialized' )

        this.lastSync = 0
        this.graceTime = 300000

        this.logger = this.parent.logger
        this.firstRun = true

    }

    destruct()
    {
        this.parent = null
        delete this.parent
    }

    /**
     * prepareSyncables
     */
    prepareSyncables()
    {

        return new Promise( ( resolve, reject ) =>
        {

            let message = {
                method      : 'users.readStatistics',
                initialFetch: this.parent.initialFetch,
                since       : this.parent.getSyncParameters().since
            }

            this.client.request( message )
                .then( list =>
                {
                    return resolve( list.result )
                } )
                .catch( () =>
                {
                    if( this.parent.f.isOnlineSyncableState() )
                    {
                        return resolve( [] )
                    }
                    else
                    {
                        return reject()
                    }
                } )

        } )

    }

    sync()
    {

        return new Promise( resolve =>
        {

            if( this.parent.f.isOnlineSyncableState()
                && Date.now() > ( this.lastSync + this.graceTime ) )
            {

                if( false === this.parent.silent )
                {
                    this.parent.ui.showBlocker( 'Synchronisiere', '<strong>Statistiken</strong> werden synchronisiert...' )
                }

                let synced = []

                this.prepareSyncables()
                    .then( list =>
                    {

                        for( let l in list )
                        {

                            this.parent.s.get( list[ l ].statsKey )
                                .then( value =>
                                {

                                    synced.push( list[ l ].statsKey )
                                    if( this.parent.f.isset( list[ l ] )
                                        && value < list[ l ].statsValue
                                        && -1 === list[ l ].statsKey.indexOf( '_CryptoStatistics' ) )
                                    {
                                        this.parent.s.register( list[ l ].statsKey, list[ l ].statsValue )
                                    }
                                    else
                                    {

                                        if( value > list[ l ].statsValue
                                            || -1 < list[ l ].statsKey.indexOf( '_CryptoStatistics' ) )
                                        {

                                            let message = {
                                                method    : 'users.writeStatistics',
                                                statsKey  : list[ l ].statsKey,
                                                statsValue: value
                                            }

                                            let jobId = this.parent.uuid.generate()
                                            this.parent.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                                        }

                                    }
                                } )
                        }

                        this.parent.database.readAllObjects( 'statistics' )
                            .then( list =>
                            {

                                for( let l in list )
                                {
                                    let item = list[ l ]
                                    if( -1 === synced.indexOf( item.key ) )
                                    {
                                        let message = {
                                            method    : 'users.writeStatistics',
                                            statsKey  : item.key,
                                            statsValue: item.item
                                        }

                                        let jobId = this.parent.uuid.generate()
                                        this.parent.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )
                                    }
                                }

                                this.lastSync = Date.now()
                                return resolve()

                            } )
                            .catch( () =>
                            {
                                return resolve()
                            } )


                    } )
                    .catch( () =>
                    {
                        return resolve()
                    } )
            }
            else
            {
                return resolve()
            }

        } )

    }


}