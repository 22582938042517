/*eslint-disable*/
import { reactive }      from "vue";
import CalculationHelper from "@/objects/stateMergers/list/helper/CalculationHelper";

export default class afterFetchList
{

    constructor( core )
    {

        this.baseClassHelper = core.getBaseClassHelper()
        this.tableCalculation = core.getTableCalculation()
        this.f = core.f()

        this.today = new Date()
        this.calculationHelper = new CalculationHelper( this.f, core.settings(), this.tableCalculation, core.getUuid() )

    }

    _createContainer( list )
    {

        let container = {}

        for( let key in list )
        {
            if( key !== 'values'
                && key !== '_keys' )
            {
                container[ key ] = list[ key ]
            }
        }

        container.isDemoContent = list.isDemoContent
        container.tsmpEnd = list.timestamp
        container.tsmpStart = list.timestamp
        container.labels = list.labels
        container.lasteditor = list.lasteditor
        container.lists = []
        container.lists.push( list )

        return container

    }

    fixMeta( container, list )
    {
        if( container.labels !== list.labels )
        {
            container.labels = list.labels
        }
        if( container.tsmpEnd < list.timestamp )
        {
            container.tsmpEnd = list.timestamp
        }
        if( container.tsmpStart > list.timestamp )
        {
            container.tsmpStart = list.timestamp
        }
        if( container.archived != list.archived )
        {
            container.archived = list.archived
        }
        if( container.update < list.update )
        {
            container.update = list.update
            container.lasteditor = list.lasteditor
        }
    }

    isListForToday( timestamp )
    {

        let then = new Date( timestamp )

        return ( this.today.getFullYear() === then.getFullYear()
                 && this.today.getMonth() === then.getMonth()
                 && this.today.getDate() === then.getDate() )

    }

    calculate( listContainer )
    {

        let listSummary = this.calculationHelper.calculateListSummary( listContainer )
        return {
            summary: listSummary,
            columns: this.calculationHelper.calculateColumns( listContainer ),
            details: {} //
        }

    }

    /**
     * afterFetch
     * @param lists
     * @param skipArchived
     * @returns {Promise<unknown>}
     */
    afterFetch( lists, skipArchived )
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( 'student' )
                ._listAll()
                .then( () =>
                {

                    let containers = {},
                        result     = []

                    for( let l in lists )
                    {
                        if( undefined === containers[ lists[ l ].referenceKey ] )
                        {
                            containers[ lists[ l ].referenceKey ] = this._createContainer( lists[ l ] )
                        }
                        else
                        {

                            let found = false

                            for( let ll in containers[ lists[ l ].referenceKey ].lists )
                            {
                                if( containers[ lists[ l ].referenceKey ].lists[ ll ].localId === lists[ l ].localId )
                                {
                                    found = true
                                    containers[ lists[ l ].referenceKey ].lists[ ll ] = lists[ l ]
                                    this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                                }
                            }
                            if( !found )
                            {
                                containers[ lists[ l ].referenceKey ].lists.push( lists[ l ] )
                                this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                                if( this.isListForToday( lists[ l ].timestamp ) )
                                {
                                    containers[ lists[ l ].referenceKey ].listForToday = lists[ l ]
                                }
                            }
                        }
                    }

                    for( let key in containers )
                    {

                        let calculationResult = this.calculate( containers[ key ] )
                        containers[ key ]._tc = reactive( calculationResult )
                        result.push( containers[ key ] )

                    }

                    return resolve( result )

                } )

        } )
    }

}