export default class Performance
{
    constructor( core )
    {
        if( !Performance.instance )
        {
            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            Performance.instance = this

            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {

                this.setup()

            } )

            this.setup()

        }

        return Performance.instance
    }

    destruct()
    {
        this.eventManager.unregisterIndexedCallback( 'core-component-reset', this.resetIndex )
        delete this.logger
        delete Performance.instance
    }

    setup()
    {

        this.logger.clog( 'Performance::setup', 'Performance monitor setting up...' )
        this.rawIndex = false
        this.index = false
        this.runtime = 1000
        this.measure()

    }

    measure()
    {

        const markerNameA = "entzettelt-idx-a",
              markerNameB = "entzettelt-idx-b"

        performance.mark( markerNameA )
        setTimeout( () =>
        {
            performance.mark( markerNameB )
            setTimeout( () =>
            {

                // Create a variety of measurements.
                performance.measure( "entzettelt-idx-ab", markerNameA, markerNameB )
                performance.measure( "entzettelt-idx-anow", markerNameA )
                performance.measure( "entzettelt-idx-nsb", undefined, markerNameB )
                performance.measure( "entzettelt-idx-nsnow" )

                // Pull out all of the measurements.
                this.rawIndex = performance.getEntriesByType( "measure" )

                // Finally, clean up the entries.
                performance.clearMarks()
                performance.clearMeasures()

                this.prepareIndex()

            }, this.runtime );
        }, this.runtime );
    }

    prepareIndex()
    {

        let prepared = {}
        for( let i in this.rawIndex )
        {
            let index = this.rawIndex[ i ]
            switch( index.name )
            {
                case 'entzettelt-idx-nsnow':
                    prepared.navigationTotal = index.duration
                    break
                case 'entzettelt-idx-nsb':
                    prepared.navigationToB = index.duration
                    break
                case 'entzettelt-idx-ab':
                    prepared.AtoB = index.duration
                    break
                case 'entzettelt-idx-anow':
                    prepared.AtoNow = index.duration
                    break
            }
        }

        prepared.overhead = ( prepared.AtoNow - prepared.AtoB )
        prepared.delayFactor = prepared.overhead / this.runtime
        prepared.performanceIndex = ( prepared.delayFactor * prepared.navigationTotal / this.runtime )
        this.index = prepared

    }
}