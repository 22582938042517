<template>
    <div class="camera-button" @click="handleCamera"></div>
</template>

<script>
export default {

    name: "CameraButton",

    emits: [ 'image' ],

    methods: {

        handleCamera()
        {

            let camId = this.$core.getUi().showCameraOverlay( 'Bild aufnehmen', true )

            this.$core.getEventManager()
                .add( 'on-camera-' + camId, ( dataUri ) =>
                {
                    this.$emit( 'image', dataUri )
                } )

            this.$core.getEventManager()
                .add( 'on-cameraclose-' + camId, () =>
                {
                    this.$core.getEventManager()
                        .remove( 'on-camera-' + camId )
                    this.$core.getEventManager()
                        .remove( 'on-cameraclose-' + camId )
                    this.$core.getEventManager()
                        .dispatch( 'on-form-reset-type', 'camera' )
                } )

        }

    }
}
</script>