<template>
    <div :class="'date hoverable '+item.color"
         :key="'date-'+item.localId">
        <div class="header">
            <ReferenceMarkers :item="item"
                              @detailView="handleDetailView"/>
            <template v-if="multiDay">
                <span>
                    <strong v-if="item.isSchoolHoliday">
                        {{ $core.t( 'school-holidays' ) }}&nbsp;
                    </strong>
                    <strong v-else>
                        {{ $core.t( 'multiday-event' ) }}&nbsp;
                    </strong>
                    <span
                        v-html="$core.t( 'from-until', [ $core.getFriendlyTimestamp().formattedDate( item.timestamp ), item.enddate ] )"/>
                </span>
            </template>
            <template v-else>
                <span><strong v-if="item.isBirthday">{{ $core.t( 'birthday' ) }}</strong>
                      <strong v-else-if="item.isHoliday">{{ $core.t( 'holiday' ) }}</strong>
                      <strong v-else>{{ $core.t( 'object-type-date' ) }}</strong>
                        {{ $core.t( 'date-on' ) }} <strong>{{
                            $core.getFriendlyTimestamp().formattedDate( item.timestamp )
                        }}</strong></span>
            </template>
        </div>
        <div class="date-body">
            <div class="date-left">
                <template v-if="item.isBirthday">
                    <div class="date-icon icon-date"
                         v-html="$core.getUi().getCakeIcon()"></div>
                </template>
                <template v-else-if="item.isSchoolHoliday">
                    <div class="date-icon icon-date"
                         v-html="$core.getUi().getPalmIcon()"></div>
                </template>
                <template v-else>
                    <template v-if="!isNaN( startHour( item.start ) )">
                        <div :class="'date-icon hours-'+startHour( item.start )"
                             v-html="$core.getUi().getClockIcon( startHour( item.start ) )"></div>
                        <span class="time">{{ item.start }}</span>
                        <template v-if="!isNaN( startHour( item.end ) )">
                            <div class="date-spacer"></div>
                            <div :class="'date-icon hours-'+startHour( item.end )"
                                 v-html="$core.getUi().getClockIcon( startHour( item.end ) )"></div>
                            <span class="time">{{ item.end }}</span>
                        </template>
                    </template>
                    <template v-else>
                        <div :class="'date-icon hours-'+startHour( item.start )"
                             v-html="$core.getUi().getClockIcon( startHour( item.start ) )"></div>
                    </template>
                </template>
            </div>
            <div class="date-right">
                <h2 class="date-head">{{ item.title }}</h2>
                <p v-if="item.description !== 'undefined'" v-html="$core.f().toHtml( item.description )"></p>
                <p v-else>- {{ $core.t( 'no-description' ) }}</p>
            </div>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
import ViewItemHandler  from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import ReferenceMarkers from '@/components/elements/markers/ReferenceMarkers'

export default {

    name      : 'Date',
    components: { ReferenceMarkers },
    extends   : ViewItemHandler,

    props: {
        item: { type: Object, required: true }
    },

    emits: [ 'detailView' ],

    computed: {
        multiDay()
        {
            return this.$core.f().isset( this.$props.item.enddate )
                   && '' !== this.$props.item.enddate.trim()
                   && this.$core.getValidator().validate( 'date', false, this.$props.item.enddate )
        }
    },

    methods: {

        startHour( time )
        {
            let temp = time.split( ':' )
            return parseInt( temp[ 0 ] )
        }

    }

}
</script>