<script>
export default {

    name: "MixinListValueHistory",

    data()
    {
        return {
            valueHistory: {}
        }
    },


    methods: {

        updateHistory( field, value, idAuthor )
        {

            if( null === this.valueHistory )
            {
                return
            }
            if( undefined === value )
            {
                this.valueHistory[ field ] = { action: 'del' }
            }
            else
            {
                this.valueHistory[ field ] = { action: 'set', value: value, id_author: idAuthor || this.$store.getters.idUser }
            }

        },

        getHistory( idAuthor )
        {

            let timeDiff = this.$core.getState( 'remoteTimeDiff' ) || 0

            return {
                id_author: idAuthor || this.$store.getters.idUser,
                history  : this.valueHistory,
                timestamp: Date.now() + ( timeDiff )
            }
        }

    }

}
</script>