export default class SyncCompetencesTemplates
{

    constructor( parent )
    {

        this.parent = parent
        this.uuid = this.parent.uuid
        this.logSign = 'SyncWorker::SyncCompetencesTemplates (SNC|CTPL)'
        this.parent.logger.clog( this.logSign, 'initialized' )
        this.f = this.parent.f

        this.processed = []
        this.fixRun = false

        this.total = 0
        this.step = 0
        this.lastAction = 0
        this.minInterval = 600000

    }

    showProgress( force )
    {
        if ( !this.parent.silent || force ) {
            this.parent.ui.blockerText( '<strong>Kompetenzen-Vorlagen</strong> werden synchronisiert...' )
            this.parent.ui.updateProgress( this.total, this.step )
        }
    }

    prepareSyncables()
    {

        return new Promise( ( resolve, reject ) =>
                            {
                                this.parent.logger.clog( this.logSign, 'fetching templates from remote...' )

                                let message = {
                                    method: 'objects.getCompetencesTemplates'
                                }

                                this.parent.client.request( message )
                                    .then( response =>
                                           {

                                               return resolve( response.result )

                                           } )
                                    .catch( error =>
                                            {
                                                return reject( error )
                                            } )

                            } )
    }

    _exactSubMatch( needle, haystack )
    {
        for ( let h in haystack ) {
            if ( haystack[ h ].value.caption === needle.value.caption
                 && haystack[ h ].value.description === needle.value.description
                 && haystack[ h ].value.type === needle.value.type ) {
                return true
            }
        }
        return false
    }

    _queueItemDiffers( item, object )
    {

        let diff = false,
            keys = [ 'color', 'localId', 'title', 'templateId', 'description' ]

        for ( let k in keys ) {
            if ( item[ keys[ k ] ] !== object[ keys[ k ] ] ) {
                diff = true
            }
        }

        if ( true === diff ) {
            return true
        }

        let needles  = item.type === 'competenceCategoryTemplate' ? item.subCompetences : item.subs,
            haystack = item.type === 'competenceCategoryTemplate' ? object.subCompetences : object.subs

        for ( let n in needles ) {
            if ( !this._exactSubMatch( needles[ n ], haystack ) ) {
                return true
            }
        }

        return false

    }

    _processQueue( queue )
    {
        return new Promise( resolve =>
                            {

                                if ( 0 < queue.length ) {

                                    this.step++
                                    this.showProgress()

                                    let item            = queue.shift(),
                                        baseClassHelper = this.parent.getBaseClassHelper(),
                                        baseClass       = baseClassHelper.get( item.type ),
                                        localId         = baseClass.registry.byReferenceId.get( item.templateLocalId ),
                                        object          = undefined

                                    if ( localId !== null && localId !== undefined ) {
                                        object = baseClassHelper.getObjectById( localId )
                                    }

                                    if ( !object ) {
                                        baseClass.create( item, undefined, undefined, undefined, undefined, item.localId )
                                        return resolve( this._processQueue( queue ) )
                                    } else {
                                        if ( this._queueItemDiffers( item, object ) ) {
                                            baseClass.update( item, item.localId, object.remoteId, object.timestamp, object.localKey, undefined, false, true )
                                        }
                                        return resolve( this._processQueue( queue ) )
                                    }

                                } else {
                                    return resolve()
                                }

                            } )
    }

    _processTemplateList( list )
    {

        let templates = {
                categories:  {},
                competences: {}
            },
            color     = 'white',
            count     = 0

        for ( let l in list ) {

            let mapSubCompetences = new Map(),
                template          = {
                    templateId:      l,
                    type:            'competenceCategoryTemplate',
                    localId:         this.uuid.generate(),
                    templateLocalId: list[ l ].template.localId,
                    title:           list[ l ].template.name,
                    color:           color,
                    labels:          [],
                    description:     list[ l ].template.description,
                    subCompetences:  []
                }

            for ( let c in list[ l ].categories ) {

                let subCompetence = {
                    mode:  'remove',
                    value: {
                        type:        'subCompetenceField',
                        caption:     list[ l ].categories[ c ].title,
                        description: list[ l ].categories[ c ].description,
                        localId:     this.uuid.generate()
                    }
                }

                mapSubCompetences.set( list[ l ].categories[ c ].id, subCompetence.value.localId )
                template.subCompetences.push( subCompetence )

            }

            templates.categories[ template.localId ] = template

            /*eslint-disable*/
            for ( let c in list[ l ].competences ) {

                let competence = {

                    templateId:         l,
                    type:               'competenceTemplate',
                    title:              list[ l ].competences[ c ].title,
                    description:        list[ l ].competences[ c ].description,
                    localId:            this.uuid.generate(),
                    templateLocalId:    list[ l ].competences[ c ].localId,
                    color:              color,
                    classReference:     '',
                    groupReference:     '',
                    yeargroupReference: '',
                    level:              parseInt( c ),


                }

                switch ( list[ l ].competences[ c ].id_category ) {
                    case 0:
                        competence.idCompetenceCategory = template.localId
                        break
                    default:
                        competence.idCompetenceCategory = 'subCompetence:' + mapSubCompetences.get( list[ l ].competences[ c ].id_category )
                        break
                }

                try {

                    let subs           = JSON.parse( list[ l ].competences[ c ].subs ),
                        competenceSubs = []

                    for ( let s in subs ) {
                        competenceSubs.push( {
                                                 mode:  'remove',
                                                 value: {
                                                     '_historic': s,
                                                     type:        'partialCompetenceField',
                                                     caption:     subs[ s ],
                                                     localId:     this.uuid.generate()
                                                 }
                                             } )
                    }

                    competence.subs = competenceSubs

                } catch ( e ) {
                }

                templates.competences[ competence.localId ] = competence

            }

        }

        this.total = count
        this.step = 0
        this.showProgress()

        return templates

    }

    _deleteOldTemplates( templates )
    {
        return new Promise( resolve =>
                            {

                                let catTemplates  = this.parent.getBaseClassHelper()
                                                        .get( 'competenceCategoryTemplate' )
                                                        .getCache( 'cache' ),
                                    compTemplates = this.parent.getBaseClassHelper()
                                                        .get( 'competenceTemplate' )
                                                        .getCache( 'cache' )

                                this.parent.logger.clog( this.logSign + ':_deleteOldTemplates', 'removing old templates and template versions...' )
                                this.parent.logger.clog( this.logSign + ':_deleteOldTemplates', 'sync categories: ' + Object.keys( templates.categories ).length
                                                                                                + ', sync competences: ' + Object.keys( templates.competences ).length
                                                                                                + ' || stored categories: ' + catTemplates.size
                                                                                                + ', stored competences: ' + compTemplates.size )

                                let actions = 0

                                for ( const [ catId, categoryTemplate ] of catTemplates ) {

                                    let foundCat = false
                                    for ( let t in templates.categories ) {
                                        if ( templates.categories[ t ].templateId === categoryTemplate.templateId ) {
                                            foundCat = true
                                        }
                                    }

                                    if ( !foundCat ) {

                                        let deletions = []

                                        deletions.push( {
                                                            type:     'competenceCategoryTemplate',
                                                            localId:  catId,
                                                            remoteId: categoryTemplate.remoteId
                                                        } )
                                        for ( const [ compId, competenceTemplate ] of compTemplates ) {
                                            if ( competenceTemplate.templateId === categoryTemplate.templateId ) {
                                                deletions.push( {

                                                                    type:     'competenceTemplate',
                                                                    localId:  compId,
                                                                    remoteId: competenceTemplate.remoteId

                                                                } )
                                            }
                                        }

                                        if ( 0 < deletions.length ) {

                                            this.parent.logger.clog( this.logSign + ':_deleteOldTemplates', 'deleting', deletions.length, 'competence and category templates as they do not exist on remote anymore...' )
                                            for ( let d in deletions ) {

                                                actions++
                                                this.parent.getBaseClassHelper()
                                                    .get( deletions[ d ].type )
                                                    .delete( deletions[ d ].localId, deletions[ d ].remoteId )

                                            }

                                        }

                                    }
                                }

                                this.parent.logger.clog( this.logSign + ':_deleteOldTemplates', 'triggered', actions, 'deletions in total: cleanup done.' )
                                return resolve()

                            } )
    }

    storeTemplates( templates )
    {

        return new Promise( resolve =>
                            {

                                let queue = []
                                for ( let id in templates.categories ) {
                                    queue.push( templates.categories[ id ] )
                                }
                                for ( let id in templates.competences ) {
                                    queue.push( templates.competences[ id ] )
                                }
                                return resolve( this._processQueue( queue ) )

                            } )

    }

    dropBrokenTemplates()
    {
        return new Promise( resolve => {

            if( true === this.fixRun )
            {
                return resolve()
            }

            let catTemplates  = this.parent.getBaseClassHelper()
                                    .get( 'competenceCategoryTemplate' )
                                    .getCache( 'cache' ),
                compTemplates = this.parent.getBaseClassHelper()
                                    .get( 'competenceTemplate' )
                                    .getCache( 'cache' ),
                deletions = []

            /*eslint-disable*/
            for( const [ localId, template ] of catTemplates )
            {
                if( !template.templateLocalId )
                {
                    deletions.push( template )
                }
            }

            for( const [ localId, template ] of compTemplates )
            {
                if( !template.templateLocalId )
                {
                    deletions.push( template )
                }
            }
            /*eslint-enable*/

            for( let d in deletions )
            {

                this.parent.getBaseClassHelper()
                    .get( deletions[ d ].type )
                    .delete( deletions[ d ].localId, deletions[ d ].remoteId )

            }

            this.fixRun = true
            return resolve()

        })
    }

    /*eslint-disable*/
    sync()
    {

        return new Promise( resolve =>
                            {

                                this.parent.logger.clog( this.logSign + ':sync', 'run' )

                                let baseClassHelper = this.parent.getBaseClassHelper()
                                baseClassHelper.get( 'competenceCategoryTemplate' )
                                               .cacheHeatup()
                                               .then( () =>
                                                      {
                                                          baseClassHelper.get( 'competenceTemplate' )
                                                                         .cacheHeatup()
                                                                         .then( () =>
                                                                                {

                                                                                    this.step = 0
                                                                                    this.total = 999

                                                                                    if ( this.parent.f.isOnlineSyncableState()
                                                                                         && undefined !== this.parent.license
                                                                                         && undefined !== this.parent.license.license ) {

                                                                                        this.showProgress()

                                                                                        this.dropBrokenTemplates()
                                                                                            .then( () => {

                                                                                                if ( this.lastAction < Date.now() - this.minInterval ) {
                                                                                                    this.prepareSyncables()
                                                                                                        .then( list =>
                                                                                                               {

                                                                                                                   let templates = this._processTemplateList( list )

                                                                                                                   this.storeTemplates( templates )
                                                                                                                       .then( () =>
                                                                                                                              {

                                                                                                                                  this.lastAction = Date.now()
                                                                                                                                  this.parent.eventManager.dispatch( 'templates-refreshed' )
                                                                                                                                  this.parent.logger.clog( this.logSign + ':sync', 'finished synchronizing templates' )

                                                                                                                                  this._deleteOldTemplates( templates )
                                                                                                                                      .then( () =>
                                                                                                                                             {
                                                                                                                                                 return resolve()
                                                                                                                                             } )

                                                                                                                              } )

                                                                                                               } )
                                                                                                        .catch( error =>
                                                                                                                {
                                                                                                                    this.parent.logger.cerror( this.logSign + ':sync', error )
                                                                                                                    return resolve()
                                                                                                                } )
                                                                                                } else {
                                                                                                    this.parent.logger.clog( this.logSign + ':sync', 'no need for resync: needs to be idle for at least 10 minutes.' )
                                                                                                    return resolve()
                                                                                                }

                                                                                            })

                                                                                    } else {
                                                                                        this.parent.logger.clog( this.logSign + ':sync', 'app not in syncable state for templates.' )
                                                                                        return resolve()
                                                                                    }

                                                                                } )

                                                      } )

                            } )

    }

}