<template>
    <transition appear name="fade">
        <div :class="'collab-editor-indicator'+( !open ? ' closed' : '' )"
             v-if="active" @click="open = !open">
            <span v-if="open" v-html="html"></span>
            <span v-else><strong>{{ count }}</strong></span>
        </div>
    </transition>
</template>

<script>
export default {

    name : "CollabEditingNotification",
    props: {
        active: { Type: Boolean, required: false, default: false },
        html  : { Type: String, required: false, default: '' },
        count : { Type: Number, required: false, default: 0 }
    },

    data()
    {
        return {
            open: true
        }
    }

}
</script>