<template>
    <div class="select-wrapper" v-if="ready">
        <select :id="'form-element-'+refName" @change="select()">
            <option value="">{{ $core.t( 'generic-please-select' ) }}</option>
            <template v-for="column in columns" :key="'key-'+refName+'-'+column.id">
                <option v-if="column.type !== 'fixed'" :value="column.id" :selected="column.id === value.value">
                    {{ column.caption }} ({{ $core.t( 'field-type-' + column.type ) }})
                </option>
            </template>
        </select>
    </div>
</template>

<script>
export default {

    name: 'ColumnSelector',

    emits: [ 'extendedFilterSelection', 'enterPressed', 'backspacePressed', 'update', 'selected', 'adderValid',
             'adderInvalid' ],

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false }

    },

    watch: {

        allValues: {
            deep: true,
            handler()
            {
                if( this.$props.unique === true )
                {
                    this.filterUnique()
                }
            }
        }

    },

    data()
    {
        return {
            ready  : true,
            formElm: false,
            columns: []
        }
    },

    created()
    {
    },

    mounted()
    {
        this.$nextTick()
            .then( () => {

                this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
                this.filterUnique()

            })
    },

    methods: {

        select()
        {

            if( null !== this.formElm )
            {

                if( '' !== this.formElm.value.trim() )
                {
                    this.$emit( 'update', this.$props.refName, 'columnSelector', 'update', undefined, this.formElm.value )
                    this.$emit( 'adderValid' )
                    return
                }
            }

            this.$emit( 'update', this.$props.refName, 'columnSelector', 'update', undefined, undefined )
            this.$emit( 'adderInvalid' )

        },
        /*eslint-disable*/
        filterUnique()
        {

            this.$nextTick()
                .then( () =>
                {


                    let excludes = []

                    for( let a in this.$props.allValues )
                    {
                        excludes.push( this.$props.allValues[ a ].value.value )
                    }

                    let newList = []

                    for( let s in this.set )
                    {
                        let column = this.set[ s ]
                        if( -1 === excludes.indexOf( column.id ) || column.id === this.$props.value.value )
                        {
                            newList.push( column )
                        }
                    }

                    this.columns = newList
                    this.ready = true

                } )

        }

    }

}
</script>