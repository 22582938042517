export default class Patch_2_SharesFix
{

    constructor( parent )
    {

        this.parent = parent
        this.p = this.parent.parent
        this.logSign = 'SyncWorker::SyncPatches::PatchLevel:2 -> SharesFix (SHR)'
        this.p.logger.clog( this.logSign, 'initialized' )

        this.baseClassHelper = this.p.getBaseClassHelper()

        this.ownUuid = this.p.store.getters.uuid
        this.ownUserId = parseInt( this.p.store.getters.idUser )

        if( undefined === this.baseClassHelper )
        {
            this.p.eventManager.append( 'on-baseclasses-available', () =>
            {
                this.baseClassHelper = this.p.getBaseClassHelper()
            } )
        }

        return this

    }

    destruct()
    {
        this.ownUuid = null
        this.ownUserId = null
    }

    /*eslint-disable*/

    getForeignKeys( element )
    {
        let keys = []
        for( let k in element._keys )
        {
            if( this.ownUuid !== element._keys[ k ].uuid )
            {
                keys.push( element._keys[ k ].uuid )
            }
        }
        return keys
    }

    getSharedElementsForQueue( queue, shared )
    {
        return new Promise( resolve =>
        {

            this.baseClassHelper
                .get( queue )
                .listAllNoWait()
                .then( elements =>
                {

                    let listed = [],
                        shares = []

                    for( let e in elements )
                    {
                        if( parseInt( elements[ e ].idOwner ) === this.ownUserId )
                        {
                            if( 'list' !== elements[ e ].type
                                && !this.isShared( elements[e].localId, shared  )
                                && ( undefined !== elements[ e ]._keys && 1 < elements[ e ]._keys.length )
                                && -1 === listed.indexOf( elements[ e ].localId ) )
                            {

                                listed.push( elements[ e ].localId )
                                let keys = this.getForeignKeys( elements[ e ] )
                                if( 0 < keys.length )
                                {
                                    shares.push( {
                                        idReference: elements[ e ].localId,
                                        objectType : queue,
                                        sharedWith : keys
                                    } )
                                }

                            }
                            else
                            {
                                for( let l in elements[ e ].lists )
                                {
                                    if( !this.isShared( elements[e].referenceKey, shared  )
                                        && -1 === listed.indexOf( elements[ e ].referenceKey ) )
                                    {

                                        if( 1 < elements[ e ].lists[ l ]._keys.length )
                                        {

                                            let keys = this.getForeignKeys( elements[ e ].lists[ l ] )
                                            if( 0 < keys.length )
                                            {
                                                shares.push( {
                                                    idReference: elements[ e ].lists[ l ].referenceKey,
                                                    objectType : queue,
                                                    sharedWith : keys
                                                } )
                                                listed.push( elements[ e ].lists[ l ].referenceKey )
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }

                    return resolve( shares )

                } )

        } )
    }

    isShared( referenceId, shares )
    {
        for( let s in shares )
        {
            if( shares[s].idReference === referenceId )
            {
                return true
            }
        }
        return false
    }

    execute()
    {
        return new Promise( resolve =>
        {

            this.p.logger.clog( this.logSign, 'executing patch...' )

            this.baseClassHelper
                .get( 'share' )
                .listAllNoWait()
                .then( list =>
                {

                    this.p.logger.clog( this.logSign, 'found ' + list.length + ' shares in object store: checking for unlinked origins...' )
                    let shares   = {},
                        promises = []

                    for( let q in this.baseClassHelper.shareableQueues )
                    {

                        let queue = this.baseClassHelper.shareableQueues[ q ]
                        shares[ queue ] = []

                        promises.push( () =>
                        {
                            return new Promise( resolve =>
                            {

                                this.getSharedElementsForQueue( queue, list )
                                    .then( sharedElements =>
                                    {

                                        shares[ queue ] = sharedElements
                                        return resolve()

                                    } )

                            } )
                        } )

                    }

                    this.p.f.promiseRunner( promises )
                        .then( () =>
                        {

                            for( let q in shares )
                            {

                                for( let e in shares[q] )
                                {

                                    let shareObject = shares[q][e]
                                    this.baseClassHelper
                                        .get( 'share' )
                                        .create( shareObject )

                                }

                            }

                            return resolve()

                        } )


                } )


        } )

    }

}