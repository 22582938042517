<template>
    <div v-if="prepared" class="competence-list-element competence-selector">

        <template v-if="0 < Array.isArray( tree.subs ) && 0 < tree.subs.length">
            <template v-for="item in tree.subs"
                      :key="'checkbox-'+id+'-'+item.localId">
                <template v-if="Array.isArray( item.subs ) && 0 < item.subs.length">
                    <div class="head-level-1">
                        <strong>{{ item.label }}</strong>
                    </div>
                    <template v-for="sub in item.subs"
                              :key="id+'-'+sub.localId">
                        <template v-if="Array.isArray( sub.subs ) && 0 < sub.subs.length">
                            <div class="sub-level-1">
                                <div class="head-level-2">
                                    <strong>{{ sub.label }}</strong>
                                </div>
                                <div class="sub-level-2">
                                    <template v-for="subsub in sub.subs"
                                              :key="id+'-'+subsub.localId">
                                        <template v-if="Array.isArray( subsub.subs)
                                                        && 0 < subsub.subs.length">
                                            -- def fail 2 --
                                        </template>
                                        <template v-else>
                                            <template v-if="allVisible || isChecked( subsub.localId )">
                                                <input type="checkbox"
                                                       :id="id+'-'+subsub.localId"
                                                       :disabled="isDisabled( subsub.localId ) || isDisabled( subsub.localId )"
                                                       :checked="isChecked( subsub.localId )"
                                                       class="compselect-input"
                                                       @change="handleChange( $event, subsub.localId )"/>
                                                <label
                                                    :for="id+'-'+subsub.localId"
                                                    class="compselect-label">{{ subsub.label }}</label>
                                                <div class="clearfix"></div>
                                            </template>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="allVisible || isChecked( sub.localId )">
                                <div class="sub-level-2">
                                    <input type="checkbox"
                                           :id="id+'-'+sub.localId"
                                           :checked="isChecked( sub.localId ) || isDisabled( sub.localId )"
                                           :disabled="isDisabled( sub.localId )"
                                           class="compselect-input"
                                           @change="handleChange( $event, sub.localId )"/>
                                    <label
                                        :for="id+'-'+sub.localId"
                                        class="compselect-label">{{ sub.label }}</label>
                                    <div class="clearfix"></div>
                                </div>
                            </template>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <div class="sub-level-1">
                        <template v-if="allVisible || isChecked( item.localId )">
                            <input type="checkbox"
                                   :id="id+'-'+item.localId"
                                   :checked="isChecked( item.localId ) || isDisabled( item.localId )"
                                   :disabled="isDisabled( item.localId )"
                                   class="compselect-input"
                                   @change="handleChange( $event, item.localId )"/>
                            <label
                                :for="id+'-'+item.localId"
                                class="compselect-label">{{ item.label }}</label>
                            <div class="clearfix"></div>
                        </template>
                    </div>
                </template>
            </template>
        </template>

    </div>
    <div v-else>
        <Placeholder type="select"/>
    </div>
</template>

<script>
import Placeholder         from "@/components/form/elements/Placeholder.vue";
import MixinCachePreheater from "@/mixins/MixinCachePreheater.vue";

export default {
    name      : "listElement-competenceSelector",
    components: { Placeholder },
    mixins    : [ MixinCachePreheater ],
    props     : {
        value            : { type: String, required: false, default: undefined },
        row              : { type: String, required: false, default: undefined },
        referenceObjectId: { type: String, required: false }
    },
    data()
    {
        return {
            display          : false,
            prepared         : false,
            neededCaches     : [ 'competence', 'competenceCategory' ],
            tree             : false,
            competenceLocalId: false,
            calculation      : false,
            studentLocalId   : false,
            allVisible       : this.$core.settings().getSetting( 'competenceShowFullTreeInListSummary' )
        }
    },
    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {

                this.$core.getCompetenceCategoryTree()
                    .awaitReadiness()
                    .then( () =>
                    {

                        let temp               = this.$props.row.split( /--/g ),
                            studentLocalIdTemp = temp.shift().split( /-/ ),
                            localId            = temp.pop()

                        studentLocalIdTemp.pop()

                        this.competenceLocalId = localId
                        this.studentLocalId = studentLocalIdTemp.join( '-' )

                        this.tree = this.$core.getCompetenceCategoryTree().tree.byRoot[ this.competenceLocalId ]
                        this.calculation = this.$core
                                               .getCompetenceCategoryCalculator()
                                               .calculateResults( undefined, this.tree, false, this.competenceLocalId ).calculation.byStudent
                        this.prepared = true

                    } )

            } )
    },

    methods: {

        isDisabled( subId )
        {
            return this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete
                   && this.calculation[ this.studentLocalId ][ subId ]._listComplete !== this.$props.referenceObjectId
        },

        isChecked( subId )
        {
            return this.calculation[ this.studentLocalId ][ subId ]._tsmpComplete
                   && this.calculation[ this.studentLocalId ][ subId ]._listComplete === this.$props.referenceObjectId
        },

        identifier( localId, label )
        {
            return localId + '-' + this.$core.getSanitizers().cleanId( label )
        }

    }
}
</script>

<style scoped lang="less">

.compselect-input
{
    max-width : 50px;
    float     : left;
}

.compselect-label
{
    float : left;
}

</style>