<template>
    <div>
        <div class="select-wrapper">
            <select v-bind:id="'form-element-'+refName" @change="checkStyleUpdate()" @click="skip">
                <option value="">bitte wählen...</option>
                <option v-for="confession in confessions"
                        v-bind:key="'key_option_'+confession.value"
                        v-bind:value="confession.value">{{ confession.caption }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

export default {
    name : 'ConfessionSelector',
    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        styleUpdate: { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'change', 'changed', 'update' ],

    created()
    {
    },
    updated()
    {
        this.select()
    },
    mounted()
    {
        this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
        this.select()
    },
    data()
    {
        return {
            confessions: [
                {
                    value  : 'ohne',
                    caption: 'Ohne Konfession'
                },
                {
                    value  : 'evangelisch',
                    caption: 'Evangelisch'
                },
                {
                    value  : 'katholisch',
                    caption: 'Katholisch'
                },
                {
                    value  : 'freikirchlich',
                    caption: 'Freikirchlich'
                },
                {
                    value  : 'buddhismus',
                    caption: 'Buddhismus'
                },
                {
                    value  : 'hinduismus',
                    caption: 'Hinduismus'
                },
                {
                    value  : 'islam',
                    caption: 'Islam'
                },
                {
                    value  : 'jude',
                    caption: 'Jüdisch'
                },
                {
                    value  : 'orthodox',
                    caption: 'Orthodox'
                },
                {
                    value  : 'zeugen jehovas',
                    caption: 'Zeugen Jehovas'
                }
            ],
            formElm    : false
        }
    },
    methods: {
        select()
        {
            if( null !== this.formElm
                && undefined !== this.$props.value )
            {
                this.formElm.value = this.$props.value
                this.checkStyleUpdate()
            }
        },
        skip( event )
        {
            event.stopPropagation()
        },
        checkStyleUpdate()
        {
            if( false !== this.$props.styleUpdate )
            {
                if( null !== this.formElm )
                {
                    this.$emit( 'changed', this.formElm.value )
                    this.$emit( 'change', this.$props.refName, this.formElm.value )
                    this.$emit( 'update', this.$props.refName, 'confessionSelector', 'update', undefined, this.formElm.value )
                }
            }
        }
    }

}
</script>
