export default class afterAllGroup
{

    constructor( core )
    {

        this.f = core.f()

    }

    afterFetch( groups, skipArchived )
    {
        return new Promise( resolve =>
        {
            for( let g in groups )
            {
                if( groups[ g ].archived !== true || !skipArchived )
                {
                    groups[ g ].displayName = 'Gruppe "'+groups[ g ].groupname+'"'
                }
            }
            return resolve( groups )
        } )
    }

    afterUpdate( groups, skipArchived )
    {
        return new Promise( resolve =>
        {
            return resolve( this.afterFetch( groups, skipArchived ) )
        } )
    }

}