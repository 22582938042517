<template>
    <div v-if="ready" :class="'message-item hoverable '+item.color+( selected ? ' selected-item' : '' )"
         @click="handleClick()" :key="'message-'+item.localId">
        <div class="message-left">
            <div class="title">
                <h2 :class="classList+' top-margin'" v-html="$core.f().toHtml( item.title )"></h2>
            </div>
            <div class="header">
                <span><span v-if="$core.f().isOwn( item )">an</span><span v-else>von</span> <strong>{{
                        formattedName
                    }}</strong></span> -
                <span v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.timestamp )"></span>
            </div>
            <div class="message-body">
                <span>{{ item.referenceMessage }}</span>
                <span v-if="!open" class="comment">anklicken, um die Nachricht zu lesen</span>
                <pre class="debug">
                    {{ item }}
                </pre>
                <div :class="'message-body-item'+( open ? ' open' : '')" v-html="item.body"></div>
            </div>
        </div>
        <div class="message-right">
            <div class="message-avatar"
                 :style="avatar">
            </div>
        </div>
    </div>
</template>

<script>
import ViewItemHandler     from '@/components/elements/viewItems/viewHandler/ViewItemHandler'

export default {
    
    name      : 'Message',
    components: {},
    extends   : ViewItemHandler,
    props     : {
        item      : { type: Object, required: true },
        selected  : { type: Boolean, required: false, default: false },
        selectMode: { type: Boolean, required: false, default: false }
    },
    
    emits: [ 'detailView', 'clicked' ],
    
    data()
    {
        return {
            ready        : false,
            formattedName: false,
            avatar       : '',
            unblurred    : false,
            box          : false,
            open         : false,
            colleagues   : false
        }
    },
    
    computed: {
        
        classList()
        {
            return ( !this.unblurred && this.$props.item.blurry ) ? 'blurred' : ''
        }
        
    },
    
    created()
    {

        this.$core
            .cc()
            .awaitReadiness()
            .then( () => {

                this.box = this.$core.f().isOwn( this.$props.item ) ? 'outbox' : 'inbox'
                let id = this.$core.f().isOwn( this.$props.item ) ? this.$props.item.id_recipient : this.$props.item.id_sender

                this.colleagues = this.$core.cc().getAll()
                this.getName( id )
                    .then( name =>
                    {
                        this.getAvatar()
                            .then( () =>
                            {
                                this.formattedName = name
                                this.ready = true
                            } )

                    } )

            })

    },
    
    methods: {
        
        getAvatar()
        {
            
            return new Promise( resolve =>
            {
                
                this.$core.getBaseClassHelper()
                    .get( 'avatar' )
                    .getPreparedCache()
                    .then( avatars =>
                    {

                        let colleagueUuid = this.box === 'inbox' ? this.$props.item.id_sender : this.$props.item.id_recipient,
                            colleagueId = false
                        
                        for( let c in this.colleagues )
                        {
                            if( this.colleagues[ c ].uuid === colleagueUuid )
                            {
                                colleagueId = this.colleagues[ c ].colleagueId
                            }
                        }

                        /* eslint-disable-next-line no-unused-vars */
                        for( const[ a, avatar ] of avatars.cache )
                        {
                            if( avatar.colleagueId === colleagueId )
                            {
                                this.avatar = 'background-image:url(' + avatar.dataUri + ')'
                            }
                        }
                        
                        return resolve()
                        
                    } )
                
            } )
            
        },
        
        getName( id )
        {

            return new Promise( resolve =>
            {
                
                for( let c in this.colleagues )
                {
                    if( this.colleagues[ c ].uuid === id )
                    {
                        return resolve( this.colleagues[ c ].lastname + ', ' + this.colleagues[ c ].firstname )
                    }
                }
                
                return resolve( 'unbekannter Benutzer' )
                
            } )
            
        },
        
        handleClick()
        {
            if( false === this.$props.selectMode )
            {
                this.open = !this.open
            }
            else
            {
                this.$emit( 'clicked' )
            }
        }
        
    }
    
}
</script>