<template>
    <div :id="'div-twt-'+id" :class="'threeway_toggle_wrapper '+getOptionState()" :key="'twt-'+id">
        <div class="threeway_toggle_inner">
            <div :class="'threeway_toggle_text '+getOptionState()" v-html="getOptionText()">
            </div>
        </div>
        <div class="threeway_toggle_outer">
            <input type="hidden" :id="'elm_'+id" @change="updateSelf"/>
        </div>
        <div class="threeway_click on" @click="set(2)"></div>
        <div class="threeway_click undefined" @click="set(false)"></div>
        <div class="threeway_click off" @click="set(1)"></div>
    </div>
</template>

<script>
import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {
    name : 'ThreeWayToggle',

    mixins: [ MixinResettableComponent ],

    props: {
        refName : { Type: String, required: true },
        id      : { Type: String, required: false },
        value   : { Type: String, required: true },
        disabled: { Type: Boolean, required: false },
        eventKey: { Type: String, required: false }
    },

    emits: [ 'update' ],

    data()
    {
        return {
            showValue: false,
            lastNext : 0
        }
    },
    watch: {
        value: {
            handler( newValue, oldValue )
            {
                if( oldValue !== newValue )
                {
                    this.showValue = newValue
                }
                this.$forceUpdate()
            },
            deep: true
        }
    },
    created()
    {
        this.showValue = undefined !== this.$props.value && false !== this.$props.value ? parseInt( this.$props.value ) : this.$props.value
    },
    methods: {
        updateSelf( event )
        {
            let newValue = ( '' === event.target.value.trim() ? undefined : event.target.value )
            this.set( newValue )
        },
        getNextState( state )
        {
            switch( state )
            {
                case 2:
                case 1:
                    return undefined
                case undefined:
                case false:
                    this.lastNext = 1 == this.lastNext ? 2 : 1
                    return this.lastNext
            }
        },
        set( newValue )
        {
            if( true === this.$props.disabled )
            {
                return
            }
            if( this.showValue === newValue )
            {
                this.toggleOption()
            } else
            {
                this.showValue = newValue
            }
            this.$emit( 'update', this.$props.refName, 'threeWayToggle', 'update', undefined, this.showValue )
        },
        toggleOption()
        {
            let state = this.getNextState( this.showValue )
            this.showValue = state
        },
        getOptionState()
        {
            switch( this.showValue )
            {
                case 2:
                    return 'on'
                case undefined:
                case false:
                    return 'undefined'
                case 1:
                    return 'off'
            }
        },
        getOptionText()
        {
            switch( this.showValue )
            {
                case 2:
                    return this.$core.getTranslation().translate( 'threeway-yes' )
                case undefined:
                case false:
                    return this.$core.getTranslation().translate( 'threeway-undefined' )
                case 1:
                    return this.$core.getTranslation().translate( 'threeway-no' )
            }
        }
    }
}
</script>