export default class TestStructureUpdates
{

    constructor( parent, core )
    {

        this.parent = parent

        this.f = core.f()
        this.logger = core.getLogger()
        this.uuid = core.getUuid()
        this.sanitizers = core.getSanitizers()

        this.logSign = 'AbstractListClass::TestStructureUpdates::'
        this.logger.clog( this.logSign + 'constructor', 'initialized' )

        return this

    }

    captionLookup( historic, caption )
    {
        for( let h in historic )
        {
            let history = historic[ h ]
            if( this.sanitizers.cleanId( history.set.caption ) === this.sanitizers.cleanId( caption.trim() ) )
            {
                return true
            }
        }
        return false
    }

    prepareTransmission( historic, column, idx, fieldIds, transmission, captionMatch = false )
    {
        if( Array.isArray( historic ) )
        {

            let oldIdentifier,
                history,
                found = false

            for( let h in historic )
            {
                history = historic[ h ]
                let captionFound = this.captionLookup( historic, column.caption )
                if( ( captionMatch && this.sanitizers.cleanId( history.set.caption )
                      === this.sanitizers.cleanId( column.caption.trim() ) )
                    || ( !captionMatch
                         && !captionFound
                         && parseInt( history.id ) === parseInt( idx ) )
                    || ( captionFound && this.sanitizers.cleanId( history.set.caption )
                         === this.sanitizers.cleanId( column.caption.trim() ) ) )
                {
                    found = true
                    oldIdentifier = this.sanitizers.cleanId( history.set.caption ) + '___' + ( parseInt( history.id ) + 1 )
                    break
                }
            }

            if( found )
            {

                let newIdentifier = this.sanitizers.cleanId( column.caption.trim() ) + '___' + ( parseInt( idx ) + 1 )
                transmission[ oldIdentifier ] = newIdentifier
                fieldIds.push( newIdentifier )
                return true

            }

        }
        return false
    }

    /*eslint-disable*/
    prepareNewVersion( values, historic, object )
    {

        let transmission = {},
            fieldIds     = [],
            newColumns   = [],
            columns      = values[ 'item___score' ],
            lastColId    = 0

        for( let c in columns )
        {

            let column = columns[ c ],
                temp   = column.value.value.split( /\|\|\|/g ),
                pair   = JSON.parse( temp[ 1 ] )

            if( 'object' === typeof ( column )
                && undefined !== pair )
            {

                if( '' !== pair.caption.trim() && '' !== pair.score.trim() )
                {

                    newColumns.push( {
                        type   : 'testItem',
                        score  : pair.score.trim(),
                        caption: pair.caption.trim()
                    } )

                    this.prepareTransmission( historic, pair, c, fieldIds, transmission )
                    fieldIds.push( this.sanitizers.cleanId( pair.caption.trim() ) + '___' + ( parseInt( c ) + 1 ) )

                    lastColId++

                }

            }

        }

        if( 1 === values.comment )
        {

            let elm = document.querySelector( '#form-element-comment' )
            if( null !== elm && true === elm.checked )
            {
                let commentColumn = {
                    type   : 'testcomment',
                    caption: 'Kommentar / Notiz'
                }
                newColumns.push(
                    commentColumn
                )
                let objectHistoryClone = []
                for( let c in object.columns )
                {
                    let colClone = {
                        set: JSON.parse( JSON.stringify( object.columns[ c ] ) ),
                        id : ( parseInt( c ) - 1 )
                    }
                    objectHistoryClone.push( colClone )
                }
                this.prepareTransmission( objectHistoryClone, commentColumn, lastColId, fieldIds, transmission, true )
            }

        }

        return {

            transmission: transmission,
            fieldIds    : fieldIds,
            newColumns  : newColumns

        }

    }

}