<template>
    <div class="select-wrapper" v-if="initialized">
        <select class="select_element" :id="'form-element-'+refName" @click="$core.f().skip"
                :disabled="disabled"
                @change="checkSelect()">
            <option value="">bitte wählen...</option>
            <template v-if="!allowForeign">
                <option v-for="item in classes"
                        :key="'key_option_'+item.localId"
                        :value="item.localId">{{ item.classname }}
                </option>
            </template>
            <template v-else>
                <optgroup label="meine Klassen">
                    <option v-for="item in classes"
                            :key="'key_option_'+item.localId"
                            :value="item.localId">{{ item.classname }}
                    </option>
                </optgroup>
                <optgroup v-for="coll in colleagues"
                          v-show="undefined !== foreigns[coll.colleagueId] && foreigns[coll.colleagueId].length > 0"
                          :key="'key_coll_'+coll.colleagueId"
                          :label="'Klassen von '+coll.firstname+' '+coll.lastname">
                    <option v-for="item in foreigns[coll.colleagueId]"
                            :key="'key_option_'+item.localId"
                            :value="item.localId">{{ item.classname }}
                    </option>
                </optgroup>
            </template>
        </select>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name  : 'ClassSelector',
    mixins: [ MixinCachePreheater ],
    props : {
        value       : { Type: String, required: false },
        refName     : { Type: String, required: true },
        id          : { Type: String, required: false },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        disabled    : { Type: Boolean, required: false, default: false },
        editor      : { Type: String, required: false },
        eventKey    : { Type: String, required: false },
        flags       : { Type: String, required: false },
        prefetch    : { Type: String, required: false },
        allowForeign: { Type: Boolean, required: false, default: false }
    },

    emits: [ 'change', 'update' ],

    updated()
    {
        if( this.initialized
            && null !== this.formElm
            && false !== this.formElm )
        {
            this.select()
        }
    },

    mounted()
    {
        this.waitReady()
            .then( () =>
            {

                this.initialize()
                    .then( () =>
                    {

                        this.formElm = document.querySelector( '#form-element-' + this.$props.refName )
                        this.select()
                        this.$core.getEventManager().add( 'on-class-preselect', localId =>
                        {
                            this.$emit( 'update', this.$props.refName, 'classSelector', 'update', undefined, localId )
                        } )

                    } )

            } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-class-preselect' )
    },

    data()
    {
        return {
            neededCaches: [ 'class', 'colleague' ],
            colleagues  : [],
            ClassObject : false,
            classes     : false,
            data        : {},
            foreigns    : {},
            initialized : false,
            formElm     : false
        }
    },

    watch: {
        prefetch: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.initialize()
                    this.select()
                }
            },
            deep: true
        }
    },

    methods: {

        waitReady()
        {
            return new Promise( resolve =>
            {
                this.$core.cc()
                    .awaitReadiness()
                    .then( () =>
                    {
                        this.awaitNeededCaches()
                            .then( () =>
                            {
                                return resolve()
                            } )
                    } )
            } )
        },

        initialize()
        {

            return new Promise( resolve =>
            {

                this.colleagues = this.$core.cc().getAll()
                let rules = this.$core.settings().getSetting( 'sortingDirections' )
                this.data = this.$props.prefetch
                if( undefined === this.data )
                {

                    let data  = [],
                        cache = this.$core.getBaseClassHelper()
                                    .get( 'class' )
                                    .getCache( 'cache' )

                    /*eslint-disable*/
                    for( const [ c, cached ] of cache )
                    {
                        data.push( cached )
                    }
                    /*eslint-enable*/

                    this.$core.getSorter().multiSortObjects( data, rules[ 'classes' ] )
                    this.data = {}
                    this.data.all = data
                    this.classes = []
                    this.foreigns = {}

                    for( let d in data )
                    {
                        if( this.$core.f().isOwn( data[ d ] ) )
                        {
                            this.classes.push( data[ d ] )
                        }
                        else
                        {
                            if( this.$props.allowForeign === true )
                            {
                                if( undefined === this.foreigns[ data[ d ].idOwner ] )
                                {
                                    this.foreigns[ data[ d ].idOwner ] = []
                                }
                                this.foreigns[ data[ d ].idOwner ].push( data[ d ] )
                            }
                        }
                    }

                    if( false === this.$props.allowForeign )
                    {
                        this.filterOwnClasses()
                    }

                    this.initialized = true
                    return resolve()

                }
                else
                {
                    this.classes = []
                    this.initialized = true
                    return resolve()
                }

            } )

        },

        filterOwnClasses()
        {

            let filteredList = []

            for( let c in this.classes )
            {

                let classObject = this.classes[ c ]
                if( parseInt( this.$core.getStore().getters.idUser ) === parseInt( classObject.idOwner ) )
                {
                    filteredList.push( classObject )
                }

            }

            this.classes = filteredList

        },

        select()
        {

            if( null !== this.formElm
                && this.initialized )
            {
                if( undefined !== this.$props.value
                    && null !== this.$props.value )
                {
                    this.formElm.value = this.$props.value
                }
            }
        },

        checkSelect()
        {
            if( null !== this.formElm )
            {
                if( '' !== this.formElm.value.trim() )
                {
                    this.$emit( 'change', this.$props.id, this.formElm.value )
                    this.$emit( 'update', this.$props.refName, 'classSelector', 'update', undefined, this.formElm.value )
                }
                else
                {
                    this.$emit( 'change', this.$props.id, undefined )
                    this.$emit( 'update', this.$props.refName, 'classSelector', 'update', undefined, undefined )
                }
            }
        }

    }

}
</script>