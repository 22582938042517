import { reactive } from "vue";

export default class PersonalAttributes
{

    constructor( logger, functions, eventManager, database )
    {

        if( !PersonalAttributes.instance )
        {

            this.logger = logger
            this.functions = functions
            this.eventManager = eventManager
            this.database = database

            this.logSign = 'AbstractObjectClass::Helpers::PersonalAttributes::'

            this.registry = reactive( {
                pinLists : new Map(),
                hideLists: new Map()
            } )

            this.state = 'initial'
            this.processing = false

            PersonalAttributes.instance = this

        }

        return PersonalAttributes.instance

    }

    processPrepareStateList( list )
    {

        return new Promise( resolve =>
        {

            this.logger.cdebug( this.logSign + 'processPrepareStateList', 'preparing ' + list.storage )
            this.database.readAllObjects( list.storage )
                .then( states =>
                {

                    this.registry[ list.list ].clear()

                    for( let p in states )
                    {
                        this.registry[ list.list ].set( states[ p ].key, states[ p ].item )
                    }

                    return resolve()

                } )
                .catch( e =>
                {
                    this.logger.cdebug( this.logSign + 'processPrepareStateList', 'preparation failed', list.storage, e )
                    return resolve()
                } )

        } )

    }

    prepareStateList( force )
    {

        return new Promise( resolve =>
        {

            if( ( 'initial' === this.state
                  || force ) && !this.processing )
            {

                this.processing = true
                this.state = 'preparing'

                let promises = [],
                    lists    = [
                        {
                            storage: 'pinning',
                            list   : 'pinLists'
                        },
                        {
                            storage: 'hiding',
                            list   : 'hideLists'
                        }
                    ]

                for( let l in lists )
                {
                    promises.push( () =>
                    {
                        return this.processPrepareStateList( lists[ l ] )
                    } )
                }

                this.functions.promiseRunner( promises )
                    .then( () =>
                    {
                        this.state = 'filled'
                        this.processing = false
                        return resolve()
                    } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    setState( object, status, table )
    {

        return new Promise( resolve =>
        {

            let pins = []

            this.logger.clog( this.logSign, 'setting pinned status for ' + object.localId + ', ' + ( true === status ? 'true' : 'false' ) )
            this.database.read( table, object.type )
                .then( result =>
                {
                    pins = result
                } )
                .catch()
                .finally( () =>
                {

                    let change  = false,
                        pinlist = []

                    if( undefined !== object.referenceKey )
                    {
                        pinlist.push( object.referenceKey )
                    }
                    pinlist.push( object.localId )

                    if( status === true )
                    {
                        for( let p in pinlist )
                        {
                            if( -1 === pins.indexOf( pinlist[ p ] ) )
                            {
                                pins.push( pinlist[ p ] )
                                change = true
                            }
                        }
                    }
                    if( status === false )
                    {
                        for( let p in pinlist )
                        {
                            let index = pins.indexOf( pinlist[ p ] )
                            if( -1 < index )
                            {
                                pins.splice( index, 1 )
                                change = true
                            }
                        }
                    }

                    if( change )
                    {
                        this.database.write( table, object.type, pins )
                            .then( () =>
                            {

                                this.database.write( table, '_tsmp', Date.now() )
                                    .then( () =>
                                    {

                                        this.eventManager.dispatch( 'on-push-pinning' )
                                        this.prepareStateList( true )
                                            .then( () =>
                                            {
                                                return resolve()
                                            } )

                                    } )

                            } )
                    }
                    else
                    {
                        return resolve()
                    }

                } )

        } )

    }

}