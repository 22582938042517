<script>
export default {

    name: 'MixinEvents',

    data()
    {
        return {
            events       : [],
            namedEvents  : [],
            indexedEvents: []
        }
    },

    beforeUnmount()
    {
        for( let e in this.events )
        {
            this.$core.getEventManager().remove( this.events[ e ] )
        }
        this.events = []
        for( let e in this.namedEvents )
        {
            this.$core.getEventManager().removeNamed( this.namedEvents[ e ].event, this.namedEvents[ e ].name )
        }
        this.namedEvents = []
        for( let e in this.indexedEvents )
        {
            this.$core.getEventManager().removeIndexedCallback( this.indexedEvents[ e ].name, this.indexedEvents[ e ].index )
        }
        this.indexedEvents = []
    },

    created()
    {
        this.events = []
        this.namedEvents = []
        this.indexedEvents = []
    },

    methods: {

        addEvent( name, callback )
        {
            if( !Array.isArray( this.events ) )
            {
                return
            }
            this.events.push( name )
            this.$core.getEventManager().add( name, callback )
        },

        appendEvent( name, callback )
        {
            if( !Array.isArray( this.events ) )
            {
                return
            }
            if( -1 === this.events.indexOf( name ) )
            {
                this.events.push( name )
            }
            this.$core.getEventManager().append( name, callback )
        },

        appendNamedEvent( event, name, callback )
        {
            if( !Array.isArray( this.namedEvents ) )
            {
                return
            }
            this.namedEvents.push( {
                event: event,
                name : name
            } )
            this.$core.getEventManager()
                .appendNamed( event, name, callback )

        },

        addIndexedEvent( name, callback )
        {

            if( !Array.isArray( this.indexedEvents ) )
            {
                return
            }
            let idx = this.$core.getEventManager()
                          .addIndexed( name, callback )

            this.indexedEvents.push( {
                index: idx,
                name : name
            } )

        }

    }

}
</script>