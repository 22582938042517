<script>
export default {

    name: "MixinLivingTimestamps",

    data()
    {
        return {
            headerKey: false
        }
    },

    created()
    {
        this.$core.getCoreTimer()
            .addInterval( 'timing-updates-for-' + this.$props.item.localId, 1000, () =>
            {
                this.headerKey = 'trig-' + Date.now()
            } )
    },

    beforeUnmount()
    {
        this.$core.getCoreTimer()
            .removeInterval( 'timing-updates-for-' + this.$props.item.localId )
    }

}
</script>