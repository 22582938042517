<template>
    <div class="default-list-page">
        <TabbedContent :active="activeTab"
                       :tabs="tabs"
                       :embedded="true"
                       @switched="setTab"/>
        <div class="tab-content no-overflow x" v-if="'organizers' === activeTab">
            <OrganizerSlotList @dragging="$emit( 'dragging', $event )"/>
        </div>
        <div class="tab-content no-overflow x" v-if="'organizercontents' === activeTab">
            <OrganizerContentsList/>
        </div>
    </div>
</template>

<script>
/*eslint-disable*/
import InlineMessage         from '@/components/messages/InlineMessage'
import TabbedContent         from "@/components/layout/tabs/TabbedContent";
import OrganizerSlotList     from "@/components/elements/viewItems/plannerView/modes/organizer/OrganizerSlotList";
import OrganizerContentsList from "@/components/elements/viewItems/plannerView/modes/organizer/OrganizerContentsList";

export default {

    name      : 'PlannerSetupSlots',
    components: {
        OrganizerContentsList,
        OrganizerSlotList,
        TabbedContent,
        InlineMessage
    },
    emits     : [ 'setContentMode', 'dragging' ],

    data()
    {
        return {
            tabs     : [
                {
                    id     : 'organizers',
                    caption: 'Planungsvorlagen'
                },
                {
                    id     : 'organizercontents',
                    caption: 'Planungs- / Unterrichtsreihen'
                }
            ],
            activeTab: 'organizers'
        }
    },

    props: {
        errors: {
            Type: Array, required: false, default: () =>
            {
                return []
            }
        },
        slots : {
            Type: Array, required: false, default: () =>
            {
                return []
            }
        }
    },

    created()
    {


    },

    methods: {

        setTab( which )
        {
            this.activeTab = which
            switch( which )
            {
                case 'organizers':
                    this.$emit( 'setContentMode', false )
                    break
                case 'organizercontents':
                    this.$emit( 'setContentMode', true )
                    break
            }
        }

    }

}
</script>