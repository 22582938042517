<template>
    <div class="color-filters" id="color-filters" v-if="initialized">
        <div v-for="gender in genders" class="color-filter" v-bind:class="getClassName( gender )"
             v-bind:key="'key_studentFilter_'+gender"
             v-bind:id="getGenderId( gender )" @click="filter( gender )"
             v-bind:title="title">
        </div>
    </div>
</template>

<script>

import MixinFilterState from "@/mixins/MixinFilterState";

export default {
    name : 'StudentFilter',
    props: [ 'title' ],
    emits: [ 'filter', 'detach', 'attach' ],

    mixins: [ MixinFilterState ],

    data()
    {
        return {
            genders    : [],
            filters    : [],
            initialized: false
        }
    },

    created()
    {

        this.genders = [ 'divers', 'female', 'male' ]
        this.$emit( 'attach', { type: 'student', filter: this.filters } )
        this.initialized = true

    },

    mounted()
    {
        this.restoreFilters()
    },

    beforeUnmount()
    {
        this.$emit( 'detach', { type: 'student', filter: this.filters } )
    },

    methods: {

        isActive( gender )
        {
            return ( -1 < this.filters.indexOf( gender ) ? true : false )
        },

        getGenderId( gender )
        {
            return 'gender-filter-gender-' + gender
        },

        filter( gender )
        {

            this.$core.s().count( 'clicked-student-filter' )
            if( this.isActive( gender ) )
            {
                this.filters = this.filters.filter( function( value )
                {
                    return value != gender
                } )
            }
            else
            {
                if( this.$core.settings().getSetting( 'multiFilter' ) !== true )
                {
                    this.filters = []
                }
                this.filters.push( gender )
            }

            this.emitFilters()

        },

        emitFilters()
        {

            let emitFilters = []
            for( let f in this.filters )
            {
                emitFilters.push( { attr: 'gender', value: this.filters[ f ] } )
            }

            this.$emit( 'filter', 'student', emitFilters, true )
            this.storeFilterState( emitFilters )

        },

        getClassName( gender )
        {

            let append     = '',
                colorClass = this.$core.f().genderColorClass( gender )

            if( 'divers' === gender )
            {
                append = ' marge-right'
            }

            return colorClass + ( this.isActive( gender ) === true ? ' active' : '' ) + append

        },

        resetFilters()
        {
            this.filters = []
            this.emitFilters()
        },

        restoreFilters()
        {

            let filters = this.getStoredFilterState(),
                viewFiltered = false

            for( let f in filters )
            {
                if( -1 === this.filters.indexOf( filters[f].value ) )
                {
                    this.filters.push( filters[f].value )
                    viewFiltered = true
                }
            }

            if( viewFiltered )
            {
                this.emitFilters()
            }

        }

    }
}
</script>