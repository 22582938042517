import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class AvatarsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !AvatarsObjectClass.instance )
        {

            let refList = [ 'dataUri', 'colleagueId', 'timestamp', 'update' ]

            let personalAttributesStatesList = []

            super( core, 'avatar', refList, personalAttributesStatesList )
            this.isStudentShareable = false
            this.objectType = 'avatars'

            AvatarsObjectClass.instance = this
        }

        return AvatarsObjectClass.instance

    }

    _isShareable()
    {
        return true
    }

    getTabs()
    {
        return []
    }

    getFunctions()
    {
        return []
    }

}