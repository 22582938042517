export default class Bubbles
{

    constructor( core )
    {

        if( !Bubbles.instance )
        {

            this.database = core.getDatabase()
            this.cryptoHelper = core.getCryptoHelper()
            this.eventManager = core.getEventManager()
            this.queueWorker = core.getQueueWorker()

            this.uuid = core.getUuid()
            this.logger = core.getLogger()
            this.client = core.getClient()

            this.setup()

            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {

                this.setup()

            } )

            this.eventManager.add( 'on-new-bubbles', () =>
            {
                this.initBubbleDetails()
            } )

            Bubbles.instance = this

        }

        return Bubbles.instance

    }

    destruct()
    {
        this.eventManager.unregisterIndexedCallback( 'core-component-reset', this.resetIndex )
        delete Bubbles.instance
    }

    setup()
    {

        this.logger.clog( 'Bubbles::setup', 'setup networking bubbles...' )

        this.map = {
            classes   : 'classes',
            colleagues: 'colleagues',
            planner   : 'dates',
            groups    : 'groups',
            office    : 'lists',
            notes     : 'notes',
            students  : 'students',
            todo      : 'todos'
        }

        this.firstInit = true
        this.bubbleDetails = []
        this.initBubbleDetails()

    }

    get( bubble )
    {
        return new Promise( resolve =>
        {

            this.database.read( 'bubbles', bubble )
                .then( count =>
                {
                    return resolve( parseInt( count ) )
                } )
                .catch( () =>
                {
                    return resolve( 0 )
                } )

        } )
    }

    getById( id )
    {
        return this.get( this.map[ id ] )
    }

    reset( bubble )
    {

        if( undefined !== bubble )
        {
            this.client.request( {
                method: 'network.resetBubble',
                bubble: bubble
            } ).then( () =>
            {
                this.database.write( 'bubbles', bubble, 0 )
            } ).catch( () =>
            {
                this.database.write( 'bubbles', bubble, 0 )
            } )
        }

    }

    resetForId( id )
    {

        this.reset( this.map[ id ] )

    }

    _resolveEncryptedBubbles( list, result )
    {
        return new Promise( resolve => {

            result = result || []
            if( 0 < list.length )
            {
                let item = list.shift()
                this.cryptoHelper.decrypt( item )
                    .then( decrypted => {

                        if( false !== decrypted )
                        {
                            result.push( decrypted )
                        }
                        return resolve( this._resolveEncryptedBubbles( list, result ) )

                    })
            }
            else
            {
                return resolve( result )
            }

        })
    }

    initBubbleDetails()
    {

        this.database
            .readAllObjects( 'bubble_details' )
            .then( list =>
            {

                let countBefore = this.bubbleDetails.length

                this._resolveEncryptedBubbles( list )
                    .then( details => {

                        this.bubbleDetails = details
                        if( this.bubbleDetails.length !== countBefore
                            || this.firstInit )
                        {
                            this.firstInit = false
                            this.eventManager.dispatch( 'on-bubble-details' )
                        }

                    })

            } )
    }

    /*eslint-disable*/
    setBubbleDetailRead( id )
    {
        let found = false

        for( let d in this.bubbleDetails )
        {
            if( null === this.bubbleDetails[ d ].datetime_read
                && id === this.bubbleDetails[ d ].idObject )
            {

                let newDetail = JSON.parse( JSON.stringify( this.bubbleDetails[ d ] ) )
                newDetail.datetime_read = new Date().toISOString()
                this.bubbleDetails[ d ] = newDetail

                this.cryptoHelper.encrypt( JSON.stringify( newDetail ) )
                    .then( crypted =>
                    {

                        this.database.write( 'bubble_details', newDetail.id, crypted )

                        let message = {
                            method: 'network.setBubbleDetailRead',
                            id    : newDetail.id
                        }

                        let jobId = this.uuid.generate()
                        this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                    } )

                found = true

            }
        }

        if( found )
        {
            this.eventManager.dispatch( 'on-bubble-details' )
            this.initBubbleDetails()
        }

    }

    getBubbleDetails()
    {
        let details = []
        for( let d in this.bubbleDetails )
        {
            if( null === this.bubbleDetails[ d ].datetime_read )
            {
                details.push( this.bubbleDetails[ d ] )
            }
        }
        return details
    }

    getNewDetailCount()
    {

        let count = 0

        for( let d in this.bubbleDetails )
        {
            if( null === this.bubbleDetails[ d ].datetime_read
                && this.bubbleDetails[ d ].notificationType !== 'delete_object' )
            {
                count++
            }
        }

        return count

    }

}