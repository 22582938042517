<script>
export default {
    name: 'ViewItemHandler',
    methods: {
        handleDetailView( referenceItem )
        {
            this.$emit( 'detailView', referenceItem )
        }
    }
}
</script>