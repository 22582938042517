import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class CompetenceCategoriesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !CompetenceCategoriesObjectClass.instance )
        {

            let refList                      = [
                    'title',
                    'color',
                    'labels',
                    'description',
                    'subCompetences',
                    'timestamp',
                    'update'
                ],
                personalAttributesStatesList = [
            {
                storage: 'pinning',
                state  : 'pinned',
                list   : 'pinLists'
            },
            {
                storage: 'hiding',
                state  : 'hidden',
                list   : 'hideLists'
            }
        ]

            super( core, 'competenceCategory', refList, personalAttributesStatesList )
            this.isStudentShareable = false
            this.objectType = 'competenceCategories'
            this.creationDependsOn = [ 'competence' ]
            this.hasDetailView = true

            CompetenceCategoriesObjectClass.instance = this

        }

        return CompetenceCategoriesObjectClass.instance

    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'pin', 'hide', 'edit', 'share', 'delete' ]
    }

    getFormFields()
    {
        return [
            {
                ref        : 'title',
                caption    : 'Bezeichnung',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'description',
                caption    : 'Beschreibung',
                type       : 'TextArea',
                reformatter: null,
                optional   : false,
                validator  : 'text',
                styleUpdate: false,
                placeholder: '_t'
            },
            {
                ref         : 'subCompetences',
                caption     : 'Teilkompetenzen',
                type        : 'SubCompetence',
                allowForeign: true,
                reformatter : null,
                optional    : true,
                validator   : null,
                styleUpdate : false
            },

        ]
    }


}