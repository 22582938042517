<script>
export default {

    name: "MixinCacheStateReporter",

    mounted()
    {

        let baseClassHelper = this.$core.getBaseClassHelper()
        if( !baseClassHelper.ready )
        {
            this.$core.getEventManager().append( 'on-baseclasses-available', () =>
            {
                this.setup( baseClassHelper )
            } )
        }
        else
        {
            this.setup( baseClassHelper )
        }

    },

    methods: {
        /*eslint-disable*/
        setup( baseClassHelper )
        {

            let baseClass = baseClassHelper.get( this.cacheState )
            baseClass.cacheHeatup()
                     .then( () =>
                     {

                         this.prepare( baseClass )

                     } )

        },

        prepare( baseClass )
        {

            if( 'string' === typeof this.$props.reference )
            {
                let ref = baseClass.getById( this.$props.reference )
                if( undefined !== ref )
                {
                    if( undefined !== this.refItem )
                    {
                        this.refItem = ref
                    }
                    else
                    {
                        this.refItems.push( ref )
                    }
                }
                this.ready = true
            }
            if( 'object' === typeof this.$props.reference )
            {
                for( let r in this.$props.reference )
                {
                    let ref = baseClass.getById( this.$props.reference[ r ] )
                    if( undefined !== ref )
                    {
                        if( undefined !== this.refItem )
                        {
                            this.refItem = ref
                        }
                        else
                        {
                            this.refItems.push( ref )
                        }
                    }
                }
                this.ready = true
            }

        }

    }

}
</script>