<template>
    <div v-if="initialized && isVisible">
        <template v-if="listSetup === 'freestyle'">
            <div class="select-wrapper" v-if="isEditor()" @click="openSelectBox()">
                <select :id="'form-element-'+refName" @change="checkFormUpdate()" @click="skip">
                    <option value="">bitte wählen...</option>
                    <optgroup label="neue Liste erzeugen">
                        <option v-for="type in types" :key="'key_lstTypeSel_'+type.value" :value="type.value"
                                :selected="value === type.value">
                            {{ type.caption }}
                        </option>
                    </optgroup>
                </select>
            </div>
        </template>
        <template v-if="listSetup === 'template'">
            <ListTemplateSelector :refName="refName"
                                  :ownTemplates="ownLists"
                                  :archivedTemplates="archivedLists"
                                  :vendorTemplates="vendorPresets"
                                  :foreignTemplates="foreignTemplates"
                                  @update="checkFormUpdate"/>
        </template>
    </div>
</template>

<script>
import MixinCachePreheater  from "@/mixins/MixinCachePreheater";
import ListTemplateSelector from "@/components/form/elements/ListTemplateSelector";

export default {

    name      : 'ListTypeSelector',
    components: { ListTemplateSelector },
    mixins    : [ MixinCachePreheater ],

    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        styleUpdate: { Type: Boolean, required: false },
        skipInit   : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'formUpdate', 'formUseTemplate' ],

    created()
    {

        if( true === this.$props.skipInit )
        {
            this.initialized = true
        }

        this.eventIndex = this.$core.getEventManager()
                              .addIndexed( 'on-list-setup-change', ( setup =>
                              {
                                  this.isVisible = true
                                  this.listSetup = setup
                              } ) )

        this.initialize()
            .then( () =>
            {
                this.$core.getEventManager()
                    .dispatchAndRemove( 'after-mount-' + this.$props.eventKey )
            } )

    },

    updated()
    {
        this.select()
    },

    mounted()
    {

        this.$nextTick().then( () =>
        {
            this.select()
            this.$nextTick().then( () =>
            {
                this.checkFormUpdate()
            } )
        } )

    },

    beforeUnmount()
    {

        this.$core.getEventManager()
            .removeIndexedCallback( 'on-list-setup-change', this.eventIndex )

        this.$core.getEventManager()
            .remove( 'after-mount-' + this.$props.eventKey )
    },

    data()
    {
        return {
            initialized     : false,
            isVisible       : false,
            listClass       : false,
            templateClass   : false,
            listSetup       : 'template',
            neededCaches    : [ 'list' ],
            types           : [],
            ownLists        : [],
            archivedLists   : [],
            ownTemplates    : [],
            foreignTemplates: [],
            sharedTemplates : [],
            schoolTemplates : [],
            vendorPresets   : [],
            lastSwitch      : 0
        }
    },

    methods: {

        initialize()
        {

            return new Promise( resolve =>
            {

                this.awaitNeededCaches( true )
                    .then( () =>
                    {

                        this.setListTypes()
                        this.templateClass = this.$core.getTemplates()

                        if( true === this.$core.settings().getSetting( 'useOldListsAsTemplates' ) )
                        {
                            this.readOwnTemplates()
                                .then( () =>
                                {
                                    return resolve( this.resolveTemplates() )
                                } )
                        }
                        else
                        {
                            return resolve( this.resolveTemplates() )
                        }

                    } )

            } )

        },

        resolveTemplates()
        {
            return new Promise( resolve =>
            {
                this.fillTemplates()
                    .then( () =>
                    {
                        this.initialized = true
                        return resolve()
                    } )
            } )
        },

        listHasColumns( list )
        {
            if( undefined !== list.columns
                || ( Array.isArray( list.lists ) && undefined !== list.lists[ 0 ].columns ) )
            {
                return true
            }
            return false
        },

        isValidListObject( list )
        {
            if( undefined !== this.$core.getBaseClassHelper()
                                  .get( 'list', list.listType ) )
            {
                return true
            }
            return false
        },

        readOwnTemplates()
        {

            return new Promise( resolve =>
            {

                let lists    = this.$core.getBaseClassHelper()
                                   .get( 'list' )
                                   .getCache( 'cache' ),
                    archived = this.$core.getBaseClassHelper()
                                   .get( 'list' )
                                   .getCache( 'archive' )

                this.ownLists = []

                /*eslint-disable*/
                for( const [ e, list ] of lists )
                {
                    if( this.isValidListObject( list )
                        && this.listHasColumns( list ) )
                    {
                        this.ownLists.push( {
                            color   : list.color,
                            listname: list.listname,
                            localId : list.localId,
                            type    : list.listType
                        } )
                    }
                }
                this.$core.sort().multiSortObjects( this.ownLists, [ [ 'listname', 'ascending' ] ], true )

                this.archivedLists = []

                for( const [ e, list ] of archived )
                {
                    if( this.isValidListObject( list )
                        && this.listHasColumns( list ) )
                    {

                        this.archivedLists.push( {
                            color   : list.color,
                            listname: list.listname,
                            localId : list.localId,
                            type    : list.listType
                        } )

                    }
                }
                this.$core.sort().multiSortObjects( this.archivedLists, [ [ 'listname', 'ascending' ] ], true )
                /*eslint-enable*/

                return resolve()

            } )

        },

        /*eslint-disable*/
        vendorPresetColor( idx )
        {

            let colors     = this.$core.getSettingsWorker().getSetting( 'colorSequence' ),
                colorCount = this.$core.getSettingsWorker().getSetting( 'colorCount' )

            while( idx > ( colorCount - 1 ) )
            {
                idx -= colorCount
            }

            return colors[ idx ]

        },

        fillTemplates()
        {

            return new Promise( resolve =>
            {

                this.vendorPresets = []
                this.ownTemplates = []
                this.schoolTemplates = []

                let vendorIdx = 0

                if( this.templateClass.ready )
                {
                    for( let t in this.templateClass.templates )
                    {

                        let template = this.templateClass.templates[ t ],
                            preset   = {
                                value  : t,
                                caption: template.listname,
                                type   : template.listType
                            },
                            temp
                        switch( template.type )
                        {
                            case 'vendorPreset':
                                temp = preset.caption.split( / /g )
                                if( 2 >= temp[ 0 ].length )
                                {
                                    temp.shift()
                                    preset.caption = temp.join( ' ' )
                                }
                                preset.color = this.vendorPresetColor( vendorIdx )
                                vendorIdx++
                                this.vendorPresets.push( preset )
                                break
                            case 'sharedPreset':
                                this.sharedTemplates.push( preset )
                                break
                            case 'personalPreset':
                                if( this.$core.f().isOwn( preset ) )
                                {
                                    this.ownTemplates.push( preset )
                                }
                                else
                                {
                                    this.foreignTemplates.push( preset )
                                }
                                break
                            case 'schoolPreset':
                                this.schoolTemplates.push( preset )
                                break
                        }

                    }

                    return resolve()

                }
                else
                {
                    this.$core.getEventManager().add( 'on-templates-loaded', () =>
                    {
                        return resolve( this.fillTemplates() )
                    } )
                }

            } )

        },

        setListTypes()
        {

            let availableTypes = [
                'checklist',
                'ratinglist',
                'customFixed',
                'recallList',
                'test',
                'combilist'
            ]

            for( let a in availableTypes )
            {
                let type = {
                    value  : availableTypes[ a ],
                    caption: this.$core.t( 'list-type-' + availableTypes[ a ] )
                }
                if( this.$core.getLicense().isAllowed( 'listtype_' + type.value.toLowerCase() ) )
                {
                    this.types.push( type )
                }
            }

        },

        select()
        {
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element
                && undefined !== this.$props.display )
            {
                if( this.$props.editor === true )
                {
                    element.value = this.$props.value
                }
                else
                {
                    element.value = ''
                }

            }
        },

        isEditor()
        {
            return ( undefined === this.$props.display || this.$props.editor === true )
        },
        skip( event )
        {
            event.stopPropagation()
        },
        openSelectBox()
        {

            let event   = new MouseEvent( 'click' ),
                element = document.querySelector( '#form-element-' + this.$props.refName )

            if( null !== element )
            {
                element.dispatchEvent( event )
            }

        },

        getFormFieldsForList( listType )
        {

            let baseClass = this.$core.getBaseClassHelper()
                                .get( 'list', listType )

            if( undefined !== baseClass )
            {
                return baseClass.getListFormFields( listType )
            }

        },

        /*eslint-disable*/
        checkFormUpdate( fields )
        {

            if( 'freestyle' === this.listSetup )
            {
                let element = document.querySelector( '#form-element-' + this.$props.refName )
                if( null !== element )
                {
                    this.$emit( 'formUpdate', this.getFormFieldsForList( element.value ), element.value )
                }
            }
            else
            {
                this.$emit( 'formUpdate', fields )
            }

        }

        /*,
        checkLocked( listType )
        {

            return !this.$core.getLicense().isAllowed( 'listtype_' + listType )

        },

        prepareVendorListAsTemplate( value )
        {

            let id = value.replace( 'template_vendor_', '' )
            this.loadTemplateById( id )

        },

        prepareOwnTemplateListAsTemplate( value )
        {

            let temp = value.split( '_' ),
                id   = temp[ 2 ] + '_' + temp[ 3 ]

            this.loadTemplateById( id )

        }*/

    }

}
</script>