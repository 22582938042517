<template>
    <transition appear mode="out-in" :name="$core.settings().getTransition( 'crossfade' )">
        <div v-if="ready">
            <table class="list" :id="'printable-'+item.referenceKey">
                <thead>
                <tr class="list-header">
                    <th>&nbsp;</th>
                    <th v-for="( col, index ) in data[0].columns"
                        :key="'th-col-'+col.columnId">
                        <template v-if="undefined !== data[0].listItem.columns[( index + 1 )]
                                && 'fixed' !== data[0].listItem.columns[( index + 1 )].type
                                && 'testcomment' !== data[0].listItem.columns[( index + 1 )].type">
                            <TableHeadRotated :caption="col.caption"
                                              :inline="true"
                                              :keyV="'th-col-'+col.columnId+'-'+elementKey"
                                              :score="data[ 0 ].listItem.columns[ ( index + 1 ) ].score"/>
                        </template>
                        <template v-if="'testcomment' === data[0].listItem.columns[( index + 1 )].type">
                            <TableHeadRotated :caption="'Kommentar / Notiz'"
                                              :inline="true"
                                              :keyV="'th-col-'+col.columnId+'-'+elementKey"
                                              :score="data[ 0 ].listItem.columns[ ( index + 1 ) ].score"/>
                        </template>
                        <template v-if="'___averageScoreCalculation' === col.columnId">
                            <br/><span class="score-heading">{{ totalScore }} Punkte</span>
                        </template>
                    </th>
                    <th>
                        <span class="rotated">&sum; Gesamt</span>
                        <br/><span class="score-heading">{{
                            $core.getReformatter().localizedFloat( totalScore, 1 )
                        }} Punkte</span>
                    </th>
                    <th>
                        <span class="rotated">Note</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                <template v-for="( row, index ) in students" :key="'tpl-'+index">
                    <template v-if="undefined !== row">
                        <tr v-if="filterBy !== 'student' || filterId === row.localId"
                            v-show="true === isVisible.get( row.localId )"
                            @click="handleRowClick( row )"
                            :id="'tr-'+item.localId+'-'+row.localId+'-'+index"
                            :key="'tr-'+row.localId+'-'+index">
                            <td :class="'sensitive studentName'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )">
                                <strong>{{ row.lastname }}</strong>, {{ row.firstname }}
                            </td>
                            <template v-for="( col, index ) in data[0].columns"
                                      :key="'td-'+row.localId+'-'+col.columnId">
                                <td class="td-subscore" v-if="col.type !== 'testcomment'">
                                    <template v-if="undefined !== data[ 0 ].listItem.values">
                                    <span>
                                    {{
                                            data[ 0 ].listItem.values[ row.localId + '___' + col.columnId + '___' + ( 1 + index ) ]
                                        }}
                                        </span>
                                    </template>
                                </td>
                                <td class="td-textarea" v-else
                                    v-html="undefined !== data[ 0 ].listItem.values ? data[ 0 ].listItem.values[ row.localId + '___' + col.columnId + '___' + ( 1 + index ) ] : ''">
                                </td>
                            </template>
                            <td :id="'score-'+row.localId">
                                <template
                                    v-if="item._tc.summary.byStudent[ data[ 0 ].listItem.localId ][ row.localId ]">
                                    {{
                                        $core.getReformatter().floatToLocal( item._tc.summary.byStudent[ data[ 0 ].listItem.localId ][ row.localId ].__total, 1 )
                                    }}
                                </template>
                            </td>
                            <td :id="'rating-'+row.localId" class="td-scorebox">
                                {{ ratings[ row.localId ] }}
                            </td>
                        </tr>
                    </template>
                </template>
                </tbody>
            </table>
        </div>
    </transition>
</template>

<script>
import ListFilterable    from "@/components/elements/viewItems/listViews/ListFilterable";
import TableHeadRotated  from "@/components/elements/viewItems/listViews/tableElements/TableHeadRotated";
import MixinStructureKey from "@/components/elements/viewItems/listViews/mixins/MixinStructureKey";
import MixinLumHooks     from "@/components/elements/viewItems/listViews/mixins/MixinLumHooks.vue";

export default {

    name      : 'ListScores',
    components: { TableHeadRotated },
    emits     : [ 'rowClick', 'dataUpdate' ],
    mixins    : [ MixinStructureKey, MixinLumHooks ],
    extends   : ListFilterable,

    props: {
        item        : { type: Object, required: true },
        elementKey  : { Type: String, required: false },
        inListEditor: { type: Boolean, required: false, default: false }
    },

    watch: {
        elementKey: {
            immediate: true,
            handler( newValue, oldValue )
            {

                if( true !== this.$core.getState( 'listEditorOpen' )
                    && newValue !== oldValue
                    && this.ready )
                {
                    this.prepare()
                        .then( () =>
                        {
                            this.$emit( 'dataUpdate' )
                        } )
                }
                else if( false === this.registeredEvent
                         && true === this.$core.getState( 'listEditorOpen' )
                         && newValue !== oldValue
                         && this.ready )
                {
                    this.$core.getEventManager()
                        .append( 'on-listeditor-closed', () =>
                        {
                            this.prepare()
                                .then( () =>
                                {
                                    this.$emit( 'dataUpdate' )
                                } )
                        } )
                    this.registeredEvent = true
                }
            }
        },

        item: {
            immediate: true,
            handler()
            {
                if( this.ready )
                {
                    this.prepare()
                }
            }
        }
    },

    data()
    {
        return {
            registeredEvent: false,
            data           : false,
            ready          : false,
            firstcolumn    : [],
            columns        : false,
            students       : false,
            totalScore     : 0,
            totals         : false,
            filterBy       : false,
            filterId       : false,
            isUpdating     : false,
            preparing      : false,
            ratings        : {}
        }
    },

    created()
    {
        this.prepare()
    },

    mounted()
    {
    },

    methods: {

        _archiveMatch( student, timestamp )
        {

            if( student.archived !== true )
            {
                return true
            }

            let temp = student.archiveKey.split( /-/g )
            temp.shift()
            let archiveTimestamp = parseInt( temp.join( '' ) )
            if( archiveTimestamp >= parseInt( timestamp ) )
            {
                return true
            }

            return false

        },

        prepare()
        {

            return new Promise( resolve =>
            {

                if( !this.preparing
                    && undefined !== this.firstcolumn )
                {
                    this.preparing = true

                    let tmp = this.$core.getState( 'detailViewFor' )
                    if( undefined !== tmp )
                    {
                        let temp = tmp.split( ':' )
                        this.filterBy = temp[ 0 ]
                        this.filterId = temp[ 1 ]
                    }

                    this.totalScore = 0
                    let sortRules = this.$core.settings().getSetting( 'sortingDirections' ),
                        itemList  = this.$props.item.lists

                    this.$core.sort().multiSortObjects( itemList, sortRules[ 'lists' ] )

                    this.$core.getTableCalculation().getSummary( itemList, true )
                        .then( calculation =>
                        {

                            this.totals = calculation.totals
                            this.data = calculation.result

                            for( let l in calculation.result )
                            {

                                let row = calculation.result[ l ]

                                switch( this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'firstColumn' ) )
                                {
                                    case 'translate-average':
                                        this.firstcolumn.push( 'Durchschnitt' )
                                        break
                                    default:
                                        this.firstcolumn.push( this.$core.getFriendlyTimestamp().friendlyDate( row.timestamp ) )
                                        break
                                }
                            }

                            this.columns = this.$core.getTableCalculation().getSummaryDefinition( this.$props.item.lists, 'columns' )

                            if( this.$props.item.listType === 'test' )
                            {
                                for( let i in this.$props.item.lists[ 0 ].columns )
                                {
                                    if( undefined !== this.$props.item.lists[ 0 ].columns[ i ].score )
                                    {
                                        this.totalScore += this.$core.getReformatter().float( this.$props.item.lists[ 0 ].columns[ i ].score )
                                    }
                                }
                            }

                            let studentList = [],
                                filter      = this.$props.item.lists[ 0 ].columns[ 0 ].filterBy


                            if( 'all' === filter )
                            {
                                let scope = this.$props.item.archived ? 'archive' : 'cache',
                                    all   = this.$core.getBaseClassHelper().get( 'student' ).getCache( scope )

                                /*eslint-disable*/
                                for( const [ a, st ] of all )
                                {
                                    studentList.push( st.localId )
                                }
                                /*eslint-enable*/
                            }
                            else
                            {
                                let elm = this.$core.getBaseClassHelper().getObjectById( filter )
                                studentList = elm.students
                            }

                            this.students = []

                            let student
                            if( 1 === this.$store.getters.isStudent )
                            {
                                for( let s in studentList )
                                {
                                    let temp = this.$core.getBaseClassHelper().get( 'student' ).registry.studentShadows.get( studentList[ s ] )
                                    if( undefined !== temp )
                                    {
                                        student = JSON.parse( JSON.stringify( this.$core.getBaseClassHelper().get( 'student' ).getById( temp ) ) )
                                        student.localId = studentList[ s ]
                                        if( this.$core.f().valid( student )
                                            && this._archiveMatch( student, this.$props.item.lists[ 0 ].timestamp ) )
                                        {
                                            this.students.push( student )
                                        }
                                    }
                                    else
                                    {
                                        temp = this.$core.getBaseClassHelper().get( 'student' ).getById( studentList[ s ] )
                                        if( this.$core.f().valid( temp )
                                            && this._archiveMatch( temp, this.$props.item.lists[ 0 ].timestamp ) )
                                        {
                                            if( temp.isShadowCopy )
                                            {
                                                temp.localId = temp.shadowedId
                                            }
                                            this.students.push( temp )
                                        }
                                    }
                                }
                            }
                            else
                            {
                                for( let s in studentList )
                                {
                                    student = this.$core.getBaseClassHelper().get( 'student' ).getById( studentList[ s ] )
                                    if( this.$core.f().valid( student )
                                        && this._archiveMatch( student, this.$props.item.lists[ 0 ].timestamp ) )
                                    {
                                        this.students.push( student )
                                    }
                                }
                            }

                            this.$core.sort().multiSortObjects( this.students, sortRules[ 'students' ] )
                            this.ready = true

                            this.$nextTick()
                                .then( () =>
                                {
                                    this.updateRatings()
                                } )

                            this.preparing = false
                            return resolve()

                        } )

                }
            } )

        },

        updateRatings()
        {

            this.ratings = {}
            let scores = this.$core.getTestScoreCalculation().calculate( this.$props.item.lists[ 0 ] )
            for( let s in scores.scores )
            {
                this.ratings[ s ] = scores.scores[ s ].label || scores.scores[ s ].number
            }

        },

        handleRowClick()
        {
            this.$emit( 'rowClick', this.$props.item.lists[ 0 ] )
        }
    }

}
</script>