<template>
    <transition appear name="fade">
        <div v-if="visible" class="collab-editor-focusbox">
            {{ displayName }}
        </div>
    </transition>
</template>

<script>
export default {

    name : "CollabEditingFocusBox",
    props: {
        colleague: { type: Number, required: false, default: 0 }
    },

    data()
    {
        return {
            displayName: ''
        }
    },

    computed: {
        visible()
        {
            return 0 !== this.$props.colleague && this.$store.getters.idUser !== this.$props.colleague
        }
    },

    watch: {
        colleague: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && 0 !== newValue )
                {
                    this.setDisplayName()
                }
            }
        }
    },

    methods: {
        setDisplayName()
        {
            this.$core.cc()
                .awaitReadiness()
                .then( () => {

                    let colleague = this.$core.cc().getById( this.$props.colleague )
                    if( undefined !== colleague )
                    {
                        this.displayName = colleague.displayName
                    }

                })
        }
    }

}
</script>