<script>
import FieldTypeToComponent from "@/classes/Helpers/FieldTypeToComponent";

export default {

    name : "MixinListEditorPrepare",
    emits: [ 'editModeSwitch' ],

    data()
    {
        return {
            lines               : [],
            tdmarks             : {},
            lastLocalChange     : Date.now(),
            valueClone          : null,
            readOnly            : false,
            ccReady             : false,
            initialized         : false,
            remoteDiffers       : false,
            showMerge           : false,
            showEditAllActive   : false,
            listFilterInline    : false,
            hideEditAllWarning  : false,
            notificationActive  : false,
            notificationText    : '',
            notificationCount   : 0,
            editorPrepared      : false,
            swiped              : false,
            swipedRight         : false,
            swipeHandlers       : false,
            rightOnce           : false,
            fieldTypeToComponent: false,
            snapshots           : []
        }
    },

    props: {
        list             : { Type: Object, required: true },
        students         : { Type: Object, required: true },
        studentList      : { Type: Array, required: true },
        reference        : { Type: Object, required: false },
        escapeNested     : { Type: Boolean, required: false, default: false },
        listFilter       : { type: [ Object, Boolean ], required: true },
        listFilterVisible: { Type: Boolean, required: false, default: false },
        recallVisible    : { Type: Boolean, required: false, default: false },
        openVertical     : { Type: Boolean, required: false, default: false },
        showCalcFields   : { Type: Boolean, required: false, default: false },
        editMode         : { Type: String, required: false, default: 'horizontal' },
        editClosing      : { Type: Boolean || Object, required: false, default: false },
        editHistory      : { Type: Boolean || Number, required: false, default: false },
        editExcel        : { Type: Boolean, required: false, default: false },
        editPrint        : { Type: Boolean, required: false, default: false },
    },

    /*eslint-disable*/
    mounted()
    {

        this.localTimestamp = parseInt( this.$props.list.remoteUpdateTimestamp )
        this.skipPreSync = true

        this.$core.cc().awaitReadiness()
            .then( () =>
            {
                this.ccReady = true
            } )

        this.getSnapshots()

        this.preSync()
            .then( () =>
            {

                this.$emit( 'editModeSwitch', ( true === this.$props.openVertical ? 'vertical' : 'horizontal' ) )

                if( this.$core.f().isset( this.$props.list.values ) )
                {
                    this.valueClone = this.$core.f().deref( this.$props.list.values )
                    if( this.$props.list.listType === 'combilist' )
                    {
                        this.prepareCombilistValueClone()
                    }
                }

                if( this.$core.getLicense().forceReadOnly() )
                {
                    this.$core.setState( 'listEditorOpen', false )
                    this.handleClose()
                }
                else
                {

                    this.readOnly = ( this.$props.list.listType === 'combilist' || this.$props.list.archived || !this.$core.r().isAllowed( this.$props.list, 'fill' ) )
                    if( -1 < this.$props.list.localId.indexOf( 'demo-' ) )
                    {
                        this.readOnly = true
                    }

                    this.$core.setState( 'editing-object', this.$props.list.localId )
                    this.fieldTypeToComponent = new FieldTypeToComponent()
                    this.prepare()

                }

                if( !this.initialized )
                {
                    this.initialized = true
                    this.$emit( 'containerUpdate', this.$props.list.localId, 'initialized', true )
                }

            } )

    },

    beforeUnmount()
    {

        this.cleanup()
        this.fieldTypeToComponent = null

    },

    methods: {

        prepareCombilistValueClone()
        {
            for( let k in this.valueClone )
            {
                let temp = k.split( /___/g )
                if( 3 === temp.length )
                {

                    let indicator = temp[1],
                        clean = indicator.split( /_/ )

                    clean.shift()
                    temp[1] = clean.join( '_' )

                    this.valueClone[ temp.join( '___' ) ] = this.valueClone[k]


                }
            }
        },

        cleanup()
        {

            for( let l in this.lines )
            {
                this.removeElement( l )
            }

            this.lines = undefined
            this.tdmarks = undefined
            this.valueClone = undefined

        },

        prepare()
        {

            let filter   = this.$core.getState( 'detailViewFor' ),
                filterId = undefined

            if( undefined !== filter )
            {
                let temp = filter.split( ':' )
                if( 'student' === temp[ 0 ] )
                {
                    filterId = temp[ 1 ]
                }
            }

            this.prepareLines( filterId )

        },

        getSnapshots()
        {

            if( true !== this.$store.getters.online
                || this.$core.getFlags().is( 'demouser' ) )
            {
                return
            }

            this.$core.getListSnapshotHelper()
                .getAvailableSnapshots( this.$props.list.localId )
                .then( result =>
                {

                    this.snapshots = result
                    this.$emit( 'containerUpdate', this.$props.list.localId, 'snapshots', result )
                    result = null

                } )

        },

        setEventHandlers()
        {
            for( let l in this.lines )
            {
                this.setTouchEventHandler( l )
            }
        },

        removeElement( row )
        {
            let elm = document.querySelector(
                '#' + 'swipeable-' + row + '-' + 0
            )
            if( null !== elm )
            {
                elm.remove()
            }
        },

        // CLEANUP MISSING
        setTouchEventHandler( row, retries )
        {

            retries = retries || 0
            let elm = document.querySelector(
                '#' + 'swipeable-' + row + '-' + 0
            )

            if( null !== elm )
            {

                elm.addEventListener( 'mousedown', ( event ) =>
                {
                    this.handleSwipe( event, 'left', row )
                } )

                elm.addEventListener( 'touchstart', ( event ) =>
                {
                    this.touchStartX = event.changedTouches[ 0 ].screenX
                    this.touchStartY = event.changedTouches[ 0 ].screenY
                }, { passive: true } )

                elm.addEventListener( 'touchend', ( event ) =>
                {
                    this.touchEndX = event.changedTouches[ 0 ].screenX
                    this.touchEndY = event.changedTouches[ 0 ].screenY
                    this.handleGesture( elm, row )
                }, { passive: true } )

            }
            else
            {

                retries++
                if( 10 > retries )
                {
                    this.$core.getCoreTimer()
                        .addTimeout( 'listeditor-reset-toucheventhandler-' + row, 250, () =>
                        {

                            this.setTouchEventHandler( row, retries )
                        } )
                }
            }

        },

        handleGesture( element, row )
        {

            let touchDistance = this.touchStartX - this.touchEndX
            if( 0 > touchDistance )
            {
                touchDistance = touchDistance * -1
            }

            let touchDistanceY = this.touchStartY - this.touchEndY
            if( 0 > touchDistanceY )
            {
                touchDistanceY = touchDistanceY * -1
            }

            this.touchDistance = touchDistance
            this.touchDistanceY = touchDistanceY

            if( touchDistanceY > 50 )
            {
                this.$core.getLogger().clog( 'DefaultTouchable:handleGesture', 'assuming scroll event...' )
                return
            }

            if( touchDistance < 60 )
            {

                this.$core.getLogger().clog( 'DefaultTouchable:handleGesture', 'distance too short: assuming click event...' )

            }
            else
            {

                if( this.touchEndX < this.touchStartX )
                {
                    this.handleSwipe( false, 'left', row )
                }

                if( this.touchEndX > this.touchStartX )
                {
                    this.handleSwipe( false, 'right', row )
                }

            }

        },

        handleSwipe( bareEvent, direction, row )
        {

            if( this.$core.getLicense().forceReadOnly() )
            {
                return
            }

            if( false !== bareEvent
                && undefined !== bareEvent.button
                && 0 === bareEvent.button )
            {
                return
            }

            if( false !== this.swiped
                && parseInt( row ) === this.swiped )
            {
                this.swiped = false
                return
            }

            if( false !== bareEvent
                && undefined !== bareEvent.button
                && 2 === bareEvent.button )
            {
                bareEvent.preventDefault()
            }

            if( undefined === direction && !this.rightOnce )
            {
                direction = 'left'
                this.rightOnce = true
            }

            if( undefined === direction && this.rightOnce )
            {
                direction = 'right'
                this.rightOnce = false
            }

            if( 'left' === direction )
            {

                this.swiped = parseInt( row )
                this.$core.getCoreTimer()
                    .addTimeout( 'listeditor-set-swipe-right', 400, () =>
                    {

                        this.swipedRight = true

                    } )
            }
            else
            {
                this.swipedRight = false
                this.$core.getCoreTimer()
                    .addTimeout( 'listeditor-reset-swipe', 400, () =>
                    {
                        this.swiped = false
                    } )
            }

        },

        preSync()
        {
            return new Promise( resolve =>
            {

                if( true !== this.$store.getters.online
                    || this.$core.getFlags().is( 'demouser' )
                    || true === this.skipPresync )
                {
                    return resolve()
                }

                this.$core.getCryptoHelper()
                    .waitReady()
                    .then( () =>
                    {

                        this.$core.getSyncWorker()
                            .singleObjectSync( this.$props.list.localId )
                            .then( list =>
                            {

                                if( 0 === Object.keys( list.result ).length )
                                {
                                    list = null
                                    return resolve()
                                }

                                for( let i in list.result )
                                {

                                    let element     = list.result[ i ],
                                        tsmpRemote  = new Date( element.datetime_updated ).getTime(),
                                        differences = false,
                                        localKey    = this.$core.getCryptoHelper().getLocalKey( element )

                                    this.$core.getCryptoHelper()
                                        .decryptElement( localKey, this.$core.f().deref( element.object ) )
                                        .then( decrypted =>
                                        {

                                            if( tsmpRemote > this.localTimestamp
                                                && tsmpRemote > ( this.lastLocalChange + this.localChangeThreshold ) )
                                            {
                                                differences = true
                                            }

                                            if( false !== decrypted )
                                            {
                                                this.remoteValues = decrypted.values

                                                if( differences
                                                    && undefined !== decrypted.values
                                                    && this.checkDiffs( this.remoteValues, this.$props.list.values ) )
                                                {
                                                    this.localTimestamp = tsmpRemote
                                                    this.remoteDiffers = true
                                                    this.showMerge = false
                                                    this.mergeRemote()
                                                    decrypted = null
                                                    list = null
                                                    return resolve()
                                                }
                                                else
                                                {
                                                    decrypted = null
                                                    list = null
                                                    return resolve()
                                                }
                                            }

                                        } )

                                }

                            } )
                            .catch( () =>
                            {
                                return resolve()
                            } )

                    } )

            } )
        },

        _archiveMatch( student, timestamp )
        {

            if( student.archived !== true )
            {
                return true
            }

            let temp = student.archiveKey.split( /-/g )
            temp.shift()
            let archiveTimestamp = parseInt( temp.join( '' ) )
            if( archiveTimestamp >= parseInt( timestamp ) )
            {
                return true
            }

            return false

        },

        prepareLines( filterId )
        {

            let refs   = this.$core.f().deref( this.$props.students ),
                rules  = this.$core.settings().getSetting( 'sortingDirections' ),
                fields = []

            this.$core.getSorter().multiSortObjects( refs, rules.students )

            for( let c in this.$props.list.columns )
            {
                if( 0 < c )
                {
                    let field = this.$props.list.columns[ c ]
                    fields.push( field )
                }
            }

            for( let r in refs )
            {

                if( null !== refs[ r ]
                    && ( undefined !== refs[ r ].lastname && undefined !== refs[ r ].firstname )
                    && ( true !== refs[ r ].archived
                         || this._archiveMatch( refs[ r ], this.$props.list.timestamp ) ) )
                {

                    let line = []

                    line.push( {
                        name     : 'student',
                        display  : '<div class="sensitive studentName ' + ( this.$core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' ) + '">' +
                                   '    <strong>' + refs[ r ].lastname + '</strong>, ' + refs[ r ].firstname +
                                   '</div>',
                        id       : refs[ r ].localId,
                        component: false,
                        disabled : ( filterId !== undefined && refs[ r ].localId !== filterId )
                    } )

                    for( let f in fields )
                    {

                        let valueId = [
                            refs[ r ].localId,
                            this.$core.getSanitizers().cleanId( fields[ f ].caption ),
                            ( 1 + parseInt( f ) )
                        ].join( '___' )

                        let comp = this.fieldTypeToComponent.getComponent( fields[ f ].type )

                        line.push( {
                            name       : fields[ f ].caption,
                            id         : valueId,
                            display    : this.$core.f().isset( this.valueClone ) ? this.valueClone[ valueId ] : undefined,
                            type       : fields[ f ].type,
                            component  : comp.editor,
                            placeholder: comp.placeholder,
                            disabled   : ( filterId !== undefined && refs[ r ].localId !== filterId )
                        } )

                    }

                    this.lines.push( line )

                }

            }

            this.$nextTick()
                .then( () =>
                {

                    this.editorPrepared = true

                    refs = null
                    rules = null
                    fields = null

                    if( !this.swipeHandlers )
                    {
                        this.swipeHandlers = true
                        setTimeout( () =>
                        {

                            this.$core.getEventManager().dispatchIndexed( 'on-autosize' )
                            this.setEventHandlers()

                        }, 500 )
                    }
                } )

        },

        printTestCutmarked()
        {

            this.$nextTick()
                .then( () =>
                {

                    this.handlePrint( [ 'printTestCutmarked', true ] )
                    this.$core.getUi().delay( () =>
                    {
                        this.modeSwitch( 'vertical' )
                    }, 1000 )

                } )

        },

        handlePrintTestCutmarked()
        {

            this.addEvent( 'after-editor-mode-switched', () =>
            {
                this.printTestCutmarked()
            } )

            this.modeSwitch( 'horizontal' )

        },

        handlePrint( options )
        {

            let listItem = this.$props.list,
                opt      = {
                    editorMode: true,
                    elementId : 'list-editor-' + this.editMode + '-' + listItem.localId
                }

            if( listItem.listType === 'test' && 'vertical' === this.editMode )
            {
                this.handlePrintTestCutmarked()
                return
            }

            let appendTitle = undefined
            if( undefined !== listItem.columns[ 0 ].filterBy )
            {

                let element = this.$core.getBaseClassHelper()
                                  .getObjectById( listItem.columns[ 0 ].filterBy )

                if( 'all' === listItem.columns[ 0 ].filterBy )
                {
                    appendTitle = ' ' + this.$core.t( 'generic-all-students' )
                }
                else
                {
                    appendTitle = this.$core.t( 'object-type-' + element.type )
                    switch( element.type )
                    {
                        case 'class':
                            appendTitle += ' ' + element.classname
                            break
                        case 'group':
                            appendTitle += ' ' + element.groupname
                            break
                        case 'yeargroup':
                            appendTitle += ' ' + element.yeargroupname
                            break
                    }
                }
                if( undefined !== options )
                {
                    opt[ options[ 0 ] ] = options[ 1 ]
                }
                opt.appendTitle = appendTitle
                this.$core.getPrinting()
                    .print( listItem, this.editMode, undefined, opt )
            }
            else
            {
                if( undefined !== options )
                {
                    opt[ options[ 0 ] ] = options[ 1 ]
                }

                this.$core.getPrinting()
                    .print( listItem, this.editMode, undefined, opt )
            }

        },

        handleExcel()
        {
            this.$core.getExcel()
                .export( this.$props.list, 'list-editor-' + this.editMode + '-' + this.$props.list.localId )
        }

    }

}
</script>