<template>
    <div :key="'colleague-general-'+item.elementKey" v-if="initialized" class="padded-bottom">
        <h2>{{ $core.t( 'headline-colleague-general-rights' ) }}</h2>
        <p v-if="hasAdminProvisioning">
            <strong>Die generellen Berechtigungen werden vom Administrator dieser Schullizenz eingestellt und lassen
                sich nicht individualisieren.</strong>
        </p>
        <div class="spacer"></div>
        <Checkbox :caption="$core.t( 'button-caption-set-general-rights-for-all' )"
                  v-if="!hasAdminProvisioning"
                  id="set-global"
                  :value="setGlobal"
                  @update="toggleGlobal()"
                  refName="set-global"/>
        <div v-for="set in setters"
             class="rights switchboard"
             :key="set.id">
            <div class="rights-headline">
                <h3>{{ $core.t( 'object-type-' + set.caption ) }}</h3>
            </div>
            <div class="rights-switches">
                <template v-for="( s, index ) in set.switches"
                          :key="set.id+'-'+s.value">
                    <div class="half rights-pad">
                        <div class="switch">
                            <Toggle :id="'general-'+set.id+'-'+s.value"
                                    :refName="'general-'+set.id+'-'+s.value"
                                    :value="values['general-'+set.id+'-'+s.value]"
                                    :disabled="hasAdminProvisioning"
                                    disabled-comment="vom Administrator eingestellt"
                                    @update="handleToggle"/>
                        </div>
                        <div class="caption"><span :class="'strong'+( 'delete' === s.value ? ' error' : '' )">
                                        {{ $core.f().ucFirst( $core.t( s.value ) ) }}</span>
                        </div>
                        <div v-if="index%2 == 1" class="clearfix"></div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import Toggle               from '@/components/form/elements/Toggle'
import MixinWatchElementKey from '@/mixins/MixinWatchElementKey'
import Checkbox             from '@/components/form/elements/Checkbox'

export default {
    name      : 'ColleagueGeneralRights',
    components: { Checkbox, Toggle },
    mixins    : [ MixinWatchElementKey ],
    props     : {
        item: { type: Object, required: true }
    },

    data()
    {
        return {
            hasAdminProvisioning: false,
            updateTimer         : false,
            initialized         : false,
            itemClone           : false,
            setGlobal           : false,
            values              : {},
            setters             : [
                {
                    caption : 'classes',
                    id      : 'classElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'groups',
                    id      : 'groupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'yeargroups',
                    id      : 'yeargroupElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'students',
                    id      : 'studentElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'readMeta'
                        },
                        {
                            value: 'changeMeta'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'notes',
                    id      : 'noteElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'todos',
                    id      : 'todoElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'markDone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'dates',
                    id      : 'dateElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'lists',
                    id      : 'listElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'fill'
                        },
                        {
                            value: 'clone'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                },
                {
                    caption : 'media',
                    id      : 'mediaElements',
                    switches: [
                        {
                            value: 'read'
                        },
                        {
                            value: 'change'
                        },
                        {
                            value: 'delete'
                        }
                    ]
                }
            ]
        }
    },

    created()
    {
        this.prepare()
        this.initialized = true
    },

    methods: {

        prepare()
        {
            this.itemClone = JSON.parse( JSON.stringify( this.$props.item ) )
            if( undefined !== this.$core.r().adminRights
                && undefined !== this.$core.r().adminRights.rights
                && undefined !== this.$core.r().adminRights.rights.general )
            {
                if( 0 < Object.keys( this.$core.r().adminRights.rights.general ).length )
                {
                    this.hasAdminProvisioning = true
                }
            }
            this.initializeValues()
        },

        initializeValues()
        {

            let defaults = this.$core.r().getRights( 'basic' )

            for( let s in this.setters )
            {

                let set     = this.setters[ s ],
                    scope   = 'general',
                    section = set.id

                for( let sw in set.switches )
                {
                    let swkey       = set.switches[ sw ],
                        adminRights = this.$core.r().adminRights,
                        value

                    switch( this.hasAdminProvisioning )
                    {
                        case true:
                            value = this.$core.f().isset( adminRights )
                                    && this.$core.f().isset( adminRights.rights[ scope ] )
                                    && this.$core.f().isset( adminRights.rights[ scope ][ section ] )
                                    && this.$core.f().isset( adminRights.rights[ scope ][ section ][ swkey.value ] ) ? adminRights.rights[ scope ][ section ][ swkey.value ] : defaults.rights[ section ][ swkey.value ]
                            break
                        case false:
                            value = this.$core.f().isset( this.itemClone.rights )
                                    && this.$core.f().isset( this.itemClone.rights[ scope ] )
                                    && this.$core.f().isset( this.itemClone.rights[ scope ][ section ] )
                                    && this.$core.f().isset( this.itemClone.rights[ scope ][ section ][ swkey.value ] ) ? this.itemClone.rights[ scope ][ section ][ swkey.value ] : defaults.rights[ section ][ swkey.value ]
                            break
                    }

                    this.values[ scope + '-' + section + '-' + swkey.value ] = value

                }

            }

        },

        copyRights( template )
        {

            let colleagues = this.$core.cc().getAll()

            for( let c in colleagues )
            {

                let colleague = JSON.parse( JSON.stringify( colleagues[ c ] ) )
                if( colleague.colleagueId !== this.itemClone.colleagueId )
                {

                    colleague.rights = colleague.rights || {}
                    colleague.rights.general = template

                    let setup         = {
                            role  : colleague.role,
                            rights: colleague.rights
                        },
                        jsonSetup     = JSON.stringify( setup ),
                        cryptedRights = this.$core.getCryptoCore().encryptWithPublicKey(
                            colleague.publicKey,
                            jsonSetup
                        )

                    this.$core.getCryptoHelper()
                        .encrypt( this.$core.getCryptoHelper().encrypt( jsonSetup ) )
                        .then( cryptedStore =>
                        {

                            let params = {
                                method     : 'network.storeColleagueRights',
                                colleagueId: colleague.colleagueId,
                                rights     : cryptedRights,
                                ownRights  : btoa( JSON.stringify( cryptedStore ) )
                            }

                            let jobId = this.$core.getUuid().generate()
                            this.$core.getQueueWorker().enqueue( 'message', jobId, 'socketMessage', JSON.stringify( params ) )

                            this.$core.baseClass( 'colleague' ).update(
                                colleague,
                                colleague.localId,
                                colleague.remoteId,
                                colleague.timestamp,
                                colleague.localKey
                            )

                        } )

                }
            }
        },

        performUpdate()
        {

            let setup         = {
                    role  : this.itemClone.role,
                    rights: this.itemClone.rights
                },
                cryptedRights = this.$core.getCryptoCore().encryptWithPublicKey(
                    this.itemClone.publicKey,
                    JSON.stringify( setup )
                )

            this.$core.getCryptoHelper().encrypt( JSON.stringify( setup ) )
                .then( cryptedStore =>
                {

                    let params   = {
                            method     : 'network.storeColleagueRights',
                            colleagueId: this.itemClone.colleagueId,
                            rights     : cryptedRights,
                            ownRights  : btoa( JSON.stringify( cryptedStore ) )
                        },
                        template = JSON.parse( JSON.stringify( this.itemClone.rights.general ) ),
                        jobId    = this.$core.getUuid().generate()

                    this.$core.getQueueWorker().enqueue( 'message', jobId, 'socketMessage', JSON.stringify( params ) )

                    this.$core.baseClass( 'colleague' ).update(
                        this.itemClone,
                        this.itemClone.localId,
                        this.itemClone.remoteId,
                        this.itemClone.timestamp,
                        this.itemClone.localKey
                    )

                    if( this.setGlobal === 1 )
                    {
                        this.copyRights( template )
                    }

                } )

        },

        toggleGlobal()
        {

            let check = document.querySelector( '#form-element-set-global' )
            this.setGlobal = check.checked ? 1 : 0

        },

        updateOnLeave()
        {
            if( false !== this.updateTimer )
            {
                clearTimeout( this.updateTimer )
                this.updateTimer = false
                this.performUpdate()
            }
        },

        lazyElementsRightsUpdate()
        {

            if( false !== this.updateTimer )
            {
                clearTimeout( this.updateTimer )
                this.updateTimer = false
            }

            this.updateTimer = setTimeout( () =>
            {

                this.performUpdate()

            }, 3000 )

        },

        handleToggle( id, type, method, reference, value )
        {

            let temp    = id.split( '-' ),
                scope   = temp[ 0 ],
                section = temp[ 1 ],
                key     = temp[ 2 ]

            this.itemClone.rights = this.itemClone.rights || {}

            if( !this.$core.f().isset( this.itemClone.rights[ scope ] ) )
            {
                this.itemClone.rights[ scope ] = {}
            }
            if( !this.$core.f().isset( this.itemClone.rights[ scope ][ section ] ) )
            {
                this.itemClone.rights[ scope ][ section ] = {}
            }

            this.itemClone.rights[ scope ][ section ][ key ] = value
            this.lazyElementsRightsUpdate()

        }

    }

}
</script>