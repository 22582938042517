<template>
    <div class="page-bottombuttons-boundaries">
        <div class="page-bottombuttons-container">
            <template v-if="$core.getLicense().isAllowed( 'show_recommend' )">
                <div v-if="$store.getters.authorized === true && $store.getters.online === true"
                     class="page-bottombutton small" @click="handleRecommend()">
                    {{ $core.t( 'recommend' ) }}
                </div>
            </template>
            <div class="clearfix"></div>
        </div>
    </div>
</template>

<script>
export default {

    name: 'BottomButtons',

    data()
    {
        return {}
    },

    methods: {

        handleRecommend()
        {
            this.$router.push( { name: 'promotion', params: { scope: this.$route.name } } )
        }

    }

}
</script>