/*eslint-disable*/
import CopyList      from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/copyclasses/CopyList";
import CopyClass     from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/copyclasses/CopyClass";
import CopyStudent   from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/copyclasses/CopyStudent";
import CopyYeargroup from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/copyclasses/CopyYeargroup";
import CopyGroup     from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/copyclasses/CopyGroup";
import CopyNote      from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/copyclasses/CopyNote";
import CopyTodo      from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/copyclasses/CopyTodo";

export default class SyncCopies
{
    constructor( parent )
    {

        this.parent = parent

        this.store = parent.parent.store
        this.cryptoHelper = parent.parent.cryptoHelper
        this.eventManager = parent.parent.eventManager
        this.logger = parent.parent.logger
        this.database = parent.parent.database
        this.f = parent.parent.f
        this.uuid = parent.uuid

        this.shadowHelpers = {
            list     : new CopyList( this ),
            class    : new CopyClass( this ),
            student  : new CopyStudent( this ),
            yeargroup: new CopyYeargroup( this ),
            group    : new CopyGroup( this ),
            note     : new CopyNote( this ),
            todo     : new CopyTodo( this )
        }

        this.baseClassHelper = parent.baseClassHelper
        this.logSign = 'SyncShadowCopies::SyncCopies (SHC)'
        this.logger.cconstructed( this.logSign, 'initialized' )

        return this

    }

    handleDeletions( jobs )
    {

        return new Promise( resolve =>
        {

            let promises = [],
                lists    = {
                    shadowCopy: []
                }

            for( let j in jobs )
            {

                if( undefined === lists[ jobs[ j ].type ] )
                {
                    lists[ jobs[ j ].type ] = []
                }

                lists[ jobs[ j ].type ].push( jobs[ j ].shadowLocalId )
                lists.shadowCopy.push( jobs[ j ].localId )

                this.baseClassHelper
                    .get( jobs[ j ].type )
                    .deleteShadowCopy( jobs[ j ].shadowLocalId )

            }

            for( let k in lists )
            {
                promises.push( () =>
                {
                    return this.baseClassHelper
                               .get( k )
                               .deleteList( lists[ k ] )
                } )
            }

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    promises = null
                    lists = null
                    return resolve()

                } )

        } )

    }

    handleCreations( jobs )
    {

        return new Promise( resolve =>
        {

            let promises  = [],
                deletions = [],
                jobList   = []

            for( let j in jobs )
            {
                if( undefined !== this.shadowHelpers[ jobs[ j ].type ]
                    && 'function' === typeof this.shadowHelpers[ jobs[ j ].type ].create )
                {
                    promises.push( () =>
                    {

                        return new Promise( resolve =>
                        {

                            this.shadowHelpers[ jobs[ j ].type ].create( jobs[ j ] )
                                                                .then( result =>
                                                                {
                                                                    jobList.push( result )
                                                                } )
                                                                .catch( e =>
                                                                {
                                                                    if( 'INVALID' === e )
                                                                    {
                                                                        deletions.push( jobs[ j ] )
                                                                    }
                                                                    else
                                                                    {
                                                                        this.logger.cerror( this.logSign + ':handleUpdates', 'failed to create shadowcopy: ' + e )
                                                                    }
                                                                } )
                                                                .finally( () =>
                                                                {
                                                                    return resolve()
                                                                } )

                        } )

                    } )
                }
            }

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    this.handleDeletions( deletions )
                        .then( () =>
                        {

                            return resolve( this._finalResolve( jobList ) )

                        } )

                } )

        } )

    }

    handleUpdates( jobs )
    {

        return new Promise( resolve =>
        {

            let promises = [],
                jobList  = []

            for( let j in jobs )
            {
                if( undefined !== this.shadowHelpers[ jobs[ j ].type ]
                    && 'function' === typeof this.shadowHelpers[ jobs[ j ].type ].update )
                {
                    promises.push( () =>
                    {

                        return new Promise( resolve =>
                        {

                            this.shadowHelpers[ jobs[ j ].type ].update( jobs[ j ] )
                                                                .then( result =>
                                                                {

                                                                    jobList.push( result )

                                                                } )
                                                                .catch( e =>
                                                                {

                                                                    this.logger.cerror( this.logSign + ':handleUpdates', 'failed to update shadowcopy: ' + e )

                                                                } )
                                                                .finally( () =>
                                                                {
                                                                    return resolve()
                                                                } )

                        } )

                    } )
                }
            }

            this.f.promiseRunner( promises )
                .then( () =>
                {

                    return resolve( this._finalResolve( jobList ) )

                } )

        } )

    }

    updateShadowCopyCaches( triggers )
    {
        while( 0 < triggers.length )
        {

            let trigger = triggers.shift()

            this.baseClassHelper
                .get( trigger.type )
                .updateShadowCopy( trigger.localId, trigger.shadowCopy )

        }
    }

    _finalResolve( jobList )
    {

        return new Promise( resolve =>
        {

            if( 0 === jobList.length )
            {
                return resolve()
            }

            let dbObjects = {},
                dbTypes   = {},
                dbUploads = [],
                triggers  = []

            for( let j in jobList )
            {
                if( undefined !== jobList[ j ].db.localId )
                {
                    dbObjects[ jobList[ j ].db.localId ] = jobList[ j ].db.dbObject
                    dbTypes[ jobList[ j ].db.localId ] = jobList[ j ].db.type
                    dbUploads.push( {
                        type   : jobList[ j ].db.type,
                        localId: jobList[ j ].db.localId
                    } )
                    triggers.push( {
                        type      : jobList[ j ].db.type,
                        localId   : jobList[ j ].db.localId,
                        shadowCopy: jobList[ j ].plain
                    } )
                }
            }

            this.database.writeObjectsList( dbObjects )
                .then( () =>
                {

                    this.database.writeTypesList( dbTypes )
                        .then( () =>
                        {
                            this.database.writeUploadsList( dbUploads )
                                .then( () =>
                                {
                                    this.updateShadowCopyCaches( triggers )
                                    this.logger.clog( this.logSign + ':_finalResolve', 'success.' )

                                    dbObjects = null
                                    dbTypes = null
                                    dbUploads = null

                                    return resolve()

                                } )

                        } )
                } )

        } )

    }

    _appendAccess( result, copy, accesses )
    {
        for( const [ a, access ] of accesses )
        {
            if( access.studentLocalId === copy.studentLocalId )
            {
                result.access = access
                return
            }
        }
    }

    createNewShadows( jobs )
    {
        return new Promise( resolve =>
        {

            let shadowCopyClass = this.baseClassHelper.get( 'shadowCopy' )

            for( let j in jobs )
            {

                if( undefined !== jobs[ j ].access )
                {

                    let shadowCopy = {
                        elementType     : jobs[ j ].element.type,
                        referenceLocalId: jobs[ j ].element.localId,
                        referenceKey    : jobs[ j ].element.referenceKey,
                        timestamp       : jobs[ j ].element.timestamp,
                        update          : jobs[ j ].element.update,
                        studentLocalId  : jobs[ j ].access.studentLocalId
                    }

                    shadowCopyClass.create( shadowCopy )

                }

            }

            return resolve()

        } )
    }

    syncShadowCopies()
    {

        return new Promise( resolve =>
        {

            this.logger.clog( this.logSign + ':resolveShadowCopies', 'resolving syncables...' )
            let promises   = [],
                actions    = [],
                updates    = [],
                creations  = [],
                deletions  = [],
                newShadows = []

            this.baseClassHelper
                .get( 'studentAccess' )
                .getPreparedCache( 'cache' )
                .then( studentAccessList =>
                {

                    this.logger.clog( this.logSign + ':resolveShadowCopies', 'found', studentAccessList.size, 'student accesses...' )

                    for( let c in this.parent.shadowCopyCache )
                    {

                        if( undefined !== this.shadowHelpers[ this.parent.shadowCopyCache[ c ].elementType ] )
                        {

                            promises.push( () =>
                            {

                                return new Promise( resolve =>
                                {

                                    this.shadowHelpers[ this.parent.shadowCopyCache[ c ].elementType ]
                                        .check( this.parent.shadowCopyCache[ c ] )
                                        .then( result =>
                                        {

                                            switch( result.action )
                                            {
                                                case 'delete':
                                                    deletions.push( result )
                                                    break
                                                case 'update':
                                                case 'create':
                                                case 'create-shadow':
                                                    this._appendAccess( result, this.parent.shadowCopyCache[ c ], studentAccessList )
                                                    switch( result.action )
                                                    {
                                                        case 'update':
                                                            updates.push( result )
                                                            break
                                                        case 'create':
                                                            creations.push( result )
                                                            break
                                                        case 'create-shadow':
                                                            newShadows.push( result )
                                                            break
                                                    }
                                                    break
                                            }
                                            return resolve()

                                        } )

                                } )

                            } )

                        }

                    }

                    if( 0 === promises.length )
                    {
                        return resolve()
                    }

                    this.f.promiseRunner( promises )
                        .then( () =>
                        {

                            actions.push( () =>
                            {
                                return this.handleDeletions( deletions )
                            } )
                            actions.push( () =>
                            {
                                return this.handleCreations( creations )
                            } )
                            actions.push( () =>
                            {
                                return this.handleUpdates( updates )
                            } )
                            actions.push( () =>
                            {
                                return this.createNewShadows( newShadows )
                            } )

                            this.f.promiseRunner( actions )
                                .then( () =>
                                {

                                    promises = null
                                    actions = null
                                    deletions = null
                                    updates = null
                                    creations = null
                                    return resolve()
                                } )

                        } )

                } )
                .catch( () =>
                {
                    this.logger.clog( this.logSign + ':resolveShadowCopies', 'no students to sync to' )
                    return resolve()
                } )

        } )
    }

    /**
     * handle
     */
    handle()
    {

        return new Promise( resolve =>
        {

            let start = Date.now()

            this.syncShadowCopies()
                .then( () =>
                {

                    this.logger.cdebug( this.logSign + ':handle', 'process took ' + ( Date.now() - start ) + 'ms' )
                    return resolve()

                } )

        } )

    }

}