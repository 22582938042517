<template>
    <div>
        <div class="labels-wrapper">
            <input type="hidden" :id="'form-element-'+refName" :value="selection.join(',')"/>
            <div class="label-space">
                <template v-if="0 === selection.length">
                    <div>
                        - keine Labels
                    </div>
                </template>
                <template v-else>
                    <div>
                        <LabelMarker v-for="label in selection"
                                     :key="'label-marker-'+label"
                                     :reference="label"
                                     :emitClick="true"
                                     @clicked="handleDeselect( label )"/>
                    </div>
                </template>
                <div>
                    <Button type="edit"
                            @clicked="handleLabelManager()"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Button              from "@/components/elements/defaults/Button";
import MixinEvents         from "@/mixins/MixinEvents";
import MixinCachePreheater from "@/mixins/MixinCachePreheater";
import LabelMarker         from "@/components/elements/markers/LabelMarker";

export default {

    name      : 'LabelSelector',
    components: { LabelMarker, Button },
    props     : {

        value           : { Type: String, required: false },
        refName         : { Type: String, required: true },
        validator       : { Type: String, required: false },
        reformatter     : { Type: String, required: false },
        display         : { Type: String, required: false },
        undefBlocked    : { Type: Boolean, required: false },
        configScope     : { Type: Boolean, required: false },
        eventKey        : { Type: String, required: false },
        scope           : { Type: String, required: false },
        useDefault      : { Type: Boolean, required: false, default: false },
        defaultColorCode: { Type: String, required: false }

    },

    mixins: [ MixinEvents, MixinCachePreheater ],
    emits : [ 'update' ],

    created()
    {

        this.prepareLabels()
        this.addEvent( 'on-select-label', ( labelId ) =>
        {
            this.handleSelect( labelId )
        } )
        this.addEvent( 'on-deselect-label', ( labelId ) =>
        {
            this.handleDeselect( labelId )
        } )
    },

    data()
    {
        return {
            neededCaches   : [ 'label' ],
            selection      : [],
            labelManagerKey: false
        }
    },

    methods: {

        prepareLabels()
        {
            if( this.$core.f().valid( this.$props.value ) )
            {
                this.selection = this.$props.value.split( /,/g )
            }
        },

        handleSelect( labelId )
        {
            if( -1 === this.selection.indexOf( labelId ) )
            {
                this.selection.push( labelId )
            }
            this.update()
        },

        handleDeselect( labelId )
        {
            if( -1 < this.selection.indexOf( labelId ) )
            {
                this.$core.f().removeFromArray( this.selection, labelId )
            }
            this.update()
        },

        handleLabelManager()
        {
            this.labelManagerKey = this.$core.getUi().showLabelManager( true, this.selection )
        },

        update()
        {
            this.$emit( 'update', this.$props.refName, 'labelSelector', 'update', undefined, this.selection.join( ',' ) )
        }

    }

}
</script>