<template>
    <div :key="'defaultViewItemList-'+ownId+'-'+viewKey">

        <Pagination :page="displayedPage"
                    :pages
                    :filterKey
                    position="top"
                    @pageChange="handlePageChange"/>

        <div class="clearfix"></div>

        <div :class="'default-view-itemlist'+( galleryMode ? ' gallery' : '' )">
            <template v-if="sortable === true">
                <draggable v-model="draggableItemList"
                           @change="move"
                           @start="dragStart"
                           @end="dragEnd"
                           @ended="dragEnd"
                           :move="checkMove"
                           :itemKey="ownId"
                           ghost-class="drag-ghost"
                           animation="200"
                           :fallback-tolerance="1"
                           handle=".dragbutton"
                           :disabled="( false === sortable )">

                    <template #item="{ element }">
                        <div>

                            <Touchable v-if="Array.isArray( pages[displayedPage - 1] )
                                         && -1 < pages[displayedPage - 1].indexOf( element )
                                         && ( -1 < filtered.indexOf( element ) || !viewIsFiltered )"
                                       :localId="element"
                                       :itemZoom="itemZooms ? itemZooms[element] : undefined"
                                       :viewItem
                                       :selectMode
                                       :noShareIndicator
                                       :scope
                                       :controlKey="viewControlKey"
                                       :singleItems
                                       :galleryMode
                                       :inListEditor
                                       :sortable
                                       :noRerender="'List' === viewItem"
                                       :dragging
                                       @requestUpdate="handleRequestUpdate( element )"
                                       @clicked="handleClick( element, $event )"
                                       @findTab="$emit( 'findTab', $event )"
                                       @dragging="$emit( 'dragging', $event )"
                                       @selected="$emit( 'selected', $event )"/>

                        </div>
                    </template>

                </draggable>
            </template>
            <template v-else>
                <transition-group appear :name="$core.settings().getTransition( 'listmove' )">
                    <template v-for="item in itemList"
                              :key="'template-dvil-'+item">

                        <Touchable v-if="Array.isArray( pages[displayedPage - 1] )
                                         && -1 < pages[displayedPage - 1].indexOf( item )
                                         && ( -1 < filtered.indexOf( item ) || !viewIsFiltered )"
                                   :localId="item"
                                   :itemZoom="itemZooms ? itemZooms[item] : undefined"
                                   :viewItem
                                   :overrideQueueType
                                   :selectMode
                                   :noShareIndicator
                                   :scope
                                   :controlKey="controlKey"
                                   :singleItems
                                   :galleryMode
                                   :inListEditor
                                   :noRerender="'List' === viewItem"
                                   @requestUpdate="handleRequestUpdate( item )"
                                   @clicked="handleClick( item, $event )"
                                   @findTab="$emit( 'findTab', $event )"
                                   @dragging="$emit( 'dragging', $event )"
                                   @selected="$emit( 'selected', $event )"/>

                    </template>
                    <template v-if="itemList.length > 0">
                        <div class="clearfix"></div>
                    </template>
                </transition-group>
            </template>
            <!---->
            <template v-if="showEmptyState">
                <InlineMessage v-if="allFiltered"
                               :active="allFiltered"
                               classname="nothing"
                               :title="$core.t( 'caption-all-filtered' )"
                               :message="$core.t( 'message-all-filtered' )"/>
                <InlineMessage v-if="!allFiltered && 0 === itemList.length"
                               :active="!allFiltered && 0 === itemList.length"
                               classname="empty"
                               :title="$core.t( 'default-caption-empty' )"
                               :message="$core.t( 'message-empty-'+scope )"/>
            </template>
        </div>
        <Pagination :page="displayedPage"
                    :pages
                    :filterKey
                    position="bottom"
                    @pageChange="handlePageChange"/>
    </div>
</template>
<script>
import InlineMessage         from "@/components/messages/InlineMessage";
import Pagination            from "@/components/elements/defaults/Pagination";
import MixinPagination       from "@/components/defaults/defaultViewItemList/MixinPagination";
import MixinDetailViews      from "@/mixins/MixinDetailViews";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup";
import MixinOpenEditor       from "@/mixins/MixinOpenEditor.vue";
import draggable             from 'vuedraggable'
import MixinEvents           from "@/mixins/MixinEvents.vue";

export default {

    name      : 'DefaultViewItemList',
    components: { draggable, Pagination, InlineMessage },
    mixins    : [
        MixinEvents,
        MixinPagination,
        MixinDetailViews,
        MixinComponentCleanup,
        MixinOpenEditor
    ],

    emits: [ 'clicked', 'selected', 'dragging', 'findTab', 'requestUpdate', 'count', 'resort' ],

    props: {
        itemList         : { Type: Array, required: true },
        itemZooms        : { Type: Object, required: false },
        overrideQueueType: { Type: String, required: false },
        filtered         : { Type: Array, required: false, default: [] },
        filterKey        : { Type: String, required: true },
        controlKey       : { Type: String, required: false, default: '' },
        viewIsFiltered   : { Type: Boolean, required: false, default: false },
        viewItem         : { Type: String, required: true },
        scope            : { Type: String, required: false, default: '' },
        viewScope        : { Type: String, required: false, default: 'cache' },
        singleItems      : { Type: Boolean, required: false, default: false },
        hiddenVisible    : { Type: Boolean, required: false, default: false },
        selectMode       : { Type: Boolean, required: false, default: false },
        noShareIndicator : { Type: Boolean, required: false, default: false },
        noDetailView     : { Type: Boolean, required: false, default: false },
        emitClick        : { Type: Boolean, required: false, default: false },
        galleryMode      : { Type: Boolean, required: false, default: false },
        inListEditor     : { Type: Boolean, required: false, default: false },
        showEmptyState   : { Type: Boolean, required: false, default: true },
        sortable         : { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {

            viewControlKey   : false !== this.$props.controlKey
                               && undefined !== this.$props.controlKey
                               && this.$props.controlKey !== '' ? this.$props.controlKey : '_init',
            isSelfControlled : false,
            draggableItemList: this.$props.itemList,
            dragging         : false,
            ownId            : this.$core.getUuid().generate(),
            viewKey          : 'init'

        }
    },

    computed: {
        allFiltered()
        {
            return this.$props.viewIsFiltered && 0 === this.$props.filtered.length
        }
    },

    created()
    {
        if( '_init' === this.viewControlKey )
        {
            this.viewControlKey = this.getViewControlKey()
        }
        this.preparePagination()
    },

    methods: {

        getViewControlKey()
        {

            let queue = this.$props.viewItem ? this.$core.f().lcFirst( this.$props.viewItem ) : null
            if( null !== queue )
            {

                let baseClass = this.$core
                                    .getBaseClassHelper()
                                    .get( queue )

                if( false !== baseClass
                    && undefined !== baseClass
                    && baseClass.registry )
                {

                    this.isSelfControlled = true

                    this.addIndexedEvent( 'on-storable-create-' + queue, () =>
                    {
                        setTimeout( () =>
                        {

                            this.viewControlKey = this.$core.getBaseClassHelper().getCacheKey( queue ) + '-' + this.$core.getBaseClassHelper().getArchiveKey( queue )

                        }, 500 )
                    } )

                    return this.$core.getBaseClassHelper().getCacheKey( queue ) + '-' + this.$core.getBaseClassHelper().getArchiveKey( queue )

                }

            }

            return ''

        },

        handleRequestUpdate( localId )
        {
            this.$emit( 'requestUpdate', localId )
        },

        handleClick( localId )
        {

            if( true === this.$props.emitClick )
            {
                this.$emit( 'clicked', this.$core.getBaseClassHelper().getObjectById( localId ) )
            }

            if( true === this.$props.inListEditor )
            {
                this.$emit( 'clicked', localId )
                return
            }

            if( false === this.$props.noDetailView )
            {
                this.handleDetailView( this.$core.getBaseClassHelper().getObjectById( localId ) )
            }

        },

        move( event )
        {

            let moved = event.moved

            this.$nextTick()
                .then( () =>
                {

                    this.$emit( 'resort', moved.newIndex, moved.oldIndex )

                } )

        },

        dragStart()
        {
            this.dragging = true
        },

        dragEnd()
        {
            setTimeout( () =>
            {
                this.dragging = false
            }, 300 )
        },

        checkMove()
        {
            return this.$props.sortable === true
        },

    }

}
</script>