<template>
    <div class="planner-wrapper">
        <!-- SETUP MODE -->
        <PlannerSetupSlots v-if="setupOpen"
                           @editorMode="editorMode = $event"
                           :errors="editorErrors"
                           :slots="editorOrganizer.slots"
                           @dragging="$emit( 'dragging', $event )"
                           @update="editorOrganizer = $event"
                           @setContentMode="$emit( 'setContentMode', $event )"/>
        <!-- VIEW MODE -->
        <template v-else>
            <OrganizerPreparationDisplay v-if="organizersFetching"/>
            <OrganizerEmptyDisplay v-if="!organizersDefined"/>
            <!-- BUTTONS -->
            <div class="planner-header">
                <div class="planner-button">
                    <Button type="navigate last larger inverted" @dragover="dragChange( dragging, 'last' )"
                            @clicked="changeDate( 'last', undefined, 'organizer' )"/>
                </div>
                <div class="planner-title" v-html="title"></div>
                <div class="planner-button">
                    <Button type="navigate next larger inverted" @dragover="dragChange( dragging, 'next' )"
                            @clicked="changeDate( 'next', undefined, 'organizer'  )"/>
                </div>
            </div>

            <pre>

            </pre>
            <!-- ORGANIZER BODY -->
            <div :class="'planner-view organizer-content planner-mode-'+this.plannerView">

                <!-- DAY TITLES -->
                <div class="planner-day-title blank"></div>
                <template v-for="( date, n ) in sortedDates"
                          :key="'tpl-org-title-head-'+date.timestamp+'-'+n">
                    <div v-if="visibleStart <= date.timestamp && visibleEnd >= date.timestamp"
                         :class="'planner-day-title'+' day-'+n">
                        {{ $core.getTranslation().translate( 'day-title-' + date.weekday ) }}
                        <span class="day-bubble">{{ date.day }}</span>
                    </div>
                </template>

                <!-- ORGANIZER TITLES -->
                <div class="planner-day-title blank"></div>
                <PlannerViewOrganizerTitles :viewKey="viewKey"
                                            :dates="visibleDates"
                                            :filterList="filterList"
                                            :changeKey="changeKey"
                                            :organizers="organizers"
                                            :start="visibleStart"
                                            :end="visibleEnd"
                                            @clicked="handleClick"
                                            @changeRange="changeRange"/>

                <!-- SLOT CAPTIONS (LEFT) -->
                <PlannerSlots :display="true"
                              :slots="organizerSlots"
                              :date="date.getTime()"
                              :isFirst="true"/>

                <!-- SLOTS (RIGHT) -->
                <template v-for="( date, n ) in sortedDates"
                          :key="'tpl-org-'+date.timestamp">
                    <PlannerSlots :display="visibleStart <= date.timestamp && visibleEnd >= date.timestamp"
                                  :noCaptions="true"
                                  :changeKey="changeKey"
                                  :className="'planner-day-organizer-slots nohover day-'+n"
                                  :slotSelection="slotSelection"
                                  :slots="organizerSlots"
                                  :slotContents="slotContents"
                                  :organizers="visibleOrganizers"
                                  :highlighted="highlighted"
                                  :draggableLabels="draggableLabels"
                                  :isDragging="dragging"
                                  :copyMode="copyMode"
                                  @dragging="handleDragging"
                                  @refresh="$emit( 'refresh' )"
                                  :date="date.timestamp"
                                  @mark="$emit( 'markSlot', date, $event )"/>
                </template>

            </div>

            <!-- BUTTONS -->
            <div class="clearfix"></div>
            <template v-if="!setupOpen && !labelMode">
                <div class="planner-footer">
                    <div class="planner-button">
                        <Button type="navigate last larger inverted" @dragover="dragChange( dragging, 'last' )"
                                @clicked="changeDate( 'last', undefined, 'organizer'  )"/>
                    </div>
                    <div class="planner-title" v-html="title"></div>
                    <div class="planner-button">
                        <Button type="navigate next larger inverted" @dragover="dragChange( dragging, 'next' )"
                                @clicked="changeDate( 'next', undefined, 'organizer'  )"/>
                    </div>
                </div>
            </template>

        </template>
    </div>
</template>

<script>
import PlannerSetupSlots     from "@/components/elements/viewItems/plannerView/setup/PlannerSetupSlots";
import OrganizerPreparationDisplay
                             from "@/components/elements/viewItems/plannerView/modes/organizer/OrganizerPreparationDisplay";
import OrganizerEmptyDisplay from "@/components/elements/viewItems/plannerView/modes/organizer/OrganizerEmptyDisplay";
import PlannerViewOrganizerTitles
                             from "@/components/elements/viewItems/plannerView/modes/organizer/PlannerViewOrganizerTitles";

import MixinCalendarViewFunctions
                             from "@/components/elements/viewItems/plannerView/abstract/MixinCalendarViewFunctions";
import MixinOrganizerViewFunctions
                             from "@/components/elements/viewItems/plannerView/mixins/MixinOrganizerViewFunctions";
import MixinTypeTranslations from "@/mixins/MixinTypeTranslations";
import MixinCachePreheater   from "@/mixins/MixinCachePreheater";
import PlannerSlots          from "@/components/elements/viewItems/plannerView/PlannerSlots";

export default {

    name      : "PlannerViewOrganizer",
    components: {
        PlannerSlots,
        PlannerViewOrganizerTitles,
        OrganizerEmptyDisplay,
        OrganizerPreparationDisplay,
        PlannerSetupSlots
    },
    mixins    : [ MixinCachePreheater, MixinCalendarViewFunctions, MixinOrganizerViewFunctions, MixinTypeTranslations ],
    emits     : [ 'disableFilters', 'changeFilter', 'dragging', 'setupDone', 'markSlot', 'setContentMode', 'refresh' ],
    props     : {
        setupOpen      : { type: Boolean, required: false, default: false },
        embedded       : { type: Boolean, required: false, default: false },
        labelMode      : { type: Boolean, required: false, default: false },
        filterList     : { type: Object, required: false },
        slotSelection  : { type: [ Object, Boolean ], required: false },
        highlighted    : { type: [ Object, Boolean ], required: false },
        draggableLabels: { type: [ Object, Boolean ], required: false },
        copyMode       : { type: [ Object, Boolean ], required: false },
        changeKey      : { type: String, required: false, default: undefined }
    },

    data()
    {
        return {
            plannerView        : 'organizer',
            mode               : 'week',
            today              : false,
            date               : new Date(),
            calendarHidden     : false,
            organizerDate      : new Date(),
            check              : new Date(),
            organizers         : false,
            visibleOrganizers  : [],
            organizersDefined  : undefined,
            organizersFetching : undefined,
            visibleStart       : 0,
            visibleEnd         : 0,
            dates              : [],
            organizerSlots     : [],
            slotContents       : [],
            editorMode         : false,
            editorErrors       : [],
            editorOrganizer    : {},
            lastDragChange     : 0,
            editorAllSameSize  : false,
            showDate           : false,
            month              : false,
            year               : false,
            organizerMonth     : false,
            organizerYear      : false,
            prepared           : false,
            preparing          : false,
            dragging           : false,
            rangeFrom          : false,
            rangeUntil         : false,
            tsmpStart          : false,
            tsmpEnd            : false,
            organizerRangeFrom : false,
            organizerRangeUntil: false,
            viewKey            : false,
            neededCaches       : [ 'note', 'todo', 'date', 'list', 'student', 'organizer', 'organizerslot', 'organizercontent' ]
        }
    },

    computed: {
        sortedDates()
        {
            return this.$core.getSorter().multiSortObjects( this.dates, [ [ 'timestamp', 'ascending' ] ] )
        },
        visibleDates()
        {
            let arr = []
            for( let d in this.sortedDates )
            {
                if( this.visibleStart <= this.sortedDates[ d ].timestamp
                    && this.visibleEnd >= this.sortedDates[ d ].timestamp )
                {
                    arr.push( this.sortedDates[ d ] )
                }
            }
            return arr
        },
        slotContentsKey()
        {
            return this.$core.getBaseClassHelper().get( 'organizercontent' ).registry.cacheKey
        }
    },

    watch: {
        changeKey      : {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && newKey !== oldKey )
                {
                    this.checkOrganizerSlots( true )
                }
            }
        },
        slotContentsKey: {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && newKey !== oldKey )
                {
                    this.$emit( 'refresh' )
                }
            }
        },
        setupOpen      : {
            immediate: true,
            handler( newKey, oldKey )
            {
                if( undefined !== oldKey
                    && newKey !== oldKey )
                {
                    if( false === newKey )
                    {
                        this.editorMode = false
                    }
                    this.checkOrganizerSlots()
                }
            }
        }
    },

    mounted()
    {
        this.$core.getEventManager()
            .add( 'on-slot-template-select', ( template ) =>
            {
                this.handleSlotTemplate( template )
            } )
        this.checkOrganizerSlots()
    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-slot-template-select' )
        this.$core.getEventManager()
            .remove( 'on-planner-prepared' )
    },

    created()
    {

        this.awaitNeededCaches()
            .then( () =>
            {
                this.prepare()
                this.checkOrganizerSlots()
            } )

        this.hookKeys()

    },

    methods: {

        handleClick( localId )
        {
            if( !this.$props.setupOpen
                && !this.$props.embedded )
            {
                this.$emit( 'changeFilter', localId )
            }
        }

    }

}
</script>