/* eslint-disable */
export default class Sanitizers
{

    constructor()
    {

        if( !Sanitizers.instance )
        {
            Sanitizers.instance = this
        }

        return Sanitizers.instance

    }

    cleanId( id )
    {
        if( undefined === id )
        {
            return id
        }

        id = id.replace( /@/g, '--AT--' )
        id = id.replace( /%/g, '--PRCT--' )
        id = id.replace( /&/g, '--AND--' )
        id = id.replace( /\$/g, '--DLLR--' )
        id = id.replace( /§/g, '--PRGRPH--' )
        id = id.replace( /=/g, '--EQL--' )
        id = id.replace( /\|/g, '--PIPE--' )
        id = id.replace( /\{/g, '--SPKO--' )
        id = id.replace( /\}/g, '--SPKCL--' )
        id = id.replace( /\[/g, '--SQO--' )
        id = id.replace( /\]/g, '--SQCL--' )
        id = id.replace( /\(/g, '--BRO--' )
        id = id.replace( /\)/g, '--BRCL--' )
        id = id.replace( /</g, '--LTHN--' )
        id = id.replace( />/g, '--GTHN--' )
        id = id.replace( /,/g, '--COMMA--' )
        id = id.replace( /;/g, '--SMCLN--' )
        id = id.replace( /:/g, '--CLN--' )
        id = id.replace( /\*/g, '--ASTRSK--' )
        id = id.replace( /#/g, '--HSH--' )
        id = id.replace( /!/g, '--EXCL--' )
        id = id.replace( /\?/g, '--QST--' )
        id = id.replace( /\./g, '---' )
        id = id.replace( /'/g, '__' )
        id = id.replace( /"/g, '____' )
        id = id.replace( /\\/g, '_____' )
        id = id.replace( /\//g, '______' )
        id = id.replace( /\+/g, '___' )
        id = id.replace( /`/g, '__APOB__' )
        id = id.replace( /´/g, '__APOF__' )
        id = id.replace( /è/g, '__E_APOR__' )
        id = id.replace( /é/g, '__E_APOF__' )
        return id.trim().replace( /\ /g, '_' )

    }

    fieldId( localId, caption, index )
    {
        return localId + '___' + this.cleanId( caption ) + '___' + index
    }

}