/*eslint-disable*/

import { jsPDF } from 'jspdf'

import font                  from '@/classes/Helpers/Printing/Fonts/quicksand'
import fontBold              from '@/classes/Helpers/Printing/Fonts/quicksand-bold'
import prepareTemplate       from '@/classes/Helpers/Printing/Modules/PrepareTemplate'
import prepareList           from '@/classes/Helpers/Printing/Modules/PrepareList'
import prepareTest           from "@/classes/Helpers/Printing/Modules/PrepareTest";
import preparePlain          from '@/classes/Helpers/Printing/Modules/PreparePlain'
import printPages            from '@/classes/Helpers/Printing/Modules/PrintPages'
import printCutables         from '@/classes/Helpers/Printing/Modules/PrintCutables'
import printTestWithCutmarks from '@/classes/Helpers/Printing/Modules/PrintTestWithCutmarks'
import footer                from '@/classes/Helpers/Printing/Templates/Footer'
import headline              from '@/classes/Helpers/Printing/Templates/Headline'
import plainHeadline         from '@/classes/Helpers/Printing/Templates/PlainHeadline'

export default class Printing
{

    constructor( core )
    {

        this.logger = core.getLogger()
        this.f = core.f()
        this.friendlyTimestamp = core.getFriendlyTimestamp()
        this.ui = core.getUi()
        this.s = core.s()
        this.settings = core.settings()
        this.eventManager = core.getEventManager()

        this.blockerHash = false

        this.colors = this._colors()
        return this

    }

    destruct()
    {

        this.logger = null
        this.friendlyTimestamp = null
        this.ui = null
        this.settings = null
        this.blockerHash = false

        delete this

    }

    _colors()
    {
        return {
            default: this.f.hexToRgbA( '#1f2f4c', 1, true ),
            white  : this.f.hexToRgbA( '#fefefe', 1, true ),
            green  : this.f.hexToRgbA( '#effeef', 1, true ),
            blue   : this.f.hexToRgbA( '#efeffe', 1, true ),
            yellow : this.f.hexToRgbA( '#fefeef', 1, true ),
            red    : this.f.hexToRgbA( '#feefef', 1, true ),
            mint   : this.f.hexToRgbA( '#dff9f3', 1, true ),
            orange : this.f.hexToRgbA( '#fff6ce', 1, true ),
            grey   : this.f.hexToRgbA( '#f2f2f2', 1, true ),
            pink   : this.f.hexToRgbA( '#ffbadf', 1, true )
        }
    }

    _getPrintable( localId )
    {

        let lookup = 'printable-' + localId,
            test   = document.querySelector( '#printables-' + localId )

        if( null !== test )
        {
            lookup = test.value
        }
        else
        {
            let test = localId.split( /-/g )
            if( 6 === test.length )
            {
                test.shift()
                let combilistId = test.join( '-' )
                lookup = 'printable-' + combilistId
            }
        }

        let printable = document.querySelector( '#' + lookup )

        return printable

    }

    _initializePDF( orientation )
    {

        return new Promise( resolve =>
        {

            orientation = undefined === orientation ? 'portrait' : orientation

            let doc = new jsPDF( orientation, 'mm', 'a4' )

            doc.addFileToVFS( 'quicksand-normal.ttf', font )
            doc.addFont( 'quicksand-normal.ttf', 'quicksand', 'normal' )
            doc.setFont( 'quicksand' )

            doc.addFileToVFS( 'quicksand-bold.ttf', fontBold )
            doc.addFont( 'quicksand-bold.ttf', 'quicksand', 'bold' )

            return resolve( doc )

        } )

    }

    _print( element, mode, landscape, timestamp, options )
    {

        mode = undefined === mode ? 'default' : mode
        landscape = undefined === landscape ? false : true

        let settings   = {
                'printingLandscapeOnly'       : this.settings.getSetting( 'printingLandscapeOnly' ),
                'printingDefaultAlign'        : this.settings.getSetting( 'printingDefaultAlign' ),
                'printingTestsAlwaysCutmarked': this.settings.getSetting( 'printingTestsAlwaysCutmarked' ),
                'printingWithPunchMarks'      : this.settings.getSetting( 'printingWithPunchMarks' )
            },
            returnBlob = undefined !== options && true === options.returnBlob

        if( undefined !== options
            && undefined !== options.printTestCutmarked )
        {
            settings.printingTestsAlwaysCutmarked = options.printTestCutmarked
        }

        if( settings.printingLandscapeOnly === true )
        {
            landscape = true
        }

        let printable = options && options.elementId ? document.querySelector( '#' + options.elementId ) :
                        this._getPrintable( element.referenceKey || element.localId )

        if( null !== printable )
        {

            if( !returnBlob )
            {
                this.ui.showBlocker( 'Das geht schnell', 'PDF-Datei wird erzeugt...', 'printing' )
            }

            setTimeout( () =>
            {
                let start      = Date.now(),
                    maxHeight  = 280,
                    punchMarks = 'vertical' !== mode && settings.printingWithPunchMarks === true,
                    maxWidth   = punchMarks ? 190 : 200,
                    docHeadline

                if( landscape )
                {
                    maxHeight = punchMarks ? 183 : 193
                    maxWidth = 287
                }

                this._initializePDF( landscape === true ? 'landscape' : 'portrait' )
                    .then( doc =>
                    {

                        let template = prepareTemplate( element )
                        timestamp = undefined !== timestamp ? timestamp : 'vom ' + this.friendlyTimestamp.friendlyDate( element.timestamp )

                        switch( mode )
                        {
                            case 'plain':
                                docHeadline = plainHeadline( doc, element, template.headline, timestamp, this.colors.default, maxWidth, punchMarks, landscape )
                                break
                            default:
                                docHeadline = headline( doc, template.headline, timestamp, this.colors.default, maxWidth, options, punchMarks, landscape )
                                break
                        }

                        let y = docHeadline.y,
                            pageSetup,
                            filename

                        switch( template.type )
                        {
                            case 'list':
                            case 'scoreOverview':
                            case 'access':
                                filename = element.listname + ' - ' + timestamp
                                if( 'list' === template.type && 'test' === element.listType )
                                {
                                    pageSetup = prepareTest( printable, doc, y, ( maxHeight - y ), maxWidth, this.colors[ template.color ], 'vertical' === mode, landscape, settings, template )
                                }
                                else
                                {
                                    pageSetup = prepareList( printable, doc, y, ( maxHeight - y ), maxWidth, this.colors[ template.color ], 'vertical' === mode, landscape, settings, template )
                                }

                                if( null === pageSetup && !landscape )
                                {
                                    this._print( element, mode, true, timestamp, options )
                                    return
                                }
                                if( null === pageSetup && landscape )
                                {
                                    this.ui.hideBlocker()
                                    this.logger.cerror( 'Printing:print', 'page setup too large for landscape mode #' + element.localId + ': aborting' )
                                    this.ui.setPageMessage( 'Das funktioniert leider (noch) nicht.', 'Das als PDF zu exportierende Element ist zu groß, um auf eine A4-Seite gedruckt zu werden: Selbst im Querformat passt es nicht auf eine Seite.<br/>Wir arbeiten bereits mit Hochdruck an einer "automatischen Klebeliste", die zusammengeklebt werden kann: Wir melden uns, sobald dies möglich ist', 'error', true )
                                    return
                                }
                                break
                            case 'note':
                                filename = 'Notiz_' + this.friendlyTimestamp.friendlyDate( element.timestamp ) + '_' + this.friendlyTimestamp.friendlyTime( element.timestamp ) + '.pdf'
                                pageSetup = preparePlain( element, doc, y, ( maxHeight - y ), maxWidth, this.colors[ template.color ], this.friendlyTimestamp, settings, landscape )
                                break
                            case 'todo':
                                filename = 'Todo_' + this.friendlyTimestamp.friendlyDate( element.timestamp ) + '_' + this.friendlyTimestamp.friendlyTime( element.timestamp ) + '.pdf'
                                pageSetup = preparePlain( element, doc, y, ( maxHeight - y ), maxWidth, this.colors[ template.color ], this.friendlyTimestamp, settings, landscape )
                                break
                        }

                        let pageCount,
                            docFooter,
                            buffer

                        if( settings.printingTestsAlwaysCutmarked && template.listType === 'test' )
                        {
                            mode = 'testWithCutmarks'
                        }

                        docFooter = footer( doc, {
                            footer: '_default',
                            pages : [ '__', '~~' ]
                        }, maxWidth, maxHeight, this.colors.default )

                        switch( mode )
                        {
                            case 'testWithCutmarks':
                                printTestWithCutmarks( doc, maxHeight, template, docHeadline, pageSetup, docFooter, filename + '.pdf', settings )
                                break
                            case 'vertical':
                                printCutables( doc, maxHeight, template, docHeadline, pageSetup, docFooter, filename + '.pdf', settings )
                                break
                            default:
                                printPages( doc, ( maxHeight - y ), template, docHeadline, pageSetup, docFooter, filename + '.pdf', settings, landscape )
                                break
                        }

                        if( !returnBlob )
                        {
                            doc.save( filename + '.pdf' )

                            this.logger.csuccess( 'Printing:print', 'PDF exported printable in ' + ( Date.now() - start ) + ' ms.' )
                            this.ui.setPageMessage( 'Export erfolgreich', 'Deine PDF-Datei wurde erfolgreich erzeugt und als <strong>' + filename + '.pdf</strong> gespeichert.', 'ok' )
                            this.ui.hideBlocker()
                        }
                        else
                        {

                            let blobUrl = new Blob( [ doc.output( 'blob' ) ], { type: 'application/pdf' } )
                            this.eventManager.dispatchAndRemove( 'print-ready-' + element.key, blobUrl )

                        }

                    } )

            }, 300 )
        }
        else
        {
            this.logger.cerror( 'Printing:print', 'unable to find printable DOM for #' + element.localId + ': aborting' )
            this.ui.setPageMessage( 'Holla die Waldfee: Das hat nicht funktioniert.', 'Das als PDF zu exportierende Element wurde nicht gefunden. Sollte sich dieser Fehler wiederholen, wende dich bitte an unseren Support', 'error', true )
        }

    }

    printPlainElement( element )
    {
        this._print( element, 'plain' )
    }

    getElementAsPdf( element )
    {
        return this._print( element, 'plain', undefined, undefined, {
            returnBlob: true
        } )
    }

    print( element, mode, timestampOverride, options )
    {
        this.s.count( 'print' )
        if( 'summary' === mode )
        {
            timestampOverride = 'Zusammenfassung | Stand: ' + this.friendlyTimestamp.friendlyDate( Date.now() )
        }
        if( 'details' === mode )
        {
            timestampOverride = 'Details | Stand: ' + this.friendlyTimestamp.friendlyDate( Date.now() )
        }
        this._print( element, mode, undefined, timestampOverride, options )
    }

}