<template>
    <div>
        <div :class="'select-wrapper'">
            <select :disabled="disabledSelect" class="select_element" v-bind:id="'field-type-'+refName"
                    @click="$core.f().skip"
                    @change="checkFieldType()">
                <option value="">{{ $core.t( 'generic-please-select' ) }}</option>
                <option v-for="option in optionValues"
                        :key="'key_option_'+option.key"
                        :selected="selectedComp === option.key"
                        :value="option.key">{{ option.caption }}
                </option>
            </select>
        </div>
        <div>
            <template v-if="selectedType === 'studentData'">
                <StudentDataSelector :refName="'selected_'+refName"
                                     :id="'caption-'+refName"
                                     :value="value" :display="display"
                                     :allValues="allValues"
                                     @update="handleUpdate"/>
            </template>
            <template v-if="selectedType === 'text'">
                <TextBox @update="handleUpdate"
                         @backspacePressed="$emit( 'backspacePressed', refName )"
                         @enterPressed="$emit( 'enterPressed', refName )"
                         :id="'caption-'+refName"
                         :refName="'caption-'+refName"
                         :value="caption"
                         placeholder="Überschrift"/>
            </template>
            <template v-if="selectedType === 'competenceSelector'">
                <CompetenceSelector @update="handleUpdate"
                                    :id="'competence-'+refName"
                                    :refName="'competence-'+refName"
                                    :value="value.value"
                                    :display="value"/>
            </template>
        </div>
        <input v-if="hiddenValid" :key="localKey" type="hidden" :id="'form-element-'+refName" value=""/>
    </div>
</template>

<script>

import StudentDataSelector from '@/components/form/elements/StudentDataSelector'
import TextBox             from '@/components/form/elements/TextBox'
import CompetenceSelector  from "@/components/form/elements/CompetenceSelector.vue";

export default {

    name      : 'FieldSelector',
    components: { CompetenceSelector, TextBox, StudentDataSelector },

    props: {
        id         : { Type: String, required: true },
        component  : { Type: String, required: false },
        changeKey  : { Type: String, required: false },
        ruleSet    : { Type: String, required: false },
        refName    : { Type: String, required: false },
        value      : { Type: String, required: false },
        allValues  : { Type: Object, required: false },
        display    : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        validator  : { Type: String, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed', 'scoreBox', 'selected' ],

    watch: {
        value: {
            immediate: true,
            handler( newValue )
            {
                if( undefined !== newValue )
                {
                    let trans = this.$core.f().isObject( newValue.value ) ? newValue.value : newValue
                    this.localKey = this.$core.getUuid().generate()
                    this.selectedComp = trans.type
                    this.caption = trans.value

                    this.updateHiddenValue()

                }
            }
        }
    },

    beforeUnmount()
    {
        this.hiddenValid = false
        this.selected = false
    },

    created()
    {
        this.filterOptionValues()
    },

    mounted()
    {
        this.fetchElms()
        this.init()
    },

    data()
    {
        return {
            hiddenValid   : true,
            selected      : false,
            direction     : false,
            selectedType  : false,
            selectedComp  : false,
            caption       : false,
            disabledSelect: false,
            localKey      : this.$core.getUuid().generate(),
            optionValues  : [
                {
                    key    : 'checkbox',
                    caption: 'Checkbox',
                    type   : 'text'
                },
                {
                    key    : 'textbox',
                    caption: 'Textzeile',
                    type   : 'text'
                },
                {
                    key    : 'textarea',
                    caption: 'Freitext',
                    type   : 'text'
                },
                {
                    key             : 'image',
                    caption         : 'Dokument',
                    type            : 'mediabox',
                    limitedToUpgrade: 'mediaHandling'
                },
                {
                    key    : 'datebox',
                    caption: 'Datum',
                    type   : 'text'
                },
                {
                    key    : 'numberbox',
                    caption: 'Zahl',
                    type   : 'text'
                },
                {
                    key    : 'rateselector',
                    caption: 'Bewertung',
                    type   : 'text'
                },
                {
                    key    : 'rateplantselector',
                    caption: 'Feedback-Element "Pflanze"',
                    type   : 'text'
                },
                {
                    key    : 'ratesmileselector',
                    caption: 'Feedback-Element "Smileys" | :-( bis :-D',
                    type   : 'text'
                },
                {
                    key    : 'ratesmileselectorreverse',
                    caption: 'Feedback-Element "Smileys" | :-D bis :-(',
                    type   : 'text'
                },
                {
                    key    : 'rateflexselector',
                    caption: 'Feedback-Element "IzEL" (Montessori)',
                    type   : 'text'
                },
                {
                    key    : 'competenceSelector',
                    caption: 'Kompetenz',
                    type   : 'competenceSelector',
                    acl    : 'page_competences'
                },
                {
                    key    : 'scoreBox',
                    caption: 'Benotung',
                    type   : 'scoreBox'
                },
                {
                    key    : 'threewaytoggle',
                    caption: 'Ja / Nein / Keine Angabe',
                    type   : 'text'
                },
                {
                    key    : 'studentData',
                    caption: 'Schülerdaten',
                    type   : 'studentData'
                }
            ],
            hiddenElm     : false,
            formElm       : false
        }
    },

    methods: {

        fetchElms()
        {
            this.formElm = document.querySelector( '#field-type-' + this.$props.refName )
            this.hiddenElm = document.querySelector( '#form-element-' + this.$props.refName )
        },

        filterOptionValues()
        {
            let newOptions = []
            for( let o in this.optionValues )
            {
                if( undefined !== this.optionValues[ o ].acl )
                {
                    if( this.$core.getLicense().isAllowed( this.optionValues[ o ].acl ) )
                    {
                        newOptions.push( this.optionValues[ o ] )
                    }
                }
                else
                {
                    if( undefined === this.optionValues[ o ].limitedToUpgrade
                        || true === this.$core.getLicense()
                                        .getLicenseUpgrade( this.optionValues[ o ].limitedToUpgrade ) )
                    {
                        newOptions.push( this.optionValues[ o ] )
                    }
                }
            }
            this.optionValues = newOptions
        },

        init()
        {

            switch( this.$core.settings().getSetting( 'scoreModel' ) )
            {
                case 'basic':
                    this.optionValues.push()
                    break
                case 'extended':
                    this.optionValues.push( {
                        key    : 'scoreBoxExtended',
                        caption: 'Benotung',
                        type   : 'scoreBoxExtended'
                    } )
                    break
                case 'points':
                    this.optionValues.push( {
                        key    : 'scoreBoxPoints',
                        caption: 'Benotung',
                        type   : 'scoreBoxPoints'
                    } )
                    break
            }

            this.checkFieldType()

        },

        updateHiddenValue()
        {

            let value = this.$props.value
            this.fetchElms()
            if( null !== this.formElm )
            {
                this.hiddenElm.value = this.formElm.value.trim() + '|||' + JSON.stringify( value )
            }

        },

        handleUpdate( id, type, method, elmId, value, fillOnly )
        {

            this.fetchElms()
            if( null !== this.formElm )
            {
                this.hiddenElm.value = this.formElm.value.trim() + '|||' + JSON.stringify( value )
                if( undefined === fillOnly )
                {
                    this.$emit( 'update', this.$props.refName, 'fieldSelector', 'update', undefined, {
                        type : this.formElm.value.trim(),
                        value: value
                    } )
                }
            }
            /*            else
                        {
                            this.hiddenElm.value = ''
                            if( undefined === fillOnly )
                            {
                                this.$emit( 'update', this.$props.refName, 'fieldSelector', 'update', undefined, {
                                    type : undefined,
                                    value: value
                                } )
                            }
                        }*/

        },

        checkFieldType()
        {

            this.fetchElms()
            if( null !== this.formElm )
            {

                switch( this.formElm.value.trim() )
                {
                    case '':
                        this.selectedType = false
                        this.$emit( 'scoreBox', this.$props.refName, false )
                        break
                    case 'competenceSelector':
                        this.selectedType = 'competenceSelector'
                        this.$emit( 'scoreBox', this.$props.refName, false )
                        break
                    case 'studentData':
                        this.selectedType = 'studentData'
                        this.$emit( 'scoreBox', this.$props.refName, false )
                        break
                    case 'scoreBox':
                        this.selectedType = 'text'
                        this.$emit( 'scoreBox', this.$props.refName, true )
                        break
                    default:
                        this.selectedType = 'text'
                        this.$emit( 'scoreBox', this.$props.refName, false )
                        break
                }

                this.$emit( 'selected', this.$props.refName, this.formElm.value.trim() )
                this.handleUpdate( this.$props.refName, 'fieldSelector', 'update', undefined, this.$props.value, true )

            }

        }

    }

}
</script>