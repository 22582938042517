<template>
    <div style="position: relative; float:right;">
        <template v-if="!calendarMode && selectMode && !'StudentAccess' === viewItem">
            <Button v-if="0 < selectCount"
                    type="delete"
                    addClass="large element-handler"
                    @clicked="handleDelete()"/>
            <Button v-if="0 < selectCount && 'archive' !== scope"
                    type="archive"
                    addClass="large element-handler"
                    @clicked="handleArchive( scope )"/>
            <Button v-if="0 < selectCount && 'archive' === scope"
                    type="unarchive"
                    addClass="large element-handler"
                    @clicked="handleArchive( scope )"/>
            <Button v-if="0 < selectCount && undefined !== multiEdit"
                    type="edit"
                    addClass="large element-handler"
                    @clicked="handleMultiEdit()"/>
            <Button v-if="0 < colleagues && 0 < selectCount && false === noShare"
                    type="share"
                    addClass="large element-handler"
                    @clicked="handleShare()"/>
        </template>
        <template v-if="!calendarMode && selectMode && 'StudentAccess' === viewItem">
            <Button v-if="0 < selectCount"
                    :type="getType"
                    addClass="large element-handler"
                    @clicked="handleAccessActivationToggle()"/>
        </template>
        <template v-else>
            <Button v-if="0 < selectCount
                          && false !== targetDate
                          && 0 < targetDate"
                    type="move"
                    addClass="large margin-left"
                    @clicked="handleMove()"/>
        </template>
        <Button @clicked="handleClick()"
                type="select"
                :addClass="'medium top-margin'+( true === active ? ' active' : '' )"/>

    </div>
</template>

<script>
import MixinCachePreheater from "@/mixins/MixinCachePreheater";
import MixinEvents         from "@/mixins/MixinEvents.vue";

export default {

    name  : 'ElementHandler',
    mixins: [ MixinCachePreheater, MixinEvents ],

    props: {
        noShare     : { type: Boolean, required: false, default: false },
        selectMode  : { type: Boolean, required: false, default: false },
        selectCount : { type: Number, required: false, default: 0 },
        selected    : { Type: Object, required: false, default: {} },
        calendarMode: { type: Boolean, required: false, default: false },
        objectType  : { type: String, required: false, default: undefined },
        viewItem    : { type: String, required: false, default: undefined },
        scope       : { type: String, required: false, default: 'cache' }
    },

    emits: [ 'clicked', 'refresh', 'selectionState', 'movedElements', 'reset', 'handledElements' ],

    watch: {
        selectMode: {
            immediate: true,
            handler( newVal, oldVal )
            {
                if( false === newVal
                    && undefined !== oldVal )
                {
                    this.reset()
                }
            }
        },
        active    : {
            immediate: true,
            handler( newVal, oldVal )
            {
                if( false === newVal
                    && undefined !== oldVal )
                {
                    this.reset()
                }
            }
        }
    },

    data()
    {
        return {
            neededCaches: [ 'colleague' ],
            active      : false,
            moveList    : [],
            multiEdit   : undefined,
            colleagues  : 0,
            targetDate  : false
        }
    },

    created()
    {

        this.awaitNeededCaches()
            .then( () =>
            {

                this.multiEdit = this.$core.getBaseClassHelper()
                                     .getMultiEdit( this.$core.f().lcFirst( this.$props.viewItem ) ) || false

                this.colleagues = this.$core.getBaseClassHelper()
                                      .get( 'colleague' )
                                      .getCache( 'cache' ).size

                this.active = this.$props.selectMode

            } )

        this.addEvent( 'on-element-handler-reset', () =>
        {
            this.reset()
            this.handleClick()
        } )

        this.addEvent( 'on-day-select', ( item ) =>
        {
            this.targetDate = item
        } )

    },

    computed:
    {
          getType()
          {
              if( 0 < Object.keys( this.$props.selected ).length )
              {
                  return this.$core
                             .studentAccesses()
                             .hasStudentAccess( Object.keys( this.$props.selected )[0] ) ? 'deactivate' : 'activate'
              }
              return 'activate'
          }
    },

    methods: {

        handleClick()
        {
            this.active = !this.active
            if( !this.active )
            {
                this.selection = []
                this.$core.getEventManager()
                    .dispatchIndexed( 'on-deselect-all' )
            }
            this.$emit( 'clicked', this.active )
        },

        reset()
        {
            this.$emit( 'handledElements' )
            this.$core.getEventManager()
                .dispatchIndexed( 'on-deselect-all' )
            this.targetDate = false
            this.selection = []
        },

        handleSelection( item )
        {

            let idx = this.selection.indexOf( item )
            if( -1 === idx )
            {
                this.selection.push( item )
            }
            else
            {
                this.selection.splice( idx, 1 )
            }

            this.$core.getUi().delay( () =>
            {
                this.$emit( 'selectionState', 0 < this.selection.length )
            }, 500 )

        },

        getObjects( localId )
        {

            let result = []

            switch( this.$props.viewItem.toLowerCase() )
            {
                case 'list':
                    result = this.$core.getBaseClassHelper()
                                 .get( 'list' )
                                 .getContainer( localId )
                    if( Array.isArray( result )
                        && 0 < result.length
                        && 'combilist' === result[ 0 ].listType )
                    {

                        let test = this.$core.getBaseClassHelper()
                                       .get( 'list' )
                                       .getById( result[ 0 ].referenceKey )

                        if( undefined !== test )
                        {
                            result = [ test ]
                        }

                    }
                    break
                default:
                    result = [ this.$core.getBaseClassHelper()
                                   .getObjectById( localId ) ]
                    break
            }

            return result

        },

        /*eslint-disable*/
        handleConfirmDelete()
        {

            this.$core.getUi()
                .showBlocker( 'Augenblick bitte', 'Die ausgewählten Elemente werden gelöscht.' )

            let promises = []

            for( let s in this.$props.selected )
            {

                if( this.$props.selected[ s ] === true )
                {

                    let elem = this.getObjects( s )
                    for( let e in elem )
                    {

                        promises.push( new Promise( resolve =>
                        {

                            this.$core.getBaseClassHelper()
                                .get( elem[ e ].type )
                                .delete( elem[ e ].localId, elem[ e ].remoteId )
                                .then( () =>
                                {
                                    return resolve()
                                } )

                        } ) )

                    }

                }

            }

            Promise.all( promises )
                   .then( () =>
                   {
                       this.selection = []
                       this.active = false
                       this.$emit( 'reset' )
                       this.$core.getUi()
                           .delay( () =>
                           {

                               this.$core.getUi()
                                   .hideBlocker()

                           }, 500 )
                   } )

        },

        handleConfirmArchive( state )
        {

            state = state !== undefined ? state : true

            this.$core.getUi()
                .showBlocker( 'Augenblick bitte', 'Die ausgewählten Elemente werden ' + ( state ? 'archiviert' : 'reaktiviert' ) + '.' )

            this.$core.getUi().delay( () =>
            {

                let elems   = [],
                    elmType = ''

                for( let s in this.$props.selected )
                {

                    if( this.$props.selected[ s ] === true )
                    {

                        let elem = this.getObjects( s )
                        for( let e in elem )
                        {

                            elmType = elem[ e ].type
                            elems.push( elem[ e ] )

                        }

                    }

                }

                this.$core.getBaseClassHelper()
                    .get( elmType )
                    .multiArchiveState( elems, state )
                    .then( () =>
                    {

                        this.selection = []
                        this.active = false
                        this.$emit( 'reset' )

                        this.$core.getUi()
                            .delay( () =>
                            {

                                this.$core.getUi()
                                    .hideBlocker()

                            }, 500 )

                    } )

            }, 500 )

        },

        singleDelete()
        {

            let buttons = [
                'defaultCancel',
                {
                    type    : 'delete',
                    title   : 'löschen',
                    callback: () =>
                    {
                        this.handleConfirmDelete()
                    }
                }
            ]
            this.$core.getUi()
                .showModalDialog( 'delete',
                    'Auswahl löschen',
                    'Bist du dir sicher, dass die ausgewählten Elemente gelöscht werden sollen?',
                    buttons )

        },

        handleArchive( scope )
        {

            let buttons = [
                'defaultCancel',
                {
                    type    : 'archive',
                    title   : scope === 'archive' ? 'reaktivieren' : 'archivieren',
                    callback: () =>
                    {
                        this.handleConfirmArchive( scope !== 'archive' )
                    }
                }
            ]
            this.$core.getUi()
                .showModalDialog( 'archive',
                    'Auswahl ' + ( scope === 'archive' ? 'reaktivieren' : 'archivieren' ),
                    'Bist du dir sicher, dass die ausgewählten Elemente ' + ( scope === 'archive' ? 'reaktiviert' : 'archiviert' ) + ' werden sollen?',
                    buttons )

        },

        handleDelete()
        {

            if( 'media' === this.$props.objectType )
            {

                this.$core.getUi().delay( () =>
                {

                    let checklist = []
                    for( let s in this.$props.selected )
                    {
                        if( true === this.$props.selected[ s ] )
                        {
                            checklist.push( s )
                        }
                    }

                    if( 0 < checklist.length )
                    {

                        this.$core
                            .getUi()
                            .showBlocker( 'Augenblick bitte...', 'Es wird überprüft, ob Dokumente aus deiner Auswahl in Listen verwendet werden...' )

                        this.$core
                            .getUi()
                            .delay( () =>
                            {

                                let promises = [],
                                    count    = 0

                                for( let c in checklist )
                                {
                                    promises.push( () =>
                                    {
                                        this.$core.getMediaHelper()
                                            .findMediaInLists( checklist[ c ] )
                                            .then( lists =>
                                            {
                                                if( 0 < lists.length )
                                                {
                                                    count++
                                                }
                                            } )
                                    } )
                                }

                                this.$core
                                    .f()
                                    .promiseRunner( promises )
                                    .then( () =>
                                    {

                                        this.$core.getUi().hideBlocker()
                                        this.$core.getUi().delay( () =>
                                        {

                                            if( 0 < count )
                                            {

                                                let text = 'Deine Auswahl beinhaltet <strong>' + count + '</strong> Dokumente, die du aktuell in Listen'
                                                           + ' verwendest.<br/><br/>' +
                                                           'Wenn du sie jetzt löschst, werden sie auch aus allen Listen entfernt, in denen sie verwendet werden.'
                                                if( 1 === count )
                                                {
                                                    text = 'Deine Auswahl beinhaltet ein Dokument, das du aktuell in mindestens einer Liste'
                                                           + ' verwendest.<br/><br/>' +
                                                           'Wenn du es jetzt löschst, wird es auch aus allen Listen entfernt, in denen es verwendet wird.'
                                                }

                                                this.$core
                                                    .getUi()
                                                    .showModalDialog( 'delete',
                                                        'Dokumente in Liste gefunden',
                                                        text,
                                                        [
                                                            'defaultCancel',
                                                            {
                                                                type    : 'delete',
                                                                title   : this.$core.t( 'button-title-delete' ),
                                                                callback: () =>
                                                                {
                                                                    this.handleConfirmDelete()
                                                                }
                                                            }
                                                        ] )
                                            }
                                            else
                                            {
                                                this.singleDelete()
                                            }

                                        }, 500 )

                                    } )

                            }, 1000 )

                    }

                }, 500 )

            }
            else
            {
                this.singleDelete()
            }

        },

        handleCompletedMove( localId )
        {

            let idx = this.moveList.indexOf( localId )
            this.moveList.splice( idx, 1 )

            if( 0 === this.moveList.length )
            {

                this.$core.getUi().delay( () =>
                {

                    this.selection = []
                    this.active = false
                    this.targetDate = false
                    this.$emit( 'clicked', false )
                    this.$emit( 'selectionState', false )
                    this.$emit( 'movedElements' )

                    this.$core.getUi().delay( () =>
                    {

                        this.$core.getUi()
                            .hideBlocker()

                    }, 1000 )

                }, 1000 )

            }

        },

        moveDate( element, targetTsmp )
        {

            let tsmpStart = this.$core.getFriendlyTimestamp().toTimestamp( element.date, element.start ),
                tsmpEnd   = this.$core.getFriendlyTimestamp().toTimestamp( '' !== element.enddate ? element.enddate : element.date, element.end ),
                tsmpDiff  = tsmpEnd - tsmpStart,
                s         = this.$core.getFriendlyTimestamp().formattedDate( targetTsmp ),
                newStart  = this.$core.getFriendlyTimestamp().toTimestamp( s, element.start ),
                startDate = s,
                endDate   = ''

            if( '' !== element.enddate
                || '' !== element.end )
            {
                let e = this.$core.getFriendlyTimestamp().formattedDate( newStart + tsmpDiff )
                if( '' !== element.enddate )
                {
                    endDate = e
                }
            }

            let setup = {
                date   : startDate,
                start  : element.start,
                enddate: endDate,
                end    : element.end
            }

            return setup

        },

        /*eslint-disable*/
        handleConfirmMove( targetTsmp )
        {

            this.$core.getUi().showBlocker( this.$core.t( 'generic-please-wait' ), 'Elemente werden verschoben' )
            this.$core.getUi().delay( () =>
            {

                for( let e in this.selection )
                {

                    let element = this.selection[ e ]

                    switch( element.type )
                    {
                        case 'date':
                            if( element.isBirthday )
                            {
                                this.$core.getUi().setPageMessage( 'Das funktioniert leider nicht', 'Geburtstage können nicht über den Kalender verschoben werden', 'error', true )
                                this.handleCompletedMove( element.localId )
                                return
                            }
                            else
                            {
                                let setup = this.moveDate( element, targetTsmp )
                                for( let s in setup )
                                {
                                    element[ s ] = setup[ s ]
                                }
                            }
                            break
                        case 'todo':
                            element.duedate_friendly = this.$core.getFriendlyTimestamp().formattedDate( targetTsmp )
                            break
                        default:
                            element.timestamp = targetTsmp
                            element.timestampForced = true
                            break
                    }

                    this.addEvent( 'storable-after-updated-' + element.localId, () =>
                    {
                        this.handleCompletedMove( element.localId )
                    } )

                    this.$core.baseClass( element.type ).update( element, element.localId, element.remoteId, element.timestamp, element.localKey, undefined, true )

                }

            }, 500 )

        },

        handleMove()
        {

            let newTsmp = this.targetDate + ( 12 * 60 * 60000 )
            this.moveList = []

            for( let s in this.$props.selected )
            {
                if( this.$props.selected[ s ] === true )
                {
                    this.moveList.push( s )
                }
            }

            let buttons = [
                'defaultCancel',
                {
                    type    : 'submit',
                    title   : 'verschieben',
                    callback: () =>
                    {
                        this.handleConfirmMove( newTsmp )
                    }
                }
            ]

            this.$core.getUi()
                .showModalDialog( 'submit',
                    'Elemente verschieben',
                    'Bist du dir sicher, dass die ausgewählten Elemente auf den<br/><strong>'
                    + this.$core.getFriendlyTimestamp().friendlyDate( newTsmp )
                    + '</strong> verschoben werden sollen?',
                    buttons )

        },

        handleShare()
        {

            let selection = []

            for( let s in this.$props.selected )
            {
                if( this.$props.selected[ s ] === true )
                {
                    selection.push( this.$core.getBaseClassHelper().getObjectById( s ) )
                }
            }

            let readyKey = this.$core.getUi().share( selection )
            this.$core.getLogger().clog( 'ElementHandler:handleShare', 'triggering share dialog', readyKey )

        },

        handleMultiEdit()
        {

            if( false !== this.multiEdit )
            {

                let selection = []

                for( let s in this.$props.selected )
                {
                    if( this.$props.selected[ s ] === true )
                    {
                        selection.push( this.$core.getBaseClassHelper().getObjectById( s ) )
                    }
                }

                this.$core.getMultiEditHelper()
                    .handleMultiEdit( this.$core.baseClass( this.$props.viewItem.toLowerCase() ), this.multiEdit, selection )

            }

        }

    }
}
</script>