<template>
    <div id="blocker" :class="active ? mode : 'hidden'" @click="forceHideBlocker()">
        <div class="spinner-boundaries">
            <div class="spinner-box">
                <div :class="'spinner '+mode"/>
            </div>
            <div class="blocker-message">
                <h3><strong>{{ title }}</strong></h3>
                <p v-html="text"></p>
                <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
                    <div v-if="hasProgressBar" class="progress-container">
                        <span class="progress-bar" :style="'width: '+progress+'%'"></span>
                    </div>
                </transition>
                <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
                    <div v-if="hasProgressBar" class="margin-top">
                        <template v-if="!nan">
                            {{ progress }}%
                        </template>
                        <template v-else>
                            fast fertig...
                        </template>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    name: 'Blocker',

    data()
    {
        return {
            active        : false,
            mode          : false,
            title         : false,
            text          : false,
            locked        : false,
            hasProgressBar: false,
            nan           : false,
            progress      : 0
        }
    },

    created()
    {

        this.$core.getEventManager()
            .add( 'ui-blocker-showBlocker', ( values ) =>
            {
                this.showBlocker( values )
            } )

        this.$core.getEventManager()
            .add( 'ui-blocker-textUpdate', ( text ) =>
            {
                this.blockerTextUpdate( text )
            } )

        this.$core.getEventManager()
            .add( 'ui-blocker-message', ( message ) =>
            {
                this.blockerMessage( message )
            } )

        this.$core.getEventManager()
            .add( 'ui-blocker-hideBlocker', () =>
            {
                this.hideBlocker()
            } )

        this.$core.getEventManager()
            .add( 'ui-blocker-unlock', () =>
            {
                this.unlock()
            } )

        this.$core.getEventManager()
            .add( 'ui-blocker-updateProgress', ( values ) =>
            {
                this.updateProgress( values )
            } )

        this.$core.getEventManager()
            .add( 'ui-blocker-activateProgressbar', () =>
            {
                this.hasProgressBar = true
            } )

        this.$core.getEventManager()
            .add( 'ui-blocker-deactivateProgressbar', () =>
            {
                this.hasProgressBar = false
            } )

    },

    beforeUnmount()
    {

        this.nan = false
        this.$core.getEventManager()
            .remove( 'ui-blocker-showBlocker' )
        this.$core.getEventManager()
            .remove( 'ui-blocker-hideBlocker' )
        this.$core.getEventManager()
            .remove( 'ui-blocker-textUpdate' )
        this.$core.getEventManager()
            .remove( 'ui-blocker-message' )
        this.$core.getEventManager()
            .remove( 'ui-blocker-unlock' )
        this.$core.getEventManager()
            .remove( 'ui-blocker-updateProgress' )

    },

    methods: {

        hideBlocker( forced )
        {

            if( !this.locked
                || true === forced )
            {
                let elm = document.querySelector( '#blocker' )
                if( null !== elm )
                {

                    elm.style.opacity = 0

                    setTimeout( () =>
                    {
                        this.hasProgressBar = false
                        this.active = false
                        this.mode = false
                        this.title = false
                        this.text = false
                    }, 500 )

                }
            }

        },

        forceHideBlocker()
        {
            if( this.$core.getStore().getters.idUser === 1 )
            {
                this.hideBlocker( true )
            }
        },

        unlock()
        {
            this.locked = false
        },

        blockerMessage( values )
        {

            this.mode = values.mode
            this.hasProgressBar = 'progress' === this.mode
            this.title = values.title
            this.text = values.text

            if( undefined !== values.locked )
            {
                this.locked = values.locked
            }

        },

        blockerTextUpdate( text )
        {
            this.text = text
        },

        showBlocker( values )
        {

            this.blockerMessage( values )
            if( this.active === false )
            {

                let elm = document.querySelector( '#blocker' )
                if( null !== elm )
                {

                    elm.style.opacity = 0
                    this.active = true

                    setTimeout( () =>
                    {
                        elm.style.opacity = 1
                    }, 200 )

                }

            }

        },

        updateProgress( values )
        {

            let total = values.total
            let done = values.done

            this.progress = parseFloat( 100 / ( total / done ) ).toFixed( 0 )
            this.nan = isNaN( this.progress )

        }

    }

}
</script>