<template>
    <td v-for="( field, index ) in fields"
        :class="'td-'+role+' td-numberbox'"
        :key="'td-calc-'+index+'-'+viewKey">
        <span><strong v-html="field"></strong></span>
    </td>
</template>

<script>
export default {

    name : "CalculationTableFields",
    props: {
        data             : { Type: Object, required: true },
        calculatedColumns: { Type: Object, required: true },
        viewKey          : { Type: String, required: true },
        localId          : { Type: String, required: false },
        role             : { Type: String, required: false, default: '' },
        filterId         : { Type: String, required: false }
    },

    data()
    {
        return {
            fields: []
        }
    },

    watch: {
        viewKey: {
            immediate: true,
            handler()
            {
                this.prepare()
            }
        }
    },

    methods: {

        prepare()
        {

            this.fields = []
            if( Array.isArray( this.$props.calculatedColumns ) )
            {
                for( let c in this.$props.calculatedColumns )
                {

                    let id = this.$core.getSanitizers().cleanId( this.$props.calculatedColumns[ c ].label ),
                        results

                    if( undefined !== this.$props.data.byList[ this.$props.localId ] )
                    {
                        results = this.$props.data.byList[ this.$props.localId ][ id ]

                        if( '-%' !== results.display )
                        {
                            this.fields.push( results.display )
                        }
                        else
                        {
                            this.fields.push( '-' )
                        }
                    }
                    else
                    {
                        this.fields.push( '-' )
                    }

                }
            }

        }

    }

}
</script>