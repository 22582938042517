<template>
    <div>
        <div class="default-page-head">
            <div :id="'head-'+ownId">
                <template v-if="!$core.getLicense().forceReadOnly()">
                    <Button @clicked="handleClick( 'add' )"
                            key="top-adder-planner" type="add" addClass="large"/>
                </template>
                <ElementFilter @filter="setFilters"/>
                <ColorFilter @filter="setFilters"/>
            </div>
            <PlannerView @showForm="handleClick"
                         @refresh="handleRefresh"
                         @add="handleClick( 'add' )"
                         :selectedMode="mode"
                         :reference="reference"
                         :filterList="filterList"/>
        </div>
    </div>
</template>

<script>
import PlannerView         from '@/components/elements/viewItems/plannerView/PlannerView'
import ColorFilter         from '@/components/elements/filters/ColorFilter'
import Button              from '@/components/elements/defaults/Button'
import ElementFilter       from '@/components/elements/filters/ElementFilter'

export default {
    
    name      : 'NestedPlanner',
    components: { ElementFilter, Button, ColorFilter, PlannerView },
    props     : {
        viewId   : { type: String, required: true },
        reference: { type: Object, required: false, default: undefined }
    },
    
    data()
    {
        return {
            mode      : false,
            ownId     : false,
            filterList: {}
        }
    },
    
    created()
    {
        this.getMode()
        this.ownId = this.$core.getUuid().generate()
    },
    
    mounted()
    {

        let parentElm = document.querySelector( '#head-' + this.$props.viewId ),
            headElm = document.querySelector( '#head-' + this.ownId )

        if( null !== parentElm && null !== headElm )
        {
            parentElm.appendChild( headElm )
        }

    },
    
    beforeUnmount()
    {

        let parentElm = document.querySelector( '#head-' + this.$props.viewId ),
            headElm = document.querySelector( '#head-' + this.ownId )
        
        if( null !== parentElm
            && null !== headElm )
        {
            try {
                parentElm.removeChild( headElm )
            }
            catch( e )
            {
                this.$core.getLogger().clog( 'NestedPlanner::beforeUnmount', 'headelm not a child of parentElm - skipping removal' )
            }
        }

    },
    
    methods: {
        
        getMode()
        {
            this.mode = this.$core.settings().getSetting( 'calendarDefaultMode' )
            this.changeKey = this.$core.getUuid().generate()
        },
        
        setFilters( type, filters )
        {
            if( undefined === filters )
            {
                delete this.filterList[ type ]
                this.filterList[ type ] = []
            }
            else
            {
                this.filterList[ type ] = filters
            }
            this.changeKey = this.$core.getUuid().generate()
        },
        
        handleClick( mode )
        {
            
            let showForm = false
            let params = {}
            
            switch( mode )
            {
                case 'add':
                    showForm = true
                    break
                default:
                    if( 0 < parseInt( mode ) )
                    {
                        showForm = true
                        let date = this.$core.getFriendlyTimestamp().formattedDate( mode )
                        params.date = date
                        params.forceTimestamp = mode
                    }
                    break
            }
            
            switch( this.$props.reference.type )
            {
                case 'class':
                    params.selectedClass = 'class:' + this.$props.reference.localId
                    params.classReference = this.$props.reference.localId
                    break
                case 'group':
                    params.selectedClass = 'group:' + this.$props.reference.localId
                    params.groupReference = this.$props.reference.localId
                    break
                case 'yeargroup':
                    params.selectedClass = 'yeargroup:' + this.$props.reference.localId
                    params.yeargroupReference = this.$props.reference.localId
                    break
                case 'student':
                    params.studentReference = this.$props.reference.localId
                    break
            }
            
            params.forceCreate = true
            
            if( showForm )
            {
                this.$core.getUi().showForm( 'element', 'element', 'add', params )
            }
            
        },
        
        handleRefresh()
        {
            this.refreshKey = this.$core.getUuid().generate()
        }
        
    }
}
</script>