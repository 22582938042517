<template>
    <div>
        <div :class="'markerspace'+( additionalClass ? ' '+additionalClass : '' )" key="markerSpace">
            <!--            <div>-->
            <LabelMarkers v-if="$core.f().valid( item.labels )"
                          additionalClass="right"
                          :key="'labelref-'+item.localId"
                          :selectable="false"
                          :item="item"/>
            <StudentReferenceMarker v-if="$core.f().valid( item.studentReference )" additionalClass="right"
                                    :reference="item.studentReference"
                                    :key="'stdref-'+item.localId"/>
            <ClassReferenceMarker v-if="$core.f().valid( item.classReference )" additionalClass="right"
                                  :reference="item.classReference"
                                  :key="'clsref-'+item.localId"/>
            <GroupReferenceMarker v-if="$core.f().valid( item.groupReference )" additionalClass="right"
                                  :reference="item.groupReference"
                                  :key="'grpref-'+item.localId"/>
            <YeargroupReferenceMarker v-if="$core.f().valid( item.yeargroupReference )" additionalClass="right"
                                      :reference="item.yeargroupReference"
                                      :key="'ygpref-'+item.localId"/>
            <TodoListMarker v-if="undefined !== item.todos
                                              && 0 < item.todos.length && '' !== item.todos[0].trim()"
                            :key="'todoref-'+item.localId"
                            additionalClass="right"
                            :todos="item.todos"
                            :done="item.todos_done"/>
            <OrganizerReferenceMarker v-if="$core.f().valid( item.organizerReference )"
                                      :key="'orgref-'+item.localId"
                                      :reference="item"/>

            <!--            </div>-->
            <!--
    <OrganizerReferenceMarker v-if="$core.f().validObjectReference( item.organizerSlot )"
              :key="'orgref-'+item.localId"
              :reference="item"/>

    -->
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
/*eslint-disable*/
import YeargroupReferenceMarker from '@/components/elements/markers/YeargroupReferenceMarker'
import ClassReferenceMarker     from '@/components/elements/markers/ClassReferenceMarker'
import GroupReferenceMarker     from '@/components/elements/markers/GroupReferenceMarker'
import StudentReferenceMarker   from '@/components/elements/markers/StudentReferenceMarker'
import OrganizerReferenceMarker from '@/components/elements/markers/OrganizerReferenceMarker'
import MixinDetailViews         from "@/mixins/MixinDetailViews";
import LabelMarkers             from "@/components/elements/markers/LabelMarkers";
import TodoListMarker           from "@/components/elements/markers/TodoListMarker";

export default {
    name      : 'ReferenceMarkers',
    components: {
        TodoListMarker,
        LabelMarkers,
        OrganizerReferenceMarker,
        YeargroupReferenceMarker,
        ClassReferenceMarker,
        GroupReferenceMarker,
        StudentReferenceMarker
    },
    mixins    : [ MixinDetailViews ],
    props     : {
        item           : { type: Object, required: true },
        additionalClass: { Type: String, required: false }
    },

    data()
    {
        return {
            ready: false
        }
    }
}
</script>