<script>
import Checkbox                  from '@/components/form/elements/Checkbox'
import DateBox                   from '@/components/form/elements/DateBox'
import RateSelector              from '@/components/form/elements/RateSelector'
import RatePlantSelector         from '@/components/form/elements/RatePlantSelector'
import TextBox                   from '@/components/form/elements/TextBox'
import MediaBox                  from "@/components/form/elements/MediaBox";
import NumberBox                 from '@/components/form/elements/NumberBox'
import SmallNumberBox            from '@/components/form/elements/SmallNumberBox';
import TextArea                  from '@/components/form/elements/TextArea'
import ThreeWayToggle            from '@/components/form/elements/ThreeWayToggle'
import ScoreBox                  from '@/components/form/elements/scoreBoxes/ScoreBox'
import ScoreBoxAustria           from '@/components/form/elements/scoreBoxes/ScoreBoxAustria'
import ScoreBoxBremen            from '@/components/form/elements/scoreBoxes/ScoreBoxBremen'
import ScoreBoxExtended          from '@/components/form/elements/scoreBoxes/ScoreBoxExtended'
import ScoreBoxPoints            from '@/components/form/elements/scoreBoxes/ScoreBoxPoints'
import ScoreBoxQuarters          from '@/components/form/elements/scoreBoxes/ScoreBoxQuarters'
import StudentData               from '@/components/form/elements/StudentData'
import FieldSelector             from '@/components/form/elements/FieldSelector'
import RecallListDefinition      from '@/components/elements/viewItems/listEditor/RecallListDefinition'
import RateSmileSelector         from '@/components/form/elements/RateSmileSelector'
import RateSmileSelectorReverse  from '@/components/form/elements/RateSmileSelectorReverse'
import RateFlexSelector          from '@/components/form/elements/RateFlexSelector';
import CompetenceSelector        from "@/components/form/elements/CompetenceSelectorListElement.vue";
import TableDetailSplitted       from '@/components/elements/viewItems/listViews/tableElements/TableDetailSplitted'
import TableHeadRotated          from '@/components/elements/viewItems/listViews/tableElements/TableHeadRotated'
import FunctionButton            from '@/components/elements/defaults/FunctionButton'
import Opener                    from '@/components/elements/defaults/Opener';
import TableHeadFixed            from '@/components/elements/viewItems/listViews/tableElements/TableHeadFixed';
import CollabEditingNotification from "@/components/elements/indicators/CollabEditingNotification";
import CollabEditingFocusBox     from "@/components/elements/indicators/CollabEditingFocusBox";
import MixinListValueHistory     from "@/mixins/MixinListValueHistory";
import MixinLiveCollab           from "@/mixins/MixinLiveCollab";
import MixinLinkedColumns        from "@/mixins/MixinLinkedColumns";

export default {

    name: "MixinListEditorBase",

    components: {
        CollabEditingFocusBox,
        CollabEditingNotification,
        SmallNumberBox,
        TableHeadFixed,
        Opener,
        FunctionButton,
        TableHeadRotated,
        TableDetailSplitted,
        RecallListDefinition,
        FieldSelector,
        DateBox,
        TextArea,
        TextBox,
        MediaBox,
        NumberBox,
        ThreeWayToggle,
        RateSelector,
        RatePlantSelector,
        RateSmileSelector,
        RateSmileSelectorReverse,
        RateFlexSelector,
        Checkbox,
        StudentData,
        ScoreBox,
        ScoreBoxAustria,
        ScoreBoxExtended,
        ScoreBoxPoints,
        ScoreBoxQuarters,
        ScoreBoxBremen,
        CompetenceSelector
    },

    mixins: [ MixinLiveCollab, MixinListValueHistory, MixinLinkedColumns ],
    emits : [ 'toggleEditAll', 'unswipe' ],

    data()
    {
        return {
            showEditAll       : false,
            testValue         : false,
            editingAll        : false,
            editingAllValues  : {},
            editAllTimer      : null,
            valueClone        : {},
            mergedList        : null,
            calculatedRows    : {},
            listUpdateManager : false,
            prepared          : true,
            headClass         : '',
            localChangeKey    : this.$core.getUuid().generate(),
            updateTimeout     : null,
            notificationActive: false,
            notificationText  : '',
            notificationCount : 0
        }
    },

    created()
    {
        this.valueClone = undefined !== this.$props.list.values ? this.$core.f().deref( this.$props.list.values ) : {}
        this.mergedList = this.$core.f().deref( this.$props.list )
        this.listUpdateManager = this.$core.getListUpdateManager()
        this.listUpdateManager.hook( this.$props.list.localId, this.$core.f().deref( this.$props.list ) )
        this.recalculate()
    },

    beforeUnmount()
    {
        this.listUpdateManager.unhook( this.$props.list.localId )
        this.listUpdateManager = null
        this.editingAllValues = null
        this.calculatedRows = null
        this.valueClone = null
        this.mergedList = null
    },

    methods: {

        toggleShowEditAll()
        {
            this.showEditAll = !this.showEditAll
            this.$emit( 'toggleEditAll', this.showEditAll )
        },

        handleFieldUpdate( id, type, method, elmId, value, recursive )
        {

            if( null !== this.listUpdateManager )
            {
                this.handleCollabData( id, type, method, elmId, value )
                this.updateHistory( id, value )
                this._finalizeFieldUpdate( id, type, method, elmId, value, undefined, recursive )
                this.recalculate()
            }

        },

        _fieldInfo( id )
        {
            let temp    = id.split( /___/g ),
                localId = temp.shift(),
                index   = temp.pop()

            return {
                localId: localId,
                name   : temp.join( '___' ),
                index  : index
            }
        },

        _handleLineUpdate( id, value, unset )
        {

            if( 'all-' === id.substring( 0, 4 ) )
            {
                this.editingAllValues[ id ] = value
                return
            }

            value = value || ''
            if( unset )
            {
                value = undefined
            }
            for( let l in this.lines )
            {

                for( let f in this.lines[ l ] )
                {

                    if( this.lines[ l ][ f ].id === id )
                    {
                        this.lines[ l ][ f ].display = value
                    }

                }

            }

        },
        /*eslint-disable*/
        _performAllUpdate( id, type, method, elmId, value )
        {
            if( null !== this.listUpdateManager )
            {
                this.editingAll = true
                let temp = id.split( /___/g )
                temp.shift()
                let targetId = temp.join( '___' ),
                    updates  = []

                this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}
                for( let s in this.students )
                {

                    if( true === this.isVisible.get( this.students[ s ].localId ) )
                    {
                        let id = this.students[ s ].localId + '___' + targetId
                        this.valueClone[ id ] = value
                        this._handleLineUpdate( id, value )
                        this.resolveLinkedColumns( this._fieldInfo( id ), id, value )
                        updates.push( {
                            id   : id,
                            value: value
                        } )
                    }

                }

                this.updateTimeout = true
                setTimeout( () =>
                {

                    this.editingAll = false
                    this.lastLocalChange = Date.now()
                    //this.$emit( 'update', this.valueClone, this.getHistory() )
                    for( let u in updates )
                    {
                        let update = updates[ u ]
                        this.updateHistory( update.id, update.value )
                        this._finalizeFieldUpdate( update.id, type, 'update', elmId, update.value )
                    }

                    if( this.$props.list.listType === 'test' )
                    {
                        this.updateRatings()
                        this.recalculate()
                    }

                    setTimeout( () =>
                    {
                        this.updateTimeout = null
                    }, 1000 )

                }, 500 )
            }
        },

        handleAllUpdate( id, type, method, elmId, value )
        {
            if( null !== this.listUpdateManager )
            {
                clearTimeout( this.editAllTimer )
                this.resolveLinkedColumns( this._fieldInfo( id ), id, value )

                this.editAllTimer = setTimeout( () =>
                {
                    this._performAllUpdate( id, type, method, elmId, value )
                }, 3000 )
            }

        },

        _finalizeFieldUpdate( id, type, method, elmId, value, idAuthor, recursive )
        {

            if( undefined !== this.$props.list.linkedColumns
                && undefined === recursive )
            {
                this.resolveLinkedColumns( this._fieldInfo( id ), id, value )
            }

            this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}
            this.valueClone[ id ] = value

            if( this.prepared )
            {
                this.listUpdateManager.update( this.$props.list.localId, this.valueClone, this.getHistory( idAuthor ) )
                this.lastLocalChange = Date.now()
            }

            this._handleLineUpdate( id, value, value === undefined )

            if( this.$props.list.listType === 'test' )
            {
                this.$nextTick()
                    .then( () =>
                    {
                        this.updateRatings()
                    } )
            }

            if( -1 < id.indexOf( 'shad-' ) )
            {
                setTimeout( () =>
                {
                    this.originalIdUpdate( id, type, method, elmId, value, recursive )
                }, 300 )
            }

        },

        /** COLUMN CALCULATOR **/
        recalculate()
        {

            if( this.$props.list.listType === 'test' )
            {
                this.updateRatings()
            }

            if( !Array.isArray( this.$props.list.calculatedColumns )
                || 0 === this.$props.list.calculatedColumns.length )
            {
                return
            }

            this.calculatedRows = this.$core.getTableCalculation()
                                      .resolveCalculatedColumns( this.valueClone, this.$props.list )

        },

        /** ROW MANIPULATION **/
        handleRowDelete( field )
        {

            this.$core.getUi().showBlocker(
                this.$core.t( 'generic-please-wait' ),
                this.$core.t( 'list-row-is-being-removed' )
            )

            this.$core.getUi().delay( () =>
            {

                this.valueClone = this.$core.f().isset( this.valueClone ) ? this.valueClone : {}
                for( let key in this.valueClone )
                {
                    if( -1 < key.indexOf( field.id ) )
                    {
                        this.updateHistory( key, undefined )
                        this.handleFieldUpdate( key, 'update', 'update', key, undefined )
                        delete this.valueClone[ key ]
                    }
                }

                this.lastLocalChange = Date.now()

                this.$core.getEventManager().dispatchIndexed( 'on-reset-input-for', field.id )
                this.$emit( 'unswipe' )

                this.$core.getUi().delay( () =>
                {
                    this.$core.getUi().hideBlocker()
                }, 500 )

            }, 500 )

        },

        /** HISTORY HANDLER **/
        hasHistory( id )
        {
            let values = this.selectedHistory.values.values
            return ( undefined !== values[ id ] )
        },

        historicValue( id )
        {
            return this.selectedHistory.values.values[ id ]
        },

        historyDiffClass( id, value )
        {

            if( -1 < id.indexOf( '___' ) )
            {

                let historic = this.historicValue( id )

                if( ( historic === undefined || historic === '' ) && value !== undefined && value !== '' )
                {
                    return 'historic-undef'
                }
                if( ( historic !== undefined && historic !== '' ) && ( value === '' || value === undefined ) )
                {
                    return 'historic-def'
                }
                if( historic !== value )
                {
                    return 'historic-changed'
                }

            }

            return 'historic-nothing'

        },

        useHistory( id, value )
        {
            this.handleFieldUpdate( id, '', 'update', id, value )
        },

        /** TEST SCORES **/
        updateRatings( retry )
        {

            this.mergedList.values = this.valueClone

            let scores    = this.$core.getTestScoreCalculation().calculate( this.mergedList ),
                editorElm = document.querySelector( '#list-editor-' + this.$props.editMode + '-' + this.$props.list.localId ),
                foundElms = false

            retry = retry || 0

            if( null !== editorElm )
            {

                for( let s in scores.scores )
                {

                    let scoreElm  = editorElm.querySelector( '#rating-' + s ),
                        pointsElm = editorElm.querySelector( '#total-' + s )

                    if( null !== pointsElm
                        && null !== scoreElm )
                    {

                        foundElms = true

                        if( null !== scoreElm )
                        {
                            if( scores.scores[ s ].complete )
                            {
                                scoreElm.innerHTML = scores.scores[ s ].label || scores.scores[ s ].number
                            }
                            else
                            {
                                scoreElm.innerHTML = '<span class="ignore">&bull;</span>'
                            }
                        }

                        if( null !== pointsElm )
                        {
                            if( scores.scores[ s ].complete )
                            {
                                pointsElm.innerHTML = scores.scores[ s ].total
                            }
                            else
                            {
                                pointsElm.innerHTML = '<span class="ignore">&bull;</span>'
                            }
                        }

                    }

                }

            }
            if( !foundElms )
            {

                if( retry >= 10 )
                {
                    this.$core.getLogger().cerror( 'ListEditor::updateRatings', 'failed to update scores as elements did not appear withing a given time...' )
                }
                else
                {

                    retry++
                    this.$core.getCoreTimer()
                        .addTimeout( 'listeditor-updateratings', 200, () =>
                        {
                            this.updateRatings( retry )
                        } )

                }

            }

        }

    }
}
</script>
