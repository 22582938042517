import ListsObjectClass from "@/objects/ListsObjectClass";

export default class TestClass extends ListsObjectClass
{

    constructor( core )
    {

        super( core )
        this.logger = core.getLogger()
        this.friendlyTimestamp = core.getFriendlyTimestamp()
        this.listType = 'test'
        this.setStudentEditable( this.listType, false )

        /** for future use **/
        this.isPooled = false

        let fields = [
            {
                ref        : 'referenceKey',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : null
            },
            {
                ref        : 'listname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'listname'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'forceTimestamp',
                caption    : 'Datum',
                type       : 'DateBox',
                reformatter: 'date',
                optional   : false,
                validator  : 'date'
            },
            {
                ref        : 'selectedClass',
                caption    : 'Schüler:innen',
                type       : 'StudentGroupSelector',
                reformatter: null,
                optional   : false,
                validator  : 'select'
            },
            {
                ref                : 'item,score',
                id                 : 'item___score',
                caption            : 'Aufgaben & Punkte',
                type               : 'RowAdder',
                component          : 'TestPart',
                isColumnsDefinition: true,
                reformatter        : null,
                optional           : false,
                validator          : 'testpart'
            },
            {
                ref        : 'scoreModel',
                caption    : 'Benotungsmodell',
                type       : 'ScoreModelSelector',
                reformatter: null,
                optional   : false,
                validator  : null,
                mustCreate : true
            },
            {
                ref        : 'distribution',
                caption    : 'Verteilung',
                type       : 'TestDistributionKey',
                reformatter: null,
                optional   : false,
                validator  : 'testdistributionkey',
                reference  : 'item,score',
                mustCreate : true
            },
            {
                ref        : 'comment',
                caption    : 'Kommentar- / Notizfeld anzeigen',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : 'checkbox'
            },
            {
                ref        : 'organizerSlot',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            }
        ]

        this.setListFormFields( this.listType, fields )

        this.setColumnParser( this.listType, ( columns, values ) =>
        {

            for( let i in values[ 'item___score' ] )
            {
                let temp = values[ 'item___score' ][ i ].value.value.split( /\|\|\|/g ),
                    test = JSON.parse( temp[ 1 ] )

                if( '' !== test.caption.trim() && '' !== test.score.trim() )
                {
                    columns.push( {
                        type   : 'testItem',
                        score  : test.score.trim(),
                        caption: test.caption.trim()
                    } )
                }
            }

            if( 1 === values.comment )
            {
                let elm = document.querySelector( '#form-element-comment' )
                if( null !== elm && true === elm.checked )
                {
                    columns.push(
                        {
                            type   : 'testcomment',
                            caption: 'Kommentar / Notiz'
                        }
                    )
                }
            }

            if( undefined !== values.forceTimestamp
                && -1 < values.forceTimestamp.indexOf( '.' ) )
            {
                values.forceTimestamp = this.friendlyTimestamp.timestampForDate( values.forceTimestamp )
            }

            values.scoreModel = undefined === values.scoreModel ? 'basic' : values.scoreModel

            /*

                        for( let col in values['item___score'] )
                        {

                            let setup = values['item___score'][ col ],
                                type = setup.value.type,
                                caption = undefined !== setup.value.caption ? setup.value.caption : setup.value.value

                            if( undefined === caption && undefined === type
                                && undefined !== setup.type )
                            {
                                type = setup.type
                                caption = undefined !== setup.caption ? setup.caption : setup.value
                            }

                            let id = col + '_' + this.sanitizers.cleanId( caption )

                            if( undefined !== type && undefined !== caption )
                            {
                                if( caption.trim() !== '' )
                                {
                                    columns.push( {
                                        type   : type,
                                        id     : id,
                                        caption: caption
                                    } )
                                }
                            }

                        }*/

        } )

        return this

    }

}