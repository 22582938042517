<template>
    <template v-if="$core.f().isset( distinct )">
        <div class="list-element">
            <span class="list-element twt-display yes">
                J: <strong>{{ $core.f().isset( distinct[ '2' ] ) ? parseInt( distinct[ '2' ] ) : 0 }}</strong>
            </span>
            <span class="list-element twt-display no">
                N: <strong>{{ $core.f().isset( distinct[ '1' ] ) ? parseInt( distinct[ '1' ] ) : 0 }}</strong>
            </span>
            <span class="list-element twt-display nv">
                &bull; <strong>{{
                    ( totalCount - ( $core.f().isset( distinct[ '2' ] ) ? parseInt( distinct[ '2' ] ) : 0 )
                      - ( $core.f().isset( distinct[ '1' ] ) ? parseInt( distinct[ '1' ] ) : 0 ) )
                }}</strong>
            </span>
        </div>
    </template>
    <template v-else>
        <div class="list-element">
            <span class="list-element twt-display yes">
                J: <strong>0</strong>
            </span>
            <span class="list-element twt-display no">
                N: <strong>0</strong>
            </span>
            <span class="list-element twt-display nv">
                &bull; <strong>{{ totalCount }}</strong>
            </span>
        </div>
    </template>
</template>

<script>
export default {
    name : 'DistinctElement-threewaytoggle',
    props: {
        distinct  : { type: Object, required: false, default: undefined },
        totalCount: { type: Number, required: false, default: undefined }
    }
}
</script>