<template>
    <Dimmer/>
    <Blocker/>
    <ModalDialog/>
    <StatusHeader/>
    <TopMessages/>
    <Tutorial/>
    <div id="leUpdater"
         class="debug"
         style="position: absolute; top: 50px; left: 0px; padding: 20px; background: lightyellow; width:100%; height: 150px; overflow-y: scroll; font-family: Courier New"
         v-html="debug.join( '<br/><hr/><br/>' )">
    </div>
    <div id="content">
        <div id="tutorial-spacer"></div>
        <Snowflakes v-if="isWinter"/>
        <PageMessage/>
        <GoBack :base="true"/>
        <ScrollToTop :base="true"/>
        <HelpButton/>
        <div class="router-view" id="app-router">
            <Overlays/>
            <DetailViews/>

            <router-view v-slot="{ Component }">
                <transition :name="$core.settings().getTransition( 'slide-fade' )" mode="out-in">
                    <component :is="Component"/>
                </transition>
            </router-view>

            <iframe class="hidden-updater"
                    style="position: fixed; bottom:-1000px; width: 600px; height: 300px; border: 2px solid red;"
                    id="updater"></iframe>
        </div>
        <BottomButtons v-if="'local' === $core.getConfig().branch"/>
    </div>
    <Footer/>
</template>

<script>
import BottomButtons  from "@/components/layout/BottomButtons";
import Dimmer         from "@/components/layout/Dimmer";
import Tutorial       from "@/components/elements/tutorial/Tutorial";
import HelpButton     from "@/components/layout/HelpButton";
import ScrollToTop    from "@/components/elements/defaults/ScrollToTop";
import Overlays       from "@/components/defaults/overlays/Overlays";
import ModalDialog    from "@/components/elements/defaults/ModalDialog";
import Footer         from "@/components/layout/Footer";
import Blocker        from "@/components/blocker/Blocker";
import PageMessage    from "@/components/messages/PageMessage";
import Logo           from "@/components/layout/Logo";
import StatusHeader   from "@/components/layout/StatusHeader";
import DetailViews    from "@/components/layout/DetailViews";
import Snowflakes     from "@/components/layout/Snowflakes";
import GoBack         from "@/components/elements/defaults/GoBack.vue";
import TopMessages    from "@/components/layout/TopMessages.vue";

export default {

    name      : "AppReady",
    components: {
        TopMessages,
        GoBack,
        Snowflakes,
        DetailViews,
        BottomButtons,
        Dimmer,
        Tutorial,
        HelpButton,
        ScrollToTop,
        Overlays,
        ModalDialog,
        Footer,
        Blocker,
        PageMessage,
        Logo,
        StatusHeader
    },

    created()
    {
        this.initUi()
    },

    mounted()
    {

        this.updateTheme()

    },

    computed: {
        isWinter()
        {

            let month           = new Date().getMonth(),
                snowfallSetting = this.$core.settings().map.get( 'snowfall' ) || false

            if( ( month >= 11 || month === 0 ) && true === snowfallSetting )
            {
                return true
            }

            return false

        }
    },

    data()
    {
        return {
            layout       : 'default',
            perfIndex    : undefined,
            perfData     : false,
            debug        : [],
            selectedTheme: false,
            renderKey    : 'base-render-' + this.$core.getUuid().generate()
        }
    },

    methods: {

        initUi()
        {

            this.$core.setState( 'referrer', document.referrer )
            this.$core.getUi().updateBackground()

            if( -1 === this.$store.getters.idUser )
            {
                this.$core.getEventManager()
                    .add( 'debugger-out', ( line ) =>
                    {
                        this.debug.push( line )
                        let updater = document.querySelector( '#leUpdater' )
                        if( null !== updater )
                        {
                            this.$nextTick()
                                .then( () =>
                                {
                                    updater.scrollTop = updater.scrollHeight
                                } )
                        }
                    } )
            }

            this.$core.getEventManager()
                .add( 'update-background-style', ( override ) =>
                {
                    this.$core.getUi().updateBackground( override )
                } )

            this.$core.getEventManager()
                .add( 'update-selected-theme', () =>
                {
                    this.updateTheme()
                } )

            this.$core.getEventManager()
                .add( 'on-darker-color-mode-set', () =>
                {
                    this.updateTheme()
                } )

            this.$core.getClient().ping()

            this.$core.getEventManager()
                .dispatchAndRemove( 'after-app-init' )

        },

        updateTheme()
        {
            let theme           = this.$core.settings().getSetting( 'selectedTheme' ),
                darkerColorMode = this.$core.settings().getSetting( 'darkerColorMode' ),
                app             = document.querySelector( '#app' )

            if( theme !== this.selectedTheme )
            {
                app.className = this.$core.settings().getSetting( 'selectedTheme' )
                this.selectedTheme = theme
            }

            if( true === darkerColorMode )
            {
                app.classList.add( 'darker' )
            }
            if( false === darkerColorMode )
            {
                app.classList.remove( 'darker' )
            }
        }

    }

}
</script>

<style lang="less">
@selectedTheme : '';
@import '../../less/ui.less';
</style>