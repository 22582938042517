export default class afterAllClass
{

    constructor( core )
    {

        this.f = core.f()

    }

    afterFetch( classes, skipArchived )
    {
        return new Promise( resolve =>
        {
            for( let g in classes )
            {
                if( classes[ g ].archived !== true || !skipArchived )
                {
                    classes[ g ].displayName = 'Klasse ' + classes[ g ].classname
                }
            }
            return resolve( classes )
        } )
    }

    afterUpdate( classes, skipArchived )
    {
        return new Promise( resolve =>
        {
            return resolve( this.afterFetch( classes, skipArchived ) )
        } )
    }

}