<template>
    <div>
        <h2>Bild: <strong>{{ setup.image.fileName }}</strong></h2>
        <div class="form imageZoom">
            <img id="zoom" :src="setup.image.dataUrl"/>
        </div>
        <div class="align-right">
            Dateigröße: <strong>{{ readableFilesize }}</strong> kb
        </div>
    </div>
</template>

<script>
export default {

    name : "ImageZoom",
    props: {
        setup           : { type: Object, required: true },
        readableFilesize: { type: String, required: true }
    },

}
</script>