<template>
    <div>
        <RowAdder component="ContentSlotEditorBoxAdder"
                  :id="refName"
                  :refName="refName"
                  :value="previewSetup.slots"
                  :asPlainList="true"
                  :showOptional="showOptional"
                  :changeKey="changeKey"
                  :externalValidate="true"
                  @update="handleUpdate"
                  @structureUpdate="handleStructureUpdate"/>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import RowAdder from "@/components/form/elements/RowAdder";

export default {

    name      : "ContentSlotEditorBox",
    components: { RowAdder },
    data()
    {
        return {
            templates    : [],
            stored       : [],
            ready        : false,
            selection    : false,
            editorMode   : false,
            previewSetup : {
                slots: []
            },
            showUponStart: false,
            showOptional : false,
            changeKey    : this.$core.getUuid().generate()
        }
    },

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false },
        index       : { Type: Number, required: false }

    },

    created()
    {

        this.parseValue()

    },

    methods: {

        parseValue()
        {
            if( undefined !== this.$props.value
                && this.$core.f().isObject( this.$props.value ) )
            {
                let preview = []
                for( let v in this.$props.value )
                {
                    let val = this.$core.f().deref( this.$props.value[ v ] )
                    preview.push( JSON.stringify( val ) )
                }

                this.previewSetup.slots = preview
                this.showUponStart = true
                this.selection = true
                this.showOptional = true

            }
            this.ready = true
        },

        /*eslint-disable*/
        handleUpdate( refName, type, method, target, value )
        {

            this.previewSetup.slots = []
            if( this.$core.f().isObject( value ) )
            {

                for( let i in value )
                {

                    if( this.$core.f().isObject( value[ i ].value ) )
                    {
                        this.previewSetup.slots[ i ] = value[ i ].value
                    }
                    else
                    {
                        if( 'string' === typeof value[ i ].value
                            && '' !== value[ i ].value.trim() )
                        {
                            try
                            {
                                this.previewSetup.slots[ i ] = JSON.parse( value[ i ].value )
                            }
                            catch( e )
                            {
                                this.previewSetup.slots[ i ] = {
                                    title    : value[ i ].value,
                                    length   : 1,
                                    date     : undefined,
                                    slotIndex: undefined
                                }
                            }
                        }
                    }
                }

                this.$emit( 'update', this.$props.refName, 'ContentSlotEditorBox', 'update', refName, this.previewSetup.slots )

            }

        },

        handleStructureUpdate()
        {
            this.changeKey = this.$core.getUuid().generate()
        }
    }
}
</script>