import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class ElementsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !ElementsObjectClass.instance )
        {

            let refList                      = [ 'elementType' ],
                personalAttributesStatesList = []

            super( core, 'element', refList, personalAttributesStatesList )

            this.objectType = 'elements'

            ElementsObjectClass.instance = this
        }

        return ElementsObjectClass.instance

    }

    getFormFields()
    {
        return [ {
            ref        : 'elementType',
            caption    : 'Typ',
            type       : 'ElementTypeSelector',
            reformatter: null,
            optional   : false,
            validator  : 'select',
            styleUpdate: false
        } ]
    }

}