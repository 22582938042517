<template>
    <div style="position: relative;">
        <table class="todo-list">
            <p><strong>Dieses Todo enthält zu erledigende Teilaufgaben:</strong></p>
            <div v-for="( todo, index ) in item.todos"
                 :key="'todo_list_'+item.localId+'_'+index">
                <tr>
                    <td class="todo-wrap">
                        <div class="todo-checkbox sub positioned-center-y">
                            <div :class="'inner-checkbox sub '+( checkState[todo] === true ? 'checked' : 'xed' )"
                                 @click="handleClick( $event, todo )">
                            </div>
                        </div>
                    </td>
                    <td class="todo-caption" @click="handleClick( $event, todo )">
                        {{ todo }}
                    </td>
                </tr>
            </div>
        </table>
    </div>
</template>

<script>
export default {

    name : 'TodoList',
    props: {
        item: { type: Object, required: true },
        done: { type: [ Object, String ], required: false },
    },

    emits: [ 'stateChange' ],

    data()
    {
        return {
            checkState : {},
            changeTimer: null
        }
    },

    created()
    {
        this.checkState = undefined !== this.$props.done
                          && 'create' !== this.$props.done ? JSON.parse( JSON.stringify( this.$props.done ) ) : {}
    },

    watch: {
        done: {
            immediate: true,
            deep     : true,
            handler( oldVal, newVal )
            {
                if( oldVal !== newVal )
                {
                    this.checkState = undefined !== this.$props.done ? JSON.parse( JSON.stringify( this.$props.done ) ) : {}
                }
            }
        }
    },

    methods: {

        /*eslint-disable*/
        handleClick( event, todo )
        {

            this.$core.f().skip( event )

            if( this.$core.r().isAllowed( this.$props.item, 'markDone' ) )
            {

                if( 'create' === this.checkState )
                {
                    this.checkState = {}
                }
                if( this.checkState[ todo ] === undefined )
                {
                    this.checkState[ todo ] = true
                }
                else
                {
                    this.checkState[ todo ] = !this.checkState[ todo ]
                }

                if( this.changeTimer )
                {
                    clearTimeout( this.changeTimer )
                }

                this.changeTimer = setTimeout( () =>
                {
                    this.emitState( todo )
                }, 2000 )

            }

        },

        emitState( todo )
        {
            this.$core.getEventManager().dispatch( 'block-sync-for-update', this.$props.item.localId )
            this.$emit( 'stateChange', todo, this.checkState )
        }

    }

}
</script>