<template>
    <div>
        <div class="status-text synchronizing">synchronisiere <template v-if="counter">{{ counter }}&nbsp;</template><template v-else></template>
            <template v-if="!reference">Element{{ 1 !== counter ? 'e' : '' }}</template>
            <template v-else>{{ reference }}</template>
        </div>
        <div class="sync-checkmark">&#8635;</div>
    </div>
</template>

<script>
export default {
    name : 'Synchronizing',
    props: [ 'counter', 'reference' ]
}
</script>