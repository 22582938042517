<template>
    <div>
        <div class="center stream">
            <template v-if="initializing">
                <div class="cam-loading">
                    <strong>Augenblick bitte</strong>:<br/>
                    Deine Kamera wird für den QR-Code gestartet
                </div>
            </template>
            <qr-stream @decode="emitValue" @init="handleInit" camera="auto">
                <div v-if="!initializing" style="color: red;" class="frame">
                </div>
            </qr-stream>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { QrStream } from 'vue3-qr-reader'

export default defineComponent({
    name: 'QrCodeReader',
    emits: [ 'ondecode', 'nocamera' ],
    components: {
        QrStream
    },

    data() {
        return {
            initializing: true
        }
    },

    /*eslint-disable*/

    methods: {
        emitValue( data )
        {
            this.$emit( 'ondecode', data )
        },

        async handleInit( promise ) {

            try {
                const { capabilities } = await promise
            } catch ( e ) {

                this.$emit( 'nocamera' )

            } finally {
                this.initializing = false
            }

        }
    }
});
</script>

<style scoped>
.stream {
    max-height: 500px;
    max-width: 500px;
    margin: auto;
}
.frame2 {

    border-style: solid;
    border-width: 2px;
    border-color: red;
    width: 50%;
    height: 0;
    max-width: 200px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    margin: auto;

}
.frame {

    border-style: solid;
    border-width: 2px;
    border-color: red;
    width: 50%;
    margin-top: 12.5%;
    margin-left: 25%;
}

.frame::before {
    content: '';
    display: block;
    padding-top: 100%;
}

</style>