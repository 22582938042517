<template>
    <div v-if="accessId"
         :class="'markerspace'+( additionalClass ? ' '+additionalClass : '' )" key="markerSpace">
        <div v-if="access">
            <span @click="handleClick"
                  class="reference-marker group">{{ access.username }}<strong></strong></span>
        </div>
    </div>
    <template v-if="$core.getState( 'student-accesses-indexing' )">
        <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
            <SyncingReferenceMarker/>
        </transition>
    </template>
</template>

<script>
import SyncingReferenceMarker from "@/components/elements/markers/SyncingReferencesMarker.vue";

export default {

    name : "StudentAccessQuickInfo",
    components: { SyncingReferenceMarker },
    props: {
        access  : { type: [ Object, Boolean ], required: false, default: false },
        accessId: { type: [ String, Boolean ], required: false, default: false }
    },
    data()
    {
        return {
            initialized    : false,
            additionalClass: ''
        }
    }
}
</script>