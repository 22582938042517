<template>
    <div :class="'competence hoverable '+item.color+( selected ? ' selected-item' : '' )"
         :id="'printable-'+item.localId"
         :key="'competence-'+item.localId"> <!--+'-'+$core.getBaseClassHelper().getElementKey( 'competence', undefined, item.localId )">-->
        <div
            class="competence-body sensitive competenceBody"
            @click="handleClick()">
            <div class="header">
                <div class="title">
                    {{ item.title }}
                </div>
                <div v-if="item.subs && 0 < item.subs.length" class="category-functions">
                    <Button :type="open ? 'list-closer' : 'list-opener'" addClass="list-function"
                            @clicked="handleOpen"/>
                </div>
            </div>
            <div v-if="!preparing" class="element-info">
                <p v-html="'undefined' !== item.description ? item.description || '' : ''"></p>
                <template v-if="open && item.subs && 0 < item.subs.length">
                    <div class="category-subs">
                        <draggable v-model="sortableSubs"
                                   @change="move"
                                   :move="checkMove"
                                   :itemKey="'cpt-'+item.localId"
                                   ghost-class="drag-ghost"
                                   animation="200"
                                   :fallback-tolerance="1">
                            <template #item="{ element }">
                                <div class="category-sub" v-if="isValid( element )">
                                    <div class="dragbutton small"
                                         v-if="item.subs.length > 1">
                                    </div>
                                    {{ element.value.caption }}
                                </div>
                            </template>
                        </draggable>
                    </div>
                </template>
            </div>
            <Placeholder type="competenceSubs"
                         v-if="preparing"></Placeholder>
        </div>
    </div>
</template>

<script>
import ViewItemHandler       from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import LastEditor            from '@/components/elements/markers/LastEditor'
import ReferenceMarkers      from '@/components/elements/markers/ReferenceMarkers'
import MixinWatchElementKey  from "@/mixins/MixinWatchElementKey";
import MixinLivingTimestamps from "@/mixins/MixinLivingTimestamps";
import MixinComponentCleanup from "@/mixins/MixinComponentCleanup";
import draggable             from 'vuedraggable'
import Placeholder           from "@/components/form/elements/Placeholder.vue";
import Button                from "@/components/elements/defaults/Button.vue";
/*eslint-disable*/
export default {

    name      : 'Competence',
    components: { Button, Placeholder, ReferenceMarkers, LastEditor, draggable },
    extends   : ViewItemHandler,
    mixins    : [ MixinWatchElementKey, MixinLivingTimestamps, MixinComponentCleanup ],

    props: {
        item     : { type: Object, required: true },
        changeKey: { type: String, required: false },
        viewKey  : { type: String, required: false },
        selected : { type: Boolean, required: false, default: false }
    },

    emits: [ 'detailView' ],

    data()
    {
        return {
            sortableSubs: [],
            preparing   : false,
            lastKey     : false,
            updateCount : 0,
            open        : false
        }
    },

    watch: {
        "item.elementKey": {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( oldValue !== undefined
                    && newValue !== oldValue )
                {

                    clearTimeout( this.updateTimer )
                    this.updateTimer = null
                    this.preparing = true

                    if( oldValue !== this.lastKey )
                    {
                        this.lastKey = oldValue
                    }
                    else
                    {
                        this.updateTimer = setTimeout( () =>
                        {
                            this.preparing = false
                        }, 300 )
                    }

                }
            }
        }
    },

    computed: {
        classList()
        {
            return ( !this.unblurred && this.$props.item.blurry ) ? 'blurred' : ''
        }
    },

    created()
    {
        this.sortableSubs = this.$props.item.subs
        this.open = this.$core.getState( 'open-competence-'+this.$props.item.localId ) || false
    },

    methods: {

        handleClick()
        {
            if( this.$props.item.blurry )
            {
                this.unblurred = !this.unblurred
            }
        },

        move()
        {

            this.$nextTick()
                .then( () =>
                {

                    let element = this.$core.f().deref( this.$props.item )
                    element.subs = this.sortableSubs

                    this.$core.getBaseClassHelper()
                        .get( 'competence' )
                        .update( element, element.localId, element.remoteId, element.timestamp, element.localKey )

                } )
        },

        handleOpen()
        {
            this.open = !this.open
            this.$core.setState( 'open-competence-'+this.$props.item.localId, this.open )
        },

        isValid( element )
        {

            if( this.$core.f().isObject( element ) )
            {
                return true
            }
            return false
            /*
                        try {
                            let test = JSON.parse( element )
                            if( this.$core.f().isObject( test ) )
                            {
                                return false
                            }
                        }
                        catch( e )
                        {
                            return true
                        }*/
        }

    }

}
</script>