<template>
    <div :class="'file-preview-processing-align'+( full ? ' full' : '' )">
        <div class="file-preview-offline-label">offline<br/>nicht verfügbar</div>
        <div class="file-preview-processing offline"></div>
    </div>
</template>

<script>
export default {
    name: "FileBoxOfflineMessage",
    props: {
        full: { type: Boolean, required: false }
    }
}
</script>