<template>
    <div :id="ownId">
        <template v-if="prepared">
            <div :class="'list '
                    +( mode !== 'vertical' ? 'hoverable ' : '' )
                    +item.color+( selected ? ' selected-item' : '' )">
                <div class="listname" @click="handleOpen">
                    <ListDoneMark :doneInfo="doneInfo"/>
                    {{
                        item.listname
                    }}
                    <ListFunctions v-if="!clickDisabled"
                                   :open="open"
                                   :item="item"
                                   :mode="mode"
                                   :nested="true"
                                   :elementKey="functionsKey"
                                   :singleItems="singleItems"
                                   @openElement="handleOpenElement"
                                   @createElement="handleCreateElement"
                                   @showForToday="handleShowForToday()"
                                   @cloneForToday="handleClone( true )"
                                   @toggleopen="handleOpen"
                                   @toggleListFilter="showListFilters = !showListFilters"/>
                </div>
                <ListFilter v-if="showListFilters"
                            @filter="handleListFilter"/>
                <ListInfo :item="item"
                          :singleItems="singleItems"
                          :doneInfo="doneInfo"
                          @open="handleOpen"
                          @detailView="handleDetailView"/>
                <div v-if="open" class="list-summary-wrapper"
                     @scroll="$emit( 'scrolling' )"
                     :key="'nested-sumwrapper-'+item.localId+'-'+( undefined !== item.lists ? item.lists.length : '1' )">
                    <ListSummary v-if="mode === 'summary'"
                                 :item="isSingleItem ? listSingleItem : item"
                                 :elementKey="item.elementKey"
                                 :listFilter
                                 :inListEditor
                                 @dataUpdate="handleDataUpdate()"
                                 @rowClick="handleOpenElement"/>
                    <ListScores v-if="mode === 'scores'"
                                :item="isSingleItem ? listSingleItem : item"
                                :elementKey="item.elementKey"
                                :listFilter
                                :inListEditor
                                @dataUpdate="handleDataUpdate()"
                                @rowClick="handleOpenElement"/>
                    <ListDetails v-if="mode === 'details'"
                                 :item="isSingleItem ? listSingleItem : item"
                                 :elementKey="item.elementKey"
                                 :listFilter
                                 :inListEditor
                                 @dataUpdate="handleDataUpdate()"
                                 @rowClick="handleOpenElement"/>
                    <ListVertical v-if="mode === 'vertical'"
                                  :item="isSingleItem ? listSingleItem : item"
                                  :elementKey="item.elementKey"
                                  :listFilter
                                  :inListEditor
                                  @dataUpdate="handleDataUpdate()"
                                  @rowClick="handleOpenElement"/>
                    <ScoreDistribution v-if="( 'test' === item.listType || hasScoreBox === true )
                                              && 1 !== $store.getters.isStudent"
                                       :lists="isSingleItem ? [ item ] : item.lists"
                                       :elementKey="item.elementKey"/>
                </div>
            </div>
        </template>
        <template v-else>
            <div
                :class="'list synchronizing '+( mode !== 'vertical' ? 'hoverable ' : '' )+item.color"
                :key="'nested-list-inner-'+item.referenceKey+'-synchronizing'">
                <ListSynchronizingPlaceholder :list="item"/>
            </div>
        </template>
    </div>
</template>

<script>
/*eslint-disable*/
import ListFunctions                from '@/components/elements/defaults/ListFunctions'
import ListSummary                  from '@/components/elements/viewItems/listViews/ListSummary'
import ClassReferenceMarker         from '@/components/elements/markers/ClassReferenceMarker'
import GroupReferenceMarker         from '@/components/elements/markers/GroupReferenceMarker'
import ListStateMarker              from '@/components/elements/markers/ListStateMarker'
import ViewItemHandler              from '@/components/elements/viewItems/viewHandler/ViewItemHandler'
import ScoreDistribution            from '@/components/form/elements/tests/ScoreDistribution'
import ListScores                   from '@/components/elements/viewItems/listViews/ListScores'
import ListDetails                  from '@/components/elements/viewItems/listViews/ListDetails'
import ListVertical                 from '@/components/elements/viewItems/listViews/ListVertical'
import LastEditor                   from '@/components/elements/markers/LastEditor'
import MixinEvents                  from '@/mixins/MixinEvents'
import YeargroupReferenceMarker     from '@/components/elements/markers/YeargroupReferenceMarker'
import ListFilter                   from "@/components/elements/filters/ListFilter";
import ListDoneMark                 from "@/components/elements/viewItems/listViews/parts/ListDoneMark";
import ListInfo                     from "@/components/elements/viewItems/listViews/parts/ListInfo";
import MixinCachePreheater          from "@/mixins/MixinCachePreheater";
import ListSynchronizingPlaceholder from "@/components/elements/viewItems/listViews/ListSynchronizingPlaceHolder";
import MixinComponentCleanup        from "@/mixins/MixinComponentCleanup.vue";

export default {
    name: 'NestedList',

    components: {
        ListSynchronizingPlaceholder,
        ListInfo,
        ListDoneMark,
        ListFilter,
        YeargroupReferenceMarker,
        LastEditor,
        ListVertical,
        ListDetails,
        ListScores,
        ScoreDistribution,
        ListStateMarker,
        GroupReferenceMarker,
        ClassReferenceMarker,
        ListSummary,
        ListFunctions
    },

    extends: ViewItemHandler,

    mixins: [ MixinEvents, MixinCachePreheater, MixinComponentCleanup ],
    emits : [ 'refresh', 'detailView', 'click', 'open', 'scrolling', 'dataUpdate', 'prepared', 'findTab' ],

    props: {
        item         : { type: Object, required: true },
        clickDisabled: { type: Boolean, required: false },
        elementKey   : { Type: String, required: false },
        singleItems  : { Type: Boolean, required: false },
        swiped       : { Type: Boolean, required: false },
        remoteOpen   : { type: String, required: false },
        escapeNested : { Type: Boolean, required: false, default: false },
        startOpen    : { Type: Boolean, required: false, default: false },
        selected     : { type: Boolean, required: false, default: false },
        selectMode   : { type: Boolean, required: false, default: false },
        inListEditor : { type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            ownId          : this.$core.getUuid().generate(),
            neededCaches   : [ 'student' ],
            open           : false,
            mode           : 'summary',
            editItem       : null,
            lastEdited     : false,
            recoveryTimer  : null,
            isFiltered     : false,
            filterCount    : 0,
            listFilter     : false,
            updateTimer    : null,
            changed        : false,
            doneInfo       : false,
            triggerOpen    : false,
            hasScoreBox    : false,
            valueHistory   : false,
            changeTimer    : false,
            filterBy       : false,
            listSingleItem : false,
            prepared       : false,
            showListFilters: false,
            initialLookup  : true,
            triggerIds     : [],
            functionsKey   : false,
            emitDataUpdate : false
        }
    },

    watch: {
        'item.elementKey'                : {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== newValue
                    && undefined !== oldValue
                    && newValue !== oldValue )
                {

                    this.checkListInfo()
                    this.checkScoreBox()
                    this.functionsKey = this.item.lists[ this.item.lists.length - 1 ].elementKey
                }
            }
        }
    },

    computed: {
        isSingleItem()
        {
            return !Array.isArray( this.item.lists )
        }
    },

    created()
    {

        this.awaitNeededCaches()
            .then( () =>
            {

                this.functionsKey = this.item.lists ? this.item.lists[ 0 ].elementKey : this.item.elementKey
                if( this.isSingleItem )
                {
                    this.prepareListSingleItem()
                        .then( () =>
                        {
                            this.prepared = true
                            this.$emit( 'prepared' )
                        } )
                }
                else
                {
                    this.prepared = true
                    this.$emit( 'prepared' )
                }

            } )

    },

    mounted()
    {

        let tmp = this.$core.getState( 'detailViewFor' )
        if( undefined !== tmp )
        {
            let temp = tmp.split( ':' )
            this.filterBy = temp[ 0 ]
        }

        if( 1 === this.$core.getStore().getters.isStudent )
        {
            this.mode = 'test' === this.item.listType ? 'scores' : 'details'
        }
        else
        {
            this.mode = 'test' === this.item.listType ? 'scores' : 'summary'
        }

        this.open = this.$core.getState( 'list-open-' + this.item.referenceKey ) || false
        this.checkScoreBox()
        this.checkListInfo()

    },

    methods: {

        prepareListSingleItem()
        {

            return new Promise( resolve =>
            {

                this.listSingleItem = this.item
                return resolve()

            } )

        },

        checkScoreBox()
        {

            let item = this.isSingleItem ? this.item : this.item.lists[ 0 ]
            this.hasScoreBox = false

            if( undefined !== item
                && undefined !== item.columns )
            {
                for( let c in item.columns )
                {
                    let col = item.columns[ c ]
                    if( undefined !== col.type
                        && col.type.substr( 0, 8 ) === 'scoreBox' )
                    {
                        this.hasScoreBox = true
                        break
                    }
                }

            }
        },

        checkListInfo()
        {
            this.$nextTick()
                .then( () =>
                {
                    if( 'recallList' === this.item.listType )
                    {
                        this.doneInfo = {
                            done   : this.item.isDone,
                            duedate: this.item.duedate
                        }
                    }
                } )
        },

        handleOpen( event )
        {

            if( this.$props.clickDisabled )
            {
                return
            }

            if( undefined !== event )
            {
                this.$core.f().skip( event )
            }
            if( true === this.$props.selectMode )
            {
                this.$emit( 'click' )
            }
            else
            {
                this.open = !this.open
                if( !this.open )
                {
                    this.showListFilters = false
                }
                this.$core.setState( 'list-open-' + this.item.referenceKey, this.open )
            }
        },

        stripEmptyHistory( history )
        {

            let stripped = []

            for( let idx in history )
            {
                if( 0 < Object.keys( history[ idx ].updates ).length )
                {
                    stripped.push( history[ idx ] )
                }
            }

            return stripped
        },

        /*eslint-disable*/

        handleClose()
        {

            this.$core.getEventManager().dispatch( 'editor-closed' )
            this.$core.setState( 'last-trigger-open', null )
            this.$bus.resetTrigger( 'lastObjectCreated' )
            this.$bus.resetTrigger( 'lastObjectType' )

            this.valueHistory = null
            this.editItem = null

        },

        getListForToday()
        {

            if( undefined !== this.item.lists )
            {
                for( let l in this.item.lists )
                {
                    if( this.$core.getFriendlyTimestamp().timestampIsToday( this.item.lists[ l ].timestamp ) )
                    {
                        return this.item.lists[ l ]
                    }
                }
            }
            else
            {
                if( this.$core.getFriendlyTimestamp().timestampIsToday( this.item ) )
                {
                    return this.item
                }
            }

            return undefined

        },

        getListValueHistoryForToday()
        {

            let values = undefined

            if( undefined !== this.item.lists )
            {
                for( let l in this.item.lists )
                {
                    if( this.$core.getFriendlyTimestamp().timestampIsToday( this.item.lists[ l ].timestamp ) )
                    {
                        values = this.$core.f().deref( this.item.lists[ l ].values )
                    }
                }
            }
            else
            {
                if( this.$core.getFriendlyTimestamp().timestampIsToday( this.item ) )
                {
                    values = this.$core.f().deref( this.item.values )
                }
            }

            return values

        },

        handleShowForToday()
        {
            this.valueHistory = undefined !== this.item.listForToday
                                && undefined !== this.item.listForToday.values
                                ? this.$core.f().deref( this.item.listForToday.values )
                                : this.getListValueHistoryForToday()
            this.editItem = this.item.listForToday || this.getListForToday()
        },

        handleClone( today, timestamp )
        {

            this.openList()
            this.$core.setState( 'last-action-cloned', true )
            this.$core.getUi()
                .delay( () =>
                {
                    this.$core.setState( 'last-action-cloned', false )
                }, 2000 )

            if( today )
            {
                this.$core.getBaseClassHelper().get( this.item.type )
                    .clone( this.item.localId, Date.now() )
            }
            else
            {
                this.$core.getBaseClassHelper().get( this.item.type )
                    .clone( this.item.localId, timestamp )
            }

        },

        _archiveMatch( student, timestamp )
        {

            if( undefined !== student )
            {

                if( student.archived !== true )
                {
                    return true
                }

                let temp = student.archiveKey.split( /-/g )
                temp.shift()
                let archiveTimestamp = parseInt( temp.join( '' ) )
                if( archiveTimestamp >= parseInt( timestamp ) )
                {
                    return true
                }

            }
            return false

        },

        openList()
        {
            if( !this.open )
            {
                this.open = true
            }
        },

        handleScores( which )
        {
            this.openList()
            this.mode = which //'scores' === this.mode ? 'summary' : 'scores'
        },

        handleDetails()
        {
            this.openList()
            this.mode = 'details' === this.mode ? 'summary' : 'details'
        },

        handleVertical()
        {
            this.openList()
            this.mode = 'vertical' !== this.mode ? 'vertical' : 'summary'
        },

        handleOpenElement( element )
        {
            if( true === this.$props.selectMode
                || ( true === this.$props.inListEditor
                     && !this.$core.getListUpdateManager().hasHook( element.localId ) ) )
            {
                this.$emit( 'click', element.localId )
            }
            else if( ( true === this.$props.inListEditor
                       && this.$core.getListUpdateManager().hasHook( element.localId ) ) )
            {
                this.$emit( 'findTab', element.localId )
            }
            else
            {
                if( true !== this.$props.swiped )
                {

                    this.valueHistory = undefined !== element.values ? this.$core.f().deref( element.values ) : {}
                    this.emitDataUpdate = true
                    this.editItem = element

                }
            }
        },

        handleCreateElement( timestamp )
        {
            this.handleClone( false, timestamp )
        },

        checkVertical()
        {
            if( this.mode === 'vertical' )
            {
                let opener = document.querySelector( '.list-detail-opener' )
                if( null !== opener )
                {
                    if( opener.classList.contains( 'list-closer' ) )
                    {
                        return false
                    }
                    else
                    {
                        return true
                    }
                }
            }

            return false

        },

        handleDataUpdate()
        {
            if( true === this.emitDataUpdate )
            {
                this.$emit( 'dataUpdate' )
                this.emitDataUpdate = false
            }
        },

        handleListFilter( filter, value )
        {
            this.listFilter = value
        }

    }

}
</script>