<script>
export default {

    name: 'MixinStateHandlers',

    data()
    {
        return {
            elementList          : [ 'app', 'header', 'content', 'footer' ],
            initialViewportHeight: 0,
            reloaded             : false,
            hidden               : false,
            visibilityChange     : false,
            handlerAttached      : false,
            syncPauseAfter       : ( 60000 * 5 ),
            audioTimer           : null,
            syncTimer            : null
        }
    },

    created()
    {
        this.$core.getLogger().clog( 'MixinStateHandler:created', 'initialized app state handler, monitoring blur, focus and resize events' )

        this.$core.getEventManager().add( 'on-priority-audio-pause', () => {
            this.audioPause()
        })
        this.$core.getEventManager().add( 'on-priority-audio-resume', () => {
            this.audioPlayback()
        })

        this.initialViewportHeight = window.visualViewport.height
        this.initialViewportWidth = window.visualViewport.width
    },

    mounted()
    {

        this.$core.setState( 'keyboardJustHidden', false )
        this.$core.getEventManager()
            .add( 'on-interaction', () =>
            {
                this.mountAudioPlayback()
                this.audioPlayback()
                this.syncUnpause()
            } )

        /* prevent double tabbed usage */

        this.$core.getEventManager()
            .add( 'after-app-init', () => {

                try {

                    const bc = new BroadcastChannel( 'entzettelt-broadcast' )
                    bc.onmessage = ( event ) =>
                    {
                        if( event.data === 'check-instance-presence' )
                        {
                            bc.postMessage( 'instance-present' )
                        }
                        if( event.data === 'instance-present' )
                        {

                            let blockerHash = this.$core.getUi().reserveBlocker( true )
                            window.forceOffline = true
                            this.$core.getSyncWorker().pause()
                            this.$core.getUi()
                                .showBlocker( 'entzettelt ist mehrfach geöffnet', '<strong>Die Nutzung von entzettelt in mehreren Tabs ist leider nicht möglich!</strong><br/><br/>Bitte wechsle zurück in den ersten Browser-Tab, in dem du entzettelt verwendest.', undefined, true, blockerHash )

                        }
                    }

                    bc.postMessage( `check-instance-presence` )

                }
                catch( e )
                {
                    this.$core.getLogger().clog( 'MixinStateHandler:mounted', 'could not initialize BroadcastChannel: maybe not supported?' )
                }

            })

        /* reload detection and more trickery */
        this.$core.getUi().delay( () =>
        {
            this.$store.commit( 'setReloadDetected', false )
        }, 3000 )

        if( true !== window.hasEvents )
        {

            window.onbeforeunload = () =>
            {
                this.reloaded = true
            }

            this.$core.getLogger().clog( 'MixinStateHandler:created', 'mounted' )

            window.visualViewport.addEventListener( 'resize', () =>
            {

                this.$core.getLogger().clog( 'MixinStateHandler:resize', 'resize triggered' )

                let keyboardShown = false,
                    oldState = this.$core.getState( 'keyboardShown' )

                if( window.visualViewport.height < this.initialViewportHeight
                    && window.visualViewport.width === this.initialViewportWidth )
                {
                    keyboardShown = true
                }
                else
                {
                    this.initialViewportHeight = window.visualViewport.height
                    this.initialViewportWidth = window.visualViewport.width
                }

                this.$core.getLogger().clog( 'MixinStateHandler:resize', 'keyboard: '+( keyboardShown ? 'visible' : 'hidden' ) )

                this.$core.setState( 'keyboardShown', keyboardShown )

                if( false === keyboardShown
                    && true === oldState )
                {
                    this.$core.setState( 'keyboardJustHidden', true )
                    this.$core.getUi()
                        .delay( () =>
                        {
                            this.$core.setState( 'keyboardJustHidden', false )
                        }, 2000 )
                }

            } )

            if( !this.handlerAttached )
            {

                if( typeof document.hidden !== 'undefined' )
                { // Opera 12.10 and Firefox 18 and later support
                    this.hidden = 'hidden'
                    this.visibilityChange = 'visibilitychange'
                }
                else if( typeof document.msHidden !== 'undefined' )
                {
                    this.hidden = 'msHidden'
                    this.visibilityChange = 'msvisibilitychange'
                }
                else if( typeof document.webkitHidden !== 'undefined' )
                {
                    this.hidden = 'webkitHidden'
                    this.visibilityChange = 'webkitvisibilitychange'
                }

                document.addEventListener( this.visibilityChange, this.handleVisibilityChange, false )
                this.handlerAttached = true

            }

            window.addEventListener( 'DOMContentLoaded', () =>
            {

                this.$core.getLogger().clog( 'MixinStateHandler:DOMContentLoaded', 'DOMContentLoaded detected' )
                if( document.wasDiscarded )
                {
                    this.$core.getLogger().clog( 'MixinStateHandler::DOMContentLoaded', 'document claims discarded state -> performing a full refresh' )
                    this.$core.getUi().showBlocker( 'entzettelt wurde gestoppt', 'Während entzettelt im Hintergrund war, wurde es gestoppt. Wir starten einmal durch, damit alles richtig funktioniert...' )

                    this.$core.getCoreTimer()
                        .addTimeout( 'reload-after-discarded', 3000, () => {
                            document.location.reload( true )
                        })

                }

            } )

            window.addEventListener( 'online', () =>
            {
                this.$core.getLogger().clog( 'MixinStateHandler:ConnectionStateChange', 'online now' )
                this.$core.getEventManager().dispatchIndexed( 'on-connection-state-changed', 'online' )
            } )

            window.addEventListener( 'offline', () =>
            {
                this.$core.getLogger().clog( 'MixinStateHandler:ConnectionStateChange', 'offline now' )
                this.$core.getEventManager().dispatchIndexed( 'on-connection-state-changed', 'offline' )
            } )

            window.hasEvents = true

        }

    },

    methods: {
        handleVisibilityChange()
        {

            if( document[ this.hidden ] )
            {
                if( this.reloaded === true )
                {
                    this.$store.commit( 'setReloadDetected', true )
                    this.$core.getUi().delay( () =>
                    {
                        this.$store.commit( 'setReloadDetected', false )
                    }, 3000 )
                    this.$core.getLogger().clog( 'MixinStateHandler:' + this.visibilityChange, 'page reload detected' )
                }
                else
                {
                    this.$core.getLogger().clog( 'MixinStateHandler:' + this.visibilityChange, 'blur/hide detected' )
                    if( true !== this.$core.getState( 'keyboardShown' ) )
                    {
                        this.$core.setState( 'blurred', true )
                        this.audioPause()
                        this.syncPause()
                        this.$core.getLogger().clog( 'MixinStateHandler:' + this.visibilityChange, 'blur/hide processed' )
                        this.$nextTick()
                            .then( () =>
                            {
                                this.$core.getEventManager().dispatchIndexed( 'cache-snapshot' )
                            } )
                    }
                }
            }
            else
            {
                this.$core.getLogger().clog( 'MixinStateHandler:' + this.visibilityChange, 'focus/show detected', this.$core.getState( 'keyboardShown' ), this.$core.getState( 'keyboardJustHidden' ), this.$core.getState( 'blurred' ) )
                if( true !== this.$core.getState( 'keyboardShown' )
                    && true !== this.$core.getState( 'keyboardJustHidden' )
                    && true === this.$core.getState( 'blurred' ) )
                {
                    this.audioPlayback()
                    this.syncUnpause()
                    this.$core.getLogger().clog( 'MixinStateHandler:' + this.visibilityChange, 'focus/show processed' )
                    this.$core.getEventManager().dispatch( 'refresh-render-key' )
                    this.$core.getEventManager().dispatchIndexed( 'cache-recover' )
                }
            }
        },

        mountAudioPlayback()
        {

            if( null !== document.querySelector( '#entzettelt-player' ) )
            {
                return
            }

            try {

                this.$core.getLogger().clog( 'AudioPlayback: mounting' )
                let ctx         = new AudioContext(),
                    bufferSize  = 2 * ctx.sampleRate,
                    emptyBuffer = ctx.createBuffer( 1, bufferSize, ctx.sampleRate ),
                    output      = emptyBuffer.getChannelData( 0 )

                for( let i = 0; i < bufferSize; i++ )
                {
                    output[ i ] = 0
                }

                let source = ctx.createBufferSource()
                source.buffer = emptyBuffer
                source.loop = true

                let node = ctx.createMediaStreamDestination()
                source.connect( node )

                let audio = document.createElement( 'audio' )
                audio.id = 'entzettelt-player'
                audio.style.display = 'none'
                document.body.appendChild( audio )
                audio.srcObject = node.stream

            }
            catch( e )
            {
                this.$core.getLogger().clog( 'AudioPlayback: mounting failed.' )
            }

        },

        audioPause()
        {

            this.$core.getCoreTimer()
                .addTimeout( 'audio-pause', this.$core.getConfig().maxTickTimeout, () => {
                    this.$core.getAuthenticator().logout( true )
                })

        },

        audioPlayback()
        {
            this.$core.getCoreTimer()
                .removeTimeout( 'audio-pause' )

            if( true === this.$core.settings().getSetting( 'alwaysOn' ) )
            {
                let audio = document.querySelector( '#entzettelt-player' )
                if( null !== audio && audio.paused )
                {
                    this.$core.getLogger().clog( 'AudioPlayback: starting' )
                    audio.play()
                }
            }

        },

        syncPause()
        {
            this.$core.getCoreTimer()
                .addTimeout( 'sync-pause', this.syncPauseAfter, () => {
                    this.$core.getSyncWorker().pause( true )
                })
        },

        syncUnpause()
        {

            this.$core.getCoreTimer()
                .removeTimeout( 'sync-pause' )
            this.$core.getSyncWorker().pause( false )

        }
    }

}
</script>