<template>
    <div :class="'competence-completion-result level-'+level"
         :id="'listBoundaries-'+ownId">
        <div v-if="calculation._tsmpComplete"
             :class="'ok'+( calculation._listComplete ? ' list-icon' : '' )"
             @click="handleListOpen( calculation._listComplete )">
            {{ $core.getFriendlyTimestamp().formattedDate( calculation._tsmpComplete ) }}
        </div>
        <div v-else
             class="not-ok">X
        </div>
    </div>
</template>
<script>

export default {

    name      : "CompetenceCompletionResult",
    props: {
        calculation: { type: Object, required: false },
        level      : { type: Number, required: false, default: 1 }
    },

    data()
    {
        return {
            ownId   : this.$core.getUuid().generate()
        }
    },

    methods: {

        handleListOpen( localId )
        {

            if( undefined === localId )
            {
                return
            }

            let elm = this.$core.getBaseClassHelper()
                          .get( 'list' )
                          .findListById( localId, 'cache' )

            if( elm )
            {
                this.$core.getUi()
                    .showOverlay( {
                        type        : 'listEditor',
                        targetId    : null, //this.ownId,
                        editItem    : elm,
                        disableMulti: true,
                        inListEditor: false,
                        escapeNested: true,
                        mode        : 'summary',
                        listFilter  : false
                    } )
            }

        }

    }
}
</script>