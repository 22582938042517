<template>
    <div>
        <RowAdder component="SubCompetenceField"
                  :id="refName"
                  :refName="refName"
                  :value="value"
                  :unique="unique"
                  :dragBlocked="false"
                  :asPlainList="false"
                  :maxRowCount="maxRowCount"
                  resultingType="subCompetenceField"
                  @resortAdder="handleResort"
                  @update="handleUpdate"/>
    </div>
</template>
<script>
/*eslint-disable*/
import RowAdder from "@/components/form/elements/RowAdder.vue";

export default {
    name      : "SubCompetence",
    components: { RowAdder },

    emits: [ 'update', 'resortAdder' ],

    props: {

        refName    : { Type: String, required: true },
        value      : { Type: String, required: false },
        self       : { Type: String, required: false },
        allValues  : { Type: Array, required: false },
        unique     : { Type: Boolean, required: false },
        asPlainList: { Type: Boolean, required: false, default: true },
        exclude    : { Type: Array, required: false, default: [] },
        index      : { Type: Number, required: true },
        maxRowCount: { Type: Number, required: false }

    },

    methods: {
        /*eslint-disable-next-line*/
        handleUpdate( id, type, method, elmId, value, removed )
        {

            this.$emit( 'update', this.$props.refName, 'SubCompetence', 'update', undefined, value )

        },

        handleResort( newRows )
        {
            this.$emit( 'resortAdder', newRows, this.$props.refName )
        }
    }
}
</script>