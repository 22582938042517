<template>
<div class="ratesmileselector" :ref="'rateWrap_'+getElementRefName()">
        <div v-for="rate in rates" :key="'plant-'+rate">
            <div @click="setValue( rate )"
                 v-bind:class="'ratesmile rate-'+rate+( rate === selectedValue ? ' active' : '' )" v-html="imgs[rate]">
            </div>
        </div>
    </div>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {

    name: 'RateSmileSelector',

    mixins: [ MixinResettableComponent ],

    props: {
        refName    : { Type: String, required: true },
        value      : { Type: String, required: true },
        disabled   : { Type: Boolean, required: false },
        highlighted: { Type: Object, required: false, default: [] },
        inline     : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'focussed', 'blurred', 'update' ],

    data()
    {
        return {
            rates        : [ 0, 1, 2, 3, 4 ],
            minrate      : 0,
            imgs         : [ '&#x1f641;', '&#x1f615;', '&#x1f610;', '&#x1f642;', '&#x1f600;' ],
            hovering     : false,
            hoverValue   : 0,
            selectedValue: 0
        }
    },
    created()
    {

        this.init()

        this.$nextTick()
            .then( () =>
            {
                this.setValue( this.$props.value, true )
            } )

    },
    methods: {

        init()
        {
            switch( parseInt( this.$core.settings().getSetting( 'ratingSmiles' ) ) )
            {
                case 5:
                    this.minrate = 0
                    this.rates = [ 0, 1, 2, 3, 4 ]
                    break
                case 4:
                    this.minrate = 1
                    this.rates = [ 1, 2, 3, 4 ]
                    break
                case 3:
                    this.minrate = 1
                    this.rates = [ 1, 2, 4 ]
                    break
                case 2:
                    this.minrate = 0
                    this.rates = [ 0, 4 ]
                    break
            }
        },

        getElementRefName()
        {
            return 'elm_' + this.$props.refName
        },

        getClassName( rate )
        {

            let className = ''
            let compare = this.selectedValue
            if( this.hovering
                && !this.$core.getUi().isTouchDevice() )
            {
                compare = this.hoverValue
            }

            if( rate <= compare )
            {
                className = 'selected'
            }
            else
            {
                className = 'inactive'
            }

            if( -1 < this.highlighted.indexOf( this.$props.refName + '_' + rate ) )
            {
                className = className + ' highlighted'
            }

            return className

        },
        setValue( rate, initial )
        {
            if( ( undefined !== rate && initial )
                || ( true !== this.$props.disabled ) )
            {
                if( this.selectedValue === this.minrate && rate === this.minrate )
                {
                    this.selectedValue = undefined
                    rate = undefined
                }
                else
                {
                    this.selectedValue = rate
                }
            }
            if( !initial )
            {
                if( true !== this.$props.disabled )
                {
                    this.$emit( 'update', this.$props.refName, 'rateSelector', 'update', undefined, rate )
                }
            }
        },
        handleChange( event )
        {
            if( true !== this.$props.disabled )
            {

                if( undefined !== event.target
                    && undefined !== event.target.value )
                {
                    let newValue = '' == event.target.value ? 0 : event.target.value
                    this.setValue( newValue, false )
                }

            }
        },
        setHover( rate )
        {
            if( true !== this.$props.disabled )
            {
                this.hovering = true
                this.hoverValue = rate
                this.$emit( 'focussed', this.$props.refName + '_' + rate )
            }
        },
        clearHover()
        {
            this.hovering = false
            this.$emit( 'blurred', 'all:' + this.$props.refName )
        }
    }
}
</script>