<template>
    <div>
        <div :class="'markerspace'+( additionalClass ? ' '+additionalClass : '' )" key="markerSpace">
            <LabelMarkers v-if="$core.f().valid( item.labels )"
                          additionalClass="right"
                          :key="'labelref-'+item.localId"
                          :selectable="false"
                          :item="item"/>
            <!--            <StudentReferenceMarker v-if="$core.f().valid( item.studentReference )" additionalClass="right"
                                                :reference="item.studentReference"
                                                :key="'stdref-'+item.localId"/>
                        <ClassReferenceMarker v-if="$core.f().valid( item.classReference )" additionalClass="right"
                                              :reference="item.classReference"
                                              :key="'clsref-'+item.localId"/>-->
            <template v-if="Array.isArray( item.inGroups )">
                <template v-if="groupsOpen">
                    <GroupReferenceMarker v-for="group in item.inGroups"
                                          :reference="group.groupId"
                                          :key="'grpref-'+item.localId+'-'+group.groupId"/>
                </template>
                <template v-else>
                    <div>
                        <span @click="handleClick"
                              class="reference-marker group">in <strong>{{
                                item.inGroups.length
                            }} Gruppe{{ 1 < item.inGroups.length ? 'n' : '' }}</strong></span>
                    </div>
                </template>
            </template>
            <!--<template v-if="undefined !== item.yeargroupId">
                <YeargroupReferenceMarker :reference="item.yeargroupId"
                                          :key="'ygpref-'+item.localId+'-'+item.yeargroupId"/>
            </template>-->

            <!--            <TodoListMarker v-if="undefined !== item.todos
                                                          && 0 < item.todos.length && '' !== item.todos[0].trim()"
                                        :key="'todoref-'+item.localId"
                                        additionalClass="right"
                                        :todos="item.todos"
                                        :done="item.todos_done"/>
                        <OrganizerReferenceMarker v-if="$core.f().valid( item.organizerReference )"
                                                  :key="'orgref-'+item.localId"
                                                  :reference="item"/>-->
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>
/*eslint-disable*/
import YeargroupReferenceMarker from '@/components/elements/markers/YeargroupReferenceMarker'
import ClassReferenceMarker     from '@/components/elements/markers/ClassReferenceMarker'
import GroupReferenceMarker     from '@/components/elements/markers/GroupReferenceMarker'
import StudentReferenceMarker   from '@/components/elements/markers/StudentReferenceMarker'
import OrganizerReferenceMarker from '@/components/elements/markers/OrganizerReferenceMarker'
import MixinDetailViews         from "@/mixins/MixinDetailViews";
import LabelMarkers             from "@/components/elements/markers/LabelMarkers";
import TodoListMarker           from "@/components/elements/markers/TodoListMarker";

export default {
    name      : 'ItemReferenceMarkers',
    components: {
        TodoListMarker,
        LabelMarkers,
        OrganizerReferenceMarker,
        YeargroupReferenceMarker,
        ClassReferenceMarker,
        GroupReferenceMarker,
        StudentReferenceMarker
    },
    mixins    : [ MixinDetailViews ],
    props     : {
        item           : { type: Object, required: true },
        additionalClass: { Type: String, required: false }
    },

    data()
    {
        return {
            ready     : false,
            groupsOpen: false
        }
    },

    methods: {
        handleClick( event )
        {
            this.$core.f().skip( event )
            this.groupsOpen = true
        }
    }
}
</script>