<template>
    <div class="planner-wrapper">
        <div class="spacer"></div>
        <h3 class="clickable" @click="open = !open">Raster-Vorschau {{ open ? 'ausblenden' : 'einblenden' }}</h3>
        <div v-show="open"
             :class="'planner-view organizer-content preview planner-mode-'+this.plannerView">
            <div class="planner-day-title blank"></div>
            <div v-for="n in 1"
                 :key="'day-title-'+n"
                 :class="'planner-day-title'+' day-'+n">
                {{ $core.getTranslation().translate( 'day-title-' + n ) }}
            </div>
            <div class="planner-day-title day-3">
                ...
            </div>

            <PlannerSlots :noCaptions="false"
                          :className="'planner-slot transparent planner-day-organizer-slots preview day-0'"
                          :slots="slots"
                          :slotContents="[]"
                          :highlighted="[]"
                          :date="dates[ 0 ].dateObject.getTime()"/>

            <div v-for="n in 1"
                 :key="'day-planner-'+n">
                <PlannerSlots :noCaptions="true"
                              :className="'planner-day-organizer-slots preview nohover day-'+n"
                              :slots="slots"
                              :slotContents="[]"
                              :highlighted="[]"
                              :date="undefined !== dates[ ( n - 1 ) ] ? dates[ ( n - 1 ) ].dateObject.getTime() : undefined"/>
            </div>

        </div>

        <div class="clearfix"></div>

    </div>
</template>

<script>
import PlannerSlots from "@/components/elements/viewItems/plannerView/PlannerSlots";

export default {
    /*eslint-disable*/
    name: "SlotEditorPreview",

    components: {
        PlannerSlots,
    },

    props: {
        slots: { type: [ Object, Boolean ], required: false },
    },

    data()
    {
        return {
            plannerView   : 'organizer',
            date          : false,
            open          : false,
            dates         : [],
            organizerSlots: [],
        }
    },

    mounted()
    {
    },

    beforeUnmount()
    {
    },

    created()
    {
        this.prepare()
    },

    methods: {

        prepare()
        {
            this.date = this.$core.getFriendlyTimestamp().getMonday( new Date() )
            for( let i = 0; i < 3; i++ )
            {
                this.dates.push( { dateObject: new Date( this.date + ( i * 86400000 ) ) } )
            }
        }

    }
}
</script>