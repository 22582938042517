/*eslint-disable*/
export default class ListDataTypeHelper
{

    constructor( core )
    {

        this.logger = core.getLogger()
        this.f = core.f()

        this.converters = {
            friendlyTimestamp: core.getFriendlyTimestamp()
        }

        this.dataTypeMap = {
            listType      : [
                {
                    target: 'field',
                    attr  : 'value',
                    key   : 'listType'
                },
                {
                    target: 'root',
                    if    : false,
                    attr  : 'initialListType',
                    key   : 'listType'
                }
            ],
            distribution  : [
                {
                    target: 'field',
                    attr  : 'scoreModel',
                    key   : 'scoreModel'
                },
                {
                    target: 'field',
                    attr  : 'value',
                    key   : '.ref'
                }
            ],
            selectedClass : [
                {
                    target: 'field',
                    attr  : 'value',
                    concat: {
                        keys: [ 'columns', 0, [ 'filter', 'filterBy' ] ],
                        char: ':'
                    }
                }
            ],
            columns       : [
                {
                    target : 'field',
                    attr   : 'value',
                    source : 'columns',
                    if     : [ {
                        cond : 'not',
                        key  : 'resultingType',
                        value: 'combilistSetup'
                    } ],
                    arrayOf: {
                        id   : 'id',
                        type : 'type',
                        value: 'caption',
                        if   : [ {
                            cond : 'not',
                            key  : 'type',
                            value: 'fixed'
                        } ]
                    }
                },
                {
                    target      : 'field',
                    attr        : 'value',
                    source      : 'columns',
                    if          : [ {
                        cond : 'eq',
                        key  : 'resultingType',
                        value: 'combilistSetup'
                    } ],
                    groupedArray: {
                        groupBy: 'container',
                        element: {
                            root           : 'value',
                            fixedAttributes: [
                                {
                                    key  : 'type',
                                    value: 'combilistSetup'
                                }
                            ],
                            attrs          : [
                                {
                                    key  : 'listId',
                                    value: ':key'
                                },
                                {
                                    key  : 'columns',
                                    value: '+originalId'
                                }
                            ]
                        },
                        if     : [ {
                            cond : 'not',
                            key  : 'type',
                            value: 'fixed'
                        } ]
                    }
                }
            ],
            'item,score'  : [
                {
                    target : 'field',
                    attr   : 'value',
                    source : 'columns',
                    arrayOf: {
                        id     : 'id',
                        type   : 'type',
                        caption: 'caption',
                        score  : 'score',
                        if     : [
                            {
                                cond : 'not',
                                key  : 'type',
                                value: 'fixed'
                            },
                            {
                                cond : 'not',
                                key  : 'type',
                                value: 'testcomment'
                            }
                        ]
                    },
                    set    : [
                        {
                            target: 'root',
                            key   : 'hasScoreBox',
                            value : true
                        },
                        {
                            target: 'list',
                            key   : 'comment',
                            value : 1,
                            if    : [
                                {
                                    cond : 'in',
                                    in   : 'columns',
                                    key  : 'type',
                                    value: 'testcomment'
                                }
                            ]
                        }
                    ]
                }
            ],
            forceTimestamp: [
                {
                    target : 'field',
                    attr   : 'value',
                    convert: {
                        source   : 'timestamp',
                        converter: 'friendlyTimestamp',
                        format   : 'formattedDate'
                    }
                }
            ],
            '*'           : [
                {
                    target: 'field',
                    attr  : 'value',
                    key   : '.ref'
                }
            ]

        }

    }

    _concat( map, list )
    {

        let concat = map.concat.keys

        let key = concat[ 0 ]
        let idx = concat[ 1 ]
        let fields = concat[ 2 ]

        let result = []

        for( let f in fields )
        {
            result.push( list[ key ][ idx ][ fields[ f ] ] )
        }

        return result.join( ':' )

    }

    _conditionMatch( conditions, values )
    {

        let allMatch = true

        for( let c in conditions )
        {
            let condition = conditions[ c ]
            let compare = values[ condition.key ]
            switch( condition.cond )
            {
                case 'not':
                    if( compare === condition.value )
                    {
                        allMatch = false
                    }
                    break
                case 'eq':
                    if( compare !== condition.value )
                    {
                        allMatch = false
                    }
                    break
                case 'in':
                    let look = values[ condition.in ]
                    for( let l in look )
                    {
                        if( look[ l ][ condition.key ] === condition.value )
                        {
                            return true
                        }
                    }
                    allMatch = false
                    break
            }
        }

        return allMatch

    }

    _groupedArray( map, list )
    {

        let source = list[ map.source ],
            setup  = map.groupedArray,
            result = []


        let grouped = {}
        for( let s in source )
        {
            if( undefined === setup.if
                || this._conditionMatch( setup.if, source[ s ] ) )
            {
                if( undefined === grouped[ source[ s ][ setup.groupBy ] ] )
                {
                    grouped[ source[ s ][ setup.groupBy ] ] = []
                }
                grouped[ source[ s ][ setup.groupBy ] ].push( source[ s ] )
            }
        }

        for( let g in grouped )
        {
            let target = {}
            target[ setup.element.root ] = {}
            for( let t in setup.element.fixedAttributes )
            {
                let fix = setup.element.fixedAttributes[ t ]
                target[ setup.element.root ][ fix.key ] = fix.value
            }

            for( let gg in grouped[g] )
            {

                for( let a in setup.element.attrs )
                {
                    let attr = setup.element.attrs[a],
                        k
                    switch( attr.value.substr( 0, 1 ) )
                    {
                        case ':':
                            k = attr.value.replace( '+', '' )
                            target[ setup.element.root ][ attr.key ] = ( attr.value === ':key' ? g : grouped[g][ gg ][ k ] )
                            break
                        case '+':
                            k = attr.value.replace( '+', '' )
                            if( undefined === target[ setup.element.root ][ attr.key ] )
                            {
                                target[ setup.element.root ][ attr.key ] = []
                            }
                            target[ setup.element.root ][ attr.key ].push( grouped[g][gg][k] )
                            break
                    }

                }
            }

            result.push( target )


        }

        return result

    }

    _arrayOf( map, list )
    {

        let source     = list[ map.source ],
            setup      = map.arrayOf,
            sourceKeys = Object.keys( setup ),
            result     = []

        for( let s in source )
        {

            let entry = {}

            if( undefined === setup.if
                || this._conditionMatch( setup.if, source[ s ] ) )
            {
                for( let k in sourceKeys )
                {
                    let key = sourceKeys[ k ]
                    if( !this.f.isObject( setup[ key ] ) )
                    {
                        entry[ key ] = source[ s ][ setup[ key ] ]
                    }
                }
                result.push( entry )
            }
        }

        return result

    }

    _convert( map, list )
    {
        let source = list[ map.convert.source ]
        let value = this.converters[ map.convert.converter ][ map.convert.format ]( source )
        return value
    }

    setupField( field, list, root )
    {

        let map = this.dataTypeMap[ field.ref ]

        if( undefined === map )
        {
            map = this.dataTypeMap[ '*' ]
        }

        if( undefined !== map )
        {
            for( let m in map )
            {
                switch( map[ m ].target )
                {
                    case 'field':
                        if( undefined === map[ m ].if
                            || ( list[ map[ m ].key ] === map[ m ].if || this._conditionMatch( map[ m ].if, field ) ) )
                        {
                            if( undefined !== map[ m ].key
                                && -1 < map[ m ].key.indexOf( '.' ) )
                            {
                                let key = map[ m ].key.replace( '.', '' )
                                field[ map[ m ].attr ] = list[ field[ key ] ]
                            }
                            else
                            {
                                let skip = false
                                if( undefined !== map[ m ].concat )
                                {
                                    field[ map[ m ].attr ] = this._concat( map[ m ], list )
                                    skip = true
                                }
                                if( !skip && undefined !== map[ m ].arrayOf )
                                {
                                    field[ map[ m ].attr ] = this._arrayOf( map[ m ], list )
                                    skip = true
                                }
                                if( !skip && undefined !== map[ m ].groupedArray )
                                {
                                    field[ map[ m ].attr ] = this._groupedArray( map[ m ], list )
                                    skip = true
                                }
                                if( !skip && undefined !== map[ m ].convert )
                                {
                                    field[ map[ m ].attr ] = this._convert( map[ m ], list )
                                    skip = true
                                }
                                if( !skip )
                                {
                                    field[ map[ m ].attr ] = list[ map[ m ].key ]
                                }
                            }
                        }
                        break
                    case 'root':
                        if( undefined === map[ m ].if
                            || root[ map[ m ].key ] === map[ m ].if )
                        {
                            root[ map[ m ].attr ] = list[ map[ m ].key ]
                        }
                        break
                }

                if( undefined !== map[ m ].set )
                {
                    for( let s in map[ m ].set )
                    {
                        let set = map[ m ].set[ s ]
                        let skip = false
                        if( undefined !== set.if )
                        {
                            skip = !this._conditionMatch( set.if, list )
                        }
                        if( !skip )
                        {
                            switch( set.target )
                            {
                                case 'list':
                                    list[ set.key ] = set.value
                                    break
                                case 'field':
                                    field[ set.key ] = set.value
                                    break
                                case 'root':
                                    root[ set.key ] = set.value
                                    break
                            }
                        }
                    }
                }
            }
        }

    }

}