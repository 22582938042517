<script>
export default {
    name: "MixinOrganizerViewFunctions",

    methods: {

        changeRange( startTsmp )
        {
            let monday = this.$core.friendlyTimestamp.getMonday( new Date( startTsmp ) )
            this.changeDate( 'jump', undefined, 'organizer', monday )
        },

        changeDate( direction, returnValue, target, jumpTarget )
        {

            target = target || 'calendar'

            let timestamp = this.date.getTime()

            switch( direction )
            {
                case 'last':
                    timestamp -= ( 86400000 * 7 )
                    break
                case 'next':
                    timestamp += ( 86400000 * 7 )
                    break
                case 'jump':
                    timestamp = jumpTarget
                    break
            }

            if( undefined === returnValue )
            {
                this.date = new Date( timestamp )
                this.$core.setState( 'organizer-date', this.date )
                this.prepare()
            }
            else
            {
                return timestamp
            }

        },

        dragChange( dragging, direction )
        {
            if( dragging && ( Date.now() > this.lastDragChange + 1000 ) )
            {
                this.lastDragChange = Date.now()
                this.changeDate( direction, undefined, 'organizer', undefined )
            }
        },

        handleDragging( state )
        {
            this.dragging = state
            this.$emit( 'dragging', state )
        },

        inRange( tsmpStart, tsmpEnd )
        {

            if( this.tsmpEnd < tsmpStart
                || this.tsmpStart > tsmpEnd )
            {
                return false
            }

            return true

        },

        prepare()
        {

            let date   = new Date(),
                stored = this.$core.getState( 'organizer-date' )
            //timestamp = this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + ( date.getMonth() ) ) + '.' + date.getFullYear() )

            this.preparing = true
            this.today = date

            if( undefined !== this.$props.startTsmp )
            {
                this.date = new Date( parseInt( this.$props.startTsmp ) )
            }
            else
            {
                this.date = stored || new Date()
            }

            this.organizerDate = this.date

            this.prepareHolidays()
                .then( () =>
                {

                    this.prepareDateGrid()
                    this.prepareDateElements()
                    this.preparing = false
                    this.prepared = true
                    this.$core.getEventManager().dispatch( 'on-planner-prepared' )
                    this.viewKey = this.$core.getUuid().generate()

                } )

        },

        checkVisibleSlots( reset )
        {

            let visible   = [],
                contents  = [],
                distincts = []

            if( reset )
            {
                this.slotContents = []
            }

            this.visibleOrganizers = []

            this.$core.getBaseClassHelper()
                .get( 'organizercontent' )
                .listAll()
                .then( allContents =>
                {

                    if( !this.$props.setupOpen )
                    {

                        for( let o in this.organizers )
                        {

                            if( this.$core.f().allFilterMatch( this.organizers[ o ], this.$props.filterList ) )
                            {
                                let tsmpStart = this.$core.getFriendlyTimestamp()
                                                    .timestampForDate( this.organizers[ o ].dateStart, 0 ),
                                    tsmpEnd   = this.$core.getFriendlyTimestamp()
                                                    .timestampForDate( this.organizers[ o ].dateEnd, 24 )

                                if( this.organizers[ o ].noTimeRange
                                    || this.inRange( tsmpStart, tsmpEnd ) )
                                {

                                    let hash = this.$core.f().hashCyrB53( JSON.stringify( this.organizers[ o ].slotDisplay ) )
                                    if( -1 === distincts.indexOf( hash ) )
                                    {
                                        distincts.push( hash )
                                    }

                                    visible.push( this.organizers[ o ] )

                                    for( let a in allContents )
                                    {
                                        if( allContents[ a ].organizerReference === this.organizers[ o ].localId )
                                        {

                                            let slotContents = this.$core.f().deref( allContents[ a ] )
                                            slotContents.color = slotContents.color || this.organizers[ o ].color
                                            for( let s in slotContents.slotDisplay )
                                            {
                                                slotContents.slotDisplay[ s ].color = slotContents.color
                                            }
                                            contents.push( slotContents )

                                        }
                                    }

                                }
                            }

                        }

                    }
                    if( !this.$props.setupOpen
                        && ( 1 === visible.length
                             || 1 === distincts.length ) )
                    {
                        this.organizerSlots = visible[ 0 ].slots
                    }
                    else
                    {
                        this.organizerSlots = [ {
                            type  : 'freetext',
                            height: 30,
                            value : this.$core.t( 'organizer-generic' )
                        } ]
                    }

                    this.visibleOrganizers = visible
                    this.slotContents = contents

                } )

        },

        checkOrganizerSlots( reset )
        {

            if( true === this.$props.setupOpen )
            {
                return
            }

            this.organizersFetching = true

            this.$core.getBaseClassHelper()
                .get( 'organizer' )
                .listAll()
                .then( list =>
                {

                    this.organizersFetching = false
                    this.organizersDefined = 0 < list.length
                    this.organizers = list

                    this.checkVisibleSlots( reset )

                    this.viewKey = this.$core.getUuid().generate()

                    if( false === this.organizersDefined )
                    {
                        this.$emit( 'disableFilters' )
                    }

                } )

        },

        _getDaysAfter( date )
        {

            switch( date.getDay() )
            {
                case 1:
                case 3:
                case 5:
                    return -1
                case 6:
                    return 1
            }

        },

        _getFirstVisibleBefore( dayStart, fillersBefore )
        {
            switch( dayStart.getDay() )
            {
                case 0:
                    return fillersBefore - 2
                case 2:
                case 4:
                case 6:
                    return fillersBefore - 1
            }
        },

        isListed( timestamp )
        {
            for( let d in this.dates )
            {
                if( timestamp === this.dates[ d ].timestamp )
                {
                    return true
                }
            }
            return false
        },

        isWeekend( date )
        {
            return ( date.dateObject.getDay() > 5 || date.dateObject.getDay() === 0 )
        },

        /*eslint-disable*/
        prepareDateGrid()
        {

            let fillersBefore = 0,
                fillersAfter  = 0,
                halfDay       = 86400000 / 2,
                after,
                start         = this.$core.getFriendlyTimestamp().timestampForDateTime(
                    this.$core.getFriendlyTimestamp().formattedDate( this.getMonday( this.organizerDate ) ) + ' 12:00:00' ),
                daysTotal     = 7

            this.date = new Date( start )

            let datesList      = [], // fillerup
                daysAfter      = 0,
                firstVisBefore = fillersBefore,
                dayAfter       = new Date( after + 86400000 ),
                dayStart       = new Date( this.date.getTime() ),
                realStart      = new Date( this.$core.getFriendlyTimestamp().timestampForDate( '1.' + ( 1 + this.month ) + '.' + this.year ) ),
                realEnd        = new Date( ( realStart.getTime() + ( ( parseInt( daysTotal ) - 1 ) * 86400000 ) ) )

            realStart.setHours( 12, 0, 0, 0 )
            realEnd.setHours( 12, 0, 0, 0 )

            this.realStart = realStart.getTime()
            this.realEnd = realEnd.getTime()
            daysAfter = this._getDaysAfter( dayAfter )
            firstVisBefore = this._getFirstVisibleBefore( dayStart, fillersBefore )

            this.rangeFrom = this.$core.getFriendlyTimestamp().friendlyDate( start )
            this.rangeUntil = this.$core.getFriendlyTimestamp().friendlyDate( start + ( ( daysTotal - 1 ) * 86400000 ) )

            let checkStart = new Date( start ),
                checkEnd   = new Date( start + ( ( daysTotal - 1 ) * 86400000 ) )

            checkStart.setHours( 12, 0, 0, 0 )
            checkEnd.setHours( 12, 0, 0, 0 )

            this.visibleStart = checkStart.getTime()
            this.visibleEnd = checkEnd.getTime()

            for( let i = 0; i < daysTotal; i++ )
            {

                let calcStart       = start,
                    dateObject      = new Date( calcStart ),
                    dateKey         = new Date( calcStart ),
                    isFiller        = i < fillersBefore || i >= ( fillersBefore + daysTotal ),
                    twoRowVis       = false,
                    firstVisTwoRows = firstVisBefore,
                    lastVisTwoRows  = fillersBefore + daysTotal + daysAfter

                dateKey.setHours( 12, 0, 0, 0 )

                if( isFiller )
                {
                    twoRowVis = i >= firstVisTwoRows && i <= lastVisTwoRows
                }

                let date = {
                    dateObject: dateObject,
                    timestamp : dateKey.getTime(),
                    day       : dateObject.getDate(),
                    isFiller  : isFiller,
                    weekday   : dateKey.getDay(),
                    twoRowVis : twoRowVis ? ' filler-visible ' : ( isFiller ? ' filler-invisible ' : '' ),
                    dateRange : { from: ( calcStart - halfDay ), until: ( calcStart + halfDay ) },
                    elements  : []
                }


                if( false !== this.showDate
                    && this.showDate.dateRange.from === date.dateRange.from )
                {
                    this.prepareForDate( date )
                    this.showDate = date
                }

                if( !this.isWeekend( date )
                    && !this.isListed( date.timestamp ) )
                {
                    this.dates.push( date )
                    start += 86400000
                }

            }

            /*            this.dates[ this.organizerDate.getTime() ] = this.$core.getSorter()
                                                                         .multiSortObjects( this.dates[ this.organizerDate.getTime() ], [ [ 'timestamp', 'ascending' ] ] )*/

        },

    }
}
</script>