<template>
    <span class="reference-marker right syncing">~</span>
</template>

<script>

export default {

    name: 'SyncingReferenceMarker'

}
</script>