<script>
export default {
    name: 'TutorialBase',

    data()
    {
        return {
            step       : 1,
            highlights : [],
            clones     : [],
            highlighted: [],
            timer      : false,
            scope      : false,
            root       : null,
            nextBlocked: false,
            backBlocked: false,
            fakeElement: false,
            position   : null,
            stepsBack  : 1
        }
    },

    mounted()
    {
        this.scope = this.$.type.name
        this.root = document.querySelector( '#tutorial-root' )
        this.$core.s().count( 'tutorial-started-' + this.scope )
    },

    watch: {
        step: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.clean()
                    this.prepareHighlights()
                    this.highlight()
                }
            }
        }
    },

    beforeUnmount()
    {
        clearInterval( this.timer )
        this.clean()
    },

    emits: [ 'close' ],

    methods: {

        handleBack()
        {
            if( true === this.backBlocked )
            {
                return
            }
            this.clearTimerAndButton()
            if( this.step > 1 )
            {
                this.step -= this.stepsBack
            }
            else
            {
                this.$emit( 'close' )
            }
        },

        handleNext()
        {

            if( true === this.nextBlocked )
            {
                return
            }
            if( this.step < this.last )
            {
                /*                if( this.step < 9 )
                                {
                                    this.step = 9
                                }
                                else
                                {*/
                this.step++
                /*                } */
            }
            else
            {
                this.$emit( 'close' )
            }
        },

        clean()
        {
            if( null !== this.root
                && 0 < this.clones.length )
            {
                while( 0 < this.clones.length )
                {
                    let clone = this.clones.shift()
                    if( null !== clone )
                    {
                        this.root.removeChild( clone )
                    }
                }
            }
            if( 0 < this.highlighted.length )
            {
                while( 0 < this.highlighted.length )
                {
                    let elm = this.highlighted.shift()
                    if( null !== elm )
                    {
                        elm.style.position = this.position
                        elm.classList.remove( 'highlight' )
                        elm.classList.remove( 'tutor-highlight' )
                        elm.classList.remove( 'tutor-raised' )
                    }
                }
            }
            this.position = null
        },

        scroll( elm )
        {

            let app = document.querySelector( '#app' )
            let root = document.querySelector( '#tutorial-root' )
            let head = root.querySelector( '.inner.top' )
            let elmY = elm.getBoundingClientRect().top + app.scrollTop
            let elmH = elm.getBoundingClientRect().height

            let offset = elmY - elmH - head.offsetHeight

            app.scrollTo( {
                top     : offset,
                behavior: 'smooth'
            } )

        },

        elementHighlight( elm, scroll )
        {

            elm.classList.add( 'highlight' )
            elm.classList.add( 'tutor-highlight' )

            if( scroll === true )
            {
                this.scroll( elm )
            }

            this.highlighted.push( elm )

        },

        _checkElm( elmId )
        {
            let nextButton = document.querySelector( '#tutor-button-next' )
            let elm = document.querySelector( elmId )
            if( null !== elm )
            {
                nextButton.style.opacity = 100
                nextButton.style.cursor = 'pointer'
                clearInterval( this.timer )
                this.timer = false
                this.nextBlocked = false
                setTimeout( () =>
                {
                    this.elementHighlight( elm )
                    this.handleNext()
                }, 200 )
            }
            else
            {
                nextButton.style.cursor = 'disabled'
                nextButton.style.opacity = 0
                this.nextBlocked = true
            }
        },

        clearTimerAndButton()
        {

            let nextButton = document.querySelector( '#tutor-button-next' )
            nextButton.style.opacity = 100
            nextButton.style.cursor = 'pointer'
            this.nextBlocked = false
            clearInterval( this.timer )
            this.timer = null

        },

        setupTimer( elmId )
        {
            this._checkElm( elmId )
            if( !this.timer )
            {
                this.timer = setInterval( () =>
                {

                    this._checkElm( elmId )

                }, 250 )
            }
        },

        _prepare( prepare )
        {
            let root = ( undefined !== prepare.root ? document.querySelector( prepare.root ) : document )
            if( null !== root )
            {
                let element = root.querySelector( prepare.element )
                if( null !== element )
                {
                    switch( prepare.action )
                    {
                        case 'click':
                            element.click()
                            break
                    }
                }
            }
        },

        _fill( element, value )
        {
            return new Promise( resolve =>
            {
                element.value = value
                setTimeout( () =>
                {
                    return resolve()
                }, 100 )
            } )
        },

        _animate( element, value, display )
        {
            return new Promise( resolve =>
            {

                if( 'text' === element.type
                    || 'textarea' === element.type
                    || true === display )
                {
                    if( value.length > 0 )
                    {
                        let char = value.substr( 0, 1 )
                        if( display )
                        {
                            element.innerHTML += char
                        }
                        else
                        {
                            element.value += char
                        }
                        setTimeout( () =>
                        {
                            return resolve( this._animate( element, value.substr( 1, ( value.length - 1 ) ), display ) )
                        }, ( display ? 2 : 20 ) )
                    }
                    else
                    {
                        setTimeout( () =>
                        {
                            return resolve()
                        }, 100 )
                    }
                }
                else
                {
                    return resolve( this._fill( element, value ) )
                }
            } )

        },

        /*eslint-disable*/
        _animateFill( item, element )
        {
            return new Promise( resolve =>
            {
                if( 0 < item.fields.length )
                {
                    let target = item.fields.shift()
                    let def = target.split( ':' )
                    let mode = def[ 0 ] === 't' ? 'default' : 'display'

                    let tgt = def[ 1 ]

                    let root = document.querySelector( item.root )
                    if( null !== root )
                    {
                        let elm = root.querySelector( '#form-element-' + tgt )
                        if( 'default' === mode && null !== elm )
                        {
                            this._animate( elm, element[ tgt ], 'default' !== mode )
                                .then( () =>
                                {
                                    return resolve( this._animateFill( item, element ) )
                                } )
                        }
                        else
                        {
                            elm = root.querySelector( '#display_elm_' + tgt )
                            if( null !== elm )
                            {
                                this._animate( elm, element[ tgt ], 'default' !== mode )
                                    .then( () =>
                                    {
                                        return resolve( this._animateFill( item, element ) )
                                    } )
                            }
                            else
                            {
                                return resolve( this._animateFill( item, element ) )
                            }
                        }
                    }
                }
            } )
        },

        _staticFill( item, element )
        {
            return new Promise( resolve =>
            {
                if( 0 < item.fields.length )
                {
                    let target = item.fields.shift()
                    let def = target.split( ':' )
                    let mode = def[ 0 ]
                    switch( mode )
                    {
                        case  't':
                            mode = 'default'
                            break
                        case 'f':
                            mode = 'fixedvalue'
                            break
                        default:
                            mode = 'display'
                            break
                    }

                    let tgt = def[ 1 ]

                    let root = document.querySelector( item.root )
                    if( null !== root )
                    {
                        let elm = root.querySelector( '#form-element-' + tgt )
                        if( 'default' === mode && null !== elm )
                        {
                            this._fill( elm, element[ tgt ] )
                                .then( () =>
                                {
                                    return resolve( this._staticFill( item, element ) )
                                } )
                        }
                        else
                        {
                            if( 'display' === mode )
                            {
                                elm = root.querySelector( '#display_elm_' + tgt )
                                if( null !== elm )
                                {
                                    elm.innerHTML = element[ tgt ]
                                    return resolve( this._staticFill( item, element ) )
                                }
                                else
                                {
                                    return resolve( this._staticFill( item, element ) )
                                }
                            }
                            else
                            {
                                elm = root.querySelector( tgt )
                                if( null !== elm )
                                {
                                    elm.value = def[ 2 ]
                                    let evt = document.createEvent( 'HTMLEvents' )
                                    evt.initEvent( 'change', false, true )
                                    elm.dispatchEvent( evt )
                                    return resolve( this._staticFill( item, element ) )
                                }
                                else
                                {
                                    return resolve( this._staticFill( item, element ) )
                                }
                            }
                        }
                    }
                }
                else
                {
                    return resolve()
                }
            } )
        },

        _resolve( delay )
        {
            return new Promise( resolve =>
            {
                setTimeout( () =>
                {
                    return resolve()
                }, ( undefined === delay ? 10 : delay ) )
            } )
        },

        _handleFill( fill, element )
        {

            return new Promise( resolve =>
            {

                switch( fill.type )
                {
                    case 'animated':
                        this._animateFill( fill, element )
                            .then( () =>
                            {
                                return resolve( this._resolve( fill.delay ) )
                            } )
                        break
                    default:
                        this._staticFill( fill, element )
                            .then( () =>
                            {
                                return resolve( this._resolve( fill.delay ) )
                            } )
                        break

                }
            } )

        },

        showFill( showFill )
        {

            if( 0 < showFill.fill.length )
            {
                let fill = showFill.fill.shift()
                this._handleFill( fill, showFill.element )
                    .then( () =>
                    {
                        this.showFill( showFill )
                    } )
            }

        },

        highlight()
        {
            let delay = 0
            for( let h in this.highlights )
            {

                let highlight = this.highlights[ h ]
                if( true === highlight.prepareDemo )
                {
                }
                else
                {
                    this.stepsBack = undefined !== highlight.stepsback ? highlight.stepsback : 1
                    let rootElm = document.querySelector( highlight.root )
                    if( undefined !== highlight.prepare )
                    {
                        this._prepare( highlight.prepare )
                        if( true === highlight.prepareDemo )
                        {
                            this.$core.getEventManager()
                                .dispatch( 'on-prepare-demo-objects' )
                            delay = 500
                            continue
                        }
                    }
                    this.$core.getUi().delay( () =>
                    {

                        this.fakeElement = ( undefined !== highlight.fakeElement ? highlight.fakeElement : false )
                        this.backBlocked = true === highlight.backdisabled
                        if( true === highlight.unblurred )
                        {
                            this.root.classList.add( 'unblurred' )
                        }
                        else
                        {
                            this.root.classList.remove( 'unblurred' )
                        }
                        if( undefined !== highlight.waitForNext )
                        {
                            this.setupTimer( highlight.waitForNext )
                        }
                        if( null !== rootElm )
                        {
                            for( let hh in highlight.elements )
                            {
                                let element = highlight.elements[ hh ],
                                    elm     = rootElm.querySelector( element )

                                if( null !== elm )
                                {

                                    if( true === highlight.raise )
                                    {
                                        this.position = null === this.position ? elm.style.position : this.position
                                        elm.style.position = 'absolute'
                                        elm.classList.add( 'tutor-raised' )
                                    }
                                    this.elementHighlight( elm, highlight.scroll )

                                }
                                else
                                {
                                    this.$core.getLogger().cerror( 'TutorialBase::highlight', 'element ' + element + ' not found in ' + highlight.root + ' of ' + this.scope + ', step ' + this.step )
                                }
                            }
                        }
                        else
                        {
                            this.$core.getLogger().cerror( 'TutorialBase::highlight', 'no root element ' + highlight.root + ' of ' + this.scope + ', step ' + this.step )
                        }

                        if( undefined !== highlight.showFill )
                        {
                            setTimeout( () =>
                            {
                                this.showFill( highlight.showFill )
                            }, ( highlight.showFill.initialDelay === undefined ? 10 : highlight.showFill.initialDelay ) )
                        }

                    }, delay )
                }
            }
        }
    }

}
</script>