<template>
    <div>
        <template v-if="ready">
        <span :class="'reference-marker class '+refItem.color+( additionalClass ? ' '+additionalClass : '' )"
              :key="'refmark-class-'+refItem.localId" @click="handleDetailViewAndSkip( $event, refItem )"
              v-if="$core.f().valid( refItem ) && 'all' !== reference">
            Klasse {{ refItem.classname }}
        </span>
            <span :class="'reference-marker class'+( additionalClass ? ' '+additionalClass : '' )"
                  :key="'refmark-class-all'" v-if="'all' === reference">
            alle Schüler:innen
        </span>
        </template>
        <template v-else>
            <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
                <SyncingReferenceMarker/>
            </transition>
        </template>
    </div>
</template>

<script>

import SyncingReferenceMarker  from "@/components/elements/markers/SyncingReferencesMarker";
import MixinCacheStateReporter from "@/mixins/MixinCacheStateReporter";
import MixinDetailViews        from "@/mixins/MixinDetailViews";

export default {

    name      : 'ClassReferenceMarker',
    components: { SyncingReferenceMarker },
    mixins    : [ MixinCacheStateReporter, MixinDetailViews ],
    props     : {
        reference      : { Type: String, required: true },
        additionalClass: { Type: String, required: false }
    },

    data()
    {
        return {
            ready       : false,
            cacheState  : 'class',
            refItem     : false,
            clickBlocked: false
        }
    }

}
</script>