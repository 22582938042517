<template>
    <div key="distri-extended" class="distribox">
        <div v-for="k in distributionKeys" :key="'key_distri_'+k"
             class="scorebox eg">
            <div class="ratinglabel eg">
                <strong>{{ distributionTranslations[ k ] }}</strong>
            </div>
            <div class="scoreinput ge">
                <input v-if="( k + 1 ) === distributionKeys.length" type="text" class="score"
                       placeholder="Pkt." :id="'ratio_'+k"
                       value="0" disabled="disabled" @keyup="checkKey( $event, k )"/>
                <input v-else type="text" class="score" placeholder="Pkt." :id="'ratio_'+k"
                       :value="distributionScores[k]" @keyup="checkKey( $event, k )"/>
            </div>
        </div>
        <div class="scorebox at scoretotal">
            <div class="ratinglabel scoretotal at">
                <strong>&#x2211;</strong>
            </div>
            <div class="scoreinput scoretotal">
                <strong>{{ $core.getReformatter().reformat( totalScore, 'localizedScore' ) }}</strong>
            </div>
        </div>
        <div class="clearfix"></div>
        <input type="hidden" :id="'form-element-'+refName"/>
    </div>
</template>

<script>
import TestDistribution from '@/components/form/elements/tests/distributionKeys/TestDistribution'

export default {

    name   : 'TestDistributionHamburgEGplus',
    extends: TestDistribution,

    props: [
        'value',
        'refName',
        'reference',
        'validator',
        'reformatter',
        'display',
        'editor',
        'scores',
        'scoreModel'
    ],

    data()
    {
        return {
            distributionKeys        : [],
            distributionScores      : [],
            distributionTranslations: {},
            rows                    : {},
            refValue                : '',
            totalScore              : 0,
            distributionChanged     : false,
            changeTimer             : false,
            initialized             : false
        }
    },

    created()
    {

        this.initKeys()
        let translation = this.$core.getTranslation().translations[ 'scoreModels' ][ 'hamburgEGplus' ]
        for( let t in translation )
        {
            this.distributionTranslations[ parseInt( t ) ] = translation[ t ].translation
        }

        if( undefined !== this.$props.scores )
        {
            this.initScores()
        }

    },

    beforeUnmount()
    {
        this.$emit( 'componentChanged' )
    },

    methods: {

        initKeys()
        {

            let row = 0
            for( let i = 0; i < 26; i++ )
            {
                row = Math.floor( i/3 )

                if( undefined === this.rows[ row ] )
                {
                    this.rows[ row ] = []
                }

                this.distributionKeys.push( i )
                this.distributionScores.push( i < 9 ? undefined : 0 )
                this.rows[ row ].push( i )

            }

            this.initialized = true

        },

        initScores()
        {

            for( let i = 0; i < 26; i++ )
            {
                this.distributionScores[ i ] = this.$props.scores[ i ]
            }

        }
    }
}
</script>