/*eslint-disable*/
export default class Validator
{
    
    constructor( core )
    {
        if( !Validator.instance )
        {
            this.logger = core.getLogger()
            this.reformatter = core.getReformatter()
            this.f = core.f()
            Validator.instance = this
        }
        return Validator.instance
    }
    
    destruct()
    {
        delete Validator.instance
    }
    
    validateDate( optional, value )
    {
        
        if( '' === value.trim() && optional )
        {
            return true
        }
        
        let parts = this.reformatter.date( value ).split( '.' )
        let check = new Date( parts.reverse().join( '-' ) )
    
        return ( parseInt( check.getDate() ) == parseInt( parts[ 2 ] )
            && parseInt( ( check.getMonth() + 1 ) ) == parseInt( parts[ 1 ] )
            && parseInt( check.getFullYear() ) == parseInt( parts[ 0 ] ) )
    
    }
    
    validateEmail( optional, value )
    {
        
        if( (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value))
            || optional === true)
        {
            return true
        }
        
        return false
        
    }
    
    validateText( optional, value )
    {
        return value.length > 0 || optional === true
    }
    
    validatePassword( optional, value )
    {
        return value.length >= 8 || optional === true
    }
    
    validateCity( optional, value )
    {
        return this.validateText( optional, value )
    }
    
    validateZip( optional, value )
    {
        let expression = /^[0-9]{4,6}$/
        return expression.test( value ) || optional === true
    }
    
    validateZipCity( optional, value )
    {
        
        if( !Array.isArray( value ) )
        {
            this.logger.log( 'Validator:validateZipCity', 'input is not an array' )
            return false
        }
        
        return ( this.validateCity( optional, value [ 1 ] )
            && this.validateZip( optional, value [ 0 ] ) )
        
    }
    
    validatePhonenumber( optional, value )
    {

        if( !Array.isArray( value ) )
        {
            this.logger.log( 'Validator:validatePhonenumber', 'input is not an array' )
            return false
        }

        let validLabel = this.validateText( optional, value[0] )
        let expression = /^[0-9+ -]+$/
        return ( validLabel && expression.test( value[1] ) ) || optional
        
    }
    
    validateListName( optional, value )
    {
        if( '' === value.trim() && optional )
        {
            return true
        }
    
        let expression = /^[0-9a-zA-Z \(\)ßäöüÄÖÜ\-\–\'\"\.,]+$/
        return expression.test( value )
    }
    
    validateTime( optional, value )
    {
        if( '' === value.trim() && optional )
        {
            return true
        }
        
        let expression = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
        return expression.test( value )
    }
    
    validateColumnCaption( optional, value )
    {
        if( ( undefined === value || '' === value.trim() ) && optional )
        {
            return true
        }
        
        let expression = /^[0-9a-zA-Z ßäöüÄÖÜ\-\–\'\.,%&$§{}=;:!#<>|\[\]\(\)\*\?\/]+$/
        return expression.test( value )
    }
    
    validatePin( value )
    {
        return ( this.f.valid( value ) ? value.length === 12 : false )
    }
    
    parseLocalFloat( value )
    {
        let test = ''+value
        test = test.replace( ',', '.' )
        return parseFloat( test )
    }
    
    validate( type, optional, value )
    {
        
        switch( type )
        {
            case null:
            case 'checkbox':
            case 'testdistributionkey':
            case 'testpart':
                return true
            case 'float':
                return !isNaN( parseFloat( value ) )
            case 'localizedfloat':
                return !isNaN( this.parseLocalFloat( value ) )
            case 'number':
                return !isNaN( parseInt( value ) )
            case 'date':
                return this.validateDate( optional, value )
            case 'zipcity':
                return this.validateZipCity( optional, value )
            case 'zipcity_onefield':
                let temp = value.split( /\ /g )
                let zip = temp.shift()
                let city = temp.join( ' ' )
                return this.validateZipCity( optional, [ zip, city ] )
            case 'pin':
            case 'PIN':
                return this.validatePin( value )
            case 'email':
                return this.validateEmail( optional, value )
            case 'username':
            case 'name':
            case 'address':
            case 'select':
            case 'color':
            case 'freetext':
            case 'text':
            case 'classname':
                return this.validateText( optional, value )
            case 'time':
                return this.validateTime( optional, value )
            case 'column_caption':
                return this.validateColumnCaption( optional, value )
            case 'listname':
                return this.validateListName( optional, value )
            case 'password':
                return this.validatePassword( optional, value )
            case 'phonenumber':
                return this.validatePhonenumber( optional, value )
            
        }
        
        this.logger.cerror( 'Validator:validate', 'unknown validator:', type, 'returning FALSE in alpha-state - will be TRUE in first release' )
        return false
        
    }
    
}