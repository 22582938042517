<template>
    <div v-if="$core.r().isAllowed( reference, 'readMeta' )"
         :class="'sensitive studentMeta'+( $core.settings().getSetting( 'supportMode' ) === true ? ' blurred' : '' )"
         :key="'studentmeta-'+reference.localId">
        <div class="form-row">
            <div class="form-caption">
                <strong>Geschlecht</strong>
            </div>
            <div class="form-component text">
                {{ $core.getTranslation().translate( reference.gender ) }}
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <strong>Geburtstdatum</strong>
            </div>
            <div class="form-component text">
                {{ birthdate }}
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <strong>Alter</strong>
            </div>
            <div class="form-component text">
                {{ age }}
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <strong>Anschrift</strong>
            </div>
            <div class="form-component text" v-html="address"></div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <strong>Konfession</strong>
            </div>
            <div class="form-component text">
                {{
                    $core.f().valid( reference.confession ) ? $core.getTranslation().translate( 'confession_' + reference.confession ) : 'nicht angegeben'
                }}
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <strong>E-Mail</strong>-Adresse
            </div>
            <div class="form-component text" v-html="email"></div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <strong>Eltern</strong>
            </div>
            <div class="form-component text" v-html="parents"></div>
            <div class="clearfix"></div>
        </div>
        <div class="form-row">
            <div class="form-caption">
                <strong>Telefonische Erreichbarkeit</strong>
            </div>
            <div class="form-component text" v-html="phones"></div>
            <div class="clearfix"></div>
        </div>
    </div>
    <InlineMessage v-else
                   :active="true"
                   classname="nothing"
                   title="Fehlende Berechtigung"
                   message="Dir fehlt die Berechtigung, die Metadaten des Schülers / der Schülerin einzusehen."/>

</template>

<script>
import InlineMessage       from '@/components/messages/InlineMessage'

export default {
    
    name      : 'StudentMeta',
    components: { InlineMessage },
    props     : {
        reference: { Type: Object, required: true }
    },
    
    computed: {
        birthdate()
        {
            if( this.$core.f().valid( this.$props.reference.birthdate ) )
            {
                return this.$core.getFriendlyTimestamp().friendlyDate( this.$props.reference.birthdate )
            }
            return 'nicht angegeben'
        },
        age()
        {
            if( this.$core.f().valid( this.$props.reference.birthdate ) )
            {
                return this.$core.getFriendlyTimestamp().getAge( this.$props.reference.birthdate )
            }
            return 'unbekannt'
        },
        address()
        {
            let address = ''
            if( this.$core.f().valid( this.$props.reference.address_1 ) )
            {
                address = this.$props.reference.address_1
            }
            if( this.$core.f().valid( this.$props.reference.address_2 ) )
            {
                address = '' !== address ? address + '<br/>' + this.$props.reference.address_2 : this.$props.reference.address_2
            }
            let zipAdded = false
            if( this.$core.f().valid( this.$props.reference.zipcode ) )
            {
                address = '' !== address ? address + '<br/>' + this.$props.reference.zipcode : this.$props.reference.zipcode
                zipAdded = true
            }
            if( this.$core.f().valid( this.$props.reference.city ) )
            {
                address = '' !== address ? address + ( zipAdded ? ' ' : '<br/>' ) + this.$props.reference.city : ( zipAdded ? ' ' : '' ) + this.$props.reference.city
                zipAdded = true
            }
            
            return address
            
        },
        parents()
        {
            let parents = ''
            if( this.$core.f().valid( this.$props.reference.parent_1 ) )
            {
                parents = this.$props.reference.parent_1
            }
            if( this.$core.f().valid( this.$props.reference.parent_2 ) )
            {
                parents = '' !== parents ? parents + '<br/>' + this.$props.reference.parent_2 : this.$props.reference.parent_2
            }
            return this.$core.f().valid( parents ) ? parents : 'nicht angegeben'
        },
        email()
        {
            if( this.$core.f().valid( this.$props.reference.email ) )
            {
                return this.$core.getReformatter().email( this.$props.reference.email )
            }
            return 'nicht angegeben'
        },
        phones()
        {

            let phones = '',
                label1 = '',
                label2 = ''

            if( this.$core.f().valid( this.$props.reference.label_phone_1 ) )
            {
                label1 = '<span class="phonelabel">'+this.$props.reference.label_phone_1+'</span>'
            }
            if( this.$core.f().valid( this.$props.reference.label_phone_2 ) )
            {
                label2 = '<span class="phonelabel">'+this.$props.reference.label_phone_2+'</span>'
            }
            if( this.$core.f().valid( this.$props.reference.phone_1 ) )
            {
                phones = label1+this.$core.getReformatter().reformat( this.$props.reference.phone_1, 'phonenumber' )
            }
            if( this.$core.f().valid( this.$props.reference.phone_2 ) )
            {
                phones = '' !== phones ? phones + '<div class="clearfix"></div>' : phones
                phones = phones + label2+this.$core.getReformatter().reformat( this.$props.reference.phone_2, 'phonenumber' )
            }

            return this.$core.f().valid( phones ) ? phones : 'nicht angegeben'

        }
    }
    
}
</script>