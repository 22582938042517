<template>
    <div class="quickmarks"
         :key="'quickmarks-'+item.elementKey+'-'+ownId"
         :id="'qm-'+ownId">
        <div :key="'pinned-'+item.localId" v-if="item.pinned === true" class="quickmark pinned"></div>
        <div :key="'hidden-'+item.localId" v-if="item.hidden === true" class="quickmark is-hidden"></div>
        <SyncStateIndicator v-if="$core.settings().getSetting( 'showSyncState' ) === true"
                            :localId="item.localId"
                            :elementType="item.type"
                            :elementKey="item.elementKey"
                            :remoteId="item.remoteId"/>
        <ShareIndicator v-if="!overrideQueueType && false === noShareIndicator"
                        :scope="scope"
                        :item="item"/>
        <SharedWithStudentsIndicator v-if="!overrideQueueType && false === noShareIndicator"
                                     :scope="scope"
                                     :item="item"/>
        <DemoContentIndicator v-if="true === item.isDemoContent"/>
    </div>
</template>

<script>
/*eslint-disable*/
import SyncStateIndicator          from "@/components/elements/indicators/SyncStateIndicator";
import ShareIndicator              from "@/components/elements/indicators/ShareIndicator";
import SharedWithStudentsIndicator from "@/components/elements/indicators/SharedWithStudentsIndicator";
import DemoContentIndicator        from "@/components/elements/indicators/DemoContentIndicator";
import MixinComponentCleanup       from "@/mixins/MixinComponentCleanup.vue";

export default {

    name      : "Quickmarks",
    components: { DemoContentIndicator, SharedWithStudentsIndicator, ShareIndicator, SyncStateIndicator },
    mixins    : [ MixinComponentCleanup ],

    props: {
        item             : { Type: Object, required: true },
        scope            : { Type: String, required: true },
        noShareIndicator : { Type: Boolean, required: false, default: false },
        overrideQueueType: { Type: String, required: false }
    },

    data()
    {
        return {
            ownId: this.$core.getUuid().generate()
        }
    }

}
</script>