<template>
    <StudentData :refName="refName" :id="refName" />
</template>

<script>
/*eslint-disable*/
import StudentData from '@/components/form/elements/StudentData'
export default {

    name : 'listElement-studentdata',
    components: { StudentData },
    props: {
        value: { type: String, optional: false },
        row  : { type: String, optional: false }
    },

    data()
    {
        return {
            refName: false
        }
    },

    created()
    {

        let lookup = undefined !== this.$props.row ? this.$props.row : this.$props.value
        if( undefined !== lookup )
        {

            let temp = lookup.split( '-' ),
                key = temp.pop(),
                localId = temp.join( '-' )

            this.refName = localId+'___'+key

        }
        else
        {
            this.refName = false
        }
    }

}
</script>