<template>
    <div>
        <input type="hidden" :id="'form-element-'+refName" :value="selectedValue"/>
        <div class="list-template-wrapper" v-if="ready">
            <div class="select-wrapper">
                <select @change="select" :id="'select-element-'+refName">
                    <optgroup label="eine Vorlage von entzettelt nutzen">
                        <option v-for="( template, index ) in vendorTemplates" :key="'key_lstTemplate_'+template.value"
                                :value="'template_vendor_'+index"
                                :selected="selectedValue === 'template_vendor_'+index">
                            {{ template.caption }}
                        </option>
                    </optgroup>
                    <optgroup
                        v-if="true === $core.settings().getSetting( 'useOldListsAsTemplates' ) && 0 < ownTemplates.length"
                        label="bereits erstellte Liste als Vorlage nutzen">
                        <option v-for="( list, index ) in ownTemplates" :key="'key_lstOwnList_'+list.localId"
                                :value="'template_own_'+index"
                                :selected="selectedValue === 'template_own_'+index">
                            {{ list.listname }} ({{ $core.t( 'list-type-' + list.type ) }})
                        </option>
                    </optgroup>
                    <optgroup
                        v-if="0 < foreignTemplates.length"
                        label="Liste aus dem Kollegium als Vorlage nutzen">
                        <option v-for="( list, index ) in foreignTemplates" :key="'key_lstOwnList_'+list.localId"
                                :value="'template_foreign_'+index"
                                :selected="selectedValue === 'template_foreign_'+index">
                            {{ list.listname }} ({{ $core.t( 'list-type-' + list.type ) }})
                        </option>
                    </optgroup>
                    <optgroup
                        v-if="true === $core.settings().getSetting( 'useArchivedListsAsTemplates' ) && 0 < archivedTemplates.length"
                        label="archivierte Liste als Vorlage nutzen">
                        <option v-for="( list, index ) in archivedTemplates" :key="'key_lstOwnList_'+list.localId"
                                :value="'template_archived_'+index"
                                :selected="selectedValue === 'template_archived_'+index">
                            {{ list.listname }} ({{ $core.t( 'list-type-' + list.type ) }})
                        </option>
                    </optgroup>
                </select>
            </div>
            <div class="button-gallery-wrapper">
                <div class="button-gallery" @click="handleGalleryMode()"></div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="list-template-selector">
            <template v-if="true === $core.settings().getSetting( 'listSetupTemplatePreview' )">
                <div class="button-back" v-if="0 < templateId" @click="navigate( 'last' )"></div>
                <div class="button-next" v-if="allTemplates.length > templateId" @click="navigate( 'next' )"></div>
            </template>
            <ListPreviewFrame v-if="ready"
                              :type='selectedType'
                              :key="'template-'+selectedTemplate"
                              :template="theTemplate"
                              scope="selector"
                              @update="handleUpdate"/>
        </div>
    </div>
</template>

<script>
import ListPreviewFrame from "@/components/form/elements/ListPreviewFrame";

export default {

    name      : "ListTemplateSelector",
    components: { ListPreviewFrame },
    emits     : [ 'update' ],

    props: {
        refName          : { Type: String, required: true },
        value            : { Type: String, required: false, default: '' },
        ownTemplates     : { Type: Array, required: true },
        archivedTemplates: { Type: Array, required: true },
        foreignTemplates : { Type: Array, required: true },
        vendorTemplates  : { Type: Array, required: true }
    },

    data()
    {
        return {
            templateId      : 0,
            selectedValue   : 0,
            selectedType    : 'vendor',
            selectedId      : false,
            selectedTemplate: -1,
            allTemplates    : [],
            ready           : false,
            galleryMode     : false,
            theTemplate     : {}
        }
    },

    created()
    {

        this.hookKeys()
        let todo = [ 'vendor', 'own', 'foreign', 'archived' ]

        for( let t in todo )
        {
            let key = todo[ t ]
            for( let i in this.$props[ key + 'Templates' ] )
            {
                this.allTemplates.push( {
                    color: this.$props[ key + 'Templates' ][ i ].color,
                    type : key,
                    id   : i
                } )
            }
        }

        this.$core.getEventManager()
            .add( 'on-template-gallery-select', ( setup ) =>
            {
                this.handleGallerySelect( setup )
            } )

    },

    mounted()
    {

        this.$core.getEventManager()
            .dispatch( 'on-prepare-demo-objects' )

        this.$nextTick()
            .then( () =>
            {

                this.select( undefined, 'template_' + this.selectedType + '_' + this.selectedValue )

            } )

    },

    beforeUnmount()
    {
        this.hookKeys( false )
        this.$core.getEventManager()
            .dispatch( 'on-remove-demo-objects' )
        this.$core.getEventManager()
            .remove( 'on-template-gallery-select' )
    },

    methods: {

        hookKeys( state )
        {

            switch( state )
            {
                case undefined:
                    document.onkeydown = ( event ) =>
                    {

                        if( !event.shiftKey && 'ArrowLeft' === event.key )
                        {
                            this.navigate( 'last' )
                        }
                        if( !event.shiftKey && 'ArrowRight' === event.key )
                        {
                            this.navigate( 'next' )
                        }

                    }
                    break
                case false:
                    document.onkeydown = null
                    break
            }

        },

        navigate( direction )
        {

            switch( direction )
            {
                case 'next':
                    this.templateId++
                    break
                case 'last':
                    this.templateId -= 1
                    break
            }

            let newValue = 'template_' + this.allTemplates[ this.templateId ].type + '_' + this.allTemplates[ this.templateId ].id
            this.select( undefined, newValue )

        },

        select( event, override )
        {

            this.ready = false

            let temp = undefined !== override ? override.split( /_/g ) : event.target.value.split( /_/g ),
                type = temp[ 1 ],
                idx  = temp[ 2 ]

            this.templateId = idx
            this.selectedType = type
            this.selectedValue = undefined !== override ? override : event.target.value
            this.setTemplate()

            this.$nextTick()
                .then( () =>
                {

                    this.ready = true

                } )

        },

        handleGallerySelect( setup )
        {
            this.select( undefined, 'template_' + setup.scope + '_' + setup.index )
        },

        handleUpdate( fields )
        {

            let emittable = [],
                blockList = [ 'referenceKey', 'localId', 'forceTimestamp' ]

            for( let f in fields )
            {
                if( -1 === blockList.indexOf( fields[ f ].ref ) )
                {
                    if( 'columns' === fields[ f ].ref )
                    {
                        let field = this.$core.f().deref( fields[ f ] ),
                            value = []

                        for( let v in field.value )
                        {
                            if( undefined === field.value[ v ].mode )
                            {
                                field.value[ v ].value = field.value[ v ].caption
                                field.value[ v ]._historic = v
                                value.push( {
                                    mode : 'remove',
                                    value: field.value[ v ]
                                } )
                            }
                            else
                            {
                                value.push( field.value[ v ] )
                            }
                        }

                        field.value = value
                        emittable.push( field )
                    }
                    else
                    {
                        emittable.push( fields[ f ] )
                    }
                }
            }

            this.$emit( 'update', emittable )

        },

        handleGalleryMode()
        {

            this.$core.getUi()
                .showBlocker( 'Augenblick bitte...', 'Die Vorlagen-Galerie wird geöffnet.' )

            setTimeout( () =>
            {

                this.$core.getUi()
                    .showOverlay( {
                        type     : 'templateGallery',
                        templates: {
                            own     : this.$props.ownTemplates,
                            foreign : this.$props.foreignTemplates,
                            archived: this.$props.archivedTemplates,
                            vendor  : this.$props.vendorTemplates,
                            all     : this.allTemplates
                        }
                    } )

            }, 500 )

        },

        setTemplate()
        {

            let addIdx = 0
            for( let a in this.allTemplates )
            {
                if( this.allTemplates[ a ].type === this.selectedType )
                {
                    break
                }
                addIdx++
            }

            let template = this.allTemplates[ '' + ( addIdx + parseInt( this.templateId ) ) ],
                setup    = false

            if( undefined !== template )
            {

                switch( template.type )
                {
                    case 'vendor':
                        this.theTemplate = this.$props.vendorTemplates[ template.id ]
                        break
                    case 'own':
                    case 'archived':
                    case 'foreign':
                        if( 'own' === template.type )
                        {
                            setup = this.$props.ownTemplates[ template.id ]
                        }
                        if( 'foreign' === template.type )
                        {
                            setup = this.$props.foreignTemplates[ template.id ]
                        }
                        if( 'archived' === template.type )
                        {
                            setup = this.$props.archivedTemplates[ template.id ]
                        }

                        setup.value = setup.localId
                        setup.isOwn = true
                        this.theTemplate = setup
                }

            }

        }
    }
}
</script>