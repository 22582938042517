<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-show="visible" class="default-form-overlay labelManager">
            <div :class="'default-form-container'+( wobble ? ' wobble' : '' )">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    <h2>Labels</h2>

                    <!-- label creation form -->
                    <div class="label-form-space">
                        <h3>Neues Label erzeugen</h3>
                        <div class="label-form">
                            <div class="caption">
                                <input type="text" id="label_name" :class="errors.caption ? 'error' : ''"
                                       placeholder="gib hier den Namen deines Labels ein..."/>
                            </div>
                            <div class="color">
                                <ColorSelector id="label_color" refName="label_color"
                                               :hasErrors="true === errors.color"/>
                            </div>
                            <div class="button">
                                <Button type="add" addClass="small" @clicked="createLabel()"/>
                            </div>
                        </div>
                    </div>

                    <!-- existing labels -->
                    <div class="label-space" :key="labelKey">
                        <h3>Deine Labels</h3>
                        <transition-group appear :name="$core.settings().getTransition( 'slide-fade' )">
                            <LabelMarker v-for="label in labels"
                                         :key="'label-selector-'+label.localId"
                                         :deletionAllowed="setup.deletionAllowed"
                                         :reference="label.localId"
                                         :selectable="setup.selectionAllowed"
                                         :isSetup="true"
                                         :isSelected="-1 < primarySelection.indexOf( label.localId )"
                                         @selected="handleSelection( label.localId, true )"
                                         @deselected="handleSelection( label.localId, false )"
                                         @deleted="refreshLabels()"/>
                        </transition-group>
                    </div>

                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import ColorSelector       from "@/components/form/elements/ColorSelector";
import MixinCachePreheater from "@/mixins/MixinCachePreheater";
import LabelMarker         from "@/components/elements/markers/LabelMarker";

export default {

    name      : 'DefaultLabelManager',
    components: { LabelMarker, ColorSelector },
    emits     : [ 'close', 'closeNoReset', 'selected', 'deselected' ],
    props     : {
        readyKey: { type: String, required: true },
        setup   : { type: Object, required: true }
    },
    mixins    : [ MixinCachePreheater ],

    data()
    {
        return {
            visible         : true,
            wobble          : false,
            readableFilesize: 0,
            labels          : [],
            labelKey        : this.$core.getUuid().generate(),
            primarySelection: [],
            errors          : {
                color  : false,
                caption: false
            },
            neededCaches    : [ 'label' ]
        }
    },

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.refreshLabels()
                this.primarySelection = this.$props.setup.selection || []
            } )
    },

    methods: {

        refreshLabels()
        {

            let labels  = [],
                sortMap = this.$core.getBaseClassHelper()
                              .get( 'label' )
                              .registry
                              .sortMap
                              .get( 'cache' )

            /* eslint-disable-next-line */
            for( let localId of sortMap )
            {
                labels.push( this.$core.getBaseClassHelper()
                                 .get( 'label' )
                                 .registry
                                 .cache.get( localId ) )
            }

            this.labels = labels

        },

        handleClose()
        {
            this.visible = false
            this.$core.getUi()
                .delay( () =>
                {
                    switch( this.$props.setup.selectionAllowed )
                    {
                        case false:
                            this.$emit( 'close' )
                            break
                        default:
                            this.$emit( 'closeNoReset' )
                            break
                    }
                }, 400 )
        },

        /*eslint-disable*/
        handleClick( labelId )
        {

        },

        createLabel()
        {

            let labelNameElm = document.querySelector( '#label_name' ),
                colorElm     = document.querySelector( '#form-element-label_color' )

            if( null !== labelNameElm && null !== colorElm )
            {

                this.errors.caption = ( '' === labelNameElm.value.trim() )
                this.errors.color = ( undefined === colorElm.value
                                      || '' === colorElm.value.trim() )

                if( !this.errors.caption && !this.errors.color )
                {

                    let label = {
                        color  : colorElm.value,
                        caption: labelNameElm.value.trim()
                    }

                    let localId = this.$core.getBaseClassHelper()
                                      .get( 'label' )
                                      .create( label )

                    this.$core
                        .getEventManager()
                        .append( 'storable-after-update-' + localId, () =>
                        {
                            labelNameElm.value = ''
                            colorElm.value = ''
                            this.refreshLabels()
                        } )

                }
                else
                {
                    this.wobble = true
                    this.$core.getUi()
                        .delay( () =>
                        {
                            this.wobble = false
                        }, 1000 )
                }

            }

        },

        handleSelection( labelId, state )
        {
            this.$core.getEventManager()
                .dispatch( 'on-' + ( false === state ? 'de' : '' ) + 'select-label', labelId )
        }

    }
}
</script>