<template>
    <input type="hidden" :value="hiddenvalue" v-bind:id="'form-element-'+refName" @change="handleValue()"/>
</template>

<script>

export default {
    name: 'Hidden',

    props: {
        value      : { Type: String, required: false },
        id         : { Type: String, required: true },
        refName    : { Type: String, required: true },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        eventKey   : { Type: String, required: false }
    },

    created()
    {
        this.initValue()
        this.$core.getEventManager().add( 'on-hidden-fill-form-element-'+this.$props.id, ( value ) => {
            this.hiddenvalue = value
            this.handleValue()
        } )
    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-hidden-fill-form-element-'+this.$props.id )
    },

    data()
    {
        return {
            hiddenvalue: undefined
        }
    },

    methods: {

        initValue()
        {
            if( undefined === this.$props.value )
            {
                this.hiddenvalue = 'create'
            } else
            {
                this.hiddenvalue = this.$props.value
            }
            this.handleValue()
        },

        handleValue()
        {
            if( this.$core.f().isset( this.$props.reformatter ) )
            {
                this.hiddenvalue = this.$core.getReformatter().reformat( this.hiddenvalue, this.$props.reformatter )
            }
            this.$emit( 'change', this.$props.id, this.hiddenvalue )
            this.$emit( 'update', this.$props.refName, 'hidden', 'update', undefined, this.hiddenvalue )
        }

    }

}
</script>
