import ListsObjectClass  from "@/objects/ListsObjectClass";

export default class RatinglistClass extends ListsObjectClass
{

    constructor( core )
    {

        super( core )
        this.logger = core.getLogger()
        this.listType = 'ratinglist'
        this.setStudentEditable( this.listType, true )
        /** for future use **/
        this.isPooled = false

        let fields = [
            {
                ref        : 'referenceKey',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : null
            },
            {
                ref        : 'listname',
                caption    : 'Name',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'listname'
            },
            {
                ref        : 'color',
                caption    : 'Farbe',
                type       : 'ColorSelector',
                styleUpdate: true,
                reformatter: null,
                optional   : false,
                validator  : 'color'
            },
            {
                ref        : 'labels',
                caption    : 'Labels',
                type       : 'LabelSelector',
                styleUpdate: false,
                reformatter: null,
                optional   : true,
                extended   : true,
                validator  : 'text'
            },
            {
                ref        : 'selectedClass',
                caption    : 'Schüler:innen',
                type       : 'StudentGroupSelector',
                reformatter: null,
                optional   : false,
                validator  : 'select'
            },
            {
                ref          : 'columns',
                caption      : 'Spalten',
                type         : 'RowAdder',
                component    : 'TextBox',
                resultingType: 'rateselector',
                reformatter  : null,
                optional     : false,
                validator    : 'column_caption'
            },
            {
                ref        : 'calculatedColumns',
                caption    : 'Rechenspalten',
                type       : 'CalculatedColumnsSelector',
                styleUpdate: false,
                reformatter: null,
                extended   : true,
                validator  : null
            },
            {
                ref        : 'forceTimestamp',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            },
            {
                ref        : 'storeAsTemplate',
                caption    : 'als Vorlage speichern',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            },
            {
                ref        : 'organizerSlot',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false
            }
        ]

        if( this.isPooled )
        {
            fields.push( {
                ref        : 'storeAsSchoolTemplate',
                caption    : 'als Schulvorlage speichern',
                type       : 'Checkbox',
                reformatter: null,
                optional   : true,
                validator  : null,
                styleUpdate: false,
                extended   : true
            } )
        }

        this.setListFormFields( this.listType, fields )

        super.setColumnParser( this.listType, ( columns, values ) =>
        {

            for( let col in values.columns )
            {

                let temp = values.columns[ col ],
                    id   = col + '_' + this.sanitizers.cleanId( temp.value )

                if( '' !== id.trim() )
                {
                    columns.push( {
                        type   : 'rateselector',
                        id     : id,
                        caption: temp.value.trim()
                    } )
                }
            }

        } )

        return this

    }

}