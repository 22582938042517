export default class afterAllYeargroup
{

    constructor( core )
    {

        this.f = core.f()

    }

    afterFetch( yeargroups, skipArchived )
    {
        return new Promise( resolve =>
        {
            for( let g in yeargroups )
            {
                if( yeargroups[ g ].archived !== true || !skipArchived )
                {
                    yeargroups[ g ].displayName = 'Jahrgang '+yeargroups[ g ].yeargroupname
                }
            }
            return resolve( yeargroups )
        } )
    }

    afterUpdate( yeargroups, skipArchived )
    {
        return new Promise( resolve =>
        {
            return resolve( this.afterFetch( yeargroups, skipArchived ) )
        } )
    }

}