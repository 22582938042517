import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class AttributesObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !AttributesObjectClass.instance )
        {

            let refList = [ 'parentObject', 'attributeName', 'type', 'timestamp', 'update' ]

            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'class', refList, personalAttributesStatesList )

            this.baseClassHelper = core.getBaseClassHelper()

            this.hasDetailView = true
            this.objectType = 'attributes'
            this.creationDependsOn = []
            this.isStudentShareable = true
            AttributesObjectClass.instance = this
        }

        return AttributesObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'parentObject',
                caption    : 'parentObject',
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : null
            },
            {
                ref        : 'attributeName',
                caption    : 'Bezeichnung',
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'text'
            },
            {
                ref           : 'type',
                caption       : 'Typ',
                type          : 'AttributeTypeSelector',
                component     : 'AttributeTypeSelector',
                reformatter   : null,
                optional      : false,
                asPlainList   : true,
                validator     : 'select',
                dragBlocked   : true,
                unique        : true
            }
        ]
    }

    getTabs()
    {
        return [ 'own', 'foreign', 'archive' ]
    }

    getFunctions()
    {
        return [ 'edit', 'delete' ]
    }

}