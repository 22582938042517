<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )">
        <div v-if="prepared"
             v-show="visible" class="default-form-overlay">
            <div class="default-form-container">
                <div :class="'default-form-wrapper'+selectedColor">
                    <Button type="close"
                            @clicked="closeForm()"
                            addClass="absolute top right inverted"/>

                    <h2>{{ formTitle }}</h2>
                    <Form :elevated="true"
                          :eventKey="eventKey"
                          :fields="fields"
                          :buttons="formButtons"
                          @nestedForm="handleNestedForm"
                          @appendClassReference="handleAppendClassReference"/>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {

    name: 'FreeFormOverlay',

    props: {
        fields      : { Type: Object, required: true },
        title       : { Type: String, required: false },
        titleKey    : { Type: String, required: false },
        readyKey    : { Type: String, required: false },
        trackChanges: { Type: Boolean, required: false, default: false }
    },

    data()
    {
        return {
            initial      : {},
            eventKey     : false,
            selectedColor: '',
            visible      : true,
            formFields   : false,
            formButtons  : false,
            classFilter  : false,
            className    : false,
            prepared     : false,
            merge        : false
        }
    },

    emits: [ 'submit-form', 'close' ],

    computed: {
        formTitle()
        {
            return this.$props.title || this.$core.t( this.$props.titleKey )
        }
    },

    created()
    {

        this.initial = JSON.parse( JSON.stringify( this.$props.fields ) )
        let key = this.$core.getUuid().generate()
        this.prepareForm( key )

    },

    beforeUnmount()
    {
        this.merge = false
        this.$core.deleteState( 'classFilter' )
        this.$core.getEventManager()
            .remove( 'on-color-selected-' + this.eventKey )
        this.$core.getEventManager()
            .remove( 'on-form-close-' + this.eventKey + this.$props.readyKey )
    },

    methods: {

        fillMerger( key )
        {
            return new Promise( resolve =>
            {

                this.$core.baseClass( key )
                    .loadForRegistry( this.$props.editItem.localId )
                    .then( element =>
                    {
                        delete ( element.elementKey )
                        return resolve( element )
                    } )
            } )
        },

        prepareForm( key )
        {

            this.eventKey = this.$core.getUuid().generate()
            let submitCaption = 'message' === key ? 'caption-send' : 'caption-submit'

            this.formButtons = {
                left : {
                    type    : 'button',
                    isCancel: true,
                    caption : this.$core.getTranslation().translate( 'caption-cancel' ),
                    callback: () =>
                    {
                        this.closeForm()
                    }
                },
                right: {
                    type    : 'submit',
                    caption : this.$core.getTranslation().translate( submitCaption ),
                    callback: ( values, historic ) =>
                    {
                        this.handleSubmit( values, historic )
                    }
                }
            }

            this.$core.getEventManager()
                .add( 'on-color-selected-' + this.eventKey, ( value ) =>
                {
                    this.colorUpdate( value )
                } )

            this.$core.getEventManager()
                .add( 'on-form-close-' + this.eventKey + this.$props.readyKey, () =>
                {
                    this.visible = false
                } )

            this.prepared = true

        },

        handleAppendClassReference( classFilter, className )
        {
            this.classFilter = classFilter
            this.className = className
            this.$core.setState( 'classFilter', this.classFilter )
        },

        colorUpdate( value )
        {
            this.selectedColor = '' !== value.trim() ? ' ' + value.trim() : ''
        },

        closeForm()
        {

            this.visible = false
            setTimeout( () =>
            {
                this.$emit( 'close' )
            }, 300 )

        },

        handleNestedForm( set )
        {

            if( this.$core.f().isset( this.nestedForm.eventKey ) )
            {
                this.nestedForm = false
                this.$core.getEventManager()
                    .remove( 'on-color-selected-' + this.nestedForm.eventKey )
            }

            this.$nextTick()
                .then( () =>
                {

                    this.nestedForm = set

                    let buttonRow = document.querySelector( '#button-row-' + this.eventKey )
                    if( false === set )
                    {
                        buttonRow.classList.remove( 'hidden' )
                    }
                    else
                    {
                        buttonRow.classList.add( 'hidden' )
                        let nestedButtons = {
                            left : {
                                type    : 'button',
                                caption : this.$core.getTranslation().translate( 'caption-cancel' ),
                                callback: () =>
                                {
                                    this.closeForm()
                                }
                            },
                            right: {
                                type    : 'submit',
                                caption : this.$core.getTranslation().translate( 'caption-submit' ),
                                callback: ( values, historic ) =>
                                {
                                    this.handleSubmit( values, historic, this.nestedForm.baseClass, true )
                                }
                            }
                        }

                        this.$core.getEventManager()
                            .add( 'on-color-selected-' + set.eventKey, ( value ) =>
                            {
                                this.colorUpdate( value )
                            } )

                        set.formButtons = nestedButtons

                    }

                } )

        },

        /* eslint-disable */
        handleSubmit( values, historic, baseClass, forceCreate )
        {

            if( false !== this.merge )
            {
                let newSetup = JSON.parse( JSON.stringify( this.merge ) )
                for( let k in Object.keys( values ) )
                {
                    let key = Object.keys( values )[ k ]
                    newSetup[ key ] = values[ key ]
                }

                values = newSetup
            }

            if( undefined !== values.listType
                && -1 < values.listType.indexOf( 'template' ) )
            {
                values.listType = values.originalListType
            }

            let submit = JSON.parse( JSON.stringify( values ) )

            if( true === this.trackChanges )
            {

                for( let i in this.initial )
                {

                    if( undefined !== this.initial[ i ].value
                        || undefined !== this.initial[ i ].mapped )
                    {

                        if( undefined !== this.initial[ i ].value )
                        {

                            let key   = this.initial[ i ].ref,
                                value = JSON.parse( JSON.stringify( this.initial[ i ].value ) ),
                                comp  = submit[ key ]

                            if( 'RowAdder' === this.initial[ i ].type )
                            {
                                let test = []
                                for( let v in value )
                                {
                                    test.push( value[ v ].value )
                                }
                                value = JSON.parse( JSON.stringify( test ) )
                            }

                            if( JSON.stringify( value ) === JSON.stringify( comp ) )
                            {
                                delete submit[ key ]
                            }

                        }

                    }
                    else
                    {
                        delete submit[ this.initial[ i ].ref ]
                    }

                }

            }

            this.$emit( 'submit-form', submit, historic, baseClass, forceCreate )
            this.closeForm()

        }

    }

}
</script>