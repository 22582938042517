export default class BackupHelper
{

    constructor( core )
    {

        if( !BackupHelper.instance )
        {

            this.ui = core.getUi()
            this.client = core.getClient()
            this.friendlyTimestamp = core.getFriendlyTimestamp()
            this.cryptoHelper = core.getCryptoHelper()
            this.store = core.getStore()
            this.eventManager = core.getEventManager()

            this.lastBackupId = false

            BackupHelper.instance = this

        }

        return BackupHelper.instance

    }

    generateBackupname()
    {
        let tsmp = this.friendlyTimestamp.friendlyDate( Date.now() )
        return 'Sicherung vom ' + tsmp
    }

    waitForId( iterCount )
    {

        return new Promise( resolve =>
        {

            iterCount = undefined === iterCount ? 0 : iterCount

            let request = {
                method   : 'objects.getActiveBackup',
                id_backup: this.lastBackupId
            }

            this.client.request( request )
                .then( result =>
                {

                    if( undefined !== result.result
                        && 0 < parseInt( result.result ) )
                    {

                        return resolve( parseInt( result.result ) )

                    }
                    else
                    {
                        setTimeout( () =>
                        {

                            return resolve( this.waitForId( ( iterCount + 1 ) ) )

                        }, 1000 )

                    }

                } )

        } )

    }

    setupProgressHandler( backupId, totalCount )
    {

        this.eventManager.add( 'on-progress-message', ( data ) =>
        {

            let doneCount = parseInt( data.objectcount ) + parseInt( data.keycount )
            this.ui.updateProgress( totalCount, doneCount )

            if( doneCount === totalCount )
            {
                this.eventManager.remove( 'on-progress-message' )
            }

        } )

    }

    /*eslint-disable*/
    create( name, silent )
    {

        return new Promise( resolve => {

            this.ui
                .showBlocker( 'Sicherung wird angelegt',
                    'Augenblick bitte, wir sichern deinen aktuellen Datenstand: <strong>Das kann ein Weilchen dauern...</strong>',
                    'progress',
                    true )

            let request = {
                method: 'objects.countBackupTotals'
            }

            let totalCount = 0
            this.client.request( request )
                .then( result =>
                {

                    totalCount = result.result[ 0 ].objectcount + result.result[ 0 ].keycount

                    let backupName = name || ''
                    if( backupName.trim() === '' )
                    {
                        backupName = this.generateBackupname()
                    }

                    this.cryptoHelper.plainEncrypt( this.store.getters.publicKey, backupName )
                        .then( cryptedBackupName => {


                            let request = {
                                method: 'objects.createBackup',
                                name  : cryptedBackupName
                            }

                            this.client.request( request, 1200000 )
                                .then( () =>
                                {

                                    this.ui
                                        .delay( () => {

                                            if( true !== silent )
                                            {
                                                this.ui.unlockBlocker()
                                                this.ui.hideBlocker()
                                            }

                                            this.ui
                                                .setPageMessage( 'Backup angelegt',
                                                    'Dein Backup "<strong>' + backupName + '</strong>" wurde angelegt.',
                                                    'ok', true )

                                            return resolve()

                                        }, 1000 )

                                } )

                            this.waitForId()
                                .then( backupId =>
                                {

                                    this.setupProgressHandler( backupId, totalCount )

                                } )

                        })

                })

        })

    }

}