/*eslint-disable*/
import AbstractObjectClass  from "@/objects/abstract/ObjectClass";
import ListStructureUpdates from "@/objects/abstract/helpers/ListStructureUpdates";

export default class AbstractListClass extends AbstractObjectClass
{

    constructor( core )
    {

        let refList = [ 'listname',
                        'color',
                        'labels',
                        'pinned',
                        'singleItem',
                        'duedate',
                        'doneFields',
                        'distribution',
                        'isDone',
                        'listType',
                        'columns',
                        'linkedColumns',
                        'calculatedColumns',
                        'values',
                        'referenceKey',
                        'scoreModel',
                        'timestamp',
                        'timestampForced',
                        'revision',
                        'update',
                        'organizerSlot',
                        'isDemoContent' ]

        let personalAttributesStatesList = [
            {
                storage: 'pinning',
                state  : 'pinned',
                list   : 'pinLists'
            },
            {
                storage: 'hiding',
                state  : 'hidden',
                list   : 'hideLists'
            }
        ]

        super( core, 'list', refList, personalAttributesStatesList )

        this.logSign = 'AbstractListClass::'
        this.logger = core.getLogger()
        this.database = core.getDatabase()
        this.sanitizers = core.getSanitizers()

        super.cacheKey = 'referenceKey'

        this.studentEditable = new Map()
        this.listFormFields = new Map()
        this.columnParsers = new Map()
        this.beforeUpdates = new Map()

        this.deleteTimeout = null
        this.updateTimeout = null

        this.listStructureUpdates = new ListStructureUpdates( this, core )

        this.t = ( key ) =>
        {
            return core.t( key )
        }

    }

    setListFormFields( listType, fields )
    {
        this.listFormFields.set( listType, fields )
    }

    setStudentEditable( listType, fields )
    {
        this.studentEditable.set( listType, fields )
    }

    setColumnParser( listType, parser )
    {
        this.columnParsers.set( listType, parser )
    }

    setBeforeUpdate( listType, stateMerger )
    {
        this.beforeUpdates.set( listType, stateMerger )
    }

    getListFormFields( listType )
    {
        return this.listFormFields.get( listType )
    }

    getListFilterBy( value )
    {

        let filter = {
            filter  : 'class',
            filterBy: value
        }

        if( -1 < value.indexOf( ':' ) )
        {

            let parts = value.split( ':' )
            filter.filter = parts[ 0 ]
            filter.filterBy = parts[ 1 ]

        }

        return filter

    }

    addOptionalFields( object, values )
    {

        let formFields = this.getListFormFields( values.listType )
        for( let f in formFields )
        {
            if( true === formFields[ f ].mustCreate
                && undefined === object[ formFields[ f ].ref ] )
            {
                object[ formFields[ f ].ref ] = values[ formFields[ f ].ref ]
            }
        }

    }

    /*eslint-disable*/
    persist( values )
    {

        let filter = this.getListFilterBy( values.selectedClass )

        let columns = [ {
            type    : 'fixed',
            values  : 'students',
            caption : this.t( 'object-type-students-single' ),
            filter  : filter.filter,
            filterBy: filter.filterBy
        } ]

        this.columnParsers.get( values.listType )( columns, values )

        let object = {

            listname      : values.listname,
            color         : values.color,
            listType      : values.listType,
            columns       : columns,
            values        : values.values,
            referenceKey  : values.referenceKey,
            timestamp     : values.timestamp,
            forceTimestamp: values.forceTimestamp,
            update        : values.update,
            isDemoContent : values.isDemoContent

        }

        this.addOptionalFields( object, values )

        return super.create( object )

    }

    _storeReference( referenceKey, localId )
    {
        if( 'create' !== referenceKey
            && undefined !== referenceKey )
        {
            this.database.writeReference( localId, referenceKey )
        }
    }

    create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
    {

        this.logger.clog( this.logSign + values.listType, clone ? 'cloning list' : 'creating new list' )

        if( undefined === values.referenceKey
            || '' === values.referenceKey.trim() )
        {
            values.referenceKey = 'create'
        }

        let localId = undefined
        if( undefined !== clone )
        {
            localId = super.create( values, additional, clone, forceTimestamp, additionalKeys, jobId, local )
        }
        else
        {
            localId = this.persist( values )
        }

        values.referenceKey = ( 'create' !== values.referenceKey && undefined !== values.referenceKey ) ? values.referenceKey : localId

        this._storeReference( values.referenceKey, localId )
        return localId

    }

    getContainer( localId, referenceKey, returnWholeObject, recursion )
    {

        let temp,
            scopes = [ 'cache', 'archive' ]

        if( undefined === referenceKey )
        {

            temp = this.getById( localId )
            if( undefined !== temp
                && !recursion )
            {
                return this.getContainer( localId, temp.referenceKey, returnWholeObject, true )
            }
            else
            {

                for( let s in scopes )
                {

                    temp = this.getCache( scopes[ s ] )
                    /*eslint-disable-next-line*/
                    for( const [ t, list ] of temp )
                    {
                        if( undefined !== list.lists )
                        {
                            let returnable = list.lists.find( o => o.localId === localId )
                            if( returnable )
                            {
                                return this.getContainer( localId, returnable.referenceKey, returnWholeObject )
                            }
                        }
                    }

                    for( const [ t, list ] of temp )
                    {
                        if( list.referenceKey === localId )
                        {
                            return list
                        }
                        if( undefined !== list.lists )
                        {
                            let returnable = list.lists.find( o => o.referenceKey === localId )
                            if( returnable )
                            {
                                return this.getContainer( localId, returnable.referenceKey, returnWholeObject )
                            }
                        }
                    }

                }

                return false

            }
        }
        else
        {

            for( let s in scopes )
            {
                temp = this.registry[ scopes[ s ] ].get( referenceKey )
                if( undefined !== temp )
                {
                    return returnWholeObject ? temp : temp.lists
                }
            }
            return false

        }

    }

    returnList( localId )
    {
        return this.getContainer( localId, undefined, true )
    }

    hasValuesFor( localId, referenceId )
    {

        let item = this.getContainer( localId )
        if( 0 < item.length )
        {
            for( let i in item )
            {
                for( let v in item[ i ].values )
                {
                    if( -1 < v.indexOf( referenceId ) )
                    {
                        return true
                    }
                }
            }
        }

        return false

    }

    getContainerItem( localId )
    {

        let containerItems = this.getContainer( localId )
        if( false !== containerItems )
        {
            return containerItems.find( o => o.localId === localId )
        }
        return false

    }

    getListContainer( referenceKey )
    {
        let scopes = [ 'cache', 'archive' ]

        for( let s in scopes )
        {
            if( undefined !== this.registry[ scopes[ s ] ].get( referenceKey ) )
            {
                return this.registry[ scopes[ s ] ].get( referenceKey )
            }
        }

        return false

    }

    update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
    {

        let stateMerger = this.beforeUpdates.get( rawObject.listType )
        if( undefined !== stateMerger )
        {
            stateMerger( rawObject )
            return super.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
        }
        else
        {
            return super.update( rawObject, localId, remoteId, timestamp, localKey, additionalKeys, noUpdateTimestamp, fromSync )
        }

    }

    updateStructure( values, historic, object, jobId )
    {

        jobId = jobId || this.uuid.generate()

        let lists = this.listStructureUpdates.updateStructure( values, historic, object ),
            delay = 0

        for( let l in lists )
        {

            delay++
            super.update( lists[ l ] )

        }

        setTimeout( () =>
        {

            this.eventManager.dispatchAndRemove( 'on-queue-done-' + jobId )

        }, ( delay * 100 ) )

        return jobId

    }

    _removeFromCache( localId )
    {

        let containerItem = this.getContainerItem( localId ),
            referenceKey  = containerItem ? containerItem.referenceKey : undefined,
            container     = referenceKey ? this.getListContainer( referenceKey ) : undefined,
            dropIdx       = -1,
            afterUpdate   = [],
            cacheMap      = this.registry.sortMap.get( 'cache' ),
            archiveMap    = this.registry.sortMap.get( 'archive' )

        if( undefined !== referenceKey
            && undefined !== container
            && ( undefined === container.lists
                 || ( Array.isArray( container.lists ) && 1 === container.lists.length ) ) )
        {

            this.registry.archive.delete( referenceKey )
            this.registry.cache.delete( referenceKey )

            this.functions.removeFromArray( cacheMap, referenceKey )
            this.functions.removeFromArray( archiveMap, referenceKey )

        }
        else
        {
            if( undefined !== container
                && undefined !== container.lists )
            {
                for( let c in container.lists )
                {
                    if( container.lists[ c ].localId !== localId )
                    {
                        afterUpdate.push( container.lists[ c ] )
                    }
                    else
                    {
                        dropIdx = c
                    }
                }
            }
        }

        if( -1 !== dropIdx )
        {
            container.lists.splice( dropIdx, 1 )
        }

        if( 0 < afterUpdate.length )
        {
            this._handleAfterUpdate( afterUpdate, true )
        }

        clearTimeout( this.deleteTimeout )
        this.deleteTimeout = setTimeout( () =>
        {

            this.functions.removeFromArray( cacheMap, localId )
            this.functions.removeFromArray( archiveMap, localId )

            this.eventManager.dispatch( 'after-cache-drop-lists', referenceKey || localId )

            this.generateSortMap()
            this._baseHash()
/*            this.registry.archiveKey = this.functions.objectHash( this.list( 'archive' ), true )
            this.registry.cacheKey = this.functions.objectHash( this.list( 'cache' ), true )*/

        }, 500 )


    }


}