<template>
    <div class="rateflexselector" :ref="'rateWrap_'+getElementRefName()">
        <div v-for="rate in rates" :key="'flex-'+rate">
            <div v-bind:class="'rateflex value-'+rate+' rate-'+rate+( rate === selectedValue ? ' selected' : '' )"
                 :title="translate[ rate ].full" @click="setValue( rate )">
                {{ translate[ rate ].rate }}
            </div>
        </div>
    </div>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {

    name: 'RateFlexSelector',

    mixins: [ MixinResettableComponent ],

    props: {
        refName    : { Type: String, required: true },
        value      : { Type: String, required: true },
        disabled   : { Type: Boolean, required: false },
        highlighted: { Type: Object, required: false, default: [] },
        inline     : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },

    emits: [ 'focussed', 'blurred', 'update' ],

    data()
    {
        return {
            rates        : [ 0, 1, 2, 3 ],
            translate    : {
                0: {
                    rate: 'A',
                    full: 'Anfänge'
                },
                1: {
                    rate: 'B',
                    full: 'Basiskenntnisse'
                },
                2: {
                    rate: 'G',
                    full: 'Gesicherte Kenntnisse'
                },
                3: {
                    rate: 'V',
                    full: 'Vertiefte Kenntnisse'
                },
            },
            minrate      : 0,
            hovering     : false,
            hoverValue   : 0,
            selectedValue: -1
        }
    },

    created()
    {
        this.$nextTick()
            .then( () =>
            {
                this.setValue( this.$props.value, true )
            } )

    },


    watch: {
        value: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue
                    && newValue !== oldValue )
                {
                    this.selectedValue = newValue
                }
            }
        }
    },

    methods: {

        getElementRefName()
        {
            return 'elm_' + this.$props.refName
        },

        getClassName( rate )
        {

            let className = ''
            let compare = this.selectedValue
            if( this.hovering
                && !this.$core.getUi().isTouchDevice() )
            {
                compare = this.hoverValue
            }

            if( rate <= compare )
            {
                className = 'selected'
            }
            else
            {
                className = 'inactive'
            }

            if( -1 < this.highlighted.indexOf( this.$props.refName + '_' + rate ) )
            {
                className = className + ' highlighted'
            }

            return className

        },
        setValue( rate, initial )
        {
            if( ( undefined !== rate && initial )
                || ( true !== this.$props.disabled ) )
            {
                if( this.selectedValue === this.minrate
                    && rate === this.minrate )
                {
                    this.selectedValue = undefined
                    rate = undefined
                }
                else
                {
                    this.selectedValue = rate
                }
            }

            if( !initial )
            {
                if( true !== this.$props.disabled )
                {
                    this.$emit( 'update', this.$props.refName, 'rateFlexSelector', 'update', undefined, rate )
                }
            }
        },
        handleChange( event )
        {
            if( true !== this.$props.disabled )
            {

                if( undefined !== event.target
                    && undefined !== event.target.value )
                {
                    let newValue = '' == event.target.value ? 0 : event.target.value
                    this.setValue( newValue, false )
                }

            }
        },
        setHover( rate )
        {
            if( true !== this.$props.disabled )
            {
                this.hovering = true
                this.hoverValue = rate
                this.$emit( 'focussed', this.$props.refName + '_' + rate )
            }
        },
        clearHover()
        {
            this.hovering = false
            this.$emit( 'blurred', 'all:' + this.$props.refName )
        }
    }
}
</script>