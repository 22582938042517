export default class SharedMediaSync {

    constructor( parent, p )
    {

        if( !SharedMediaSync.instance )
        {

            this.parent = parent
            this.p = p
            this.logSign = 'Core::Share [SHR]::SharedMediaSync:'

            this.syncing = false
            this.remoteKeys = {}
            this.syncRun = 0
            this.interval = 30000

            SharedMediaSync.instance = this


        }

        return SharedMediaSync.instance

    }

    /**
     * _addKeyForColleague
     * @param sharedElement
     * @param ownKey
     * @param colleague
     * @returns {Promise<unknown>}
     * @private
     */
    _addKeyForColleague( sharedElement, ownKey, colleague )
    {
        return new Promise( resolve =>
        {

            let colleagueKey = this.parent
                                   .cryptoHelper.rekey( ownKey, colleague.publicKey )
            if( false !== colleagueKey )
            {

                this.parent
                    .client
                    .request( {
                        method     : 'media.storeMediaKey',
                        id         : sharedElement.fileId,
                        idColleague: colleague.colleagueId,
                        key        : colleagueKey
                    } )
                    .then( () =>
                    {
                        this.parent.logger.clog( this.logSign + '_addKeyForColleague',
                            'added key for colleague #' + colleague.colleagueId + ', file #' + sharedElement.fileId )
                        return resolve()
                    } )
                    .catch( () =>
                    {
                        this.parent.logger.clog( this.logSign + '_addKeyForColleague',
                            'failed to add key for colleague #' + colleague.colleagueId + ', file #' + sharedElement.fileId )
                        return resolve()
                    } )

            }
            return resolve()

        } )
    }

    /**
     * _checkKeysForSharedMedia
     * @param share
     * @param sharedElement
     * @param ownKey
     * @param foreignKeys
     * @returns {Promise<unknown>}
     * @private
     */
    _checkKeysForSharedMedia( share, sharedElement, ownKey, foreignKeys )
    {
        return new Promise( resolve =>
        {

            let keyUpdates = []

            for( let s in share.sharedWith )
            {

                let sharedWith   = share.sharedWith[ s ],
                    colleague    = this.p._getColleague( sharedWith ),
                    colleagueKey = null

                if( undefined !== colleague )
                {

                    for( let f in foreignKeys )
                    {
                        if( foreignKeys[ f ].idUser === colleague.colleagueId )
                        {
                            colleagueKey = foreignKeys[ f ].key
                        }
                    }

                    if( null === colleagueKey )
                    {
                        keyUpdates.push( () =>
                        {
                            return new Promise( resolve =>
                            {

                                this._addKeyForColleague( sharedElement, ownKey, colleague )
                                    .then( () =>
                                    {
                                        return resolve()
                                    } )

                            } )
                        } )
                    }

                }

            }

            this.parent.f.promiseRunner( keyUpdates )
                .then( () =>
                {
                    return resolve()
                } )

        } )

    }

    /**
     * _processSingleSharedMedia
     * @param share
     * @returns {Promise<unknown>}
     * @private
     */
    _processSingleSharedMedia( share )
    {

        return new Promise( resolve =>
        {

            let sharedElement = this.parent
                                    .baseClassHelper
                                    .get( 'media' )
                                    .getById( share.idReference )

            if( undefined !== sharedElement
                && null !== sharedElement
                && false !== sharedElement )
            {

                this.parent
                    .client
                    .request( {
                        method: 'media.listKeysForId',
                        id    : sharedElement.fileId
                    } )
                    .then( response =>
                    {

                        let ownKey      = null,
                            foreignKeys = []

                        for( let k in response.keys )
                        {
                            if( response.keys[ k ].id_user === this.parent.store.getters.idUser )
                            {
                                ownKey = response.keys[ k ].secret
                            }
                            else
                            {
                                foreignKeys.push( {
                                    idUser: response.keys[ k ].id_user,
                                    key   : response.keys[ k ].secret
                                } )
                            }
                        }

                        this._checkKeysForSharedMedia( share, sharedElement, ownKey, foreignKeys )
                            .then( () =>
                            {
                                return resolve()
                            } )

                    } )
                    .catch( e =>
                    {
                        this.parent.logger.clog( this.logSign + '_processSingleSharedMedia', 'unable to resolve media', e, sharedElement.fileId )
                        return resolve()
                    } )

            }
            else
            {
                return resolve()
            }

        } )

    }

    /**
     * _prepareMediaCache
     * @returns {boolean}
     * @private
     */
    _prepareMediaCache()
    {

        let cacheState = this.parent
                             .baseClassHelper
                             .get( 'media' ).state

        switch( cacheState )
        {
            case 'initial':
                this.parent
                    .baseClassHelper
                    .get( 'media' )
                    .cacheHeatup()
                return false
            case 'filled':
                return true
            default:
                return false
        }

    }

    /**
     * _processSharedMedia
     * @param shares
     * @returns {Promise<unknown>}
     */
    _processSharedMedia( shares )
    {
        return new Promise( resolve =>
        {

            if( 0 < shares.length )
            {
                if( this._prepareMediaCache() )
                {
                    let share = shares.shift()
                    this._processSingleSharedMedia( share )
                        .then( () =>
                        {
                            return resolve( this._processSharedMedia( shares ) )
                        } )
                }
                else
                {
                    setTimeout( () =>
                    {
                        return resolve( this._processSharedMedia( shares ) )
                    }, 300 )
                }
            }
            else
            {
                return resolve()
            }

        } )
    }

    /**
     * _getMediaFromLists
     * @param lists
     * @returns {{}}
     * @private
     */
    _getMediaFromLists( lists )
    {

        let mediaIds = {}

        for( let l in lists )
        {

            let listElm = this.parent
                              .baseClassHelper
                              .get( 'list' )
                              .getById( lists[ l ].idReference )

            if( undefined === listElm )
            {
                listElm = this.parent
                              .baseClassHelper
                              .get( 'list' )
                              .getContainer( lists[ l ].idReference )
            }

            if( undefined !== listElm
                && undefined !== listElm.lists )
            {
                for( let ll in listElm.lists )
                {
                    let list = listElm.lists[ ll ]
                    for( let c in list.columns )
                    {
                        if( list.columns[ c ].type === 'image' )
                        {

                            let valueKey = this.parent
                                               .sanitizers
                                               .cleanId( list.columns[ c ].caption ) + '___' + c
                            for( let v in list.values )
                            {

                                if( -1 < v.indexOf( valueKey ) )
                                {

                                    if( undefined === mediaIds[ valueKey ] )
                                    {
                                        mediaIds[ list.values[ v ] ] = []
                                    }

                                    for( let k in list._keys )
                                    {
                                        if( list._keys[ k ].uuid !== this.parent.store.getters.uuid
                                            && -1 === mediaIds[ list.values[ v ] ].indexOf( list._keys[ k ].uuid ) )
                                        {
                                            mediaIds[ list.values[ v ] ].push( list._keys[ k ].uuid )
                                        }
                                    }

                                }

                            }
                        }
                    }
                }
            }

        }

        return mediaIds

    }

    /**
     * _processSharedMediaLists
     * @param shares
     * @param media
     * @param lists
     * @returns {Promise<unknown>}
     * @private
     */
    _processSharedMediaLists( shares, media, lists )
    {
        return new Promise( resolve =>
        {

            let mediaIds = this._getMediaFromLists( lists )
            this.parent.logger.clog( this.logSign + '_processSharedMediaLists',
                'shared media in lists found', Object.keys( mediaIds ).length, 'objects to be checked' )
            for( let m in mediaIds )
            {

                let found = false
                for( let mm in media )
                {
                    if( media[ mm ].idReference === m )
                    {

                        found = true

                    }
                }

                if( !found )
                {
                    let element = this.parent
                                      .baseClassHelper
                                      .get( 'media' )
                                      .getById( m )

                    if( undefined !== element )
                    {

                        this.parent.share( mediaIds[ m ], [ element ], undefined, true )

                    }
                }

            }

            return resolve()

        } )
    }

    /**
     * _processSharedMediaInSharedLists
     * @param media
     * @returns {Promise<unknown>}
     */
    _processSharedMediaInSharedLists( media )
    {
        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( 'list' )
                .getPreparedCache( 'cache' )
                .then( lists =>
                {

                    let todo = []
                    /* eslint-disable-next-line no-unused-vars */
                    for( const [ localId, list ] of lists )
                    {
                        if( undefined !== list.lists )
                        {
                            for( let ll in list.lists )
                            {
                                if( !this.parent.f.isOwn( list.lists[ ll ] )
                                    && this.parent.isShared( list.lists[ ll ] ) )
                                {
                                    todo.push( { idReference: list.lists[ ll ].localId } )
                                }
                            }
                        }
                    }

                    let mediaIds = this._getMediaFromLists( todo )
                    this.parent.logger.clog( this.logSign + '_processSharedMediaInSharedLists',
                        'shared media in shared lists found', Object.keys( mediaIds ).length, 'objects to be checked' )

                    for( let m in mediaIds )
                    {

                        let found = false
                        for( let mm in media )
                        {
                            if( media[ mm ].idReference === m )
                            {

                                found = true

                            }
                        }

                        if( !found )
                        {
                            let element = this.parent
                                              .baseClassHelper
                                              .get( 'media' )
                                              .getById( m )

                            if( undefined !== element )
                            {

                                this.parent.share( mediaIds[ m ], [ element ], undefined, true )

                            }
                        }

                    }

                    return resolve()

                } )

        } )
    }

    /**
     * _processSharedMediaInLists
     * @returns {Promise<unknown>}
     * @private
     */
    _processSharedMediaInLists()
    {
        return new Promise( resolve =>
        {

            this.parent
                .baseClassHelper
                .get( 'share' )
                .getPreparedCache( 'cache' )
                .then( shares =>
                {

                    let lists = [],
                        media = []

                    /* eslint-disable-next-line no-unused-vars */
                    for( const [ localId, share ] of shares )
                    {
                        if( share.objectType === 'media' )
                        {
                            media.push( share )
                        }
                        if( share.objectType === 'list' )
                        {
                            lists.push( share )
                        }
                    }

                    if( 0 < lists.length )
                    {
                        this._processSharedMediaLists( shares, media, lists )
                            .then( () =>
                            {
                                return resolve( this._processSharedMediaInSharedLists( media ) )
                            } )
                    }
                    else
                    {
                        return resolve( this._processSharedMediaInSharedLists( media ) )
                    }

                } )

        } )
    }

    /**
     * _checkSharedMedia
     * @returns {Promise<unknown>}
     * @private
     */
    checkSharedMedia()
    {

        return new Promise( resolve =>
        {

            if( this.syncRun % 5 === 1 )
            {

                this.syncRun++

                this.parent
                    .baseClassHelper
                    .get( 'share' )
                    .getPreparedCache( 'cache' )
                    .then( shares =>
                    {

                        let todo = []
                        /* eslint-disable-next-line no-unused-vars */
                        for( const [ localId, share ] of shares )
                        {
                            if( share.objectType === 'media' )
                            {
                                todo.push( share )
                            }
                        }

                        if( 0 < todo.length )
                        {

                            this._processSharedMedia( todo )
                                .then( () =>
                                {
                                    return resolve( this._processSharedMediaInLists() )
                                } )
                        }
                        else
                        {
                            return resolve( this._processSharedMediaInLists() )
                        }

                    } )
            }
            else
            {
                this.syncRun++
                return resolve()
            }

        } )

    }

}