export default class FieldTypeToComponent
{

    constructor()
    {
        return this
    }

    /**
     * getComponent
     * @param fieldType
     * @returns {boolean|{editor: string, display: string, valueType: string}}
     */
    getComponent( fieldType )
    {
        if( undefined !== fieldType
            && null !== fieldType
            && false !== fieldType )
        {

            switch( fieldType.toLowerCase() )
            {
                case 'checkbox':
                    return {
                        editor   : 'Checkbox',
                        display  : 'CheckMark',
                        valueType: 'plain'
                    }
                case 'rateplantselector':
                    return {
                        editor   : 'RatePlantSelector',
                        display  : 'RatePlantSelector',
                        valueType: 'plain'
                    }
                case 'ratesmileselector':
                    return {
                        editor   : 'RateSmileSelector',
                        display  : 'RateSmileSelector',
                        valueType: 'plain'
                    }
                case 'ratesmileselectorreverse':
                    return {
                        editor   : 'RateSmileSelectorReverse',
                        display  : 'RateSmileSelectorReverse',
                        valueType: 'plain'
                    }
                case 'rateselector':
                    return {
                        editor   : 'RateSelector',
                        display  : 'RateSelector',
                        valueType: 'plain'
                    }
                case 'rateflexselector':
                    return {
                        editor   : 'RateFlexSelector',
                        display  : 'RateFlexSelector',
                        valueType: 'plain'
                    }
                case 'textarea':
                case 'testcomment':
                    return {
                        editor   : 'TextArea',
                        display  : 'TextOutput',
                        valueType: 'plain'
                    }
                case 'textbox':
                    return {
                        editor   : 'TextBox',
                        display  : 'TextOutput',
                        valueType: 'plain'
                    }
                case 'filebox':
                case 'image':
                    return {
                        editor   : 'MediaBox',
                        display  : 'MediaBox',
                        valueType: 'plain'
                    }
                case 'numberbox':
                    return {
                        editor   : 'NumberBox',
                        display  : 'NumberOutput',
                        valueType: 'plain'
                    }
                case 'testitem':
                    return {
                        editor     : 'SmallNumberBox',
                        display    : 'TextOutput',
                        valueType  : 'plain',
                        placeholder: ' • '
                    }
                case 'scorebox':
                    return {
                        editor   : 'ScoreBox',
                        display  : 'TextOutput',
                        valueType: 'plain'
                    }
                case 'datebox':
                    return {
                        editor   : 'DateBox',
                        display  : 'TextOutput',
                        valueType: 'plain'
                    }
                case 'threewaytoggle':
                    return {
                        editor   : 'ThreeWayToggle',
                        display  : 'ThreeWayToggle',
                        valueType: 'plain'
                    }
                case 'studentdata':
                    return {
                        editor   : 'StudentData',
                        display  : 'StudentData',
                        valueType: 'plain'
                    }
                case 'competenceselector':
                    return {
                        editor   : 'CompetenceSelector',
                        display  : 'CompetenceSelector',
                        valueType: 'plain'
                    }
                default:
                    return false

            }

        }
        return false

    }

}