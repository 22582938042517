<template>
    <div :class="'organizer-filter'+( className ? ' '+className : '' )
                    +( '' !== filterValue  && false !== filterValue ? ' selection' : '')"
         id="organizer-filter"
         :key="localViewKey">
        <div :class="'select-arrow'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"
             id="organizer-filter-inner" v-if="initialized">
            <div :class="'organizer-icon'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"></div>
            <select :class="'organizer-filter'+( '' !== filterValue  && false !== filterValue ? ' selection' : '')"
                    name="organizer-filter" id="organizer-filter-select" @change="filter">
                <option value="">{{ title || 'Planung wählen...' }}</option>
                <option v-for="organizerItem in organizers"
                        v-bind:key="'key_option_'+organizerItem.localId"
                        :selected="filterValue === organizerItem.localId || value === organizerItem.localId"
                        :value="organizerItem.localId">{{ organizerItem.title }}
                </option>
            </select>
        </div>
        <div class="classes-filter-select syncing" v-else>
            ...
        </div>
    </div>
</template>

<script>

import MixinCachePreheater    from "@/mixins/MixinCachePreheater";
import MixinFilterState       from "@/mixins/MixinFilterState";
import MixinOrganizerResolver from "@/components/elements/viewItems/plannerView/mixins/MixinOrganizerResolver";

export default {

    name  : 'OrganizerFilter',
    mixins: [ MixinCachePreheater, MixinFilterState, MixinOrganizerResolver ],

    props: {
        title    : { type: String, required: false },
        className: { type: String, required: false },
        value    : { type: String, required: false },
        archived : { type: Boolean, required: false },
        own      : { type: Boolean, required: false },
        list     : { type: Object, required: false }
    },

    emits: [ 'filter', 'detach', 'attach' ],

    data()
    {
        return {
            organizers  : [],
            filters     : [],
            filterValue : false,
            localViewKey: false,
            initialized : false,
            neededCaches: [ 'organizer', 'class', 'group', 'yeargroup' ]
        }
    },

    watch: {
        archived: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.organizers = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            },
            deep: true
        },
        own     : {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue
                    && undefined === this.$props.list )
                {
                    this.organizers = []
                    this.filters = []
                    this.initialize()
                    this.emitValue( '' )
                }
            }
        }
    },

    created()
    {

        this.$core.getEventManager()
            .add( 'on-reset-organizers-filter', () =>
            {
                this.resetFilters()
            } )

        this.$core.getEventManager()
            .add( 'on-remote-organizer-filter', ( value ) =>
            {
                this.setFilter( value )
            } )

        this.$nextTick()
            .then( () =>
            {

                this.initialize()
                if( undefined !== this.$props.value )
                {
                    this.$emit( 'filter', 'organizer', [ { 'attr': 'localId', value: this.$props.value } ] )
                }

            } )

    },

    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'on-reset-organizers-filter' )
        this.$core.getEventManager()
            .remove( 'on-remote-organizer-filter' )

        this.$emit( 'detach' )
    },

    methods: {

        filterByOwner( element, own )
        {
            let isOwn = this.$core.f().isOwn( element )
            return own ? isOwn : true
        },

        setFilter( localId )
        {
            this.filterValue = localId
            this.localViewKey = this.$core.getUuid().generate()
        },

        initialize()
        {

            this.initialized = false

            let isArchived = this.$props.archived === true,
                own        = this.$props.own === undefined ? true : this.$props.own

            this.awaitNeededCaches()
                .then( () =>
                {

                    let organizers = this.$core
                                         .getBaseClassHelper()
                                         .get( 'organizer' )
                                         .readCache()

                    this.$core.getSorter().sortObjects( organizers, 'title' )

                    for( let o in organizers )
                    {
                        if( isArchived === organizers[ o ].archived
                            && this.filterByOwner( organizers[ o ], own ) )
                        {

                            this.resolveOrganizerTitle( organizers[ o ] )

                            this.organizers.push( {
                                localId: organizers[ o ].localId,
                                title  : this.$core.f().toPlain( organizers[ o ].displayTitle )
                            } )

                        }
                    }

                    this.$emit( 'attach', {
                        type  : 'organizer',
                        filter: this.filters
                    } )

                    this.initialized = true
                    this.restoreFilters()

                } )

        },

        filter( event )
        {

            this.$core.s().count( 'clicked-organizer-filter' )
            this.filterValue = event.target.value
            this.emitValue()
            this.storeFilterState( this.filterValue )

        },

        emitValue()
        {

            let value = this.filterValue

            if( false === value || value === '' )
            {
                this.$emit( 'filter', 'organizer', undefined )
                return
            }

            for( let o in this.organizers )
            {
                if( this.organizers[ o ].localId === value )
                {
                    this.$emit( 'filter', 'organizer', [ { 'attr': 'localId', value: this.organizers[ o ].localId } ] )
                }
            }

        },


        resetFilters()
        {
            this.$core.setState( 'filters-OrganizerFilter-planner', [] )
            this.filterValue = false
            this.emitValue()
        },

        restoreFilters()
        {

            let filterValue = this.getStoredFilterState()

            if( ( Array.isArray( filterValue ) && 0 === filterValue.length )
                || false === filterValue )
            {
                return
            }

            this.filterValue = filterValue
            this.emitValue()
        }

    }
}
</script>