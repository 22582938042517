import AbstractShadowCopyClass
    from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/abstract/AbstractShadowCopyClass";

export default class CopyNote extends AbstractShadowCopyClass
{

    constructor( parent )
    {

        super( parent, false, 'note', 'CopyNote' )
        return this

    }

}
