<template>
    <div>
        <div>
            <input class="score" :type="getFieldType()" :value="elementValue" placeholder=""
                   :disabled="disabled"
                   :ref="refName"
                   :id="'form-element-'+refName" @keyup="handleValue" @change="handleValue"
                   @click="skip"/>
        </div>
    </div>
</template>

<script>

import MixinResettableComponent from '@/mixins/MixinResettableComponent'

export default {
    name: 'ScoreBoxPoints',

    mixins: [ MixinResettableComponent ],

    props: {
        refName  : { Type: String, required: true },
        id       : { Type: String, required: false },
        value    : { Type: String, required: true },
        validator: { Type: String, required: false },
        display  : { Type: String, required: false },
        disabled : { Type: Boolean, required: false },
        inline   : { Type: Boolean, required: false }
    },

    emits: [ 'update', 'change' ],

    data()
    {
        return {
            elementValue: '',
            toggling    : false,
            initializing: true
        }
    },
    watch: {
        elementValue: function( newValue, oldValue )
        {
            if( !this.initializing
                && newValue !== oldValue )
            {
                this.handleValue()
            }
        }
    },
    mounted()
    {
        this.setValue()
        setTimeout( () =>
        {
            this.initializing = false
        }, 200 )

    },
    methods: {

        getFieldType()
        {

            if( /Edge/.test( navigator.userAgent ) )
            {
                return 'text'
            }

            return 'number'

        },

        setValue()
        {

            this.elementValue = this.$props.value
            document.querySelector( '#form-element-' + this.$props.refName ).value = this.elementValue

            if( !this.initializing )
            {
                this.handleValue()
            }

        },

        skip( event )
        {
            event.stopPropagation()
        },

        validate( value )
        {

            if( '' !== value.trim() )
            {

                if( value < 1 )
                {
                    value = 1
                }
                if( value > 15 )
                {
                    value = 15
                }

            }

            return value

        },

        handleValue( event )
        {

            let value = this.validate( event && event.target ? event.target.value : this.$refs[this.$props.refName].value )
            this.elementValue = value.trim() !== '' ? value : undefined
            document.querySelector( '#form-element-'+this.$props.refName ).value = this.elementValue ? this.elementValue : ''

            this.$emit( 'change', this.$props.id, this.elementValue )

            if( !this.initializing )
            {
                this.$emit( 'update', this.$props.refName, 'scoreBox', 'update', undefined, this.elementValue )
            }

        }
    }

}
</script>
