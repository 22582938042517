<script>
export default {
    name: "MixinDetailViews",

    methods: {

        handleDetailView( item )
        {

            if( this.$core.getBaseClassHelper()
                    .get( item.type )
                    .hasDetailView === true )
            {

                this.$core.getEventManager()
                    .dispatch( 'on-detail-view', {
                        detailObject: item,
                        tab         : this.tab,
                        viewScope   : undefined !== this.$props.viewScope ? this.$props.viewScope : this.viewScope
                    } )

            }
        },

        handleDetailViewAndSkip( event, item )
        {
            event.stopPropagation()
            this.handleDetailView( item )
        }

    }
}
</script>