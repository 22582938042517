<template>
    <div class="select-wrapper" v-if="initialized">
        <select class="select_element" :id="'form-element-'+refName" @click="$core.f().skip" @change="checkSelect()">
            <option value="">bitte wählen...</option>
            <optgroup label="meine Jahrgänge" v-if="undefined !== groups && 0 < groups.length">
                <option v-for="item in groups"
                        :key="'key_option_'+item.localId"
                        :value="item.localId">{{ item.yeargroupname }}
                </option>
            </optgroup>
            <template v-for="colleague in $core.cc().getAll()">
                <optgroup :label="'Jahrgänge von '+colleague.firstname+' '+colleague.lastname"
                          :key="'coll-'+colleague.localId"
                          v-if="undefined !== foreignGroups[ colleague.colleagueId ]
                                    && 0 < foreignGroups[ colleague.colleagueId ].length">
                    <option v-for="groupInfo in foreignGroups[ colleague.colleagueId ]"
                            :value="groupInfo.localId"
                            :selected="value === 'class:'+groupInfo.localId"
                            :key="'key_option_'+groupInfo.localId">
                        {{ groupInfo.yeargroupname }}
                    </option>
                </optgroup>
            </template>
        </select>
    </div>
</template>

<script>

import MixinCachePreheater from "@/mixins/MixinCachePreheater";

export default {
    name  : 'YeargroupSelector',
    mixins: [ MixinCachePreheater ],
    props : {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        id         : { Type: String, required: false },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false },
        flags      : { Type: String, required: false },
        prefetch   : { Type: String, required: false }
    },

    created()
    {
        this.awaitNeededCaches()
            .then( () =>
            {
                this.initialize()
                this.select()
                this.$core.getEventManager().add( 'on-yeargroup-preselect', localId =>
                {
                    this.$emit( 'update', this.$props.refName, 'yeargroupSelector', 'update', undefined, localId )
                } )
            } )
    },

    updated()
    {
        this.select()
    },

    mounted()
    {
        this.select()
    },

    beforeUnmount()
    {
        this.$core.getEventManager().remove( 'on-yeargroup-preselect' )
    },

    data()
    {
        return {
            neededCaches : [ 'yeargroup' ],
            GroupObject  : false,
            groups       : false,
            foreignGroups: {},
            data         : {},
            initialized  : false
        }
    },

    watch: {
        prefetch: {
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.initialize()
                    this.select()
                }
            },
            deep: true
        }
    },

    methods: {

        initialize()
        {
            let rules = this.$core.settings().getSetting( 'sortingDirections' )
            this.data = this.$props.prefetch
            if( undefined === this.data )
            {

                let dataC = this.$core.getBaseClassHelper()
                                .get( 'yeargroup' )
                                .getCache( 'cache' ),
                    data  = []

                /*eslint-disable*/
                for( const [ d, item ] of dataC )
                {
                    data.push( item )
                }
                /*eslint-enable*/

                this.$core.getSorter().multiSortObjects( data, rules[ 'classes' ] )
                this.data = {}
                this.data.all = data

                let groups = []
                for( let d in data )
                {
                    if( this.$core.f().isOwn( data[ d ] ) )
                    {
                        groups.push( data[ d ] )
                    }
                    else
                    {
                        if( undefined === this.foreignGroups[ data[ d ].idOwner ] )
                        {
                            this.foreignGroups[ data[ d ].idOwner ] = []
                        }
                        this.foreignGroups[ data[ d ].idOwner ].push( data[ d ] )
                    }
                }

                this.groups = groups
                this.initialized = true

            }
            else
            {
                let groups = this.$props.value
                this.$core.getSorter().multiSortObjects( groups, rules[ 'classes' ] )
                this.groups = groups
                this.initialized = true
            }

        },

        select()
        {
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element )
            {
                if( undefined !== this.$props.value
                    && null !== this.$props.value )
                {
                    element.value = this.$props.value
                }
            }
        },

        checkSelect()
        {
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element )
            {
                if( '' !== element.value.trim() )
                {
                    this.$emit( 'change', this.$props.id, element.value )
                    this.$emit( 'update', this.$props.refName, 'yeargroupSelector', 'update', undefined, element.value )
                }
                else
                {
                    this.$emit( 'change', this.$props.id, undefined )
                    this.$emit( 'update', this.$props.refName, 'yeargroupSelector', 'update', undefined, undefined )
                }
            }
        },

        isEditor()
        {
            return ( undefined === this.$props.display || this.$props.editor === true )
        }
    }

}
</script>