<script>
import MixinCachePreheater             from "@/mixins/MixinCachePreheater";
import MixinDefaultDetailViewFiltering from "@/components/defaults/defaultDetailView/MixinDefaultDetailViewFiltering";

export default {

    name  : "MixinDefaultDetailViewTabs",
    mixins: [ MixinCachePreheater, MixinDefaultDetailViewFiltering ],

    data()
    {
        return {
            tabs           : [],
            tabMap         : new Map(),
            tab            : false,
            neededCaches   : [ 'class', 'group', 'yeargroup', 'list', 'note', 'todo', 'date', 'media' ],
            originalFilters: []
        }
    },

    created()
    {
        this.prepareTabs()
        this.visible = true
    },

    /*eslint-disable*/
    beforeUnmount()
    {
        this.tabMap.forEach( ( key, value ) =>
        {
            this.tabMap.delete( key )
        } )
    },
    /*eslint-enable*/

    methods: {

        prepareTabs()
        {
            let firstTab
            switch( this.$props.object.type )
            {
                case 'student':
                    firstTab = ( this.$core.settings().getSetting( 'studentFileAddTests' ) === true ? 'lists' : 'lists_not_test' )
                    if( !this.$props.inListEditor )
                    {
                        this.tabs = [
                            {
                                id        : firstTab,
                                caption   : 'pädagogische Akte',
                                objectType: 'lists',
                                viewItem  : 'List'
                            },
                            {
                                id        : 'lists_only_test',
                                caption   : 'Benotung',
                                objectType: 'lists',
                                viewItem  : 'List'
                            },
                            {
                                id     : 'quick',
                                caption: 'Schnellansicht'
                            },
                            {
                                id     : 'scorings',
                                caption: this.$core.t( 'object-type-scorings' ),
                            },
                            {
                                id        : 'notes',
                                caption   : 'Notizen',
                                objectType: 'notes',
                                viewItem  : 'Note'
                            },
                            {
                                id        : 'todos',
                                caption   : this.$core.t( 'object-type-todos' ),
                                objectType: 'todos',
                                viewItem  : 'Todo'
                            },
                            {
                                id        : 'media',
                                caption   : 'Dokumente',
                                objectType: 'media',
                                viewItem  : 'Media'
                            },
                            {
                                id     : 'planner',
                                caption: 'Kalender'
                            },
                            {
                                id     : 'data',
                                caption: 'Stammdaten'
                            } ]
                        this.availableFilters = [ 'LabelsFilter', 'ColorFilter' ]
                    }
                    else
                    {
                        this.tabs = [
                            {
                                id        : firstTab,
                                caption   : 'pädagogische Akte',
                                objectType: 'lists',
                                viewItem  : 'List'
                            },
                            {
                                id        : 'lists_only_test',
                                caption   : 'Benotung',
                                objectType: 'lists',
                                viewItem  : 'List'
                            }
                        ]
                        this.availableFilters = []
                    }
                    this.tab = firstTab
                    break
                case 'class':
                    if( !this.$props.inListEditor )
                    {
                        this.tabs = [
                            {
                                id        : 'lists_not_test',
                                caption   : 'Büro',
                                objectType: 'lists',
                                viewItem  : 'List'
                            },
                            {
                                id        : 'lists_only_test',
                                caption   : 'Benotung',
                                objectType: 'lists',
                                viewItem  : 'List'
                            },
                            {
                                id     : 'scorings',
                                caption: this.$core.t( 'object-type-scorings' ),
                            },
                            {
                                id        : 'notes',
                                caption   : 'Notizen',
                                objectType: 'notes',
                                viewItem  : 'Note'
                            },
                            {
                                id        : 'todos',
                                caption   : this.$core.t( 'object-type-todos' ),
                                objectType: 'todos',
                                viewItem  : 'Todo'
                            },
                            {
                                id        : 'media',
                                caption   : 'Dokumente',
                                objectType: 'media',
                                viewItem  : 'Media'
                            },
                            {
                                id     : 'planner',
                                caption: 'Kalender'
                            },
                            {
                                id              : 'students',
                                caption         : 'Schüler:innen',
                                objectType      : 'students',
                                viewItem        : 'Student',
                                availableFilters: 'gender' === this.$core.settings().getSetting( 'studentColorModel' ) ?
                                                  [ 'StudentFilter' ] : [ 'StudentFilter', 'ColorFilter' ]
                            } ]
                        this.availableFilters = [ 'LabelsFilter', 'ColorFilter' ]
                        this.tab = 'lists_not_test'
                    }
                    else
                    {
                        this.tabs = [
                            {
                                id        : 'lists_not_test',
                                caption   : 'Büro',
                                objectType: 'lists',
                                viewItem  : 'List'
                            },
                            {
                                id        : 'lists_only_test',
                                caption   : 'Benotung',
                                objectType: 'lists',
                                viewItem  : 'List'
                            }
                        ]
                        this.availableFilters = []
                        this.tab = 'lists_not_test'
                    }
                    break
                case 'group':
                case 'yeargroup':
                    this.tabs = [
                        {
                            id        : 'lists',
                            caption   : 'Büro',
                            objectType: 'lists',
                            viewItem  : 'List'
                        },
                        {
                            id        : 'notes',
                            caption   : 'Notizen',
                            objectType: 'notes',
                            viewItem  : 'Note'
                        },
                        {
                            id        : 'todos',
                            caption   : this.$core.t( 'object-type-todos' ),
                            objectType: 'todos',
                            viewItem  : 'Todo'
                        },
                        {
                            id        : 'media',
                            caption   : 'Dokumente',
                            objectType: 'media',
                            viewItem  : 'Media'
                        },
                        {
                            id     : 'planner',
                            caption: 'Kalender'
                        },
                        {
                            id        : 'students',
                            caption   : 'Schüler:innen',
                            objectType: 'students',
                            viewItem  : 'Student'
                        } ]
                    this.availableFilters = [ 'LabelsFilter', 'ColorFilter' ]
                    this.tab = 'lists'
                    break
                case 'colleague':
                    this.tabs = [
                        {
                            id       : 'coll_meta',
                            caption  : 'Info',
                            component: 'ColleagueMeta'
                        },
                        {
                            id       : 'coll_rights_general',
                            caption  : 'Generelle Berechtigungen',
                            component: 'ColleagueGeneralRights'
                        },
                        {
                            id       : 'coll_rights_objects',
                            caption  : 'Element-Berechtigungen',
                            component: 'ColleagueObjectRights'
                        },
                        {
                            id       : 'coll_sharedwithme',
                            caption  : 'mit mir geteilte Elemente',
                            component: 'ColleagueSharedWithMe'
                        }
                    ]
                    this.availableFilters = []
                    this.tab = 'coll_meta'
                    break
                case 'competenceCategory':
                    this.tabs = [
                        {
                            id       : 'comp_cats_overview',
                            caption  : 'Übersicht',
                            component: 'CompetenceCategoryOverview'
                        }
                    ]
                    this.availableFilters = []
                    this.tab = 'comp_cats_overview'
                    break
                case 'studentAccess':
                    this.tabs = [
                        {
                            id       : 'student_access_elms',
                            caption  : 'Geteilte Elemente',
                            component: 'StudentAccessElements'
                        },
                        {
                            id       : 'student_access_base',
                            caption  : 'Zugangsdaten',
                            component: 'StudentAccessBase'
                        } ]
                    this.availableFilters = []
                    this.tab = 'student_access_elms'
                    break
            }
            let tabSetup = this.getTabSetupById( this.tab )
            if( undefined !== tabSetup.objectType )
            {
                this.$core.setState( 'default-view-tab-type', tabSetup.viewItem.toLowerCase() )
            }
            this.originalFilters = JSON.parse( JSON.stringify( this.availableFilters ) )
            this.cleanTabs()
        },

        cleanTabs()
        {
            let clean = []
            for( let t in this.tabs )
            {

                this.tabMap.set( this.tabs[ t ].id, this.tabs[ t ] )

                let temp = this.tabs[ t ].id.split( '_' ),
                    type = temp[ 0 ]

                if( ( 'planner' === this.tabs[ t ].id
                      || 'scorings' === this.tabs[ t ].id
                      || 'quick' === this.tabs[ t ].id ) && 'archive' === this.scope )
                {
                    continue
                }
                else if( 'data' === this.tabs[ t ].id
                         || this.$core.getLicense().isAllowed( 'page_' + type ) )
                {
                    clean.push( this.tabs[ t ] )
                }
                else
                {
                    if( this.$core.getLicense().isAllowed( undefined, this.tabs[ t ].id ) )
                    {
                        clean.push( this.tabs[ t ] )
                    }
                }
            }
            this.tabs = clean
        },

        getTabSetupById( tab )
        {
            for( let t in this.tabs )
            {
                if( this.tabs[ t ].id === tab )
                {
                    return this.tabs[ t ]
                }
            }
        },

        setTab( tab )
        {

            this.ready = false
            this.counts = {}
            this.tab = tab

            let tabSetup = this.getTabSetupById( tab )
            if( undefined !== tabSetup.objectType )
            {
                this.$core.setState( 'default-view-tab-type', tabSetup.viewItem.toLowerCase() )
            }
            else
            {
                this.$core.deleteState( 'default-view-tab-type' )
            }

            for( let t in this.tabs )
            {
                if( this.tabs[ t ].id === tab
                    && undefined !== this.tabs[ t ].availableFilters )
                {
                    this.availableFilters = this.tabs[ t ].availableFilters
                }
                else
                {
                    this.availableFilters = this.originalFilters
                }
            }

            if( !this.isSpecialTab )
            {
                this.prepareItemList()
                    .then( () =>
                    {
                        this.$nextTick()
                            .then( () =>
                            {
                                this.changeKey = this.$core.getUuid().generate()
                                this.ready = true
                            } )
                    } )
            }
            else
            {
                this.changeKey = this.$core.getUuid().generate()
                this.ready = true
            }

        },

        _getSortMap( mapKey, sortKey )
        {

            mapKey = mapKey || 'cache'

            return this.$core.getBaseClassHelper()
                       .get( sortKey )
                       .registry
                       .sortMap.get( mapKey )

        },

        prepareItemList()
        {

            return new Promise( resolve =>
            {

                if( undefined !== this.tabMap.get( this.tab ).component )
                {
                    return resolve()
                }

                let mapKey = this.$props.object.archived ? 'archive' : 'cache'
                this.setupFilter()

                this.awaitNeededCaches()
                    .then( () =>
                    {

                        let list   = this._getSortMap( mapKey, this.tabMap.get( this.tab ).viewItem.toLowerCase() ),
                            result = [],
                            counts = {
                                visible  : 0,
                                invisible: 0
                            }

                        for( let l in list )
                        {
                            if( this.detailViewFilterMatch( list[ l ] ) )
                            {
                                result.push( list[ l ] )
                                if( !this.$core.getBaseClassHelper().isElementHidden( list[ l ], this.tabMap.get( this.tab ).viewItem.toLowerCase() ) )
                                {
                                    counts.visible++
                                }
                                else
                                {
                                    counts.invisible++
                                }
                            }
                        }

                        this.prepared[ this.tab ] = result
                        this.ready = true

                        this.setCount( counts )
                        return resolve()

                    } )

            } )
        }

    }
}
</script>