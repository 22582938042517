<template>
    <div v-if="ccReady"
         :id="'list-container-wrapper-'+list.localId"
         :key="'listEditorMain-'+ownId"
         class="max-dimensions">
        <input type="hidden" :id="'printables-'+list.localId" :value="'list-'+editMode+'-'+list.localId"/>
        <!-- GENERIC LIST FILTERS & MESSAGES -->
        <ListFilter v-if="listFilterVisible"
                    :editormode=true
                    @filter="handleListFilter"/>

        <ListOfflineWarning/>

        <ListUpdatedMessage v-if="!notificationActive && showMerge"
                            @clicked="showMerge = false; remoteDiffers = false; tdmarks = []; lastLocalChange = Date.now()"/>

        <ListEditAllWarning v-if="showEditAllActive && !hideEditAllWarning"
                            @click="hideEditAllWarning = true"/>

        <RecallListDefinition v-if="list.listType === 'recallList'"
                              :list
                              :recallVisible
                              @storeRecallDoneDefinition="handleRecallDoneDefinition"/>

        <!-- END: GENERIC LIST FILTERS & MESSAGES -->

        <template v-if="initialized && editorPrepared">

            <div v-if="editMode === 'vertical'" class="max-dimensions">
                <ListEditorVertical :list
                                    :students
                                    :studentList
                                    :reference
                                    :lines
                                    :tdmarks
                                    :readOnly
                                    :swiped
                                    :listFilter
                                    :listFilterInline
                                    :selectedHistory="editHistory"
                                    :editMode
                                    @unswipe="swiped = false"/>
            </div>

            <div v-if="editMode === 'horizontal'" class="max-dimensions">
                <ListEditorHorizontal :list
                                      :students
                                      :studentList
                                      :reference
                                      :lines
                                      :tdmarks
                                      :readOnly
                                      :swiped
                                      :listFilter
                                      :listFilterInline
                                      :showCalcFields
                                      :selectedHistory="editHistory"
                                      :editMode
                                      @toggleEditAll="toggleEditAll"
                                      @unswipe="swiped = false"/>
            </div>

        </template>
        <template v-else>
            <div>
                <PreheatingIndicator
                    captionHtml="<strong>Augenblick bitte:</strong><br/>Die aktuelle Listenversion wird abgerufen und überprüft..."/>
            </div>
        </template>

    </div>
</template>

<script>
/*eslint-disable*/
import MixinListEditorMainPrepare from "@/components/elements/viewItems/listEditor/mixins/MixinListEditorMainPrepare";
import ListEditorVertical         from "@/components/elements/viewItems/listEditor/layout/ListEditorVertical";
import ListEditorHorizontal       from "@/components/elements/viewItems/listEditor/layout/ListEditorHorizontal";
import ListEditAllWarning         from "@/components/elements/viewItems/listEditor/layout/generic/ListEditAllWarning";
import ListUpdatedMessage         from "@/components/elements/viewItems/listEditor/layout/generic/ListUpdatedMessage";
import ListOfflineWarning         from "@/components/elements/viewItems/listEditor/layout/generic/ListOfflineWarning";
import ListFilter                 from "@/components/elements/filters/ListFilter";
import PreheatingIndicator        from "@/components/elements/indicators/PreheatingIndicator";
import RecallListDefinition       from "@/components/elements/viewItems/listEditor/RecallListDefinition";
import MixinComponentCleanup      from "@/mixins/MixinComponentCleanup.vue";
import CollabEditingNotification  from "@/components/elements/indicators/CollabEditingNotification.vue";

export default {

    name: "ListEditorMain",

    components: {
        CollabEditingNotification,
        RecallListDefinition,
        PreheatingIndicator,
        ListFilter,
        ListOfflineWarning,
        ListUpdatedMessage,
        ListEditAllWarning,
        ListEditorHorizontal,
        ListEditorVertical
    },

    emits : [ 'update', 'definitionUpdate', 'containerUpdate' ],
    mixins: [ MixinListEditorMainPrepare, MixinComponentCleanup ],

    data()
    {
        return {
            ownId             : this.$core.getUuid().generate()
        }
    },

    watch: {
        editPrint: {
            immediate: true,
            handler( newState, oldState )
            {
                if( newState !== oldState
                    && true === newState )
                {
                    this.handlePrint()
                }
            }
        },
        editExcel: {
            immediate: true,
            handler( newState, oldState )
            {
                if( newState !== oldState
                    && true === newState )
                {
                    this.handleExcel()
                }
            }
        }
    },

    methods: {

        setNotification( id )
        {

            let coll = this.$core.cc().getById( id )
            if( undefined !== coll )
            {
                this.notificationActive = true
                this.notificationText = '<strong>' + coll.firstname + ' ' + coll.lastname + '</strong> hat dieses Element geöffnet.'
            }

        },

        handleRecallDoneDefinition( values )
        {

            this.$core.getListUpdateManager()
                .updateDoneDefinition( this.$props.list.localId, values )

        },

        handleListFilter( filter, value )
        {
            this.listFilterInline = value
        },

        toggleEditAll( state )
        {
            this.$emit( 'containerUpdate', this.$props.list.localId, 'editingAll', state )
        }

    }

}
</script>