export default class SyncBackgrounds
{
    
    constructor( parent )
    {
        
        this.parent = parent

        this.backgrounds = [
            'baby-blue.webp',
            'black-beauty.webp',
            'carrots.webp',
            'cat.webp',
            'chalkboard.webp',
            'cut-n-draw.webp',
            'fish.webp',
            'floral.webp',
            'greenish.webp',
            'lose-papers.webp',
            'lose-papers-wallpaper.webp',
            'love.webp',
            'maths.webp',
            'paperstack2.webp',
            'pencils.webp',
            'pink-lady.webp',
            'rabbit.webp',
            'science.webp',
            'summer.webp',
            'tapete.webp',
            'unicorns.svg',
            'white.webp',
            'abstract.webp',
            'flowers.webp',
            'flowers_ananas.svg',
            'flowers_dark.webp',
            'smiles.webp',
            'newyork.webp',
            'xmas.webp'
        ]

        this.totalCount = this.backgrounds.length
        this.doneCount = 0

        this.allSynced = false
        this.sync()
        
    }
    
    ready()
    {
        return this.allSynced === true
    }
    
    sync()
    {

        if( false !== this.parent.store.getters.idSession
            && true === this.parent.store.getters.online )
        {
    
            for( let i in this.backgrounds )
            {
        
                let id = this.backgrounds[ i ]
        
                this.parent.database.read( 'backgrounds', id )
                    .then( () =>
                    {
                    } )
                    .catch( () =>
                    {

                        let request = {
                            method      : 'objects.getBackground',
                            name        : id,
                            looseSession: true
                        }

                        let temp = id.split( /\./g )
                        let name = temp[0]

                        this.parent.client.request( request )
                            .then( result =>
                            {
                                this.parent.database.write( 'backgrounds', name, result.data )
                            } )
                
                    } )
                    .finally( () => {

                        this.doneCount++
                        this.allSynced = this.doneCount === this.totalCount

                    })
        
            }

            this.parent.client.request({
                    method: 'objects.getPersonalBackground'
                })
                .then( result => {
                    if( undefined !== result.data )
                    {

                        this.parent.cryptoHelper.plainDecrypt( result.data )
                            .then( image => {
                                this.parent.database.write( 'backgrounds', 'personal', image )
                                this.parent.eventManager.dispatch( 'update-background-style' )
                            })

                    }
                })
            
        }
        else
        {
            setTimeout( () => {
                this.sync()
            }, 5000 )
        }
        
    }
    
}