<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
        <div :class="className || 'planner-day organizer setup preview'"
             :style="!display ? 'visibility:hidden; display: none;' : ''"
             :key="'container-'+id"
             :id="id">
            <div class="planner-element-wrapper">
                <template v-if="isLabelDragging">
                    <draggable v-model="deleteField"
                               group="draggery"
                               :id="'dropzone-deletion-'+id"
                               handle=".draggable">
                        <template #item="{ element }">
                            <div :id="'dropzone-deletion-'+id+'-'+element"
                                 class="draggable-organizer-delete-zone">LÖSCHEN
                            </div>
                        </template>
                    </draggable>
                </template>

                <div v-for="( slot, idx ) in copy"
                     :key="'slot-setup-'+idx"
                     :class="'planner-slot'+( selectedTarget === idx ? ' highlighted' : '' )"
                     :id="'planner-slot-'+date+'-'+idx"
                     :style="'min-height:'+slotHeights[idx]+'px; height: '+slotHeights[idx]+'px'"
                     @mouseover="markTarget( false, slot, idx )"
                     @mouseout="unmarkTarget( false, slot, idx )"
                     @click="markTarget( true, slot, idx )">

                    <div v-if="!noCaptions">
                        <strong v-if="slot.type==='time'">{{ slot.value }} Uhr</strong>
                        <strong v-else>{{ slot.value }}</strong>
                    </div>
                    <template v-else>
                        <template v-if="1 === organizers.length">
                            <draggable v-model='labels[ idx ]'
                                       class="organizer-draggery"
                                       group="draggery"
                                       ghost-class="drag-ghost"
                                       :animation="200"
                                       handle=".draggable"
                                       :item-key="'draggable-planner-element-'+date+'-'+idx"
                                       :id="'draggable-planner-'+date+'-'+idx"
                                       :empty-insert-threshold="1000"
                                       :move="checkMove"
                                       @start="handleDragStart"
                                       @end="handleMove">
                                <template #item="{ element, index }">
                                    <div
                                        :class="'organizer-label '+( draggableLabels ? 'draggable ' : '' )+element.color"
                                        :style="{ '--index': index }"
                                        :key="'slot-label-'+date+'-'+idx+'-'+index">
                                        <input type="hidden" :id="'draggable-label-'+date+'-'+idx"
                                               :value="element.localId"/>
                                        {{ element.caption }}
                                    </div>
                                </template>
                                <template #footer>
                                    <div v-if="isLabelDragging"
                                         :id="'draggable-footer-'+date+'-'+idx"
                                         class="organizer-draggable-labels-dropzone"></div>
                                </template>
                            </draggable>
                        </template>

                        <draggable v-model='contents[ idx ]'
                                   class="organizer-contents-draggery"
                                   group="draggery-contents"
                                   ghost-class="drag-ghost"
                                   :animation="200"
                                   handle=".draggable"
                                   :item-key="'draggable-planner-contents-element-'+date+'-'+idx"
                                   :id="'draggable-contents-planner-'+date+'-'+idx"
                                   :empty-insert-threshold="1000"
                                   :move="checkMove"
                                   @start="handleDragStart"
                                   @end="handleMove">
                            <template #item="{ element, contentIndex }">
                                <PlannerSlotContent :date="date"
                                                    :index="idx"
                                                    :slotContent="element"
                                                    :draggable="!draggableLabels"
                                                    :highlighted="highlighted"
                                                    :key="'slot-content-'+date+'-'+contentIndex"/>
                            </template>
                            <template #footer>
                                <div v-if="isContentDragging"
                                     :id="'draggable-contents-footer-'+date+'-'+idx"
                                     class="organizer-draggable-contents-dropzone"></div>
                            </template>
                        </draggable>
                    </template>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import draggable              from 'vuedraggable'
import PlannerSlotContent     from "@/components/elements/viewItems/plannerView/PlannerSlotContent";
import MixinDraggable         from "@/mixins/MixinDraggable";
import MixinOrganizerResolver from "@/components/elements/viewItems/plannerView/mixins/MixinOrganizerResolver";

export default {
    /*eslint-disable*/
    name      : 'PlannerSlots',
    components: { PlannerSlotContent, draggable },

    emits : [ 'refresh' ],
    mixins: [ MixinDraggable, MixinOrganizerResolver ],

    props: {
        slots          : { Type: Object, required: true },
        date           : { Type: Number, required: false },
        className      : { Type: String, required: false },
        changeKey      : { Type: String, required: false },
        display        : { Type: Boolean, required: false, default: false },
        noCaptions     : { Type: Boolean, required: false, default: false },
        isFirst        : { Type: Boolean, required: false, default: false },
        organizers     : { type: [ Object, Boolean ], required: false },
        slotSelection  : { type: [ Object, Boolean ], required: false },
        slotContents   : { type: [ Object, Boolean ], required: false },
        highlighted    : { type: [ Object, Boolean ], required: false },
        isDragging     : { type: [ Object, Boolean ], required: false },
        draggableLabels: { type: [ Object, Boolean ], required: false, default: false },
        copyMode       : { type: Boolean, required: false }
    },

    data()
    {
        return {
            deleteField   : [ 'deleteDropZone' ],
            copy          : {},
            contents      : {},
            labels        : [],
            slotHeights   : [],
            eventIdx      : [],
            selectedTarget: -1,
            id            : false
        }
    },

    watch: {
        slots    : {
            immediate: true,
            deep     : true,
            handler()
            {
                this.handleSlotSizes()
            }
        },
        changeKey: {
            immediate: true,
            handler( newSet, oldSet )
            {
                if( newSet !== oldSet )
                {
                    this.$core
                        .getUi()
                        .delay( () =>
                        {
                            this.updateContents()
                        }, 100 )
                }
            }
        }
    },

    computed: {
        isLabelDragging()
        {
            return this.$props.isDragging && this.$props.draggableLabels !== false
        },
        isContentDragging()
        {
            return this.$props.isDragging && this.$props.draggableLabels === false
        },
        slotContentsKey()
        {
            return this.$core.getBaseClassHelper().get( 'organizercontent' ).registry.cacheKey
        }
    },

    created()
    {
        this.check = new Date()
        this.id = 'planner-day-' + this.$props.date
    },

    mounted()
    {
        this.handleSlotSizes()
        this.updateContents()
    },

    methods: {

        getLabels( dayIndex, slotIndex )
        {

            let labels = []

            for( let o in this.$props.organizers )
            {
                let organizer = this.$props.organizers[ o ]
                if( this.$core.f().isObject( organizer.labels )
                    && undefined !== organizer.labels[ dayIndex ]
                    && undefined !== organizer.labels[ dayIndex ][ slotIndex ] )
                {

                    let label = this.$core
                                    .getBaseClassHelper()
                                    .get( 'organizerlabel' )
                                    .getById( organizer.labels[ dayIndex ][ slotIndex ] )

                    labels.push( label )

                }
            }

            return labels

        },

        finalize()
        {
            this.copy = this.$core.f().deref( this.$props.slots )
            this.labels = []
            for( let s in this.$props.slots )
            {
                this.labels.push( this.getLabels( new Date( this.$props.date ).getDay(), parseInt( s ) ) )
            }
        },

        handleSlotSizes()
        {

            let totalHeight     = 0,
                heights         = [],
                availableHeight = 0

            let element     = document.querySelector( '#' + this.id ),
                defaultView = document.defaultView

            if( null !== element )
            {

                this.slotHeights = []

                availableHeight = parseInt( defaultView.getComputedStyle( element, '' ).getPropertyValue( 'max-height' ) )

                for( let s in this.$props.slots )
                {
                    let slot = this.$props.slots[ s ]
                    heights.push( slot.height )
                    totalHeight += slot.height
                }

                for( let h in heights )
                {

                    let factor = ( 100 / ( totalHeight / heights[ h ] ) ) / 100,
                        pixels = availableHeight * factor

                    this.slotHeights.push( pixels )

                }

            }

            this.finalize()

        },

        assignSlot( slot )
        {

            let slotIndex = 0

            if( 1 < this.$props.slots.length )
            {
                slotIndex = slot.slotIndex
            }

            if( undefined === this.contents[ slotIndex ] )
            {
                this.contents[ slotIndex ] = []
            }

            this.contents[ slotIndex ].push( slot )

        },

        updateContents()
        {

            if( undefined === this.$props.slotContents
                || !Array.isArray( this.$props.slotContents ) )
            {
                return
            }

            this.contents = []

            for( let c in this.$props.slotContents )
            {
                for( let s in this.$props.slotContents[ c ].slotDisplay )
                {
                    if( this.$props.slotContents[ c ].slotDisplay[ s ].date === this.$props.date )
                    {
                        let slot = this.$core.f().deref( this.$props.slotContents[ c ].slotDisplay[ s ] )
                        slot.reference = this.$props.slotContents[ c ].localId
                        slot.contentIndex = s
                        this.assignSlot( slot )
                    }
                }
            }

            this.finalize()

        },

        markTarget( click, slot, index )
        {

            if( undefined !== this.$props.slotSelection
                && false !== this.$props.slotSelection )
            {
                this.selectedTarget = index
                if( click )
                {
                    this.selectedTarget = -1
                    this.$emit( 'mark', index )
                }
            }
        },

        unmarkTarget( click, slot, index )
        {

            if( undefined !== this.$props.slotSelection
                && false !== this.$props.slotSelection )
            {
                this.selectedTarget = -1
            }
        },

        performLabelDrop( sourceDate, sourceIndex )
        {

            if( 'deleteDropZone' === this.deleteField[ 0 ] )
            {

                let original = this.deleteField[ 1 ]
                this.performLabelMove( original.localId, sourceDate, sourceIndex, sourceDate, sourceIndex )
                this.deleteField = [ 'deleteDropZone' ]
                return

            }

            let sourceDay = new Date( sourceDate ).getDay(),
                organizer = this.$props.organizers[ 0 ]

            if( this.$core.f().isObject( organizer.labels )
                && undefined !== organizer.labels[ sourceDay ]
                && undefined !== organizer.labels[ sourceDay ][ sourceIndex ] )
            {

                let clone = this.$core.f().deref( organizer )

                if( !this.$props.copyMode )
                {
                    delete clone.labels[ sourceDay ][ sourceIndex ]
                }

                this.$core.getBaseClassHelper()
                    .get( 'organizer' )
                    .update( clone,
                        clone.localId,
                        clone.remoteId,
                        clone.timestamp,
                        clone.localKey,
                        undefined,
                        false,
                        false
                    )

            }

            this.deleteField = [ 'deleteDropZone' ]

        },

        performLabelMove( localId, sourceDate, sourceIndex, targetDate, targetIndex )
        {

            let sourceDay = new Date( sourceDate ).getDay(),
                targetDay = new Date( targetDate ).getDay(),
                organizer = this.$props.organizers[ 0 ]

            if( this.$core.f().isObject( organizer.labels )
                && undefined !== organizer.labels[ sourceDay ]
                && undefined !== organizer.labels[ sourceDay ][ sourceIndex ] )
            {

                let clone = this.$core.f().deref( organizer )

                if( !this.$props.copyMode )
                {
                    delete clone.labels[ sourceDay ][ sourceIndex ]
                }
                if( undefined === clone.labels[ targetDay ] )
                {
                    clone.labels[ targetDay ] = {}
                }

                clone.labels[ targetDay ][ targetIndex ] = localId

                this.$core.getBaseClassHelper()
                    .get( 'organizer' )
                    .update( clone,
                        clone.localId,
                        clone.remoteId,
                        clone.timestamp,
                        clone.localKey,
                        undefined,
                        false,
                        false
                    )

            }

        }

    }

}
</script>