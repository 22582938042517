/**
 * entzettelt
 * - copyright 2019-2024 Hill-Commerce GmbH
 * - developed by Manuel Pohl (m.pohl@hill-commerce.de)
 */

import DependencyWalker from '@/classes/Core/Share/helpers/DependencyWalker'
import ShareHelper      from '@/classes/Core/Share/helpers/ShareHelper'
import ShareCore        from "@/classes/Core/Share/core/ShareCore";

export default class Share
{

    constructor( core )
    {

        if( !Share.instance )
        {

            this.core = core
            this.logger = core.getLogger()
            this.store = core.getStore()
            this.f = core.f()
            this.eventManager = core.getEventManager()
            this.baseClassHelper = core.getBaseClassHelper()
            this.syncWorker = core.getSyncWorker()
            this.uuid = core.getUuid()
            this.cryptoCore = core.getCryptoCore()
            this.cryptoHelper = core.getCryptoHelper()
            this.queueWorker = core.getQueueWorker()
            this.database = core.getDatabase()
            this.client = core.getClient()
            this.ui = core.getUi()
            this.rights = core.r()
            this.sanitizers = core.getSanitizers()
            this.tsmp = core.getFriendlyTimestamp()
            this.timer = core.getCoreTimer()
            this.mediaHelper = core.getMediaHelper()

            if( undefined === this.baseClassHelper )
            {
                this.eventManager.append( 'on-baseclasses-available', () =>
                {
                    this.baseClassHelper = core.getBaseClassHelper()
                } )
            }

            this.ready = false
            this.awaitingColleagueHeatup = false
            this.awaitingShareHelpers = false
            this.colleagues = []
            this.queueForType = []

            this.eventStates = {
                'colleague-refresh' : false,
                'share-helper-ready': false
            }

            this.init()

            this.dependencyWalker = new DependencyWalker( this )
            this.shareHelper = new ShareHelper( this )
            this.shareCore = new ShareCore( this )

            this.logSign = 'Core::Share [SHR]:'

            Share.instance = this


        }

        return Share.instance

    }

    /**
     * initialize share class
     */
    init()
    {
        if( !this.awaitingColleagueHeatup )
        {

            this.awaitingColleagueHeatup = true
            this.logger.clog( this.logSign + 'init', 'colleagues cache filled - setting up event handlers...' )
            this.setEventHandler()

        }
    }

    getMediaHelper()
    {
        return this.mediaHelper
    }

    /**
     * destruct instance
     */
    destruct()
    {
        delete Share.instance
    }

    /**
     * refreshColleagues
     * - initialize the local colleagues cache
     */
    refreshColleagues()
    {

        if( 'filled' === this.baseClassHelper
                             .get( 'colleague' ).state )
        {

            let scopes     = [ 'cache', 'archive' ],
                colleagues = this.baseClassHelper
                                 .get( 'colleague' )
                                 .getCache(),
                list       = []

            for( let s in scopes )
            {
                /* eslint-disable-next-line no-unused-vars */
                for( const [ localId, colleague ] of colleagues[ scopes[ s ] ] )
                {
                    if( 2 === colleague.state )
                    {
                        list.push( colleague )
                    }
                }
            }

            this.colleagues = list
            this.eventManager.dispatch( 'on-share-ready', this )
            this.eventManager.dispatch( 'on-share-refresh' )
            this.ready = true
            this.eventManager.dispatchIndexed( 'on-share-refresh-component' )

        }

    }

    /**
     * setEventHandler
     */
    setEventHandler()
    {

        if( !this.eventStates[ 'colleague-refresh' ] )
        {

            this.eventStates[ 'colleague-refresh' ] = true
            this.eventManager.append( 'on-filled-state-colleague', () =>
            {
                if( true === this.store.getters.authorized )
                {
                    this.ready = false
                    this.refreshColleagues()
                }
                else
                {
                    this.eventManager.append( 'on-login-state-change', () =>
                    {
                        this.ready = false
                        this.refreshColleagues()
                    } )
                }
            } )

            this.eventManager.append( 'on-refresh-cache-colleague', () =>
            {
                this.ready = false
                this.refreshColleagues()
            } )

        }
    }

    /**
     * awaitShareHelperReady
     * @returns {Promise<unknown>}
     */
    awaitShareHelperReady()
    {
        return new Promise( resolve =>
        {

            if( this.shareHelper.ready )
            {
                return resolve()
            }
            else
            {
                this.awaitingShareHelpers = true
                if( !this.eventStates[ 'share-helper-ready' ] )
                {
                    this.eventManager.append( 'after-share-helper-ready', () =>
                    {

                        if( this.awaitingShareHelpers )
                        {
                            this.awaitingShareHelpers = false
                            return resolve()
                        }

                    } )
                }
            }

        } )
    }

    /**
     * isShared
     * @param object
     * @returns {boolean}
     */
    isShared( object )
    {
        return this.shareHelper.isShared( object )
    }

    /**
     * shareCount
     * @param colleague
     * @returns {Promise<unknown>}
     */
    shareCount( colleague )
    {
        return this.shareHelper.shareCount( colleague )
    }

    /**
     * sharedWith
     * @param object
     * @param uuid
     * @returns {boolean}
     */
    sharedWith( object, uuid )
    {

        if( this.f.isset( object._keys ) )
        {

            for( let k in object._keys )
            {
                if( object._keys[ k ].uuid === uuid )
                {
                    return true
                }
            }

            return false

        }

        if( 'list' === object.type
            && Array.isArray( object.lists ) )
        {
            for( let l in object.lists )
            {
                if( this.sharedWith( object.lists[ l ], uuid ) )
                {
                    return true
                }
            }
        }

        return false

    }

    /**
     * sharedWithArray
     * @param object
     * @param colleagues
     * @returns {boolean}
     */
    sharedWithArray( object, colleagues )
    {
        for( let c in colleagues )
        {
            if( this.sharedWith( object, colleagues[ c ].uuid ) )
            {

                return true

            }
        }

        return false

    }

    /**
     * shareList
     * @param object
     * @returns {*[]}
     */
    shareList( object )
    {
        return this.shareHelper.shareList( object )
    }

    /**
     * getOwnerColleague
     * @param object
     * @returns {*}
     */
    getOwnerColleague( object )
    {
        return this.shareHelper.getOwnerColleague( object )
    }

    /**
     * share
     */
    share( colleagueUuid, elms, shareDependencies, noSync )
    {

        return this.shareCore.share( colleagueUuid, elms, shareDependencies, noSync )

    }

    /**
     * unshare
     */
    unshare( colleagueUuid, elms, shareDependencies, noSync )
    {

        return this.shareCore.unshare( colleagueUuid, elms, shareDependencies, noSync )

    }

    /**
     * unshareAll
     * @param elms
     * @param shareDependencies
     */
    unshareAll( elms, shareDependencies )
    {
        return this.shareCore.unshareAll( elms, shareDependencies )
    }

    /**
     * unshareAllForColleague
     * @param shareWith
     */
    unshareAllForColleague( shareWith )
    {
        return this.shareCore.unshareAllForColleague( shareWith )
    }

    /***
     *
     *   STUDENT SHARING
     *
     *************************************************************/

    /**
     * _shadowCopyExists
     * @param studentLocalId
     * @param referenceLocalId
     * @returns {boolean}
     * @private
     */
    _shadowCopyExists( studentLocalId, referenceLocalId )
    {

        let allCopies = this.baseClassHelper.get( 'shadowCopy' )
                            .getCache( 'cache' )
        /*eslint-disable*/
        for( const [ a, copy ] of allCopies )
        {
            if( copy.studentLocalId === studentLocalId
                && copy.referenceLocalId === referenceLocalId )
            {

                return true

            }
        }
        /*eslint-enable*/
        return false

    }

    /**
     * _getShadowCopy
     * @param studentLocalId
     * @param referenceLocalId
     * @returns {*|boolean}
     * @private
     */
    _getShadowCopy( studentLocalId, referenceLocalId )
    {

        let allCopies = this.baseClassHelper
                            .get( 'shadowCopy' )
                            .getCache( 'cache' )

        /*eslint-disable*/
        for( const [ a, copy ] of allCopies )
        {
            if( copy.studentLocalId === studentLocalId
                && copy.referenceLocalId === referenceLocalId )
            {

                return copy

            }
        }
        /*eslint-enable*/
        return false

    }

    /**
     * createShadowCopy
     * @param source
     * @param shareWith
     * @param studentEditable
     * @returns {Promise<unknown>}
     */
    createShadowCopy( source, shareWith, studentEditable )
    {

        return new Promise( resolve =>
        {

            let element = undefined === source.object ? source : this.baseClassHelper.getObjectById( source.id )

            if( undefined !== element )
            {

                if( !this._shadowCopyExists( shareWith.studentLocalId, element.localId ) )
                {

                    let shadowCopyClass = this.baseClassHelper.get( 'shadowCopy' ),
                        shadowCopy      = {
                            elementType     : element.type,
                            referenceLocalId: element.localId,
                            referenceKey    : element.referenceKey,
                            timestamp       : element.timestamp,
                            update          : element.update,
                            studentLocalId  : shareWith.studentLocalId,
                            studentEditable : true === studentEditable
                        }

                    shadowCopyClass.create( shadowCopy )
                    return resolve()

                }
                else
                {
                    return resolve()
                }

            }
            else
            {
                return resolve()
            }

        } )

    }

    /**
     * _shadowCopyId
     * @param localId
     * @param studentLocalId
     * @returns {string}
     * @private
     */
    _shadowCopyId( localId, studentLocalId )
    {
        return 'shad-'
               + localId + '-'
               + this.f.hashCyrB53( studentLocalId )
    }

    /**
     * _remoteShadowCopyDeletion
     * @param deletions
     * @returns {Promise<unknown>}
     * @private
     */
    _remoteShadowCopyDeletion( deletions )
    {

        return new Promise( resolve =>
        {

            if( 0 < deletions.length )
            {

                let message = {
                        method    : 'objects.deleteShadowCopyElements',
                        deleteList: deletions
                    },
                    jobId   = this.uuid.generate()

                this.queueWorker.enqueue( 'message', jobId, 'socketMessage', JSON.stringify( message ) )

                return resolve()

            }
            else
            {
                return resolve()
            }

        } )

    }

    /**
     * _shadowCopyDeletion
     * @param remotes
     * @param locals
     * @returns {Promise<unknown>}
     * @private
     */
    _shadowCopyDeletion( remotes, locals )
    {

        return new Promise( resolve =>
        {

            for( let l in locals )
            {
                this.baseClassHelper.get( locals[ l ].type )
                    .deleteShadowCopy( locals[ l ].localId )
            }

            return resolve( this._remoteShadowCopyDeletion( remotes ) )

        } )

    }

    /**
     * removeShadowCopy
     * @param element
     * @param studentAccess
     * @returns {Promise<unknown>}
     */
    removeShadowCopy( element, studentAccess )
    {
        return new Promise( resolve =>
        {

            let removables           = [],
                promises             = [],
                shadowDeletions      = [],
                localShadowDeletions = []

            if( undefined !== element.lists )
            {
                for( let l in element.lists )
                {
                    shadowDeletions.push( this._shadowCopyId( element.lists[ l ].localId, studentAccess.studentLocalId ) )
                    localShadowDeletions.push( {
                        type   : element.type,
                        localId: this._shadowCopyId( element.lists[ l ].localId, studentAccess.studentLocalId )
                    } )
                    let shadowCopy = this._getShadowCopy( studentAccess.studentLocalId, element.lists[ l ].localId )
                    if( false !== shadowCopy )
                    {
                        removables.push( shadowCopy )
                    }
                }
            }
            else
            {
                shadowDeletions.push( this._shadowCopyId( element.localId, studentAccess.studentLocalId ) )
                localShadowDeletions.push( {
                    type   : element.type,
                    localId: this._shadowCopyId( element.localId, studentAccess.studentLocalId )
                } )
                let shadowCopy = this._getShadowCopy( studentAccess.studentLocalId, element.localId )
                if( false !== shadowCopy )
                {
                    removables.push( shadowCopy )
                }
            }

            if( 0 < removables.length )
            {
                let shadowCopyClass = this.baseClassHelper.get( 'shadowCopy' )
                for( let r in removables )
                {
                    promises.push( () =>
                    {
                        return shadowCopyClass.delete( removables[ r ].localId ) //, removables[ r ].remoteId )
                    } )
                }
            }

            if( 0 < shadowDeletions.length )
            {
                promises.push( () =>
                {
                    return this._shadowCopyDeletion( shadowDeletions, localShadowDeletions )
                } )
            }

            this.f.promiseRunner( promises )
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    /**
     * shareWithStudent
     * @param studentId
     * @param elms
     * @param shareDependencies
     * @returns {Promise<unknown>}
     */
    shareWithStudent( studentId, elms, shareDependencies )
    {

        return new Promise( resolve =>
        {

            this.shareHelper.resolveShareWithStudent( studentId )
                .then( ( shareWith ) =>
                {

                    this.dependencyWalker.resolveDependencies( elms, shareDependencies, 'add', false )
                        .then( elementList =>
                        {

                            elementList = [ ...elementList, ...elms ]

                            let promises        = [],
                                total           = elementList.length,
                                step            = 0,
                                studentEditable = shareDependencies[ 'isStudentEditable' ] === true

                            this.ui.updateProgress( total, step )

                            for( let s in shareWith )
                            {

                                for( let e in elementList )
                                {
                                    promises.push( () =>
                                    {
                                        return this.createShadowCopy( elementList[ e ], shareWith[ s ], studentEditable )
                                                   .then( () =>
                                                   {
                                                       step++
                                                       this.ui.updateProgress( total, step )
                                                   } )
                                    } )
                                }
                            }

                            this.f.promiseRunner( promises )
                                .then( () =>
                                {
                                    return resolve()
                                } )

                        } )

                } )

        } )

    }

    /**
     * unshareWithStudent
     * @param studentId
     * @param elms
     * @returns {Promise<unknown>}
     */
    unshareWithStudent( studentId, elms )
    {
        return new Promise( resolve =>
        {

            this.shareHelper.resolveShareWithStudent( studentId )
                .then( ( shareWith ) =>
                {

                    let promises = [],
                        total    = elms.length,
                        step     = 0

                    this.ui.updateProgress( total, step )

                    for( let s in shareWith )
                    {
                        for( let e in elms )
                        {
                            let element = elms[ e ]
                            promises.push( () =>
                            {

                                return this.removeShadowCopy( element, shareWith[ s ] )
                                           .then( () =>
                                           {
                                               step++
                                               this.ui.updateProgress( total, step )
                                           } )

                            } )
                        }

                    }

                    this.f.promiseRunner( promises )
                        .then( () =>
                        {
                            return resolve()
                        } )

                } )

        } )
    }

}