import AbstractShadowCopyClass
    from "@/classes/Core/Workers/SyncWorker/SyncShadowCopies/abstract/AbstractShadowCopyClass";

export default class CopyTodo extends AbstractShadowCopyClass
{

    constructor( parent )
    {

        super( parent, false, 'todo', 'CopyTodo' )
        return this

    }

}
