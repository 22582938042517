<template>
    <div :class="'audio-player'+( fullSize ? ' full' : '' )" @click="clicked">
        <template v-if="'stopped' === state">
            <div class="audio-recorder play centered"
                 @click="play">
            </div>
        </template>
        <template v-if="'playing' === state">
            <div class="audio-recorder stop centered"
                 @click="stopPlayer">
                <span class="record-timer">{{ timeleft }}</span>
            </div>
        </template>
    </div>
</template>

<script>
export default {

    name: "AudioPlayer",

    props: {
        item     : { type: String, required: true },
        fullSize : { type: Boolean, required: false },
        emitClick: { type: Boolean, required: false }
    },

    emits: [ 'mediaClicked', 'clicked' ],

    data()
    {
        return {
            state        : 'stopped',
            timeleft     : 60,
            audiorecorder: null,
            audioplayer  : null,
            data         : null,
            timerKey     : this.$core.getUuid().generate()
        }
    },

    created()
    {
        this.data = this.$props.item
    },

    methods: {

        play( event )
        {

            this.$emit( 'mediaClicked', event )
            this.state = 'playing'

            this.$core.getEventManager().dispatch( 'on-priority-audio-pause' )
            this.audioplayer = new Audio( this.data )
            this.audioplayer.preload = 'metadata'

            this.audioplayer.onloadedmetadata = () =>
            {

                this.timeleft = parseInt( this.audioplayer.duration )

                this.$core.getCoreTimer()
                    .addInterval( 'play-timer-' + this.timerKey, 1000, () =>
                    {
                        if( 0 === this.timeleft )
                        {
                            this.stopPlayer()
                        }
                        else
                        {
                            this.timeleft -= 1
                        }
                    } )

                this.audioplayer.play()

            }

        },

        stopPlayer( event )
        {

            this.$emit( 'mediaClicked', event )
            this.state = 'stopped'
            this.$core.getCoreTimer()
                .removeInterval( 'play-timer-' + this.timerKey )
            this.audioplayer.pause()
            this.audioplayer.currentTime = 0
            this.$core.getEventManager().dispatch( 'on-priority-audio-resume' )

        },

        clicked()
        {
            if( this.$props.emitClick )
            {
                this.$emit( 'clicked' )
            }
        }

    }
}
</script>