import FloatingFunctionBox from '@/classes/Core/Ui/FloatingFunctionBox'

export default class Ui
{

    constructor( core )
    {

        if( !Ui.instance )
        {

            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.database = core.getDatabase()
            this.uuid = core.getUuid()
            this.config = core.getConfig()
            this.store = core.getStore()
            this.cryptoHelper = core.getCryptoHelper()
            this.coreTimer = core.getCoreTimer()
            this.flags = core.getFlags()

            this.t = ( key, params ) =>
            {
                return core.t( key, params )
            }

            this.getState = ( key ) =>
            {
                return core.getState( key )
            }

            this.f = core.f()

            this.setState = ( key, value ) =>
            {
                core.setState( key, value )
            }
            this.reset = ( direct ) =>
            {
                core.reset( direct )
            }

            this.blockerExclusive = false
            this.updateRunning = false
            this.floatingFunctionBox = new FloatingFunctionBox( core )
            this.isOverlay = false

            this.registry = false

            this.logger.cconstructed( 'Ui:constructor', 'ui initializing...' )
            this.attachEscapeHandler()
            this.path = []

            this.selectedBackground = false

            this.viewRegister = {}

            Ui.instance = this

            this.coreTimer.addTimeout( 'ui-init', 500, () =>
            {
                this.eventManager.dispatch( 'on-ui-initialized' )
            } )

        }

        return Ui.instance

    }

    destruct()
    {

        this.floatingFunctionBox.destruct()
        this.path = []
        this.viewRegister = {}

        delete Ui.instance

    }

    /**
     * injectRegistry
     * @param registry
     */
    injectRegistry( registry )
    {
        this.registry = registry
    }

    getFloatingFunctionBox()
    {
        return this.floatingFunctionBox
    }

    reserveBlocker( enable, hash )
    {
        if( enable && !this.blockerExclusive )
        {
            this.blockerExclusive = this.f.hash( 'wurst' )
            return this.blockerExclusive
        }
        if( !enable && hash === this.blockerExclusive )
        {
            this.blockerExclusive = false
            return true
        }
        return false
    }

    showBlocker( title, text, mode, locked, exclusive )
    {

        if( this.blockerExclusive === false || exclusive === this.blockerExclusive )
        {

            mode = ( undefined === mode ? 'processing' : mode )

            this.eventManager
                .dispatch( 'ui-blocker-showBlocker',
                    {
                        mode  : mode,
                        title : title,
                        text  : text,
                        locked: locked
                    } )

        }

    }

    updateProgress( total, done )
    {

        this.eventManager
            .dispatch( 'ui-blocker-activateProgressbar' )

        this.eventManager
            .dispatch( 'ui-blocker-updateProgress',
                {
                    total: total,
                    done : ( done < total ? done : total )
                } )

    }

    blockerText( text, exclusive )
    {
        if( this.blockerExclusive === false || exclusive === this.blockerExclusive )
        {

            this.eventManager
                .dispatch( 'ui-blocker-textUpdate', text )

        }
    }

    blockerMessage( message, exclusive )
    {
        if( this.blockerExclusive === false || exclusive === this.blockerExclusive )
        {

            this.eventManager
                .dispatch( 'ui-blocker-message', message )

        }
    }

    hideBlocker( exclusive )
    {
        if( this.blockerExclusive === false || exclusive === this.blockerExclusive )
        {
            if( !this.updateRunning )
            {
                this.unlockBlocker( exclusive )
                this.eventManager
                    .dispatch( 'ui-blocker-hideBlocker' )
            }
        }
    }

    unlockBlocker( exclusive )
    {

        if( !this.updateRunning && this.blockerExclusive === false
            || exclusive === this.blockerExclusive )
        {
            this.eventManager
                .dispatch( 'ui-blocker-unlock' )
        }
    }

    showModalDialog( mode, title, message, buttons, multi )
    {
        this.modalOpen = true
        this.eventManager.dispatch( 'ui-modal-dialog', {
            mode   : mode,
            title  : title,
            message: message,
            buttons: buttons,
            multi  : multi
        } )
    }

    share( objectList )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            type      : 'share',
            readyKey  : readyKey,
            objectList: objectList
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    imageZoom( image )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            type    : 'imageZoom',
            readyKey: readyKey,
            image   : image
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    showLabelManager( deletionAllowed, selection, selectionAllowed )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            type            : 'labelManager',
            readyKey        : readyKey,
            deletionAllowed : deletionAllowed,
            selection       : selection,
            selectionAllowed: selectionAllowed !== undefined ? selectionAllowed : true
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    shareWithStudents( objectList )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            type      : 'shareWithStudents',
            readyKey  : readyKey,
            objectList: objectList
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    showExtendedStudentFilter( onlyUnassigned )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            type          : 'extendedStudentFilter',
            registry      : this.registry,
            readyKey      : readyKey,
            onlyUnassigned: onlyUnassigned
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    showPin()
    {

        let readyKey = this.uuid.generate()

        let setup = {
            readyKey: readyKey,
            type    : 'pin'
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

    }

    showCameraOverlay( caption, noCloseCall )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            readyKey   : readyKey,
            type       : 'camera',
            caption    : caption,
            noCloseCall: noCloseCall || false
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    showOverlay( setup )
    {

        let readyKey = this.uuid.generate()

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    showStatsOverlay( item )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            item: item,
            type: 'statistics'
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    showMediaGallery( id )
    {

        let readyKey = this.uuid.generate()

        let setup = {
            type: 'mediaGallery',
            id  : id
        }

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    hideModalDialog()
    {
        this.modalOpen = false
        this.eventManager.dispatch( 'ui-hide-modal-dialog' )
    }

    setPageMessage( title, message, classname, active )
    {
        active = ( undefined === active ? true : active )

        this.eventManager
            .dispatch( 'ui-set-page-message',
                {
                    active   : active,
                    title    : title,
                    message  : message,
                    classname: classname,
                    timeout  : 'error' === classname ? 45000 : 15000
                } )

    }

    /* eslint-disable */

    clearSecondFactorEvents()
    {
        this.eventManager.remove( '2fa-authorized' )
        this.eventManager.remove( '2fa-failed' )
    }

    requestSecondFactor( tokenResult, result )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let setup = {
                type  : '2fa',
                token : tokenResult,
                result: result
            }
            this.eventManager.dispatch( 'ui-show-overlay', setup )

            this.eventManager.add( '2fa-authorized', () =>
            {
                this.clearSecondFactorEvents()
                return resolve()
            } )

            this.eventManager.add( '2fa-failed', () =>
            {
                this.clearSecondFactorEvents()
                return reject()
            } )

        } )

    }

    hidePageMessage()
    {
        this.eventManager
            .dispatch( 'ui-remove-page-message' )
    }

    setOnlineStatus( status, counter, reference, lockTimeout )
    {

        this.eventManager
            .dispatch( 'ui-set-online-status',
                {
                    status     : status.charAt( 0 ).toUpperCase() + status.slice( 1 ),
                    counter    : counter,
                    reference  : reference,
                    lockTimeout: lockTimeout
                } )

    }

    readBackground( style )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let backgrounds = {
                'baby-blue'   : 'baby-blue',
                'black-beauty': 'black-beauty',
                carrots       : 'carrots',
                cat           : 'cat',
                chalkboard    : 'chalkboard',
                cutndraw      : 'cut-n-draw',
                fish          : 'fish',
                floral        : 'floral',
                greenish      : 'greenish',
                'lose-papers' : 'lose-papers-wallpaper',
                love          : 'love',
                maths         : 'maths',
                basic         : 'paperstack2',
                pencils       : 'pencils',
                'pink-lady'   : 'pink-lady',
                rabbit        : 'rabbit',
                'blue-science': 'science',
                summer        : 'summer',
                wallpaper     : 'tapete',
                unicorns      : 'unicorns',
                white         : 'white',
                abstract      : 'abstract',
                flowers       : 'flowers',
                flowers_ananas: 'flowers_ananas',
                flowers_dark  : 'flowers_dark',
                smiles        : 'smiles',
                personal      : 'personal',
                newyork       : 'newyork',
                xmas          : 'xmas'
            }

            let id = backgrounds[ style ]
            this.database.read( 'backgrounds', id )
                .then( result =>
                {
                    return resolve( result )
                } )
                .catch( error =>
                {
                    return reject( error )
                } )

        } )

    }

    backgroundFileType( id )
    {

        switch( id )
        {
            case 'abstract':
            case 'carrots':
            case 'love':
            case 'cat':
            case 'fish':
            case 'floral':
            case 'smiles':
            case 'wallpaper':
            case 'summer':
            case 'rabbit':
            case 'xmas':
                return 'webp'
            case 'unicorns':
            case 'flowers_ananas':
                return 'svg+xml'
            case 'personal':
                return 'plain'
            default:
                return 'webp'
        }

    }

    updateBackground( override )
    {

        let style   = override || 'basic',
            bodyElm = document.querySelector( 'body' ),
            cssOnly = [ 'quarters-blue', 'quarters-red', 'quarters-green', 'quarters-orange', 'quarters-black-white' ]

        this.database.read( 'settings', 'backgroundStyle' )
            .then( result =>
            {

                if( undefined !== result
                    && null !== result )
                {

                    style = override || result

                }

                if( style === this.selectedBackground
                    && style !== 'personal' )
                {
                    return
                }

                this.selectedBackground = style
                if( -1 < cssOnly.indexOf( style ) )
                {
                    bodyElm.style.backgroundImage = null
                    bodyElm.className = 'background-' + style
                }
                else
                {
                    this.readBackground( style ).then( background =>
                        {

                            let appliedStyle = this.backgroundFileType( style )
                            if( bodyElm !== null )
                            {
                                if( 'plain' === appliedStyle )
                                {
                                    bodyElm.style.backgroundImage = 'url(' + background + ')'
                                }
                                else
                                {
                                    bodyElm.style.backgroundImage = 'url(data:image/' + appliedStyle + ';base64,' + background + ')'
                                }
                                bodyElm.className = ''
                                bodyElm.classList.add( 'background-' + style )
                            }

                        } )
                        .catch( () =>
                        {
                            bodyElm.className = ''
                            bodyElm.classList.add( 'background-fallback' )
                        } )
                }

            } )
            .catch( () =>
            {

                if( bodyElm !== null )
                {
                    bodyElm.className = ''
                    bodyElm.classList.add( 'background-fallback' )
                }

            } )

    }

    vibrate()
    {

        navigator.vibrate = navigator.vibrate || navigator.webkitVibrate || navigator.mozVibrate || navigator.msVibrate
        if( navigator.vibrate )
        {
            navigator.vibrate( [ 200 ] )
        }

    }

    showForm( viewItem, objectType, formMethod, editItem, organizerSlot )
    {

        let readyKey = this.uuid.generate()

        this.eventManager.dispatch( 'ui-show-overlay', {
            type         : 'form',
            viewItem     : viewItem,
            objectType   : objectType,
            formMethod   : formMethod,
            editItem     : editItem,
            readyKey     : readyKey,
            organizerSlot: organizerSlot || undefined
        } )

        return readyKey

    }

    showFreeForm( title, titleKey, editFields, trackChanges )
    {

        this.formOpen = true
        let readyKey = this.uuid.generate()

        this.eventManager.dispatch( 'ui-show-overlay', {
            type        : 'freeform',
            title       : title,
            titleKey    : titleKey,
            fields      : editFields,
            trackChanges: trackChanges,
            readyKey    : readyKey
        } )

        return readyKey

    }

    showElementZoom( item, changeKey )
    {

        let readyKey = this.uuid.generate()

        this.eventManager.dispatch( 'ui-show-overlay', {
            type     : 'elementZoom',
            changeKey: changeKey,
            item     : item,
            readyKey : readyKey
        } )

        return readyKey

    }

    showImageOverlay( setup )
    {

        let readyKey = this.uuid.generate()
        setup.readyKey = readyKey

        this.eventManager.dispatch( 'ui-image-overlay', setup )

        return readyKey

    }

    updateZoom( zoomId, element, changeKey )
    {
        this.eventManager.dispatch( 'ui-update-zoom', {
            changeKey: changeKey,
            item     : element,
            zoomId   : zoomId
        } )
    }

    showCalendar( editItem, noClone )
    {

        let readyKey = this.uuid.generate()

        this.eventManager.dispatch( 'ui-show-overlay', {
            type    : 'calendar',
            editItem: editItem,
            readyKey: readyKey,
            noClone : noClone
        } )

        return readyKey

    }

    showMessage( setup )
    {

        let readyKey = this.uuid.generate()

        setup.type = 'message'
        setup.readyKey = readyKey

        this.eventManager.dispatch( 'ui-show-overlay', setup )

        return readyKey

    }

    hideCalendar()
    {
        this.eventManager.dispatch( 'ui-hide-calendar' )
    }

    hideForm()
    {
        this.formOpen = false
        this.eventManager.dispatch( 'ui-hide-overlay' )
    }

    resizeTouchable( idLeft, idRight, swiped )
    {

        let containerLeft = document.querySelector( '#' + idLeft ),
            elmRight      = document.querySelector( '#' + idRight ),
            buttonsRight  = elmRight.querySelectorAll( '.button' ),
            count         = buttonsRight.length,
            buttonHeight  = 0

        if( 0 < count )
        {
            for( let i = 0; i < count; i += 2 )
            {

                let button = buttonsRight[ i ],
                    style  = getComputedStyle( button )

                buttonHeight += parseInt( style.height ) + parseInt( style.marginTop ) + ( parseInt( style.marginBottom ) * 3 )

            }
        }

        if( swiped )
        {
            containerLeft.style.minHeight = buttonHeight + 'px'
        }
        else
        {
            this.coreTimer.addTimeout( 'ui-containerleft', 450, () =>
            {
                containerLeft.style.minHeight = '0px'
            } )
        }

    }

    isTouchDevice()
    {
        return ( ( 'ontouchstart' in window ) || ( navigator.maxTouchPoints > 0 ) || ( navigator.msMaxTouchPoints > 0 ) )
    }

    addReferencePath( path )
    {
        this.path.push( path )
    }

    removeReferencePath( path )
    {
        let index = this.path.indexOf( path )
        this.path.splice( index, 1 )
    }

    isReferencedPath( path )
    {
        return -1 < this.path.indexOf( path )
    }

    hasDetailView( type )
    {
        switch( type )
        {
            case 'note':
            case 'todo':
                return false
            default:
                return true
        }
    }

    getCakeIcon()
    {
        return '&#x1f382;'
    }

    getPalmIcon()
    {
        return '&#x1f3dd;'
    }

    getMultiIcon()
    {
        return '&raquo;'
    }

    getClockIcon( hours )
    {

        if( undefined !== hours
            && !isNaN( hours ) )
        {

            if( hours > 12 )
            {
                hours -= 12
            }

            hours -= 1

            let timeHex = hours.toString( 16 )
            return '&#x1f55' + timeHex + ';'

        }

        return '&#x1f4c5;'

    }

    removeSelection()
    {

        let fakeInput = document.createElement( 'input' )
        fakeInput.setAttribute( 'type', 'text' )
        document.querySelector( 'body' ).appendChild( fakeInput )

        fakeInput.focus()
        fakeInput.blur()

        fakeInput.parentNode.removeChild( fakeInput )

    }

    performUpdate( show, message, hash )
    {

        if( 'local' === this.config.branch || 'build-240' === this.config.branch )
        {
            return
        }

        this.store.commit( 'setUpdateRunning', 'perform' )
        this.logger.clog( 'Ui:performUpdate', '%c new version available: actually installed: ' + this.config.build + ', available: ' + this.store.getters.updateVersion, 'background:#feacac; color:#99000;' )
        this.updateRunning = true

        message = message || {
            mode  : 'update',
            title : this.t( 'ui-blocker-software-update' ),
            text  : this.t( 'ui-blocker-software-update-text' ),
            locked: true
        }

        let exclusive = hash || this.reserveBlocker( true )

        if( show )
        {
            this.showBlocker( message.title, message.text, message.mode, message.locked, exclusive )
        }
        else
        {
            this.blockerMessage( message, exclusive )
        }

        let hashed = btoa( window.location.href )
        let updater = document.querySelector( '#updater' )
        updater.src = '/?_update=' + this.store.getters.updateVersion + '&reference=' + hashed + '&tsmp=' + Date.now()

        this.delay( () =>
        {

            this.blockerText( this.t( 'ui-blocker-software-update-text-2' ), exclusive )
            updater.src = '/?_update=' + this.store.getters.updateVersion + '&reference=' + hashed + '&tsmp=' + Date.now()

            this.delay( () =>
            {

                this.hideBlocker( exclusive )

                this.delay( () =>
                {

                    this.setState( 'update-available', false )
                    window.location.reload( true )

                }, 500 )

            }, 4500 )

        }, 5000 )

    }

    handleKeyPress( event )
    {

        if( event.key === 'Escape' || event.key === 'Esc' || event.keyCode === 27 )
        {

            let elm = document.querySelectorAll( '.close' )
            for( let e in elm )
            {
                if( elm[ e ] instanceof Element )
                {
                    elm[ e ].click()
                    return
                }
            }

            let cnc = document.querySelectorAll( '.cancelbutton' )
            for( let e in cnc )
            {
                if( cnc[ e ] instanceof Element )
                {
                    cnc[ e ].click()
                    return
                }
            }

            let formCancel = document.querySelectorAll( 'button.cancel' )
            for( let e in formCancel )
            {
                if( formCancel[ e ] instanceof Element )
                {
                    formCancel[ e ].click()
                    return
                }
            }

        }
    }

    attachEscapeHandler()
    {
        let body = document.querySelector( 'body' )
        if( null !== body )
        {
            document.onkeyup = ( evnt ) => this.handleKeyPress( evnt )
        }
        else
        {
            this.coreTimer.addTimeout( 'ui-attachescapehandler', 450, () =>
            {
                this.attachEscapeHandler()
            } )
        }
    }

    overlayActive()
    {
        return this.isOverlay
    }

    formOverlayActive()
    {
        let elm = document.querySelector( '.default-form-overlay' )
        return null !== elm
    }

    decodeHtml( html )
    {

        let txt = document.createElement( 'textarea' )
        txt.innerHTML = html
        return txt.value

    }

    registerView( changeKey, view, objectType, viewItem, parentChangeKey )
    {

        this.viewRegister[ changeKey ] = {
            changeKey : changeKey,
            view      : view,
            objectType: objectType,
            viewItem  : viewItem,
            parentKey : parentChangeKey
        }

    }

    updateView( changeKey, view, objectType, viewItem )
    {
        this.registerView( changeKey, view, objectType, viewItem )
    }

    handleViewKeyChange( oldKey, newKey, parentChangeKey )
    {
        let register = JSON.parse( JSON.stringify( this.viewRegister[ oldKey ] ) )
        this.unregisterView( oldKey )
        this.registerView( newKey, register.view, register.objectType, register.viewItem, parentChangeKey )
    }

    unregisterView( changeKey )
    {
        delete this.viewRegister[ changeKey ]
    }

    addParentChangeKey( changeKey, parentKey )
    {
        this.viewRegister[ changeKey ].parentKey = parentKey
    }

    delay( callback, timeout )
    {
        let key = 'ui-delay-' + Date.now()
        this.coreTimer.addTimeout( key, timeout, () =>
        {
            callback()
        } )
        return key
    }

    testUserMessage()
    {
        this.setPageMessage( 'Testnutzer',
            '<strong>Dem Testnutzer steht diese Funktion leider nicht zur Verfügung.</strong>',
            'error', true )
    }

}
