<template>
    <div v-if="ready"
         v-show="true === $core.settings().getSetting( 'listSetupTemplatePreview' ) || 'gallery' === scope">
        <div class="list-preview-wrapper">
            <div class="list-preview-blocker">
            </div>
            <div class="list-preview-frame"
                 :key="template.id">
                <div :class="'list '+template.color">
                    <ListDetails v-if="ready"
                                 :changeKey="previewItem.localId+'-'+id"
                                 :previewMode="true"
                                 :listFilter="false"
                                 :item="previewItem"/>
                </div>
            </div>
            <div class="list-preview-title">
                <span v-if="undefined !== caption">
                    <span style="font-weight:bold;">{{ caption }}</span>
                </span>
                <template v-else>
                    <span v-if="'vendor' === type" style="font-weight:bold;">
                        Vorlage von entzettelt
                    </span>
                    <span v-if="'own' === type" style="font-weight:bold;">
                        Deine Liste: {{ template.listname }}
                    </span>
                    <span v-if="'foreign' === type" style="font-weight:bold;">
                        Liste aus dem Kollegium: {{ template.listname }}
                    </span>
                    <span v-if="'archived' === type">
                        Archivierte Liste: {{ template.listname }}
                    </span>
                </template>
                <br/><span>{{
                    $core.t( 'list-type-' + template.type )
                }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import ListDetails from "@/components/elements/viewItems/listViews/ListDetails";

export default {

    name      : "ListPreviewFrame",
    components: { ListDetails },
    props     : {
        type    : { Type: String, required: true },
        template: { Type: Object, required: true },
        caption : { Type: String, required: false },
        scope   : { Type: String, required: false, default: 'gallery' }
    },

    emits: [ 'update' ],

    data()
    {
        return {
            ready      : false,
            setup      : false,
            columns    : [],
            values     : {},
            previewItem: false,
            updating   : false
        }
    },

    watch: {
        id: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( newValue !== oldValue )
                {
                    this.prepare()
                }
            }
        }
    },

    computed: {
        id()
        {
            return this.$props.template.value + '_' + this.$props.template.type
        }
    },

    mounted()
    {
        this.$core.getEventManager().dispatch( 'formUseTemplate', false )
    },

    methods: {

        prepare()
        {
            switch( this.$props.type )
            {
                case 'vendor':
                    this.loadTemplateById( this.id )
                    break
                case 'own':
                case 'archived':
                case 'foreign':
                    this.prepareOwnListAsTemplate()
                    break
            }
        },

        getListPreviewElement()
        {

            let list    = this.setup,
                sublist = JSON.parse( JSON.stringify( list ) )

            list.localId = this.$core.getUuid().generate()

            delete list.remoteId
            delete list.remoteUpdateTimestamp
            delete list.timestamp
            delete list.update
            delete list.duedate

            list.originalListType = list.listType

            sublist.localId = this.$core.getUuid().generate()
            sublist.columns = [
                {
                    type    : 'fixed',
                    values  : 'students',
                    caption : 'Schüler:in',
                    filter  : 'class',
                    filterBy: 'demo-class-object'
                },
                ...this.columns ]

            list.color = 'red'
            sublist.values = this.values
            list.lists = []
            list.lists.push( sublist )

            return list

        },

        getRandom( min, max )
        {
            min = Math.ceil( min )
            max = Math.floor( max )
            return Math.floor( Math.random() * ( max - min + 1 ) ) + min
        },

        /*eslint-disable*/
        randomizeValues()
        {

            let students = this.$core.getBaseClassHelper().get( 'class' ).getById( 'demo-class-object' ).students,
                i        = 0

            this.values = {}

            for( let c in this.columns )
            {
                i++
                for( let s in students )
                {

                    let valueKey = students[ s ]
                                   + '___' + this.$core.getSanitizers().cleanId( this.columns[ c ].caption )
                                   + '___' + i,
                        value

                    switch( this.columns[ c ].type )
                    {
                        case 'rateplantselector':
                        case 'rateflexselector':
                            value = this.getRandom( 0, 4 )
                            break
                        case 'rateselector':
                        case 'ratestarselector':
                        case 'ratesmileselector':
                            value = this.getRandom( 0, 5 )
                            break
                        case 'checkbox':
                            value = this.getRandom( 0, 1 )
                            break
                        case 'textbox':
                            value = this.$core.t( 'short_ipsum_' + this.getRandom( 1, 7 ) )
                            break
                        case 'textarea':
                            value = this.$core.t( 'lorem_ipsum_' + this.getRandom( 1, 7 ) )
                            break
                    }

                    this.values[ valueKey ] = value

                }
            }

        },

        getFormFieldsForList( listType )
        {
            return this.$core.getBaseClassHelper()
                       .get( 'list', listType )
                       .getListFormFields( listType )

        },

        stripValues( item )
        {
            let result = JSON.parse( JSON.stringify( item ) )
            delete result.values
            delete result.lists[0].values
            return JSON.stringify( result )
        },

        prepareOwnListAsTemplate()
        {

            if( !this.updating )
            {
                this.updating = true

                let lists = this.$core.getBaseClassHelper()
                                .get( 'list' )
                                .getContainer( this.$props.template.localId ),
                    list  = lists[ 0 ]

                let setup = JSON.parse( JSON.stringify( undefined !== list.lists ? list.lists[ 0 ] : list ) )
                if( undefined !== setup.columns )
                {
                    setup.isOwn = true
                    this.setup = setup
                    setup.columns.shift()
                    this.columns = setup.columns
                    this.randomizeValues()
                    this.previewItem = this.getListPreviewElement()
                    this.ready = true

                    this.$nextTick()
                        .then( () =>
                        {

                            this.$emit( 'update', this.getFormFieldsForList( this.previewItem.listType ) )
                            this.updating = false
                            let out = this.stripValues( this.previewItem )
                            this.$core.getEventManager().dispatch( 'formUseTemplate', out )

                        } )
                }
                else
                {
                    this.updating = false
                }

            }

        },

        loadTemplateById( id )
        {

            this.ready = false
            this.$core
                .getTemplates()
                .load( id )
                .then( list =>
                {

                    this.setup = this.$core.f().deref( list )
                    this.columns = list.columns

                    this.randomizeValues()
                    this.previewItem = this.getListPreviewElement()

                    this.$core.getEventManager().add( 'after-form-field-update', () =>
                    {
                        this.$nextTick()
                            .then( () =>
                            {
                                this.updating = false
                                this.$core.getEventManager().dispatch( 'formUseTemplate', this.stripValues( this.previewItem ) )
                            } )
                    } )

                    this.ready = true

                    this.$nextTick()
                        .then( () =>
                        {

                            let emittable = this.getFormFieldsForList( this.previewItem.listType )
                            for( let e in emittable )
                            {
                                if( emittable[e].ref === 'columns' )
                                {
                                    emittable[e].value = this.columns
                                }
                            }

                            this.$emit( 'update', JSON.parse( JSON.stringify( emittable ) ) )

                        } )

                } )
        }
    }
}
</script>