export default class afterFetchColleague
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
        this.store = core.getStore()
    }

    afterFetch( colleagues )
    {
        return new Promise( resolve => {

            for( let c in colleagues )
            {

                switch( colleagues[c].state )
                {
                    case 2:
                        colleagues[c].displayName = colleagues[c].lastname+', '+colleagues[c].firstname
                        break
                    default:
                        switch( parseInt( colleagues[c].initiator ) )
                        {
                            case parseInt( this.store.getters.idUser ):
                                colleagues[c].displayName =  'unbestätigte Einladung'
                                break
                            default:
                                colleagues[c].displayName =  'unbestätigte Einladung von '+colleagues[c].lastname+', '+colleagues[c].firstname
                                break
                        }
                }

            }

            return resolve( colleagues )

        })
    }

}