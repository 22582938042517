/*eslint-disable*/
import CalculationHelper from "@/objects/stateMergers/list/helper/CalculationHelper";
import { reactive }      from "vue";

export default class afterUpdateList
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
        this.tableCalculation = core.getTableCalculation()
        this.eventManager = core.getEventManager()
        this.f = core.f()
        this.parent = parent

        this.calculationHelper = new CalculationHelper( this.f, core.settings(), this.tableCalculation, core.getUuid() )
    }

    _createContainer( list )
    {

        let container = {}

        for( let key in list )
        {
            if( key !== 'values' )
            {
                container[ key ] = list[ key ]
            }
        }

        container.isDemoContent = list.isDemoContent
        container.tsmpEnd = list.timestamp
        container.tsmpStart = list.timestamp
        container.labels = list.labels
        container.lasteditor = list.lasteditor
        container.lists = []
        container.lists.push( list )

        return container

    }

    fixMeta( container, list )
    {
        if( container.columns !== list.columns )
        {
            container.columns = list.columns
        }
        if( container.labels !== list.labels )
        {
            container.labels = list.labels
        }
        if( container.tsmpEnd < list.timestamp )
        {
            container.tsmpEnd = list.timestamp
        }
        if( container.tsmpStart > list.timestamp )
        {
            container.tsmpStart = list.timestamp
        }
        if( container.update < list.update )
        {
            container.update = list.update
            container.lasteditor = list.lasteditor
        }
        if( container.duedate !== list.duedate )
        {
            container.duedate = list.duedate
        }
        if( container.isDone !== list.isDone )
        {
            container.isDone = list.isDone
        }
    }

    updateBaseMeta( container, list )
    {

        let baseMeta = [
            'archiveKey', 'archived', 'color', 'distribution', 'listname', 'scoreModel'
        ]

        for( let b in baseMeta )
        {
            let key = baseMeta[ b ]
            if( container[ key ] !== list[ key ] )
            {
                container[ key ] = list[ key ]
            }
        }

    }

    _appendRemoteId( list, container )
    {
        return undefined !== list.remoteId && undefined === container.remoteId
    }

    isListForToday( timestamp )
    {

        let now  = new Date(),
            then = new Date( timestamp )

        return ( now.getFullYear() === then.getFullYear()
                 && now.getMonth() === then.getMonth()
                 && now.getDate() === then.getDate() )

    }

    calculate( listContainer )
    {


        let listSummary = this.calculationHelper.calculateListSummary( listContainer )

        if( null !== listSummary )
        {
            return {
                summary: listSummary,
                columns: this.calculationHelper.calculateColumns( listContainer ),
                details: {} //
            }
        }

        return null

    }

    checkCollectors( cache )
    {

        let todo = []

        for( const [ localId, list ] of cache )
        {
            if( 'combilist' === list.listType )
            {
                if( -1 === todo.indexOf( localId ) )
                {
                    todo.push( localId )
                    break
                }
            }
        }

        if( 0 < todo.length )
        {
            this.eventManager.dispatch( 'onRecollectCollectors-list' )
        }

    }

    checkEventTriggers( lists )
    {

        let triggerNeeded = false

        for( let l in lists )
        {
            for( let c in lists[l].columns )
            {
                if( 'competenceSelector' === lists[l].columns[c].type )
                {
                    triggerNeeded = true
                    break
                }
            }
        }

        if( triggerNeeded )
        {
            setTimeout( () => {
                this.eventManager.dispatch( 'on-list-update-containing-competenceSelector' )
            }, 2000 )
        }

    }

    afterUpdate( lists, cache, skipArchived )
    {

        return new Promise( ( resolve, reject ) =>
        {

            let containers  = {},
                recalculate = {},
                result      = [],
                updates     = []

            for( let c of cache.keys() )
            {
                containers[ cache.get( c ).referenceKey ] = cache.get( c )
            }

            for( let c in containers )
            {
                let container = lists.find( o => o.referenceKey === c )
                if( undefined !== container )
                {
                    for( let l in lists )
                    {
                        this.updateBaseMeta( containers[ c ], lists[ l ] )
                    }
                }
            }

            for( let l in lists )
            {
                if( undefined !== lists[ l ].lists )
                {
                    containers[ lists[ l ].referenceKey ] = lists[ l ]
                    for( let ll in lists[ l ].lists )
                    {
                        this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ].lists[ ll ] )
                    }
                }
                else
                {

                    if( undefined === containers[ lists[ l ].referenceKey ] )
                    {
                        containers[ lists[ l ].referenceKey ] = this._createContainer( lists[ l ] )
                        recalculate[ lists[ l ].referenceKey ] = containers[ lists[ l ].referenceKey ]
                    }
                    else
                    {

                        let found = false

                        for( let ll in containers[ lists[ l ].referenceKey ].lists )
                        {
                            if( containers[ lists[ l ].referenceKey ].lists[ ll ].localId === lists[ l ].localId )
                            {
                                found = true
                                if( true === this._appendRemoteId( lists[ l ], containers[ lists[ l ].referenceKey ] ) )
                                {
                                    containers[ lists[ l ].referenceKey ].remoteId = lists[ l ].remoteId
                                }
                                containers[ lists[ l ].referenceKey ].lists[ ll ] = lists[ l ]
                                this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                                recalculate[ lists[ l ].referenceKey ] = containers[ lists[ l ].referenceKey ]
                            }
                        }

                        if( !found )
                        {
                            if( true === this._appendRemoteId( lists[ l ], containers[ lists[ l ].referenceKey ] ) )
                            {
                                containers[ lists[ l ].referenceKey ].remoteId = lists[ l ].remoteId
                            }
                            containers[ lists[ l ].referenceKey ].lists.push( lists[ l ] )
                            this.fixMeta( containers[ lists[ l ].referenceKey ], lists[ l ] )
                            if( this.isListForToday( lists[ l ].timestamp ) )
                            {
                                containers[ lists[ l ].referenceKey ].listForToday = lists[ l ]
                            }
                            recalculate[ lists[ l ].referenceKey ] = containers[ lists[ l ].referenceKey ]
                        }

                    }

                    updates.push( { localId: lists[ l ].localId, referenceKey: lists[ l ].referenceKey } )

                }

            }

            for( let key in containers )
            {

                let calculationResult = this.calculate( containers[ key ] )
                containers[ key ]._tc = reactive( calculationResult )
                result.push( containers[ key ] )

            }

            this.checkCollectors( cache )
            this.checkEventTriggers( lists )

            return resolve( result )

        } )
    }

}