<script>
export default {

    name: 'DraggableFunctions',

    emits: [ 'dragging' ],

    data()
    {
        return {
            inDragMode: false
        }
    },

    /*eslint-disable*/
    created()
    {

        this.nullError = ( e ) =>
        {
            return true
        }

        this.$core.getLogger()
            .disableErrorHandler()

        window.onerror = ( e ) =>
        {
            return true
        }

        window.addEventListener( 'error', this.nullError, true )
        window.addEventListener( 'unhandledrejection', this.nullError, true )

    },
    /*eslint-enable*/

    beforeUnmount()
    {

        window.removeEventListener( 'error', this.nullError, true )
        window.removeEventListener( 'unhandledrejection', this.nullError, true )

        this.$core.getLogger()
            .setupErrorHandler()

    },

    methods: {

        _getList( localId, referenceKey )
        {

            let element = this.$core.getBaseClassHelper()
                              .get( 'list' )
                              .getListById( referenceKey, localId, 'cache' )

            return element

        },

        /*eslint-disable*/
        /**
         * moveDate
         *   prepares needed parameters for a moved date
         * @param element
         * @param targetTsmp
         * @returns {{date: string, enddate: string, start, end}}
         */
        moveDate( element, targetTsmp )
        {

            let tsmpStart = this.$core.getFriendlyTimestamp().toTimestamp( element.date, element.start ),
                tsmpEnd   = this.$core.getFriendlyTimestamp().toTimestamp( '' !== element.enddate ? element.enddate : element.date, element.end ),
                tsmpDiff  = tsmpEnd - tsmpStart,
                s         = this.$core.getFriendlyTimestamp().formattedDate( targetTsmp ),
                newStart  = this.$core.getFriendlyTimestamp().toTimestamp( s, element.start ),
                startDate = s,
                endDate   = ''

            if( '' !== element.enddate
                || '' !== element.end )
            {
                let e = this.$core.getFriendlyTimestamp().formattedDate( newStart + tsmpDiff )
                if( '' !== element.enddate )
                {
                    endDate = e
                }
            }

            let setup = {
                date   : startDate,
                start  : element.start,
                enddate: endDate,
                end    : element.end
            }

            return setup

        },

        /**
         * handleElementMove
         *   moves an element from a given time to a given new time (timestamps)
         * @param event
         */
        handleElementMove( event )
        {

            if( event.sourceTsmp !== event.targetTsmp )
            {

                let element = this.$core.getBaseClassHelper()
                                  .getObjectById( event.elementId )

                if( undefined !== event.referenceKey )
                {
                    element = this._getList( event.elementId, event.referenceKey )
                }

                if( undefined !== element )
                {

                    let timestamp = parseInt( event.targetTsmp )

                    this.$core.getUi().showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'element-is-being-moved', this.$core.t( 'object-type-' + element.type ) ) )

                    let baseClass = this.$core.getBaseClassHelper()
                                        .get( element.type )

                    switch( element.type )
                    {
                        case 'date':
                            if( element.isBirthday )
                            {
                                this.$core.getUi().setPageMessage( 'Das funktioniert leider nicht', 'Geburtstage können nicht über den Kalender verschoben werden', 'error', true )
                                this.$core.getUi().delay( () =>
                                {
                                    this.$core.getUi().hideBlocker()
                                }, 500 )
                                return
                            }
                            else
                            {
                                let setup = this.moveDate( element, timestamp )
                                for( let s in setup )
                                {
                                    element[ s ] = setup[ s ]
                                }
                            }
                            break
                        case 'todo':
                            element.duedate_friendly = this.$core.getFriendlyTimestamp().formattedDate( timestamp )
                            break
                        default:
                            element.timestamp = timestamp
                            element.timestampForced = true
                            break
                    }

                    this.$core.getEventManager()
                        .add( 'storable-after-updated-' + element.localId, () =>
                        {
                            this.$core.getUi().delay( () =>
                            {
                                this.$core.getUi().hideBlocker()
                            }, 500 )
                        } )

                    baseClass.update( element, element.localId, element.remoteId, element.timestamp, element.localKey, undefined, true )

                }

            }

        },

        addToSlot( localId, referenceKey, slotId, targetTsmp )
        {

            let element = this.$core.getBaseClassHelper().getObjectById( localId )
            if( undefined === element )
            {
                element = this._getList( localId, referenceKey )
            }

            if( undefined !== element && undefined !== slotId )
            {

                this.$core.getUi().showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'element-is-being-added-to-slot', this.$core.t( 'object-type-' + element.type ) ) )

                let baseClass = this.$core.baseClass( element.type ),
                    slot      = {
                        organizerSlotId: slotId,
                        timestamp      : targetTsmp
                    }

                element.organizerSlot = JSON.stringify( slot )

                this.$core.getEventManager()
                    .add( 'storable-after-updated-' + element.localId, () =>
                    {
                        this.$core.getUi().delay( () =>
                        {
                            this.$core.getUi().hideBlocker()
                        }, 500 )
                    } )

                baseClass.update( element, element.localId, element.remoteId, element.timestamp, element.localKey, undefined, true )

            }

        },

        removeSlot( localId, referenceKey, slotId )
        {

            let element = this.$core.getBaseClassHelper().getObjectById( localId )
            if( undefined === element )
            {
                element = this._getList( localId, referenceKey )
            }

            if( undefined !== element && undefined !== slotId )
            {

                this.$core.getUi().showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'element-is-being-removed-from-slot', this.$core.t( 'object-type-' + element.type ) ) )

                let baseClass = this.$core.baseClass( element.type ),
                    slot      = {}

                element.organizerSlot = JSON.stringify( slot )

                this.$core.getEventManager()
                    .add( 'storable-after-updated-' + element.localId, () =>
                    {
                        this.$core.getUi().delay( () =>
                        {
                            this.$core.getUi().hideBlocker()
                        }, 500 )
                    } )

                baseClass.update( element, element.localId, element.remoteId, element.timestamp, element.localKey, undefined, true )

            }

        },

        /**
         * checkMove
         *   check, if move action is supported
         * @param event
         */
        checkMove( event )
        {

            let target = event.to.id,
                id     = target.replace( 'draggable-planner-', '' )

            this.$core.getEventManager().dispatchIndexed( 'on-drag-move', id )

        },

        handleLabelMove( event )
        {

            if( undefined !== event.to
                && undefined !== event.to.id
                && undefined !== event.from
                && undefined !== event.from.id )
            {

                if( -1 < event.to.id.indexOf( 'dropzone-deletion' ) )
                {
                    let source      = event.from.id.split( '-' ),
                        sourceDate  = parseInt( source[ 2 ] ),
                        sourceIndex = parseInt( source[ 3 ] )

                    this.performLabelDrop( sourceDate, sourceIndex )

                }
                else
                {

                    let source      = event.from.id.split( '-' ),
                        target      = event.to.id.split( '-' ),
                        sourceDate  = parseInt( source[ 2 ] ),
                        sourceIndex = parseInt( source[ 3 ] ),
                        targetDate  = parseInt( target[ 2 ] ),
                        targetIndex = parseInt( target[ 3 ] )

                    let valueElm = document.querySelector( '#draggable-label-' + targetDate + '-' + targetIndex )
                    if( null !== valueElm )
                    {

                        this.performLabelMove( valueElm.value, sourceDate, sourceIndex, targetDate, targetIndex )

                    }

                }

            }

        },

        performOrganizerContentsMove( reference, contentIndex, sourceDate, sourceIndex, targetDate, targetIndex )
        {

            let organizerContents    = this.$core.getBaseClassHelper()
                                           .get( 'organizercontent' )
                                           .getById( reference ),
                targetPlannerElement = document.querySelector( '#planner-slot-' + targetDate + '-' + targetIndex ) //document.querySelector( '#draggable-contents-planner-' + targetDate + '-' + targetIndex )

            if( undefined !== organizerContents )
            {

                for( let s in organizerContents.slots )
                {

                    if( organizerContents.slotDisplay[ s ].slotIndex === sourceIndex
                        && organizerContents.slotDisplay[ s ].date === sourceDate
                        && parseInt( s ) === parseInt( contentIndex ) )
                    {
                        organizerContents.slotDisplay[ s ].slotIndex = targetIndex
                        organizerContents.slotDisplay[ s ].date = targetDate
                    }
                }

                if( null !== targetPlannerElement )
                {
                    targetPlannerElement.scrollTop = 0;
                }

                this.$core.getEventManager()
                    .add( 'storable-after-updated-' + organizerContents.localId, () =>
                    {
                        this.$core.getUi().delay( () =>
                        {
                            this.$core.getUi().hideBlocker()
                            this.$emit( 'refresh' )
                        }, 500 )
                    } )


                this.$core.getBaseClassHelper()
                    .get( 'organizercontent' )
                    .update( organizerContents, organizerContents.localId, organizerContents.remoteId, organizerContents.timestamp, organizerContents.localKey, undefined, true )

            }

        },

        moreSlotsToMove( reference, targetDate, targetIndex )
        {

            let organizerContents = this.$core.getBaseClassHelper()
                                        .get( 'organizercontent' )
                                        .getById( reference )

            for( let s in organizerContents.slotDisplay )
            {
                if( organizerContents.slotDisplay[s].date >= targetDate )
                {
                    return true
                }
            }

            return false

        },

        handleOrganizerContentsMove( event )
        {

            if( undefined !== event.from
                && undefined !== event.from.id )
            {

                let source       = event.from.id.split( '-' ),
                    target       = event.to.id.split( '-' ),
                    ref          = event.item.id.split( '_' ),
                    reference    = ref[ 1 ],
                    contentIndex = ref[ 2 ],
                    sourceDate   = parseInt( source[ 3 ] ),
                    sourceIndex  = parseInt( source[ 4 ] ),
                    targetDate   = parseInt( target[ 3 ] ),
                    targetIndex  = parseInt( target[ 4 ] )

                if( sourceDate !== targetDate || sourceIndex !== targetIndex )
                {

                    if( this.moreSlotsToMove( reference, targetDate, targetIndex ) )
                    {

                        this.resolveOrganizerSlotMove( reference, sourceDate, sourceIndex, targetDate, targetIndex )
                        //this.performOrganizerContentsMove( reference, contentIndex, sourceDate, sourceIndex, targetDate, targetIndex )
                    }
                    else
                    {
                        this.$core.getUi()
                            .showBlocker( this.$core.t( 'generic-please-wait' ), '...' )
                        this.performOrganizerContentsMove( reference, contentIndex, sourceDate, sourceIndex, targetDate, targetIndex )
                    }
                }

            }

        },

        getTimestampFromId( id )
        {
            let todo = [ 'organizer', 'planner' ],
                temp = id
            for( let t in todo )
            {
                temp = temp.replace( 'draggable-' + todo[ t ] + '-', '' )
            }
            return temp
        },

        handleMove( event )
        {

            this.inDragMode = false
            this.$emit( 'dragging', false )

            if( this.$props.draggableLabels === false )
            {
                if( -1 < event.to.id.indexOf( 'draggable-contents' ) )
                {
                    this.handleOrganizerContentsMove( event )
                    return
                }
            }

            if( this.$props.draggableLabels === true )
            {
                this.handleLabelMove( event )
                return
            }

            this.$core.getUi()
                .delay( () =>
                {
                    this.skipPlannerDay = false
                }, 500 )

            let sourceId,
                targetId,
                sourceTsmp,
                targetTsmp,
                localId,
                referenceKey,
                action,
                id,
                temp

            id = event.item.id.replace( 'element-', '' )

            this.$core.getEventManager().dispatchIndexed( 'on-drag-end', id )

            temp = id.split( '___' )
            localId = temp[ 0 ]
            referenceKey = temp[ 1 ] !== undefined && temp[ 1 ] !== 'undefined' ? temp[ 1 ] : undefined

            if( -1 < event.to.id.indexOf( 'slot-' )
                && -1 < event.from.id.indexOf( 'slot-' ) )
            {
                action = 'slot-to-slot'
            }
            if( -1 < event.to.id.indexOf( 'slot-' )
                && -1 === event.from.id.indexOf( 'slot-' ) )
            {
                action = 'cal-to-slot'
            }
            if( -1 === event.to.id.indexOf( 'slot-' )
                && -1 < event.from.id.indexOf( 'slot-' ) )
            {
                action = 'slot-to-cal'
            }
            if( -1 === event.to.id.indexOf( 'slot-' )
                && -1 === event.from.id.indexOf( 'slot-' ) )
            {
                action = 'cal-to-cal'
            }

            switch( action )
            {
                case 'cal-to-slot':
                case 'slot-to-slot':
                    temp = event.to.id.replace( 'slot-', '' ).split( '___' )
                    targetId = temp[ 0 ]
                    targetTsmp = temp[ 1 ]
                    this.addToSlot( localId, referenceKey, targetId, targetTsmp )
                    break
                case 'slot-to-cal':
                    temp = event.to.id.replace( 'slot-', '' ).split( '___' )
                    targetId = temp[ 0 ]
                    this.removeSlot( localId, referenceKey, targetId )
                    break
                case 'cal-to-cal':
                    sourceId = event.item.id.replace( 'element-', '' )
                    targetId = undefined
                    sourceTsmp = this.getTimestampFromId( event.from.id )
                    targetTsmp = this.getTimestampFromId( event.to.id )

                    this.$core.getLogger().clog( 'MixinDraggable:handleMove', '(cal-to-cal) moving', localId, 'ref', referenceKey, 'from', sourceTsmp, 'to', targetTsmp )

                    this.handleElementMove( {
                        sourceTsmp  : sourceTsmp,
                        targetTsmp  : targetTsmp,
                        elementId   : localId,
                        referenceKey: referenceKey
                    } )
                    break

            }

        },

        handleDragStart( event )
        {

            let target = event.target.to || event.to.id

            if( undefined !== target )
            {

                this.$emit( 'dragging', true )
                let id = target.replace( 'draggable-planner-', '' )

                this.$core.getEventManager().dispatchIndexed( 'on-drag-move', id )
                this.skipPlannerDay = true
                this.inDragMode = true

            }

        }

    }
}
</script>