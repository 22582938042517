<template>
    <Teleport to="#app">
        <div class="form-on-top competence-category-creator">
            <div class="default-form-overlay">
                <div class="default-form-container">
                    <div class="default-form-wrapper">
                        <Button type="close"
                                @clicked="close()"
                                addClass="absolute top right inverted"/>
                        <h2>{{ $core.t( 'create-new-competence-category' ) }}</h2>
                        <Form :elevated="true"
                              scope="competenceCategory"
                              :fields="$core.getBaseClassHelper()
                                      .get( 'competenceCategory' )
                                      .getFormFields()"
                              @formUpdated="$core.f().skip"
                              :eventKey
                              :buttons/>
                        <!--                                  :eventKey="nestedForm.eventKey"
                                                          :fields="nestedForm.fields"
                                                          :buttons="nestedForm.formButtons"
                                                          :editItem="nestedForm.editItem"
                                                          :organizerSlot="organizerSlot"/>-->
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script>
export default {

    name: 'CompetenceCategoryCreator',

    emits: [ 'close' ],

    data()
    {
        return {
            buttons : {},
            eventKey: this.$core.getUuid().generate()
        }
    },

    created()
    {
        this.buttons = {
            left : {
                type    : 'button',
                isCancel: true,
                caption : this.$core.getTranslation().translate( 'caption-cancel' ),
                callback: () =>
                {
                    this.close()
                }
            },
            right: {
                type    : 'submit',
                caption : this.$core.getTranslation().translate( 'caption-submit' ),
                callback: ( values, historic ) =>
                {
                    this.handleCompetenceCategorySubmit( values, historic )
                }
            }
        }
    },

    methods: {

        /*eslint-disable-next-line*/
        handleCompetenceCategorySubmit( values, historic )
        {

            this.$core.getBaseClassHelper()
                .get( 'competenceCategory' )
                .create( values )

            this.close()

        },

        close()
        {
            this.$emit( 'close' )
        }

    }

}
</script>