<template>
    <div class="activeLayout">
        <div class="xs">XS</div>
        <div class="sm">S</div>
        <div class="md">M</div>
        <div class="lg">L</div>
        <div class="phone">phone</div>
        <div class="tablet">tablet</div>
        <div class="desktop">desktop</div>
        <div class="large">large</div>
        <div class="lowheight">low height</div>
    </div>
</template>

<script>
export default {
    name: 'ActiveLayout'
}
</script>