<template>
    <TabbedContent v-if="!reference"
                   :tabs="tabs"
                   :active="tab"
                   @switched="setTab"/>
    <div class="tabbed-content">
        <PlannerViewDefault v-if="tab === 'calendar'"
                            :filterList="filterList"
                            :selectedMode="selectedMode"
                            :selectMode="selectMode"
                            :selectionState="selectionState"
                            :reference="reference"
                            @add="$emit('add')"
                            @refresh="$emit('refresh')"
                            @showForm="showForm"/>
        <PlannerViewOrganizer v-if="tab === 'organizer'"
                              :filterList="filterList"
                              :selectedMode="selectedMode"
                              :selectMode="selectMode"
                              :selectionState="selectionState"
                              :setupOpen="setupOpen"
                              :reference="reference"
                              :startTsmp="plannerStartTsmp"
                              :changeKey="changeKey"
                              @dragging="$emit( 'dragging', $event )"
                              @setupDone="$emit('setupDone')"
                              @disableFilters="$emit('disableFilters', true)"
                              @changeFilter="$emit('changeFilter', $event)"
                              @add="$emit('add')"
                              @refresh="$emit('refresh')"
                              @setContentMode="$emit( 'setContentMode', $event )"/>
    </div>
</template>
<script>
import PlannerViewDefault   from '@/components/elements/viewItems/plannerView/modes/PlannerViewDefault'
import TabbedContent        from '@/components/layout/tabs/TabbedContent'
import PlannerViewOrganizer from '@/components/elements/viewItems/plannerView/modes/PlannerViewOrganizer'

export default {

    name      : 'PlannerView',
    components: { PlannerViewOrganizer, TabbedContent, PlannerViewDefault },
    emits     : [ 'refresh', 'showForm', 'add', 'dragging', 'plannerViewModeSwitch', 'disableFilters', 'changeFilter', 'setupDone', 'setContentMode' ],

    props: {
        filterList      : { type: Object, required: false, default: undefined },
        selectedMode    : { type: String, required: false, default: 'month' },
        selectMode      : { type: Boolean, required: false, default: false },
        selectionState  : { type: Boolean, required: false, default: false },
        setupOpen       : { type: Boolean, required: false, default: false },
        reference       : { type: Object, required: false, default: undefined },
        changeKey       : { type: String, required: false, default: undefined },
        plannerViewMode : { type: String, required: false, default: undefined },
        plannerStartTsmp: { type: String, required: false, default: undefined }
    },

    data()
    {
        return {
            tabs: [
                {
                    id     : 'calendar',
                    caption: 'Kalender'
                }
            ],
            tab : 'calendar'
        }
    },

    created()
    {
        if( this.$core.license.isAllowed( 'planner_organizer' ) )
        {
            this.tabs.push(
                {
                    id     : 'organizer',
                    caption: 'Planung'
                }
            )
        }
        if( !this.$props.reference )
        {
            if( this.$core.license.isAllowed( 'planner_organizer' ) )
            {
                this.setTab( this.$props.plannerViewMode === 'default' ? this.$core.settings().getSetting( 'plannerDefaultMode' ) : this.$props.plannerViewMode )
            }
            else
            {
                this.setTab( 'calendar' )
            }
        }
    },

    methods: {

        setTab( tab )
        {
            if( 'calendar' === tab )
            {
                this.$emit( 'setContentMode', false )
            }
            this.$emit( 'setupDone' )
            this.$emit( 'disableFilters', false )
            this.tab = tab
            this.$emit( 'plannerViewModeSwitch', tab )
        },

        showForm( which )
        {
            this.$emit( 'showForm', which )
        }

    }

}
</script>