export default class afterFetchMedia
{

    constructor( core )
    {
        this.baseClassHelper = core.getBaseClassHelper()
        this.store = core.getStore()
    }

    afterFetch( media )
    {
        return new Promise( resolve => {

            for( let m in media )
            {

                if( undefined !== media[m].label )
                {
                    media[m].displayName = media[m].label + ' ('+media[m].filename +')'
                }
                else
                {
                    media[m].displayName = media[m].filename
                }

            }

            return resolve( media )

        })
    }

}