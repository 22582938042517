<template>
    <div :id="'form-element-filebox-'+refName" class="filebox">
        <input type="hidden" :id="'filebox-valid-'+refName" :value="fileId !== false ? 'valid' : 'invalid'"/>
        <template v-if="$store.getters.online === true">
            <template v-if="!isProcessing">
                <div class="file_uploader">
                    <CameraButton v-if="!display"
                        @image="handleImage"/>
                    <label v-if="!display" class="file-selector"
                           :id="'file-label-'+id"
                           :for="'file-selector-'+id">
                        <input type="file" class="file-selector"
                               :id="'file-selector-'+id"
                               @change="handleImage()"/>
                    </label>
                    <AudioRecorder v-if="!display"
                                   :emitChange="true"
                                   @change="handleImage"/>
                    <div v-if="display" class="file_preview hoverable">
                        <template v-if="-1 < display.indexOf( 'audio/mp3' )">
                            <AudioPlayer :item="display"
                                         @mediaClicked="$emit( 'mediaClicked', $event )"/>
                        </template>
                        <template v-else>
                            <img class="file-preview" :src="display"
                                 @click="handleImageZoom"/>
                        </template>
                        <Button v-if="!noDelete"
                                type="delete"
                                @click="handleDelete"/>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="file-preview-processing-align">
                    <div class="file-preview-processing"></div>
                </div>
            </template>
        </template>
        <FileBoxOfflineMessage v-if="false === $store.getters.online"/>
        <div class="clearfix"></div>
    </div>
</template>

<script>
import MixinResettableComponent from '@/mixins/MixinResettableComponent'
import FileBoxOfflineMessage    from "@/components/form/elements/FileBoxOfflineMessage";
import AudioRecorder            from "@/components/elements/defaults/AudioRecorder";
import AudioPlayer              from "@/components/elements/defaults/AudioPlayer";
import CameraButton             from "@/components/form/elements/CameraButton";

export default {

    name: 'FileBox',

    components: { CameraButton, AudioPlayer, AudioRecorder, FileBoxOfflineMessage },
    mixins    : [ MixinResettableComponent ],

    props: {
        id          : { Type: String, required: true },
        refName     : { Type: String, required: false },
        vModel      : { Type: String, required: false },
        value       : { Type: String | Object, required: false },
        reformatter : { Type: String, required: false },
        highlighted : { Type: Object, required: false, default: [] },
        eventKey    : { Type: String, required: false },
        disabled    : { Type: Boolean, required: false, default: false },
        placeholder : { Type: String, required: false },
        autocomplete: { Type: String, required: false },
        className   : { Type: String, required: false },
        next        : { Type: String, required: false },
        type        : { Type: String, required: false },
        steps       : { Type: Number, required: false },
        plain       : { Type: Boolean, required: false, default: false },
        allValues   : { Type: Object, required: false },
        unique      : { Type: Boolean, required: false },
        noDelete    : { Type: Boolean, required: false }
    },

    emits: [ 'update', 'enterPressed', 'backspacePressed', 'focussed', 'blurred', 'mediaClicked' ],

    data()
    {
        return {
            timer       : false,
            hasErrors   : false,
            display     : false,
            fileId      : false,
            fileInfo    : false,
            isProcessing: false,
            initialValue: false,
            clicked     : false
        }
    },

    computed: {
        foreignHighlighted()
        {
            return this.$props.className + ( this.hasErrors ? ' error' : '' ) + ' '
                   + ( -1 < this.$props.highlighted.indexOf( this.$props.refName ) ? 'highlighted' : '' )
        }
    },

    created()
    {
        this.mediaHelper = this.$core.getMediaHelper()
    },

    beforeUnmount()
    {
        delete this.mediaHelper
    },

    mounted()
    {

        let fileIdElm = document.querySelector( '#form-element-fileId' )
        if( null !== fileIdElm
            && 'create' !== fileIdElm.value
            && this.$core.f().valid( fileIdElm.value ) )
        {
            this.initialValue = fileIdElm.value || false
        }
        else
        {
            this.initialValue = ( ( undefined !== this.$props.value && false !== this.$props.value ) ? '' + this.$props.value : false )
        }
        this.fileId = this.initialValue !== false ? this.initialValue : false
        if( false !== this.fileId )
        {
            this.preparePreview()
        }
    },

    methods: {

        handleImage( media )
        {

            this.isProcessing = true
            this.mediaHelper.storeMedia( 'file-selector-' + this.$props.refName, media )
                .then( result =>
                {

                    this.fileId = result.response.id
                    this.update()
                    this.preparePreview()

                } )
                .catch( e =>
                {

                    this.reset()
                    switch( e )
                    {
                        case 'E_PDF_SIZE':
                            this.$core.getUi()
                                .showModalDialog( 'error',
                                    'Ooops: Deine Datei ist zu groß!',
                                    'Aktuell liegt die maximale Dateigröße für PDF-Dateien bei 10MB', [ 'defaultOk' ] )
                            break
                        default:
                            this.$core.getUi()
                                .showModalDialog( 'error',
                                    'Ooops: Das hat nicht funktioniert!',
                                    'Wir konnten das Dateiformat nicht erkennen.', [ 'defaultOk' ] )
                            break
                    }

                } )

        },

        reset()
        {
            this.display = false
            this.fileId = false
            this.mediaId = false
            this.isProcessing = false
        },

        handleDelete()
        {
            this.isProcessing = true
            this.mediaHelper.deleteFileById( this.fileId )
                .then( () =>
                {

                    this.reset()
                    this.update()

                } )
        },

        preparePreview()
        {

            this.isProcessing = true

            this.mediaHelper.getFileById( this.fileId )
                .then( result =>
                {

                    this.display = result.dataUrl
                    this.fileInfo = result
                    this.isProcessing = false
                    this.update()

                } )
                .catch( () =>
                {
                    this.display = false
                    this.fileInfo = false
                    this.isProcessing = false
                } )
        },

        handleImageZoom( event )
        {
            this.$emit( 'mediaClicked', event )
            this.$core.getUi()
                .imageZoom( this.fileInfo )
        },

        populateFormFields()
        {

            let filenameElm = document.querySelector( '#form-element-filename' ),
                mimetypeElm = document.querySelector( '#form-element-mimetype' ),
                fileIdElm   = document.querySelector( '#form-element-fileId' ),
                fileSizeElm = document.querySelector( '#form-element-fileSize' )

            if( null !== filenameElm )
            {
                filenameElm.value = this.fileInfo.fileName
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-filename', this.fileInfo.fileName )
            }
            if( null !== mimetypeElm )
            {
                mimetypeElm.value = this.fileInfo.format
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-mimetype', this.fileInfo.format )
            }
            if( null !== fileIdElm )
            {
                fileIdElm.value = this.fileId
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-fileId', this.fileId )
            }
            if( null !== fileSizeElm )
            {
                fileSizeElm.value = this.fileInfo.size
                this.$core.getEventManager().dispatch( 'on-hidden-fill-form-element-fileSize', this.fileInfo.size )
            }

        },

        update()
        {

            this.$emit( 'update', this.$props.refName, 'fileBox', 'update', undefined, ( false !== this.fileId ? this.fileId : undefined ) )
            this.populateFormFields()

        },


    }

}
</script>