<template>
    <div :class="'tab-container'+( inline === true ? ' inline' : '' )+( embedded === true ? ' embedded' : '' )"
         :id="'tab-container-'+tabId"
         :key="'tabContainer-'+ownId">
        <div class="tab-space">
            <transition-group appear :name="$core.settings().getTransition( 'crossfade' )">
                <div v-if="leftVisible" :key="tabId+'-left'" class="tab-button left" @click="applyScroll( 'left' )">
                    &#9664;
                </div>
                <div v-if="rightVisible" :key="tabId+'-right'" class="tab-button right"
                     @click="applyScroll( 'right' )">
                    &#9654;
                </div>
            </transition-group>
            <ul @scroll="handleScroll" :id="tabId">
                <template v-for="tab in tabs" :key="'tab-'+tab.id">
                    <Tab v-if="( 'archive' !== tab.id
                                || false === $core.settings().getSetting( 'archiveCachingDisabled' ) )
                                 && $core.getLicense().isUnrestricted( 'tab', tab.id )"
                         :id="tab.id"
                         :caption="getCaption( tab.caption )"
                         :class="( tab.id === activeTab ? 'active' : '' )"
                         :active="tab.id === activeTab"
                         :bubble="tab.bubble"
                         @click="setActive( tab.id )" :anchor="tabId"/>
                </template>
                <li class="tab button" :id="id" v-if="showAdder">
                    <Button type="add"/>
                </li>
            </ul>
        </div>
    </div>
    <div class="clearfix"></div>
</template>

<script>
import Tab    from '@/components/layout/tabs/Tab'
import Button from '@/components/elements/defaults/Button'

export default {
    name      : 'TabbedContent',
    components: { Button, Tab },
    props     : {
        'tabs'           : { Type: Object, reqiured: true },
        'active'         : { Type: String, required: true },
        'additionalClass': { Type: String, required: false, default: '' },
        'inline'         : { Type: Boolean, required: false, default: false },
        'embedded'       : { Type: Boolean, required: false, default: false },
        'showAdder'      : { Type: Boolean, required: false, default: false },
        'callback'       : { Type: Function, required: false, default: undefined }
    },

    emits: [ 'switched' ],

    data()
    {
        return {
            ownId       : this.$core.getUuid().generate(),
            tabId       : false,
            leftVisible : false,
            rightVisible: false,
            activeTab   : false
        }
    },

    watch: {
        active: {
            immediate: true,
            handler( oldTab, newTab )
            {
                if( oldTab !== newTab )
                {
                    this.activeTab = '' + this.$props.active
                }
            }
        },
        tabs: {
            immediate: true,
            handler()
            {
                this.handleScroll()
            }
        }
    },

    created()
    {
        this.activeTab = '' + this.$props.active
        this.tabId = 'tab-space-ul-' + this.$core.getUuid().generate()
    },

    mounted()
    {
        this.handleScroll()
    },

    methods: {

        handleScroll()
        {

            let elm = document.querySelector( '#' + this.tabId )
            if( null !== elm )
            {

                let boundaries = document.querySelector( '#tab-container-' + this.tabId )

                if( elm.scrollLeft > 10 )
                {
                    this.leftVisible = true
                }
                else
                {
                    this.leftVisible = false
                }

                let lastElm = elm.lastElementChild
                if( null !== lastElm )
                {
                    let maxX = elm.offsetWidth - lastElm.offsetWidth

                    if( elm.scrollLeft < ( maxX - 10 ) && elm.scrollWidth > boundaries.offsetWidth )
                    {
                        this.rightVisible = true
                    }
                    else
                    {
                        this.rightVisible = false
                    }
                }

            }

        },

        applyScroll( dir )
        {

            let elm = document.querySelector( '#' + this.tabId )
            let scrollWidth = elm.offsetWidth - 200

            switch( dir )
            {
                case 'left':
                    elm.scrollBy( {
                        left    : ( scrollWidth * -1 ),
                        behavior: 'smooth'
                    } )
                    break
                case 'right':
                    elm.scrollBy( {
                        left    : scrollWidth,
                        behavior: 'smooth'
                    } )
                    break
            }
        },

        setActive( tabId )
        {
            if( tabId !== this.activeTab )
            {
                this.activeTab = tabId
                this.$emit( 'switched', tabId )
            }
        },

        getCaption( caption )
        {
            if( -1 === caption.indexOf( 't:' ) )
            {
                return caption
            }
            let temp = caption.split( 't:' )
            return this.$core.t( temp[ 1 ] )
        }

    }
}
</script>