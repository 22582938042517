import { reactive } from "vue";

export default class SettingsWorker
{

    constructor( core )
    {

        if( !SettingsWorker.instance )
        {

            this.config = core.getConfig()
            this.store = core.getStore()
            this.logger = core.getLogger()
            this.client = core.getClient()
            this.db = core.getDatabase()
            this.flags = core.getFlags()
            this.eventManager = core.getEventManager()
            this.coreTimer = core.getCoreTimer()
            this.queueWorker = core.getQueueWorker()
            this.friendlyTimestamp = core.getFriendlyTimestamp()

            this.eventManager.add( 'on-max-classname-length', ( length ) =>
            {
                this.setSetting( 'maxClassNameLength', length )
            } )

            this.map = reactive( new Map() )

            this.flush()

            this.ready = false

            this.initialize()

            SettingsWorker.instance = this

        }

        return SettingsWorker.instance

    }

    destruct()
    {
        delete SettingsWorker.instance
    }

    flush()
    {

        this.map = reactive( new Map() )

        this.settings = {
            version  : this.config.version,
            branch   : this.config.branch,
            verbosity: this.config.verbosity,
            baseUrl  : this.config.baseUrl
        }

        this.lastStoreTimer = null
        this.fixedTsmp = -1

    }

    initialize()
    {

        this.getSettings()
            .then( settings =>
            {

                let all    = this.getAll(),
                    result = {}

                for( let k in all )
                {
                    result[ k ] = all[ k ]
                }

                for( let n in settings )
                {
                    result[ settings[ n ].key ] = settings[ n ].item
                }

                this.settings = result
                this.settings[ 'sortingDirections' ] = this.sortingFix( 'sortingDirections', this.settings[ 'sortingDirections' ] )
                this.settings[ 'colorSequence' ] = this.sequenceFix( 'colorSequence', this.settings[ 'colorSequence' ] )
                this.settings[ 'dashboardSequence' ] = this.dashboardSequenceFix( 'dashboardSequence', this.settings[ 'dashboardSequence' ] )

                for( let s in this.settings )
                {
                    this.map.set( s, this.settings[ s ] )
                }

                this.ready = true
                this.eventManager.dispatch( 'update-selected-theme' )
                this.eventManager.dispatch( 'on-settingsworker-ready' )
                this.eventManager.dispatch( 'on-settings-mountable', this )

            } )

    }

    isReady()
    {
        return this.ready === true
    }

    awaitReady()
    {
        return new Promise( resolve =>
        {

            if( this.isReady() )
            {
                return resolve()
            }
            else
            {
                this.coreTimer
                    .addTimeout( 'settings-await-ready', () =>
                    {
                        return resolve( this.awaitReady() )
                    }, 300 )
            }

        } )
    }

    getAllSettings()
    {
        return new Promise( resolve =>
        {

            if( this.ready )
            {
                return resolve( this.settings )
            }
            else
            {
                this.eventManager.append( 'on-settingsworker-ready', () =>
                {
                    return resolve( this.settings )
                } )
            }

        } )

    }

    getDefaultSetting( key )
    {

        switch( key )
        {
            case 'archiveCachingDisabled':
                return false
            case 'animations':
                return true
            case 'multiFilter':
                return false
            case 'openAfterCreate':
                return true
            case 'scrollToTop':
                return false
            case 'sortingDirections':
                return {
                    notes                        : [
                        [ 'timestamp', 'descending' ],
                        [ 'update', 'descending' ],
                        [ 'pinned', 'descending' ]
                    ],
                    todos                        : [
                        [ 'duedate', 'ascending' ],
                        [ 'pinned', 'descending' ],
                        [ 'done_timestamp', 'ascending' ]
                    ],
                    office                       : [
                        [ 'tsmpEnd', 'descending' ],
                        [ 'color', 'ascending' ]
                    ],
                    students                     : [
                        [ 'firstname', 'ascending' ],
                        [ 'lastname', 'ascending' ]
                    ],
                    classes                      : [
                        [ 'classname', 'ascending' ]
                    ],
                    yeargroups                   : [
                        [ 'groupname', 'ascending' ]
                    ],
                    dates                        : [
                        [ 'timestamp', 'descending' ]
                    ],
                    groups                       : [
                        [ 'groupname', 'ascending' ]
                    ],
                    teams                        : [
                        [ 'teamname', 'ascending' ]
                    ],
                    lists                        : [
                        [ 'timestamp', 'descending' ],
                        [ 'update', 'descending' ]
                    ],
                    calendar                     : [
                        [ 'timestamp', 'ascending' ]
                    ],
                    organizer                    : [
                        [ 'title', 'ascending' ]
                    ],
                    organizercontent             : [
                        [ 'title', 'ascending' ]
                    ],
                    statistics                   : [
                        [ 'timestamp', 'ascending' ]
                    ],
                    colleagues                   : [
                        [ 'firstname', 'ascending' ],
                        [ 'lastname', 'ascending' ]
                    ],
                    messages                     : [
                        [ 'timestamp', 'descending' ]
                    ],
                    media                        : [
                        [ 'label', 'ascending' ]
                    ],
                    labels                       : [
                        [ 'caption', 'ascending' ]
                    ],
                    competenceCategories         : [
                        [ 'title', 'ascending' ]
                    ],
                    competences                  : [
                        [ 'level', 'ascending' ]
                    ],
                    competenceCategoriesTemplates: [
                        [ 'title', 'ascending' ]
                    ],
                    competenceTemplates          : [
                        [ 'level', 'ascending' ]
                    ]
                }
            case 'ratingStars':
            case 'ratingSmiles':
                return 5
            case 'colorCount':
                return 9
            case 'allColors':
                return [ 'blue', 'green', 'red', 'white', 'yellow', 'mint', 'orange', 'grey', 'pink' ]
            case 'colorSequence':
                return [ 'white', 'green', 'red', 'blue', 'yellow', 'mint', 'orange', 'grey', 'pink' ]
            case 'colorTranslation':
                return {
                    'white' : 'weiß',
                    'green' : 'grün',
                    'red'   : 'rot',
                    'blue'  : 'blau',
                    'yellow': 'gelb',
                    'mint'  : 'mintgrün',
                    'orange': 'orange',
                    'grey'  : 'grau',
                    'pink'  : 'pink'
                }
            case 'dashboardSequence':
                return [ 'students', 'classes', 'groups', 'yeargroups', 'office', 'documents', 'competences', 'notes', 'todos', 'izel', 'planner',
                         'colleagues', 'messages', 'secretary', 'studentsaccesses', 'settings', 'experimental' ]
            case 'pageVisibility':
                return {
                    'experimental'    : true,
                    'students'        : true,
                    'classes'         : true,
                    'groups'          : true,
                    'yeargroups'      : false,
                    'documents'       : true,
                    'competences'     : true,
                    'office'          : true,
                    'notes'           : true,
                    'todos'           : true,
                    'izel'            : false,
                    'planner'         : true,
                    'colleagues'      : true,
                    'messages'        : true,
                    'secretary'       : true,
                    'settings'        : true,
                    'studentsaccesses': true
                }
            case 'forceOffline':
            case 'remoteDebugging':
                return false
            case 'calendarShowNotes':
            case 'calendarShowLists':
            case 'calendarShowTodos':
                return true
            case 'calendarDefaultMode':
                return 'week'
            case 'combineShares':
                return false
            case 'studentColorModel':
                return 'gender'
            case 'studentFileShowOnlyFilled':
                return false
            case 'studentFileAddTests':
                return true
            case 'studentFileAddFlexListsWithScores':
                return true
            case 'defaultCountry':
                return 'DE'
            case 'callAnonymous':
                return false
            case 'tutorialDone':
                return false
            case 'tutorialStep':
                return 0
            case 'tutorialRunning':
                return false
            case 'showSyncState':
                return false
            case 'scoreModel':
                return [ 'basic' ]
            case 'backgroundStyle':
                return 'basic'
            case 'printQuality':
                return 2
            case 'printingBlackAndWhite':
                return true
            case 'printingLandscapeOnly':
                return false
            case 'printingDefaultAlign':
                return 'center'
            case 'printingTestsAlwaysCutmarked':
                return false
            case 'printingWithPunchMarks':
                return false
            case 'lastPatch':
                return 19700101
            case 'patches':
                return {}
            case 'useOldListsAsTemplates':
                return true
            case 'useArchivedListsAsTemplates':
                return false
            case 'showSharesByDefault':
                return false
            case 'showOfflineWarnings':
                return true
            case 'listGroupLimit':
                return 10
            case 'elementCount':
                return -1
            case 'maxClassNameLength':
                return -1
            case 'csvMapping':
                return {}
            case 'debugInfo':
                return false
            case 'supportMode':
                return false
            case 'notificationsAllowed':
                return false
            case 'notificationDeliveryTime':
                return 30
            case 'textAreaWidth':
                return 400
            case 'notificationsForDates':
            case 'notificationsForTodos':
            case 'notificationsForBirthdays':
            case 'notificationsForRecalllists':
            case 'notificationsForMessages':
                return true
            case 'alwaysOn':
                return true
            case 'registryCount':
                return {
                    students  : 0,
                    classes   : 0,
                    groups    : 0,
                    lists     : 0,
                    notes     : 0,
                    todos     : 0,
                    colleagues: 0
                }
            case 'organizerSetup':
                return false
            case 'plannerDefaultMode':
                return 'calendar'
            case 'selectedTheme':
                return 'default'
            case 'scoreOverviewShowComments':
                return true
            case 'scoreOverviewShowPoints':
                return true
            case 'scoreOverviewShowListType':
                return false
            case 'promotionKey':
                return null
            case 'listCalcType-scoreBox':
            case 'listCalcType-numberbox':
            case 'listCalcType-checkbox':
            case 'listCalcType-rateselector':
            case 'listCalcType-testItem':
                return 'default'
            case 'listDefaultCalcType-threewaytoggle':
                return 'distinct'
            case 'listDefaultCalcType-checkbox':
                return 'count'
            case 'listDefaultCalcType-numberbox':
            case 'listDefaultCalcType-scoreBox':
            case 'listDefaultCalcType-rateselector':
            case 'listDefaultCalcType-testItem':
                return 'average'
            case 'listSetupTemplatesFirst':
            case 'listSetupTemplatePreview':
                return true
            case 'darkerColorMode':
                return false
            case 'genderColorMale':
            case 'genderColorFemale':
            case 'genderColorNonBinary':
                return 'default'
            case 'isNewUser':
                return true
            case 'showNewUserWelcome':
                return null
            case 'demoContentsCreated':
                return false
            case 'liveCollabMode':
                return true
            case 'persistFilterStates':
                return false
            case 'lastUsedCamera':
                return -1
            case 'listEditorForceFullscreen':
                return false
            case 'snowfall':
                return true
            case 'competenceCompleteRatio':
                return 50
            case 'competenceShowFullTreeInListSummary':
                return true
        }

    }

    getSetting( key )
    {
        return this.settings[ key ] !== undefined ? this.settings[ key ] : this.getDefaultSetting( key )
    }

    getAll()
    {

        let settings = {}

        let keys = [
            'archiveCachingDisabled',
            'animations',
            'multiFilter',
            'openAfterCreate',
            'scrollToTop',
            'sortingDirections',
            'colorCount',
            'colorSequence',
            'colorTranslation',
            'combineShares',
            'calendarShowNotes',
            'calendarShowLists',
            'calendarShowTodos',
            'calendarDefaultMode',
            'plannerDefaultMode',
            'studentFileShowOnlyFilled',
            'studentFileAddTests',
            'studentColorModel',
            'defaultCountry',
            'callAnonymous',
            'showSyncState',
            'ratingStars',
            'ratingSmiles',
            'scoreModel',
            'backgroundStyle',
            'dashboardSequence',
            'remoteDebugging',
            'pageVisibility',
            'printQuality',
            'printingBlackAndWhite',
            'printingLandscapeOnly',
            'printingDefaultAlign',
            'printingTestsAlwaysCutmarked',
            'printingWithPunchMarks',
            'lastPatch',
            'useOldListsAsTemplates',
            'useArchivedListsAsTemplates',
            'listGroupLimit',
            'elementCount',
            'maxClassNameLength',
            'showOfflineWarnings',
            'showSharesByDefault',
            'csvMapping',
            'notificationsAllowed',
            'notificationDeliveryTime',
            'notificationsForDates',
            'notificationsForTodos',
            'notificationsForBirthdays',
            'notificationsForRecalllists',
            'notificationsForMessages',
            'registryCount',
            'debugInfo',
            'supportMode',
            'organizerSetup',
            'alwaysOn',
            'selectedTheme',
            'textAreaWidth',
            'scoreOverviewShowComments',
            'scoreOverviewShowPoints',
            'scoreOverviewShowListType',
            'promotionKey',
            'listCalcType-numberbox',
            'listCalcType-checkbox',
            'listCalcType-rateselector',
            'listCalcType-testItem',
            'listCalcType-scoreBox',
            'listDefaultCalcType-numberbox',
            'listDefaultCalcType-checkbox',
            'listDefaultCalcType-rateselector',
            'listDefaultCalcType-testItem',
            'listDefaultCalcType-scoreBox',
            'listSetupTemplatesFirst',
            'listSetupTemplatePreview',
            'genderColorMale',
            'genderColorFemale',
            'genderColorNonBinary',
            'darkerColorMode',
            'isNewUser',
            'showNewUserWelcome',
            'demoContentsCreated',
            'liveCollabMode',
            'persistFilterStates',
            'lastUsedCamera',
            'snowfall',
            'listEditorForceFullscreen',
            'competenceCompleteRatio',
            'competenceShowFullTreeInListSummary'
        ]

        for( let k in keys )
        {

            let key   = keys[ k ],
                value = this.getSetting( key )

            settings[ key ] = value
            this.map.set( key, value )

        }

        return settings

    }

    sortingFix( key, value )
    {

        if( 'sortingDirections' !== key )
        {
            return value
        }

        let sortings = this.getDefaultSetting( 'sortingDirections' )

        for( let s in sortings )
        {

            let sort = sortings[ s ]
            if( undefined !== value
                && undefined === value[ s ] )
            {
                value[ s ] = sort
            }

        }

        for( let k in Object.keys( value ) )
        {

            let key = Object.keys( value )[ k ]
            let newVal = []
            for( let v in value[ key ] )
            {
                if( value[ key ][ v ][ 0 ] !== 'archiveKey' )
                {
                    newVal.push( value[ key ][ v ] )
                }
            }

            value[ key ] = newVal

        }

        return value

    }

    sequenceFix( key, value )
    {

        if( 'colorSequence' !== key )
        {
            return value
        }

        let count    = 0,
            stored   = [],
            needsFix = false,
            defined  = this.getDefaultSetting( 'colorSequence' )

        if( undefined !== value
            && 0 < value.length )
        {

            for( let i = 0; i < value.length; i++ )
            {
                if( null !== value[ i ]
                    && undefined !== value[ i ]
                    && ( '' !== ( '' + value[ i ] ).trim() ) )
                {
                    if( count < defined.length )
                    {
                        stored.push( ( '' + value[ i ] ).trim() )
                        count++
                    }
                    else
                    {
                        needsFix = true
                    }
                }
            }

        }

        if( needsFix )
        {
            this.setSetting( 'colorSequence', stored, true, true )
            return stored
        }

        if( count < defined.length )
        {

            for( let d in defined )
            {
                let color = defined[ d ]
                if( -1 === stored.indexOf( color ) )
                {
                    defined.push( color )
                }
            }

            this.logger.clog( 'SettingsWorker:sequenceFix', 'adding undefined colors to sorting rules...' )
            this.setSetting( 'colorSequence', defined, true, true )
            return defined

        }

        return stored

    }

    dashboardSequenceFix( key, values )
    {

        let defaults    = this.getDefaultSetting( key ),
            insertAfter = false,
            clean       = []

        if( defaults.length !== values.length )
        {

            for( let v in values )
            {
                if( -1 < defaults.indexOf( values[ v ] ) )
                {
                    clean.push( values[ v ] )
                }
            }

            for( let d in defaults )
            {
                if( -1 === clean.indexOf( defaults[ d ] ) )
                {

                    if( undefined !== defaults[ ( d - 1 ) ] )
                    {
                        insertAfter = d
                    }

                    if( false !== insertAfter )
                    {
                        clean = [ ...clean.slice( 0, insertAfter ), defaults[ d ], ...clean.slice( insertAfter ) ]
                    }
                    else
                    {
                        clean.push( defaults[ 0 ] )
                    }

                }
            }

            return clean

        }

        return values

    }

    storeSettings()
    {
        return new Promise( ( resolve, reject ) =>
        {

            this.db
                .writeSettingsList( this.settings )
                .then( () =>
                {
                    return resolve()
                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )
    }

    setSetting( key, value, initial )
    {

        this.settings[ key ] = this.sortingFix( key, value )
        this.settings[ key ] = this.sequenceFix( key, value )

        this.map.set( key, value )

        let interval = ( 'backgroundStyle' === key ? 0 : 1000 )

        clearTimeout( this.lastStoreTimer )
        this.lastStoreTimer = setTimeout( () =>
        {
            this.storeSettings()
                .then( () =>
                {
                    if( undefined === initial )
                    {

                        this.pushSettings()

                    }
                } )
                .catch( () =>
                {
                } )
        }, interval )
    }

    pushSettings()
    {

        if( !this.flags.ready || this.flags.is( 'demouser' ) )
        {
            if( !this.flags.ready )
            {
                this.logger.clog( 'SettingsWorker:pushSettings', 'not pushing: flags not yet ready' )
            }
            else
            {
                this.logger.clog( 'SettingsWorker:pushSettings', 'not pushing: demo-account' )
            }

            return
        }

        this.logger.clog( 'SettingsWorker:pushSettings', 'synchronizing settings to remote...' )
        let settings = JSON.stringify( this.getAll() )

        let message = {
            method  : 'objects.storeSettings',
            settings: settings
        }

        this.client.request( message )
            .then( () =>
            {
                this.logger.clog( 'SettingsWorker:pushSettings', 'success' )
            } )
            .catch( () =>
            {

                setTimeout( () =>
                {

                    this.pushSettings()

                }, 10000 )
            } )

    }

    getSettings()
    {

        return new Promise( resolve =>
        {

            this.db.readSettings()
                .then( settings =>
                {

                    settings.push( {
                        key : 'version',
                        item: this.config.version
                    } )

                    return resolve( settings )

                } )

        } )

    }

    getProfile( key )
    {

        if( 'district' == key )
        {
            return 'NW'
        }
        return undefined
    }

    getTransition( transitionName )
    {
        return this.getSetting( 'animations' ) == true ? transitionName : ''
    }

}