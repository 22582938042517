<template>
    <div>
        <div class="select-wrapper" v-if="initialized">
            <select :id="'form-element-'+refName" @change="checkStyleUpdate()" @click="$core.f().skip">
                <option value="">bitte wählen...</option>
                <option v-for="country in countries"
                        :selected="country.iso2 === selected"
                        :key="'key_option_'+country.iso2"
                        :value="country.iso2">{{ country.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script>

import Countries from '@/classes/Helpers/Countries'

export default {
    name : 'CountrySelector',
    props: {
        value      : { Type: String, required: false },
        refName    : { Type: String, required: true },
        id         : { Type: String, required: false },
        validator  : { Type: String, required: false },
        reformatter: { Type: String, required: false },
        display    : { Type: String, required: false },
        editor     : { Type: String, required: false },
        styleUpdate: { Type: Boolean, required: false },
        eventKey   : { Type: String, required: false }
    },
    created()
    {
        this.init()
    },
    updated()
    {
        this.select()
    },
    mounted()
    {
        this.select()
    },
    data()
    {
        return {
            countries  : [],
            selected   : false,
            initialized: false
        }
    },
    methods: {
        init()
        {
            if( this.$core.f().valid( this.$props.value ) )
            {
                this.selected = this.$props.value
            }
            else
            {
                this.selected = this.$core.settings().getSetting( 'defaultCountry' )
            }
            this.countries = new Countries().get()
            this.initialized = true
        },
        select()
        {
            let element = document.querySelector( '#form-element-' + this.$props.refName )
            if( null !== element
                && undefined !== this.$props.value )
            {
                element.value = this.$props.value
                this.checkStyleUpdate()
            }
        },
        checkStyleUpdate()
        {

            let element = document.querySelector( '#form-element-' + this.$props.refName )

            if( null !== element )
            {
                this.$emit( 'update', this.$props.refName, 'countrySelector', 'update', undefined, element.value )
            }

        }
    }

}
</script>
