<template>
    <transition appear name="fade">
        <ListEditorMessage v-if="true !== $store.getters.online
                                         && true === $core.settings().getSetting( 'showOfflineWarnings' )"
                           :active="true"
                           classname="warning"
                           message="Du bist zur Zeit offline und siehst eventuell eine alte Version dieser Liste: Nicht synchronisierte Inhalte können dabei verlorengehen!"/>
    </transition>
</template>

<script>
import ListEditorMessage from "@/components/messages/ListEditorMessage";

export default {
    name      : "ListOfflineWarning",
    components: { ListEditorMessage }
}
</script>