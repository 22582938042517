<template>
    <div key="distri-quarters" class="distribox">
        <div v-for="k in distributionKeys" :key="'key_distri_'+k"
             class="scorebox quarters">
            <div class="ratinglabel quarters">
                <strong>{{ distributionTranslations[ k ] }}</strong>
            </div>
            <div class="scoreinput">
                <input v-if="( k + 1 ) === distributionKeys.length" type="text" class="score"
                       placeholder="Pkt." :id="'ratio_'+k"
                       value="0" disabled="disabled" @keyup="checkKey( $event, k )"/>
                <input v-else type="text" class="score" placeholder="Pkt." :id="'ratio_'+k"
                       :value="distributionScores[k]" @keyup="checkKey( $event, k )"/>
            </div>
        </div>
        <div class="scorebox quarters scoretotal">
            <div class="ratinglabel scoretotal quarters">
                <strong>&#x2211;</strong>
            </div>
            <div class="scoreinput scoretotal">
                <strong>{{ $core.getReformatter().reformat( totalScore, 'localizedScore' ) }}</strong>
            </div>
        </div>
        <div class="clearfix"></div>
        <input type="hidden" :id="'form-element-'+refName" />
    </div>
</template>

<script>
import TestDistribution from '@/components/form/elements/tests/distributionKeys/TestDistribution'

export default {
    name : 'TestDistributionQuarters',
    extends: TestDistribution,
    props: [
        'value',
        'refName',
        'reference',
        'validator',
        'reformatter',
        'display',
        'editor',
        'scores',
        'scoreModel'
    ],

    data()
    {
        return {
            distributionKeys        : [],
            distributionScores      : [],
            distributionTranslations: {
                0 : '1+',
                1 : '1',
                2 : '1-',
                3 : '1-2',
                4 : '2+',
                5 : '2',
                6 : '2-',
                7 : '2-3',
                8 : '3+',
                9 : '3',
                10 : '3-',
                11 : '3-4',
                12 : '4+',
                13: '4',
                14: '4-',
                15: '4-5',
                16: '5+',
                17: '5',
                18: '5-',
                19: '5-6',
                20: '6'
            },
            rows                    : {},
            refValue                : '',
            totalScore              : 0,
            distributionChanged     : false,
            timer                   : false,
            changeTimer             : false,
            initialized             : false
        }
    },

    created()
    {

        this.initKeys()

        if( undefined !== this.$props.scores )
        {
            this.initScores()
        }

    },

    beforeUnmount()
    {
        this.$emit( 'componentChanged' )
    },

    methods: {

        getRefName( id )
        {
            let ref = this.$props.refName.split( ',' )
            return ref[ id ]
        },
        initKeys()
        {

            let row = 0
            for( let i = 0; i < 21; i++ )
            {

                if( i > 3 )
                {
                    row = 1
                }
                if( i > 7 )
                {
                    row = 2
                }
                if( i > 11 )
                {
                    row = 3
                }
                if( i > 15 )
                {
                    row = 4
                }
                if( i > 19 )
                {
                    row = 5
                }

                if( undefined === this.rows[ row ] )
                {
                    this.rows[ row ] = []
                }

                this.distributionKeys.push( i )
                this.distributionScores.push( i < 21 ? undefined : 0 )
                this.rows[ row ].push( i )

            }

            this.initialized = true

        },
        initScores()
        {

            for( let i = 0; i < 21; i++ )
            {
                this.distributionScores[ i ] = this.$props.scores[ i ]
            }

        },
        emitChange()
        {
            this.$emit( 'change', this.$props.refName, this.distributionScores )
        },
        skip( event )
        {
            event.stopPropagation()
        }
    }
}
</script>