import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class OrganizerSlotsObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !OrganizerSlotsObjectClass.instance )
        {

            let refList = [
                'label',
                'color',
                'day',
                'slotIndex',
                'organizerId'
            ]
            
            let personalAttributesStatesList = [
                {
                    storage: 'hiding',
                    state  : 'hidden',
                    list   : 'hideLists'
                }
            ]

            super( core, 'organizerslot', refList, personalAttributesStatesList )
            this.objectType = 'organizerslot'
            this.isStudentShareable = true

            OrganizerSlotsObjectClass.instance = this
        }

        return OrganizerSlotsObjectClass.instance

    }

    getFormFields()
    {
        return [
            {
                ref        : 'label',
                caption    : 'title',
                translate  : true,
                type       : 'TextBox',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: true
            },
            {
                ref        : 'color',
                caption    : 'color',
                translate  : true,
                type       : 'ColorSelector',
                reformatter: null,
                optional   : false,
                validator  : 'color',
                styleUpdate: true
            },
            {
                ref        : 'day',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: false
            },
            {
                ref        : 'slotIndex',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: false
            },
            {
                ref        : 'organizerId',
                caption    : null,
                type       : 'Hidden',
                reformatter: null,
                optional   : false,
                validator  : 'freetext',
                styleUpdate: false
            }
        ]
    }

    getTabs()
    {
        return []
    }

    getFunctions()
    {
        return [ 'delete' ]
    }

}