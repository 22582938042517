<template>
    <div v-if="referencedOrganizer">
        <span
            :class="'reference-marker organizer '+referencedOrganizer.color+( additionalClass ? ' '+additionalClass : '' )"
            :key="'refmark-class-'+referencedOrganizer.localId" @click="detailView">
            <div class="small-icon" v-html="'&#x1f4c5; '+referencedOrganizer.displayTitle"></div>
        </span>
    </div>
</template>

<script>

import MixinOrganizerResolver from "@/components/elements/viewItems/plannerView/mixins/MixinOrganizerResolver";

export default {

    name: 'OrganizerReferenceMarker',

    props: {
        reference      : { Type: String, required: true },
        additionalClass: { Type: String, required: false }
    },

    emits : [ 'detailView' ],
    mixins: [ MixinOrganizerResolver ],

    data()
    {
        return {
            referencedOrganizer: undefined,
            timestamp          : false
        }
    },

    created()
    {
        this.referencedOrganizer = this.$core.f().deref( this.$core.getBaseClassHelper()
                                                             .get( 'organizer' )
                                                             .getById( this.$props.reference.organizerReference ) )
        this.resolveOrganizerTitle( this.referencedOrganizer )
    },

    methods: {

        detailView( event )
        {

            this.$core.f().skip( event )

        }

    }

}
</script>