<template>
    <!-- :style="'min-width: '+( false !== width ? width+'px' : '0px' )">-->
    <div class="color-filters" v-bind:class="getLegendVisible()" :id="'color-filters-'+viewId" v-if="initialized">

        <div :class="'color-filter disable marge-right '+getClassName( '__disable__' )"
             :key="'key_colorSelector_disableAll'"
             :id="'__disable__'" @click="filter( '__disable__' )"
             :title="'alles ausblenden'">
        </div>
        <div v-for="color in colors" :class="'color-filter '+getClassName( color )"
             :key="'key_colorSelector_'+getColorId( color )"
             :id="getColorId( color )" @click="filter( color )"
             :title="title">
        </div>
        <div :class="'color-filter filter-legend '+getLegendButton()" @click="toggleLegend()">
        </div>
        <div class="clearfix"></div>
        <div :id="'color-legends-'+viewId" :class="'color-legends'+getOpened()">
            <div :class="'color-legend marge-right '+getLegendVisible()">
                <span class="legend-text filterAll">alles&nbsp;ausblenden</span>
            </div>
            <div v-for="color in colors" :key="'legend-'+color" :class="'color-legend '+getLegendVisible()">
                <span class="legend-text">{{ getLegend( color ) }}</span>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
</template>

<script>

import MixinFilterState from "@/mixins/MixinFilterState";

export default {
    name : 'ColorFilter',
    props: [ 'title', 'scope', 'filterPreset', 'tab' ],

    mixins: [ MixinFilterState ],

    data()
    {
        return {
            viewId       : false,
            height       : false,
            width        : false,
            colors       : [],
            filters      : [],
            initialized  : false,
            legend       : this.$core.settings().getSetting( 'colorTranslation' ),
            colornames   : this.$core.settings().getDefaultSetting( 'colorTranslation' ),
            legendVisible: false
        }
    },

    created()
    {

        this.viewId = this.$core.getUuid().generate()

        let colors     = [ ...this.$core.settings().getSetting( 'colorSequence' ) ],
            colorCount = parseInt( this.$core.settings().getSetting( 'colorCount' ) ),
            colorArray = []

        for( let i = 0; i < colorCount; i++ )
        {
            colorArray.push( colors[ i ] )
        }

        this.colors = colorArray.reverse()
        if( undefined !== this.$props.filterPreset )
        {
            for( let p in this.$props.filterPreset )
            {
                this.filters.push( this.$props.filterPreset[ p ] )
            }
            this.$emit( 'filter', this.filters )
        }
        this.$emit( 'attach', { type: 'color', filter: this.filters } )
        this.initialized = true

    },

    mounted()
    {
        this.setDimensions()
        this.restoreFilters()
    },

    beforeUnmount()
    {
        this.$emit( 'detach', { type: 'color', filter: this.filters } )
    },

    methods: {

        setDimensions()
        {
            this.$nextTick( () =>
            {
                let view = document.querySelector( '#color-filters-' + this.viewId )
                if( null !== view )
                {
                    this.height = view.offsetHeight - 10
                    this.width = view.offsetWidth
                }
            } )
        },

        isActive( color )
        {
            return -1 < this.filters.indexOf( color )
        },

        getLegend( color )
        {

            let scope = 'base'

            if( undefined === this.$props.scope
                && undefined === this.$props.tab )
            {
                switch( this.$route.name )
                {
                    case 'notes':
                        scope = 'notes'
                        break
                    case 'classes':
                        scope = 'classes'
                        break
                    case 'groups':
                        scope = 'groups'
                        break
                    case 'todos':
                        scope = 'todos'
                        break
                    case 'office':
                        scope = 'lists'
                        break
                    case 'students':
                        scope = 'students'
                        break
                }
            }
            else
            {

                let lookup = undefined !== this.$props.scope ? this.$props.scope : this.$props.tab
                switch( lookup )
                {
                    case 'file':
                    case 'tests':
                    case 'lists':
                    case 'lists_test':
                    case 'lists_not_test':
                        scope = 'lists'
                        break
                    case 'notes':
                        scope = 'notes'
                        break
                    case 'todos':
                        scope = 'todos'
                        break
                    case 'students':
                        scope = 'students'
                        break

                }
            }

            if( undefined !== this.legend[ scope ] )
            {
                return this.legend[ scope ][ color ]
            }

            return this.colornames[ color ]

        },

        getColorId( color )
        {
            return 'color-filter-color-' + color
        },

        filter( color )
        {

            this.$core.s().count( 'clicked-color-filter' )

            let filterAll = '__disable__' === color

            if( filterAll && !this.isActive( color ) )
            {
                this.filters = [ '__disable__' ]
            }
            else
            {
                if( !filterAll && this.isActive( '__disable__' ) )
                {
                    this.filters = this.filters.filter( function( value )
                    {
                        return value !== '__disable__'
                    } )
                }
                if( this.isActive( color ) )
                {
                    this.filters = this.filters.filter( function( value )
                    {
                        return value !== color
                    } )
                }
                else
                {
                    if( this.$core.settings().getSetting( 'multiFilter' ) !== true )
                    {
                        this.filters = []
                    }
                    this.filters.push( color )
                }
            }

            this.emitFilters()

        },

        emitFilters()
        {
            let emitFilters = []
            for( let f in this.filters )
            {
                emitFilters.push( { attr: 'color', value: this.filters[ f ] } )
            }
            this.$emit( 'filter', 'color', emitFilters )
            this.storeFilterState( emitFilters )
        },

        getClassName( color )
        {
            return color + ( this.isActive( color ) === true ? ' active' : '' )
        },

        getLegendButton()
        {
            return this.legendVisible ? 'close' : 'open' //'&#x25b4;' : '&#x25be;'
        },

        getLegendVisible()
        {
            return this.legendVisible ? 'legend-visible' : ''
        },

        getOpened()
        {
            return this.legendVisible ? ' open' : ''
        },

        toggleLegend()
        {

            let view = document.querySelector( '#color-filters-' + this.viewId )
            if( !this.legendVisible )
            {

                let legends = view.querySelectorAll( '.color-legend' )
                let maxHeight = 60
                for( let l in legends )
                {
                    if( undefined !== legends[ l ].offsetHeight && legends[ l ].offsetHeight > maxHeight )
                    {
                        maxHeight = legends[ l ].offsetHeight
                    }
                }

                view.style.height = this.height + maxHeight + 'px'
            }
            else
            {
                view.style.height = this.height + 'px'
            }

            this.legendVisible = !this.legendVisible

        },

        resetFilters()
        {
            this.filters = []
            this.emitFilters()
        },

        restoreFilters()
        {

            let filters      = this.getStoredFilterState(),
                viewFiltered = false

            for( let f in filters )
            {
                if( -1 === this.filters.indexOf( filters[ f ].value ) )
                {
                    this.filters.push( filters[ f ].value )
                    viewFiltered = true
                }
            }

            if( viewFiltered )
            {
                this.emitFilters()
            }

        }

    }
}
</script>
<script setup lang="ts">
</script>