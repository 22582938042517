<template>
    <div :key="'colleague-'+item.localId" @click="$emit( 'clicked' )">
        <template v-if="item.state === 2">
            <div :class="'colleague hoverable state-'+item.state+' '+item.color+( selected ? ' selected-item' : '' )">
                <div :class="'colleague-avatar'+( !avatarSync ? ' syncing' : '')" :style="avatar"></div>
                <div class="colleague-body">
                    <div class="colleague-title">
                        <h2><strong>{{ item.lastname }}</strong>, {{ item.firstname }}</h2>
                    </div>
                    <span v-if="-1 < $store.getters.secretaryUser.indexOf( item.colleagueId )"
                          class="reference-marker student divers">
                        <strong>Admin</strong>
                    </span>
                    <span>{{ item.school }}, {{ item.school_zipcity }}</span><br/><br/>
                    <strong>hinzugefügt</strong>&nbsp;<span
                    v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.timestamp )"></span><br/>
                    <template v-if="initialized">
                        teilt <strong>{{ counts.sharedFrom.total }}</strong> Elemente mit mir | ich teile
                        <strong>{{ counts.sharedTo.total }}</strong> Elemente
                    </template>
                    <template v-else>
                        <div class="count syncing">geteilte Elemente werden ermittelt...</div>
                    </template>
                    <div class="debug">
                        <pre>
                            {{ item }}
                        </pre>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="item.initiator === undefined || parseInt( item.initiator ) === parseInt( $store.getters.idUser )"
                 :class="'colleague hoverable state-'+item.state+' '+item.color+( selected ? ' selected-item' : '' )">
                <div class="colleague-avatar"></div>
                <div class="colleague-body">
                    <div class="colleague-title">
                        <h2>Unbestätigte Einladung</h2>
                    </div>
                    <span>angefragter Vernetzungs-PIN: {{ item.inviteKey }}</span><br/><br/>
                    <strong>hinzugefügt</strong> <span
                    v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.timestamp )"></span><br/>
                </div>
            </div>
            <div v-else
                 :class="'colleague hoverable state-'+item.state+' '+item.color+( selected ? ' selected-item' : '' )">
                <div class="colleague-avatar"></div>
                <div class="colleague-body">
                    <div class="colleague-title">
                        <h2>Einladung von: <strong>{{ item.lastname }}</strong>, {{ item.firstname }}</h2>
                    </div>
                    <span>{{ item.school }}, {{ item.school_zipcity }}</span><br/><br/>
                    <strong>angefragt</strong> <span
                    v-html="$core.getFriendlyTimestamp().friendlyTimestamp( item.timestamp )"></span><br/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import MixinColleagueBaseInfo from "@/mixins/MixinColleagueBaseInfo";

export default {

    name : 'Colleague',
    props: {
        item     : { type: Object, required: true },
        selected : { type: Boolean, required: false, default: false },
        changeKey: { type: String, required: false }
    },

    mixins: [ MixinColleagueBaseInfo ],
    emits : [ 'blockFunction', 'clicked' ],

    data()
    {
        return {
            initialized: false
        }
    },

    created()
    {

        if( 1 === this.$props.item.state )
        {
            this.$core.getEventManager()
                .add( 'on-invite-accepted-' + this.$props.item.localId, ( item ) =>
                {
                    this.handleAccept( item )
                } )
        }

        this.prepareCounts()
            .then( () =>
            {
                this.initialized = true
            } )
        this.getAvatar()

        if( -1 < this.$store.getters.secretaryUser.indexOf( this.$props.item.colleagueId ) )
        {
            this.$emit( 'blockFunction', 'delete' )
        }

    },

    methods: {
        handleAccept( item )
        {

            this.$core.getUi()
                .showBlocker( this.$core.t( 'generic-please-wait' ), this.$core.t( 'invite-is-being-accepted' ) )

            this.$core.getClient()
                .request( {
                    method      : 'network.acceptColleague',
                    id_colleague: item.colleagueId
                } )
                .then( () =>
                {

                    this.$core.getUi()
                        .setPageMessage( this.$core.t( 'invite-accepted' ), this.$core.t( 'invite-accepted-message' ), 'ok', true )
                    this.$core.getUi()
                        .hideBlocker()

                } )

        }
    }

}
</script>