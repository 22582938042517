export default class ChecklistDefinition
{
    static fieldset = {
        summary : [ 'total' ],
        fieldDef: 'numeric'
    }
    
    static summary = {
        firstColumn: 'timestamp',
        columns: 'summary',
    }
    
    static summaryDisplay = {
        component: 'Checkbox'
    }
    
}