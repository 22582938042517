import nacl from 'tweetnacl'
import util from 'tweetnacl-util'
/* eslint-disable */
export default class CryptoCore
{

    /**
     * class constructor
     */
    constructor()
    {

        if( !CryptoCore.instance )
        {

            this.messageKeyLength = 255
            this.paddingString = 'PaDdInG'
            this.paddingLength = 32
            this.randomCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#_,.;!%&/öÄüÜß?*:=)($'
            this.randomBackupCharacters = 'ABCDEFGHIJKLMNPQRSTUVWXYZabcdefghkmnpqrstuvwxyz123456789'

            this.serverPublicKey = 'IsBMhBYWq4BIVeDPdcrYqSr3qWCC0yQoVdLMlshytQQ='

            CryptoCore.instance = this

        }

        return CryptoCore.instance

    }

    destruct()
    {
        delete CryptoCore.instance
    }

    _cutUTF8Passphrase( utf8 )
    {
        let result = new Uint8Array( 32 )
        for( let i = 0; i < 32; i++ )
        {
            result[ i ] = utf8[ i ]
        }
        return result
    }

    /**
     * _encryptPrivateKey
     * @param string privateKey - the "readable" private key
     * @param string passphrase - the passphrase
     * @returns object          - a key object, containing privateKey and nonce
     */
    _encryptPrivateKey( privateKey, passphrase )
    {

        passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )
        let phrase = this._cutUTF8Passphrase( util.decodeUTF8( passphrase ) )
        let nonce = nacl.randomBytes( nacl.box.nonceLength )

        let encryptedPrivateKey = nacl.secretbox( privateKey,
            nonce,
            phrase )

        return {
            privateKey: util.encodeBase64( encryptedPrivateKey ),
            nonce     : util.encodeBase64( nonce )
        }

    }

    /**
     * _decryptPrivateKey
     * @param string privateKey - the encrypted private key
     * @param string passphrase - the passphrase to unlock the key
     * @returns string          - decrypted private key
     */
    _decryptPrivateKey( privateKey, passphrase )
    {

        passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )

        let phrase = this._cutUTF8Passphrase( util.decodeUTF8( passphrase ) )
        let test = atob( privateKey )
        let privateJson = JSON.parse( test )

        let decryptedSecretKey = nacl.secretbox.open( util.decodeBase64( privateJson.privateKey ),
            util.decodeBase64( privateJson.nonce ),
            phrase )

        return decryptedSecretKey

    }


    /**
     * encryptForServer
     * @param string plaintext  - the plaintext input
     * @returns string          - base64 encoded object, containing crypted text and nonce
     */
    encryptForServer( plaintext )
    {

        return this.encryptWithPublicKey( this.serverPublicKey, plaintext )

    }

    /**
     * sign
     * @param string        plaintext  - the plaintext input
     * @param Uint8Array(64 privateKey - own private key for signature
     * @returns string                 - valid signature
     */
    sign( plaintext, privateKey )
    {

        let signedMessage = nacl.sign( util.decodeUTF8( plaintext ), privateKey )
        return signedMessage

    }

    /**
     * encrypt
     * @param string plaintext  - the plaintext input
     * @param string passphrase - the passphrase
     * @returns string          - base64 encoded object, containing crypted text and nonce
     */
    encrypt( plaintext, passphrase )
    {

        passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )

        let phrase    = this._cutUTF8Passphrase( util.decodeUTF8( passphrase ) ),
            nonce     = nacl.randomBytes( nacl.box.nonceLength ),
            encrypted = nacl.secretbox(
                util.decodeUTF8( plaintext ),
                nonce,
                phrase )

        let result = {
            crypttext: util.encodeBase64( encrypted ),
            nonce    : util.encodeBase64( nonce )
        }

        return btoa( JSON.stringify( result ) )

    }

    /**
     * decrypt
     * @param string crypttext  - the encrypted text as string
     * @param string passphrase - the passphrase
     * @returns string          - the decrypted result
     */
    decrypt( crypttext, passphrase )
    {

        try
        {

            passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )

            let phrase      = this._cutUTF8Passphrase( util.decodeUTF8( passphrase ) ),
                test        = atob( crypttext ),
                privateJson = JSON.parse( test )

            let decryptedSecretKey = nacl.secretbox.open( util.decodeBase64( privateJson.crypttext ),
                util.decodeBase64( privateJson.nonce ),
                phrase )

            if( null == decryptedSecretKey )
            {
                return null
            }

            return util.encodeUTF8( decryptedSecretKey )

        }
        catch( e )
        {
            return false
        }

    }

    /**
     * generateRendomString
     * @param int length        - the wanted length of the random string
     * @param boolean useBackupChars - use the backup character set (without O, o, 0, j, i, l)
     * @returns string          - a random string of the given length
     */
    generateRandomString( length, useBackupChars )
    {

        var randomString = ''

        for( var i = 0; i < length; i++ )
        {
            switch( useBackupChars )
            {
                case true:
                    randomString += this.randomBackupCharacters.charAt( Math.floor( Math.random() * this.randomBackupCharacters.length ) )
                    break
                default:
                    randomString += this.randomCharacters.charAt( Math.floor( Math.random() * this.randomCharacters.length ) )
            }
        }

        return randomString

    }

    padPassphrase( passphrase )
    {
        return passphrase.padEnd( this.paddingLength, this.paddingString )
    }

    /**
     * generateKeyPair
     * @param string passphrase     - the passphrase, the keypair shall be secured with
     * @returns object              - object, containing pulic(string) and private key (string)
     */
    generateKeyPair( passphrase )
    {

        passphrase = passphrase.padEnd( this.paddingLength, this.paddingString )

        let ephemeralKeyPair = nacl.box.keyPair(),
            backup           = this.generateRandomString( 16, true ),
            myPrivateKey     = this._encryptPrivateKey( ephemeralKeyPair.secretKey, passphrase ),
            myBackupKey      = this._encryptPrivateKey( ephemeralKeyPair.secretKey, backup ),
            jsonPrivate      = JSON.stringify( myPrivateKey ),
            jsonBackup       = JSON.stringify( myBackupKey )

        let keyObject = {
            public   : util.encodeBase64( ephemeralKeyPair.publicKey ),
            private  : btoa( jsonPrivate ),
            backup   : btoa( jsonBackup ),
            backupKey: backup
        }

        return keyObject

    }

    /**
     * generateSignature
     * @returns object              - personal signature set, containing public and private key
     */
    generateSignature()
    {
        return nacl.sign.keyPair()
    }

    /**
     * generateBackupKey
     * @returns object              - object, containing pulic(string) and private key (string)
     */
    generateBackupKey( secretKey )
    {

        let backup = this.generateRandomString( 12, true )
        let myBackupKey = this._encryptPrivateKey( secretKey, backup )
        let jsonBackup = JSON.stringify( myBackupKey )

        let keyObject = {
            backup   : btoa( jsonBackup ),
            backupKey: backup
        }

        return keyObject

    }

    /**
     * encryptWithPublicKey
     * @param string publicKey      - the public key
     * @param string text           - the plaintext input string
     * @returns string              - encrypted result, base64 encoded string with a json object
     */
    encryptWithPublicKey( publicKey, text )
    {

        const localKey = nacl.box.keyPair()
        const pubKeyUInt8Array = util.decodeBase64( publicKey )
        const msgParamsUInt8Array = util.decodeUTF8( text )
        const nonce = nacl.randomBytes( nacl.box.nonceLength )

        const encryptedMessage = nacl.box(
            msgParamsUInt8Array,
            nonce,
            pubKeyUInt8Array,
            localKey.secretKey
        )

        let encryptedResult = {
            ciphertext : util.encodeBase64( encryptedMessage ),
            ephemPubKey: util.encodeBase64( localKey.publicKey ),
            nonce      : util.encodeBase64( nonce ),
            version    : 'x25519-xsalsa20-poly1305'
        }

        return btoa( JSON.stringify( encryptedResult ) )

    }

    /**
     * decryptWithPrivateKey
     * @param string decryptedPrivateKey    - the decrypted private key
     * @param string text                   - the encrypted jsonobject, base64 encoded
     * @returns string                      - the decrypted result
     */
    decryptWithPrivateKey( decryptedPrivateKey, text )
    {

        try
        {

            let cipheredObject = JSON.parse( atob( text ) )
            const nonce = util.decodeBase64( cipheredObject.nonce )
            const ciphertext = util.decodeBase64( cipheredObject.ciphertext )
            const ephemPubKey = util.decodeBase64( cipheredObject.ephemPubKey )

            const decryptedMessage = nacl.box.open(
                ciphertext,
                nonce,
                ephemPubKey,
                decryptedPrivateKey
            )

            return util.encodeUTF8( decryptedMessage )

        }
        catch( e )
        {
            return false
        }

    }

    /**
     * generateMessageKey
     * @returns {string}
     */
    generateMessageKey()
    {

        return this.generateRandomString( this.messageKeyLength )

    }

    /**
     * basicAuthSetup
     */
    basicAuthSetup()
    {
        return {
            passphrase: '/%H9ssD8ÄYood*fNIjÜLjcU=bF*psK/j',
            public    : 'RpLk2t+8F//PmNeNX7UuysWn0FvdQfORAp7RHrm9RlI=',
            private   : 'eyJwcml2YXRlS2V5IjoiVDBnRW5Rb3dFSmkzQUhqSWliZTBZcHFFOTNCODcyYnhxNVlKOElPeW9uaDBFa3JRVDdCM1FwdktqWjROMHQzRSIsIm5vbmNlIjoiUmdiWm5ISlY3OWJVclUyeFRIOWJrbVN4d21xdlRIcU8ifQ==',
            backup    : 'eyJwcml2YXRlS2V5IjoiVEFiQ0x4YU8rL1hjRUZmd2o3S3ZsWDVuMm5sQ3hKL3lseTg3UEZyV1k3QXR6UUV0SUJ5ODVodGcrV21TanBEMSIsIm5vbmNlIjoickE0Tk5HUFJ5V3hGNkxxSktVZ2FGT3ZPVXVMY3Z4WDAifQ==',
            backupKey : 'LHyL5e8SZRPyfK2I'
        }
    }
}