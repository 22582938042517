<template>
    <transition appear :name="$core.settings().getTransition( 'fade' )">
        <div class="edit-all-indicator">
            <span :class="'edit-all-indicator'+addClass">
                {{ label }}
            </span>
        </div>
    </transition>
</template>

<script>
export default {
    name : "EditAllIndicator",
    data()
    {
        return {
            storing : false,
            addClass: '',
            label   : ''
        }
    },
    created()
    {
        this.label = 'Änderungen in "Alle Zeilen" werden für alle Schüler:innen übernommen!'
    }
}
</script>