<template>
    <span>
        <span v-if="doneInfo !== false && doneInfo.done" class="done-mark">&check;</span>
        <span v-if="doneInfo !== false && !doneInfo.done" class="not-done-mark">&#x2717;</span>
    </span>
</template>

<script>
export default {
    name : "ListDoneMark",
    props: {
        doneInfo: { type: [ Object, Boolean ], required: false, default: false }
    }
}
</script>