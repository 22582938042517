<template>
    <div>
        <input type="text" :ref="'sub-'+refName"
               :id="'sub-'+refName"
               :value="display.caption"
               :placeholder="'Bezeichnung'"
               @keyup="checkKey"/>
        <input type="text" :ref="'desc-'+refName"
               :id="'desc-'+refName"
               :placeholder="'Kompetenzformulierung wie im Zeugnis'"
               :value="display.description"
               @keyup="checkKeyDescription"/>
        <input type="hidden" :ref="'subId-'+refName"
               :id="'subId-'+refName"
               :value="display.localId"/>
    </div>
</template>
<script>
export default {
    name      : "SubCompetenceField",
    components: {},
    emits     : [ 'update', 'enterPressed', 'backspacePressed' ],

    props: {

        refName    : { Type: String, required: true },
        id         : { Type: String, required: true },
        value      : { Type: Object, required: false },
        self       : { Type: String, required: false },
        allValues  : { Type: Array, required: false },
        unique     : { Type: Boolean, required: false },
        asPlainList: { Type: Boolean, required: false, default: true },
        exclude    : { Type: Array, required: false, default: [] },
        index      : { Type: Number, required: true },
        maxRowCount: { Type: Number, required: false }

    },

    data()
    {
        return {

            emptyBefore : false,
            isProcessing: false,
            display     : {
                caption    : '',
                description: '',
                localId    : this.$core.getUuid().generate()
            }

        }
    },

    created()
    {
        this.prepareValue()
    },

    /*eslint-disable*/
    watch: {
        value: {
            immediate: true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue )
                {
                    this.prepareValue()
                }
            }
        }
    },
    /*eslint-enable*/

    methods: {

        prepareValue()
        {
            if( undefined !== this.$props.value
                && this.$core.f().isObject( this.$props.value ) )
            {
                this.display.caption = this.$props.value.caption || ''
                this.display.description = this.$props.value.description || ''
                this.display.localId = this.$props.value.localId || this.$core.getUuid().generate()
            }
        },

        checkKey( key )
        {
            if( 13 === key.keyCode
                || 13 === key.which
                || 'Enter' === key.key )
            {
                key.preventDefault()
                this.$refs[ 'desc-' + this.$props.refName ].focus()
            }
            else
            {
                if( 8 === key.keyCode )
                {
                    if( '' === this.display.caption.trim()
                        && this.emptyBefore )
                    {
                        this.$emit( 'backspacePressed', this.$props.id )
                    }
                }
                else
                {
                    this.emptyBefore = '' === this.display.caption.trim()
                }
                this.update()

            }
        },

        checkKeyDescription( key )
        {
            if( 13 === key.keyCode
                || 13 === key.which
                || 'Enter' === key.key )
            {
                key.preventDefault()
                this.$emit( 'enterPressed', this.$props.id )
            }
            else
            {
                if( 8 === key.keyCode )
                {
                    if( '' === this.display.description.trim()
                        && this.emptyBefore )
                    {
                        this.$refs[ 'sub-' + this.$props.refName ].focus()
                    }
                }
                else
                {
                    this.emptyBefore = '' === this.display.description.trim()
                }
                this.update()

            }
        },

        update()
        {

            if( !this.isProcessing )
            {

                this.isProcessing = true

                let value = {
                    caption    : this.$refs[ 'sub-' + this.$props.refName ].value,
                    description: this.$refs[ 'desc-' + this.$props.refName ].value,
                    localId    : this.$refs[ 'subId-' + this.$props.refName ].value
                }

                this.display = value
                this.$emit( 'update', this.$props.refName, 'SubCompetenceField', 'update', undefined, ( value.caption.trim() !== '' ? value : undefined ) )

                this.isProcessing = false

            }

        }

    }
}
</script>