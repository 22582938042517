<template>
    <div>
        <!--<TabbedContent :tabs="tabs"
                       :showAdder="true"/>-->
        <component v-bind:is="component"
                   :value="value"
                   :refName="refName"
                   :reference="reference"
                   :validator="validator"
                   :reformatter="reformatter"
                   :display="display"
                   :editor="editor"
                   :scores="scores"
                   :scoreModel="scoreModel"
                   @update="handleUpdate"
                   @values="storeTempValues"
                   @componentChanged="handleChange"
        />
    </div>
</template>

<script>

import TestDistributionBasic     from '@/components/form/elements/tests/distributionKeys/TestDistributionBasic'
import TestDistributionExtended  from '@/components/form/elements/tests/distributionKeys/TestDistributionExtended'
import TestDistributionQuarters  from '@/components/form/elements/tests/distributionKeys/TestDistributionQuarters'
import TestDistributionPoints    from '@/components/form/elements/tests/distributionKeys/TestDistributionPoints'
import TestDistributionAustria   from '@/components/form/elements/tests/distributionKeys/TestDistributionAustria'
import TestDistributionBremen    from '@/components/form/elements/tests/distributionKeys/TestDistributionBremen'
import TestDistributionHamburgEG from '@/components/form/elements/tests/distributionKeys/TestDistributionHamburgEG'
import TestDistributionHamburgEGplus
                                 from "@/components/form/elements/tests/distributionKeys/TestDistributionHamburgEGplus";
import TabbedContent             from '@/components/layout/tabs/TabbedContent'

export default {
    name : 'TestDistributionKey',
    props: [
        'value',
        'refName',
        'reference',
        'validator',
        'reformatter',
        'display',
        'editor',
        'scores',
        'scoreModel'
    ],

    emits: [ 'update' ],

    components: {
        TabbedContent,
        TestDistributionBremen,
        TestDistributionHamburgEG,
        TestDistributionHamburgEGplus,
        TestDistributionBasic,
        TestDistributionExtended,
        TestDistributionPoints,
        TestDistributionQuarters,
        TestDistributionAustria
    },

    data()
    {
        return {
            component : false,
            hookOn    : false,
            tabs      : [
                {
                    id     : 0,
                    caption: 'Notenschlüssel 1'
                }
            ],
            selected  : false,
            storedTemp: false
        }
    },

    created()
    {

        let scoreModel = this.$props.scoreModel

        if( undefined === scoreModel )
        {

            let model = this.$core.settings().getSetting( 'scoreModel' )
            if( Array.isArray( model ) )
            {
                scoreModel = model[ 0 ]
            }
            else
            {
                scoreModel = model
            }

        }

        this.selected = scoreModel
        switch( scoreModel )
        {
            case 'basic':
                this.component = 'TestDistributionBasic'
                break
            case 'extended':
                this.component = 'TestDistributionExtended'
                break
            case 'quarters':
                this.component = 'TestDistributionQuarters'
                break
            case 'points':
                this.component = 'TestDistributionPoints'
                break
            case 'bremen':
                this.component = 'TestDistributionBremen'
                break
            case 'hamburgEG':
                this.component = 'TestDistributionHamburgEG'
                break
            case 'hamburgEGplus':
                this.component = 'TestDistributionHamburgEGplus'
                break
            case 'austria':
                this.component = 'TestDistributionAustria'
                break
        }

        this.$core.getEventManager().add( 'on-scoremodel-change', ( set ) =>
        {

            this.monitorHook( set )

        } )

    },

    beforeUnmount()
    {

        this.$core.getEventManager().remove( 'on-scoremodel-change' )

    },

    methods: {

        handleChange()
        {
            setTimeout( () =>
            {
                this.checkStoredTemp()
            }, 300 )
        },

        monitorHook( set )
        {

            if( set !== this.selected )
            {

                this.selected = set
                switch( this.selected )
                {
                    case 'basic':
                        this.component = 'TestDistributionBasic'
                        break
                    case 'extended':
                        this.component = 'TestDistributionExtended'
                        break
                    case 'quarters':
                        this.component = 'TestDistributionQuarters'
                        break
                    case 'bremen':
                        this.component = 'TestDistributionBremen'
                        break
                    case 'hamburgEG':
                        this.component = 'TestDistributionHamburgEG'
                        break
                    case 'hamburgEGplus':
                        this.component = 'TestDistributionHamburgEGplus'
                        break
                    case 'points':
                        this.component = 'TestDistributionPoints'
                        break
                    case 'austria':
                        this.component = 'TestDistributionAustria'
                        break
                }

            }

        },

        checkStoredTemp()
        {

            if( !this.storedTemp )
            {
                return
            }

            let elm = document.querySelector( '#elm_distribution' )

            if( null !== elm )
            {

                let triggerVal = [ 'RETRIGGER' ]
                let retrigger = triggerVal.concat( this.storedTemp )
                elm.value = retrigger
                let trigger = new CustomEvent( 'prefill', { value: retrigger } )
                elm.dispatchEvent( trigger )

                this.storedTemp = false

            }

        },

        storeTempValues( values )
        {

            if( !this.storedTemp )
            {

                this.monitorHook()
                this.storedTemp = values

            }

        },

        handleUpdate( refName, keys )
        {
            this.$emit( 'update', refName, 'testDistributionKey', 'update', undefined, keys.join( ',' ) )
        }

    }


}
</script>