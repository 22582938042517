<template>
    <transition appear :name="$core.settings().getTransition( 'next' )">
        <div v-if="storing"
             class="storing-indicator">
            <span :class="'storing-indicator'+addClass">
                {{ label }}
            </span>
        </div>
    </transition>
</template>

<script>
export default {
    name : "ListStoringIndicator",
    props: {
        localId: { type: String, required: true }
    },
    data()
    {
        return {
            storing : false,
            addClass: '',
            label   : ''
        }
    },
    created()
    {
        this.$core.getEventManager()
            .add( 'storing-' + this.$props.localId, () =>
            {

                this.label = 'wird gespeichert'
                this.storing = true

                this.$core.getEventManager()
                    .append( 'storable-after-updated-' + this.$props.localId, () =>
                    {

                        setTimeout( () => {

                            this.addClass = ' done'
                            this.label = 'gespeichert'

                            setTimeout( () => {

                                this.storing = false
                                this.addClass = ''

                            }, 1000 )

                        }, 1000 )

                    } )

            } )

        setTimeout( () => {
            this.storing = false
        }, 300 )

    },
    beforeUnmount()
    {
        this.$core.getEventManager()
            .remove( 'storing-' + this.$props.localId )
    }
}
</script>