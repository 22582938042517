<template>
    <div :class="'organizer-slot-content '+slotContent.color+(isHighlighted ? ' highlighted' : '')
            +( draggable ? ' draggable' : '' )" :id="'reference_'+slotContent.reference+'_'+slotContent.contentIndex+'_'+index">
        <strong v-html="slotContent.displayTitle"></strong>
    </div>
</template>

<script>
export default {
    name : "PlannerSlotContent",
    props: {
        slotContent : { type: Object, required: false },
        date        : { type: Number, required: false },
        index       : { type: Number, required: false },
        draggable   : { type: Boolean, required: false },
        highlighted : { type: [ Object, Boolean ], required: false }
    },

    computed: {
        isHighlighted()
        {
            return false !== this.$props.highlighted
                   && undefined !== this.$props.highlighted
                   && this.$props.highlighted.date === this.$props.date
                   && this.$props.highlighted.reference === this.$props.slotContent.reference
                   && this.$props.highlighted.slotIndex === this.$props.slotContent.slotIndex
        }
    }
}
</script>