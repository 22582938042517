<template>
    <th :class="'fixed '+color"
        @click="$emit('table-head-clicked')">
        <span v-html="$core.t( 'object-type-'+values+'-single' )"></span>
        <Opener classname="listeditor-show-edit-all"
                id="toggle-edit-all"
                @click="$core.f().skip( $event ); $emit('show-edit-all')"/>
    </th>
</template>

<script>
import Opener from "@/components/elements/defaults/Opener";

export default {
    name      : "TableHeadFixed",
    components: { Opener },
    props     : {
        caption: { Type: String, required: true },
        color  : { Type: String, required: true },
        values : { Type: String, required: true }
    },
    emits     : [ 'show-edit-all', 'table-head-clicked' ]
}
</script>