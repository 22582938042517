export default class SyncCrudPrepareSyncables
{

    constructor( parent )
    {

        this.p = parent
        this.parent = this.p.parent
        this.lastSync = Date.now() - ( 60000 * 60 * 24 * 365 * 20 )
        this.timeZoneOffset = ( new Date() ).getTimezoneOffset() * 60000

        return this

    }

    shouldSync()
    {
        return true
    }

    rateConnectionSpeed( bytes, mbPerSec )
    {

        let connectionRating = 'offline',
            map              = {
                hb: {
                    1 : 'bad',
                    3 : 'poor',
                    5 : 'okay',
                    7 : 'good',
                    9 : 'very good',
                    11: 'perfect'
                },
                lb: {
                    1 : 'bad',
                    3 : 'poor',
                    5 : 'okay',
                    7 : 'good',
                    9 : 'very good',
                    11: 'perfect'
                }
            }

        let selectedMap  = ( bytes > 128000 ? 'hb' : ( bytes > 2048 ? 'lb' : 'none' ) ),
            rateSelector = mbPerSec * ( 'hb' === selectedMap ? 10 : 1000 )

        if( 'none' !== selectedMap )
        {

            for( let i = 1; i <= 11; i += 2 )
            {
                if( i < rateSelector )
                {
                    connectionRating = map[ selectedMap ][ i ]
                }
            }

            this.parent.setState( selectedMap + '-transmit-speed', mbPerSec )
            this.parent.setState( selectedMap + '-transmit-quality', connectionRating )

        }

    }

    /*eslint-disable*/
    checkConnectionSpeed( bytes, diff )
    {

        let factor = 1000 / diff
        let mbPerSec = ( bytes / 1024 / 1024 ) * factor
        this.rateConnectionSpeed( bytes, mbPerSec )

    }

    sync()
    {

        return new Promise( resolve =>
        {

            this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPrepareSyncables', 'performing sync run...' )

            this.parent.database.readAllObjects( 'deletions' )
                .then( deletions =>
                {

                    if( 0 === deletions.length )
                    {

                        let tsmpSince = new Date( this.lastSync + this.p.diff - 120000 - this.timeZoneOffset )
                            .toISOString().slice( 0, 19 ).replace( 'T', ' ' )

                        let message = {
                            method      : 'objects.getObjectsList',
                            initialFetch: this.parent.getSyncParameters().mode === 'full',
                            since       : tsmpSince
                        }

                        if( this.parent.getSyncParameters().mode !== 'full' )
                        {
                            this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPrepareSyncables', 'since:', tsmpSince )
                        }

                        let tsStart = Date.now()

                        this.parent.client.request( message )
                            .then( list =>
                            {

                                let tsEnd            = Date.now(),
                                    bytesTransmitted = JSON.stringify( list ).length,
                                    count            = 0

                                this.checkConnectionSpeed( bytesTransmitted, ( tsEnd - tsStart ) )
                                this.lastSync = Date.now()

                                for( let o in list.result )
                                {

                                    let object = list.result[ o ]

                                    if( -1 === this.p.noSync.indexOf( object.id_local ) )
                                    {
                                        count++
                                        this.p.queue.push( { raw: object } )
                                        if( -1 === this.p.remoteObjects.indexOf( object.id_local ) )
                                        {
                                            this.p.remoteObjects.push( object.id_local )
                                        }
                                    }
                                    else
                                    {
                                        this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPrepareSyncables', 'skipping ' + object.id_local + ':in nosync list...' )
                                    }

                                }

                                this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPrepareSyncables', 'total ' + count + ' elements queued.' )
                                return resolve()

                            } )
                            .catch( error =>
                            {

                                this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPrepareSyncables', 'done (faulty:', error, ')' )
                                return resolve()

                            } )

                    }
                    else
                    {
                        this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPrepareSyncables', 'skipped run: awaiting ' + deletions.length + ' deletions before next sync run...' )
                        return resolve()
                    }

                } )
                .catch( error =>
                {

                    this.parent.logger.clog( 'SyncWorker::SyncCrud::SyncCrudPrepareSyncables', 'done (database fault:', error, ')' )
                    return resolve()

                } )


        } )

    }

}