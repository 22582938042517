<template>
    <div class="debug-info" :key="'debug-'+item.localId">
        #localId: <input type="text" :value="item.localId" style="width:50%;"/><br/>
        #remoteId: {{ item.remoteId }}, #owner: {{ item.idOwner }} - #archiveKey: {{ item.archiveKey }} - keychain length: {{
            item._keys !== undefined ? item._keys.length :
                ( item.lists !== undefined && item.lists[ 0 ]._keys !== undefined ? item.lists[ 0 ]._keys.length : '1' )
        }} - object-hash: {{ item.elementKey }} - content-key: {{ item.contentKey }} - localKey: {{ item.localKey ? item.localKey.substr( 0, 32 )+'...' : 'n/a' }}
    </div>
</template>

<script>
export default {
    name : "DebugInfo",
    props: {
        item: { Type: Object, required: true }
    }
}
</script>