<template>
    <transition appear :name="$core.settings().getTransition( 'slide-fade' )" mode="in-out">
        <div>
            <div class="planner-synchronizing synchronizing">
                <strong>Augenblick bitte...</strong><br/>
                Deine Planung wird synchronisiert.
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "OrganizerPreparationDisplay"
}
</script>