<template>
    <div>
        <h2>PDF-Dokument: <strong>{{ setup.image.fileName }}</strong></h2>
        <div class="form imageZoom">
            <iframe :src="toBlobUrl()+'#view=FitH'" class="pdf-object" type="application/pdf"></iframe>
        </div>
        <div class="align-right">
            Dateigröße: <strong>{{ readableFilesize }}</strong> kb
        </div>
    </div>
</template>

<script>
export default {

    name : "PdfZoom",
    props: {
        setup           : { type: Object, required: true },
        readableFilesize: { type: String, required: true }
    },

    methods: {

        _b64toBlob( b64Data, contentType = '', sliceSize = 512 )
        {

            const byteCharacters = atob( b64Data );
            const byteArrays = [];

            for( let offset = 0; offset < byteCharacters.length; offset += sliceSize )
            {
                const slice = byteCharacters.slice( offset, offset + sliceSize );

                const byteNumbers = new Array( slice.length );
                for( let i = 0; i < slice.length; i++ )
                {
                    byteNumbers[ i ] = slice.charCodeAt( i );
                }

                const byteArray = new Uint8Array( byteNumbers );
                byteArrays.push( byteArray );
            }

            const blob = new Blob( byteArrays, { type: contentType } );
            return blob;

        },

        toBlobUrl()
        {

            let base64Data = this.$props.setup.image.document.replace( 'data:application/pdf;base64,', '' ),
                blob       = this._b64toBlob( base64Data, 'application/pdf' ),
                blobUrl    = URL.createObjectURL( blob )

            return blobUrl

        }
    }

}
</script>