import CoreTimerWorker from '@/classes/Core/CoreTimerWorker'

export default class CoreTimer
{

    constructor( core )
    {

        if( !CoreTimer.instance )
        {

            this.logger = core.getLogger()
            this.eventManager = core.getEventManager()
            this.store = core.getStore()

            this.events = {}
            this.triggers = []
            this.triggering = false

            this.activeIntervals = new Map()
            this.t = 0
            this.triggerGraceTime = 3000
            this.resetIndex = this.eventManager.addIndexed( 'core-component-reset', () =>
            {

                this.setup()

            } )

            CoreTimer.instance = this

            this.coreTimerWorker = new CoreTimerWorker( this.logger )
            this.setup()

        }

        return CoreTimer.instance

    }

    setup()
    {

        this.logger.clog( 'CoreTimer::setup', 'setting up main timing instance...' )

        if( this.timer )
        {
            this.coreTimerWorker.doClearInterval( this.timer )
        }

        this.timer = this.coreTimerWorker.doSetInterval( () =>
        {

            if( this.t % 30 === 0 )
            {

                let eventCount   = Object.keys( this.events ).length,
                    triggerCount = this.triggers.length

                this.logger.cdebug( 'CoreTimer::Statistics', eventCount, 'events |', triggerCount, 'triggers' )

            }

            this.t++
            this.handleTimer()

        }, 300 )

    }

    destruct()
    {
        this.eventManager.unregisterIndexedCallback( 'core-component-reset', this.resetIndex )
        this.coreTimerWorker.doClearInterval( this.timer )
        delete CoreTimer.instance
    }

    handleTimer()
    {

        if( 0 === this.triggers.length )
        {
            return
        }
        if( !this.triggering)
        {

            this.triggering = true

            let now   = Date.now(),
                dones = []

            for( let t in this.triggers )
            {

                let trigger      = this.triggers[ t ],
                    triggerEvent = this.events[ trigger ],
                    fired        = false

                if( undefined !== triggerEvent )
                {

                    if( now >= ( triggerEvent.lastTrigger + ( triggerEvent.interval ) ) )
                    {

                        fired = true
                        triggerEvent.lastTrigger = now

                        setTimeout( () =>
                        {
                            triggerEvent.callback()
                        }, 0 )

                    }

                    if( triggerEvent.singleShot === true && fired )
                    {
                        dones.push( t )
                        delete this.events[ trigger ]
                    }
                }

            }

            let newTriggers = []

            for( let t in this.triggers )
            {
                if( -1 === dones.indexOf( t ) )
                {
                    newTriggers.push( this.triggers[ t ] )
                }
            }

            this.triggers = newTriggers

            this.triggering = false

        }

    }

    registerEvent( name, interval, callback, singleShot, fireOnCreate )
    {

        this.removeInterval( name )

        let event     = {
                interval   : interval,
                lastTrigger: ( fireOnCreate ? 0 : Date.now() ),
                callback   : callback,
                singleShot : singleShot
            }/*,
            allEvents = this.store.getters.timerCallbacks || {}

        allEvents[ name ] = event
        this.store.commit( 'setTimerCallbacks', allEvents )*/

        this.events[ name ] = event

        if( !singleShot )
        {
            this.activeIntervals.set( name, interval )
        }

        this.triggers.push( name )

    }

    unregisterEvent( name )
    {
        delete this.events[ name ]
    }

    addTimeout( name, interval, callback )
    {
        this.registerEvent( name, interval, callback, true, undefined )
    }

    awaitDeletion( name )
    {
        return new Promise( resolve => {
            if( !this.hasTimer( name ) )
            {
                return resolve()
            }
            else
            {
                setTimeout( () => {
                    return resolve( this.awaitDeletion( name ) )
                }, 200 )
            }
        })
    }

    addInterval( name, interval, callback, fireOnCreate, awaitDeletion )
    {
        if( !awaitDeletion )
        {
            if( !this.hasTimer( name ) )
            {
                fireOnCreate = undefined === fireOnCreate ? false : fireOnCreate
                this.registerEvent( name, interval, callback, false, fireOnCreate )
            }
        }
        else
        {
            this.awaitDeletion( name )
                .then( () => {
                    if( !this.hasTimer( name ) )
                    {
                        fireOnCreate = undefined === fireOnCreate ? false : fireOnCreate
                        this.registerEvent( name, interval, callback, false, fireOnCreate )
                    }
                })
        }
    }

    changeInterval( name, interval )
    {
        this.events[ name ].interval = interval
    }

    removeInterval( name )
    {

        this.activeIntervals.delete( name )
        let index = this.triggers.indexOf( name )
        while( -1 < index )
        {
            this.triggers.splice( index, 1 )
            if( undefined !== this.events[ name ] )
            {
                delete ( this.events[ name ] )
            }
            index = this.triggers.indexOf( name )
        }

    }

    hasTimer( name )
    {
        return -1 < this.triggers.indexOf( name )
    }

    removeTimeout( name )
    {
        this.removeInterval( name )
    }

}