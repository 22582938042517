export default class CryptoCache
{
    constructor( core )
    {

        if( !CryptoCache.instance )
        {
            this.logger = core.getLogger()
            this.f = core.f()
            this.timer = core.getCoreTimer()

            this.cache = {}

            this.cacheLifetime = 60000

            this.timer.addInterval( 'crypto-cache-cleanup', this.cacheLifetime, () => {
                this.cleanCache()
            }, false )

            CryptoCache.instance = this

        }

        return CryptoCache.instance

    }

    destruct()
    {
        this.timer.removeInterval( 'crypto-cache-cleanup' )
    }

    cleanCache()
    {
        for( let h in this.cache )
        {
            if( this.cache[h].tsmp < ( Date.now() - this.cacheLifetime ) )
            {
                delete this.cache[h]
            }
        }
        this.logger.cdebug( 'CryptoCache::cleanCache', Object.keys( this.cache ).length, 'objects remaining in cache' )
    }

    get( crypted )
    {

        let hash = this.f.objectHash( crypted, true )
        if( undefined !== this.cache[hash] )
        {
            return this.cache[hash].plain
        }
        return false

    }

    store( crypted, plain )
    {

        let hash = this.f.objectHash( crypted, true )
        this.cache[ hash ] = {
            tsmp : Date.now(),
            plain: plain
        }

    }

}