<template>
    <div class="select-wrapper" v-if="ready">
        <select :id="'form-element-'+refName" @change="select">
            <option value="">{{ $core.t( 'generic-please-select' ) }}</option>
            <template v-for="column in columns" :key="'key-'+refName+'-'+column">
                <template
                    v-if="( -1 === excludeList.indexOf( column ) || selectedColumn === column ) && '' !== column.trim()">
                    <option :value="column" :selected="selectedColumn === column">
                        {{ column }}
                    </option>
                </template>
            </template>
        </select>
        <template v-if="selectedColumn">
            <RowAdder component="PlainSelectBox"
                      :id="refName"
                      :refName="refName"
                      :valueSet="columns"
                      :value="setup.linked || undefined"
                      :unique="true"
                      :asPlainList="true"
                      :exclude="excludeList"
                      @update="handleUpdate"/>
        </template>
    </div>
</template>

<script>
import RowAdder from "@/components/form/elements/RowAdder";

export default {

    name      : 'SingleLinkedColumnsSelector',
    components: { RowAdder },
    emits     : [ 'extendedFilterSelection', 'enterPressed', 'backspacePressed', 'update', 'selected', 'adderValid',
                  'adderInvalid' ],

    props: {

        value       : { Type: String, required: false },
        set         : { Type: Object, required: false },
        allValues   : { Type: Object, required: false },
        refName     : { Type: String, required: true },
        validator   : { Type: String, required: false },
        reformatter : { Type: String, required: false },
        display     : { Type: String, required: false },
        undefBlocked: { Type: Boolean, required: false },
        configScope : { Type: Boolean, required: false },
        eventKey    : { Type: String, required: false },
        scope       : { Type: String, required: false },
        unique      : { Type: Boolean, required: false },
        exclude     : { Type: Array, required: true },
        index       : { Type: Number, required: true }

    },

    data()
    {
        return {
            ready         : false,
            formElm       : false,
            columns       : [],
            excludeList   : [],
            selectedColumn: false,
            setup         : {}
        }
    },

    created()
    {

        if( undefined !== this.$props.value )
        {
            this.setup = JSON.parse( JSON.stringify( this.$props.value ) )
            this.selectedColumn = this.setup.linkTo || false
        }
        this.$core.getCoreTimer()
            .addInterval( 'linked-column-selector', 1000, () =>
            {
                this.scanColumns()
            } )

    },

    beforeUnmount()
    {
        this.$core.getCoreTimer()
            .removeInterval( 'linked-column-selector' )
    },

    mounted()
    {
        this.scanColumns()
        this.performExclude()
        this.ready = true
    },

    watch: {
        exclude: {
            immediate: true,
            deep     : true,
            handler( newValue, oldValue )
            {
                if( undefined !== oldValue && newValue !== oldValue )
                {
                    this.performExclude()
                }
            }
        }
    },

    methods: {

        performExclude()
        {
            this.excludeList = []
            this.excludeList.push( this.setup.linkTo )
        },

        scanColumns()
        {

            try
            {

                let idx          = 0,
                    foundColumns = [],
                    column       = document.querySelector( '#form-element-adder-columns-' + idx )

                while( null !== column )
                {
                    if( column.value !== undefined
                        && column.value.trim() !== '' )
                    {
                        if( -1 < column.value.indexOf( '|||' ) )
                        {
                            let temp   = column.value.split( '|||' ),
                                scan   = temp[ 1 ],
                                object = JSON.parse( scan )
                            if( undefined !== object.value
                                && '' !== object.value.trim() )
                            {
                                foundColumns.push( object.value )
                            }
                        }
                        else
                        {
                            foundColumns.push( column.value )
                        }
                    }
                    idx++
                    column = document.querySelector( '#form-element-adder-columns-' + idx )
                }

                this.performExclude()

                this.columns = foundColumns

            }
            catch( e )
            {
                //empty by default
            }
        },

        select( event )
        {

            if( undefined !== event.target.value
                && '' !== event.target.value.trim() )
            {
                this.selectedColumn = event.target.value
            }
            else
            {
                this.selectedColumn = false
            }

            this.setup = {
                linkTo: this.selectedColumn,
                linked: []
            }

            this.$emit( 'update', this.$props.index, 'linkedColumnSelector', 'linkedColumns', 'form-element-' + this.$props.refName, this.setup )

        },

        handleUpdate( refName, type, component, call, value )
        {

            this.setup = {
                linkTo: this.selectedColumn,
                linked: []
            }

            for( let v in value )
            {
                if( undefined !== value[ v ].value
                    && 'function' === typeof value[ v ].value.trim
                    && '' !== value[ v ].value.trim() )
                {
                    this.setup.linked.push( value[ v ].value.trim() )
                }
            }

            this.$emit( 'update', this.$props.index, 'linkedColumnSelector', 'linkedColumns', 'form-element-' + this.$props.refName, this.setup )

        }

    }

}
</script>