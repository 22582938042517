<template>
    <template v-for="( detailView, index ) in detailViews"
              :key="'detail-'+index+'-'+detailView.detailObject.localId">
        <DefaultDetailView v-if="false !== detailView.detailObject"
                           :viewItem = detailView.detailObject.type
                           :object="detailView.detailObject"
                           :scope="detailView.tab"
                           :escapeNested="true"
                           @close="handleClose( index )"/>
    </template>
</template>

<script>
import DefaultDetailView from "@/components/defaults/defaultDetailView/DefaultDetailView";

export default {

    name      : "DetailViews",
    components: { DefaultDetailView },
    data()
    {
        return {
            detailViews: []
        }
    },

    created()
    {

        this.$core.getEventManager()
            .add( 'on-detail-view', ( detailView ) =>
            {

                let topDetailView = false
                for( let d in this.detailViews )
                {
                    if( false !== this.detailViews[ d ].detailObject )
                    {
                        topDetailView = this.detailViews[ d ].detailObject.localId
                    }
                }

                if( undefined !== detailView
                    && undefined !== detailView.detailObject
                    && detailView.detailObject.localId !== topDetailView )
                {
                    this.detailViews.push( detailView )
                }

            } )

    },

    methods: {

        handleClose( index )
        {

            this.detailViews[ index ].detailObject = false
            let allFalse = true

            for( let d in this.detailViews )
            {
                if( false !== this.detailViews[ d ].detailObject )
                {
                    allFalse = false
                }
            }

            if( allFalse )
            {
                this.detailViews = []
            }

        }

    }


}
</script>