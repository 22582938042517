<script>
export default {

    name : 'TestDistribution',
    emits: [ 'update', 'componentChanged' ],

    data()
    {
        return {
            checkTimer  : false,
            reversedOnce: false
        }
    },

    created()
    {
        this.$core.getCoreTimer()
            .addInterval( 'score-calculation', 500, () =>
            {
                this.getScore()
            }, true )
    },

    beforeUnmount()
    {
        this.$core.getCoreTimer()
            .removeInterval( 'score-calculation' )

    },

    mounted()
    {
        this.update( this.$props.value )
    },

    watch: {
        value: {
            handler( value )
            {
                this.update( value )
            }
        }
    },

    methods: {

        update( value )
        {

            if( this.$core.f().isset( value ) )
            {
                let test = value.split( ',' )
                for( let t in test )
                {

                    let id  = 'ratio_' + t,
                        elm = document.querySelector( '#' + id )

                    if( null !== elm )
                    {
                        this.distributionScores[ t ] = test[ t ]
                        elm.value = test[ t ]
                    }
                }
            }
        },

        getScore()
        {

            let score = 0
            let elements = document.querySelectorAll( '[id^="form-element-score-adder-score-"]' )
            for( let e in elements )
            {
                let element = elements[ e ]
                if( undefined !== element.type )
                {
                    if( this.$core.getValidator().validate( 'localizedfloat', false, element.value ) )
                    {
                        score += this.$core.getReformatter().reformat( element.value, 'float' )
                    }
                }
            }
            this.totalScore = score

        },

        checkKey( key, id )
        {

            clearTimeout( this.checkTimer )
            if( 13 === key.keyCode )
            {

                if( ( 1 + parseInt( id ) ) < Object.keys( this.distributionKeys ).length
                    || ( this.stepsInversion === true && ( parseInt( id ) > 0 ) ) )
                {
                    let elm = document.querySelector( '#ratio_' + ( parseInt( id ) + 1 ) )
                    if( this.stepsInversion === true )
                    {
                        elm = document.querySelector( '#ratio_' + ( parseInt( id ) - 1 ) )
                    }
                    if( null !== elm )
                    {
                        elm.focus()
                    }
                }

            }
            else
            {

                if( 8 === key.keyCode )
                {

                    let checkElm = document.querySelector( '#ratio_' + id )
                    if( '' === checkElm.value.trim()
                        && this.reversedOnce === true )
                    {
                        if( parseInt( id ) > 0 )
                        {
                            let elm = document.querySelector( '#ratio_' + ( parseInt( id ) - 1 ) )
                            if( null !== elm )
                            {
                                elm.focus()
                            }
                        }
                    }
                    else if( '' === checkElm.value.trim() )
                    {
                        this.reversedOnce = true
                        setTimeout( () =>
                        {
                            this.reversedOnce = false
                        }, 200 )
                    }

                }
                else
                {
                    this.reversedOnce = false
                }

            }

            this.checkTimer = setTimeout( () =>
            {

                this.localChange( id )
                this.handleChange()

            }, 1000 )

        },

        validateAll()
        {

            let lastValue = 99999
            for( let d in this.distributionScores )
            {

                let checkElm   = document.querySelector( '#ratio_' + d ),
                    fieldValue = null !== checkElm ? checkElm.value : '' + 0,
                    score      = ( 'undefined' !== fieldValue && '' !== fieldValue.trim() ) ?
                                 this.$core.getReformatter().reformat( fieldValue, 'float' ) : 0

                this.distributionScores[ d ] = score

                let hasError = false
                if( score > 0
                    && score >= lastValue )
                {
                    hasError = true
                }

                if( null !== checkElm )
                {
                    if( hasError )
                    {
                        checkElm.classList.add( 'error' )
                    }
                    else
                    {
                        this.handleSingleChange( d )
                        checkElm.classList.remove( 'error' )
                    }
                }

                lastValue = score

            }

        },

        handleChange()
        {

            this.validateAll()

        },

        localChange( index )
        {

            let checkElm = document.querySelector( '#ratio_' + index )
            if( null !== checkElm )
            {
                if( '' !== checkElm.value.trim() )
                {
                    this.distributionScores[ index ] = checkElm.value.trim()
                }
                else
                {
                    this.distributionScores[ index ] = 0
                }
            }

        },

        handleSingleChange( index )
        {

            let checkElm = document.querySelector( '#ratio_' + index )
            if( null !== checkElm )
            {
                if( '' !== checkElm.value.trim() )
                {
                    let num = this.$core.getReformatter().reformat( checkElm.value, 'float' )
                    this.distributionScores[ index ] = isNaN( num ) ? 0 : num
                    this.$emit( 'update', this.$props.refName, this.distributionScores )
                }
                else
                {
                    this.distributionScores[ index ] = 0
                    this.$emit( 'update', this.$props.refName, this.distributionScores )
                }
            }

        }

    }
}
</script>