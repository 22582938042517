<template>
    <div class="list-element textbox">
        {{ value }}
    </div>
</template>

<script>
export default {
    name : 'listElement-datebox',
    props: {
        value   : { type: String, required: false, default: undefined },
    }
}
</script>
