<template>
    <div v-if="ready && 0 < elementList.length" class="competence-category-overview">
        <div :class="'box '+referenceCompetence.color"
             :key="'ccob-'+referenceCompetence.localId">
            <div class="box-body list">
                <table :class="'list competences-complete-list '+referenceCompetence.color">
                    <thead>
                    <tr class="list-header">
                        <th>
                            <strong>Kompetenz</strong>
                        </th>
                        <th v-for="student in allStudents"
                            class="rotated"
                            :key="'column-student-'+student.localId">
                            <span class="rotated" v-html="student.htmlName"></span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="sub in tree.subs"
                              :key="'tree-item-'+sub.localId">
                        <tr>
                            <td class="competence-completion level-1">
                                {{ sub.label }}
                            </td>
                            <td v-for="student in allStudents"
                                :key="sub.label+' level-1-stud-'+student.localId"
                                class="completion-result">
                                <CompetenceCompletionResult
                                    v-if="calculation.byStudent[student.localId][sub.localId]"
                                    :level=1
                                    :calculation="calculation.byStudent[student.localId][sub.localId]"/>
                            </td>
                        </tr>
                        <template v-if="hasSubs( sub )">

                            <template v-for="subsub in sub.subs"
                                      :key="'tree-item-'+subsub.localId">
                                <tr>
                                    <td class="competence-completion level-2">
                                        {{ subsub.label }}
                                    </td>
                                    <td v-for="student in allStudents"
                                        :key="subsub.label+' level-2-stud-'+student.localId"
                                        class="completion-result">
                                        <CompetenceCompletionResult
                                            v-if="calculation.byStudent[student.localId][subsub.localId]"
                                            :level=2
                                            :calculation="calculation.byStudent[student.localId][subsub.localId]"/>
                                    </td>
                                </tr>

                                <template v-if="hasSubs( subsub )">

                                    <template v-for="subsubsub in subsub.subs"
                                              :key="'tree-item-'+subsubsub.localId">
                                        <tr>
                                            <td class="competence-completion level-3">
                                                {{ subsubsub.label }}
                                            </td>
                                            <td v-for="student in allStudents"
                                                :key="subsubsub.label+' level-3-stud-'+student.localId"
                                                class="completion-result">

                                                <CompetenceCompletionResult
                                                    v-if="calculation.byStudent[student.localId][subsubsub.localId]"
                                                    :level=3
                                                    :calculation="calculation.byStudent[student.localId][subsubsub.localId]"/>

                                            </td>
                                        </tr>
                                        <template v-if="hasSubs( subsubsub )">

                                            <template v-for="subsubsubsub in subsubsub.subs"
                                                      :key="'tree-item-'+subsubsubsub.localId">
                                                <tr>
                                                    <td class="competence-completion level-4">
                                                        {{ subsubsubsub.label }}
                                                    </td>
                                                    <td v-for="student in allStudents"
                                                        :key="subsubsubsub.label+' level-3-stud-'+student.localId"
                                                        class="completion-result">

                                                        <CompetenceCompletionResult
                                                            v-if="calculation.byStudent[student.localId][subsubsubsub.localId]"
                                                            :level=4
                                                            :calculation="calculation.byStudent[student.localId][subsubsubsub.localId]"/>

                                                    </td>
                                                </tr>

                                            </template>

                                        </template>
                                    </template>

                                </template>

                            </template>
                        </template>
                    </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div v-else-if="ready && 0 === elementList.length">
        <InlineMessage :title="$core.t( 'title-no-competence-content' )"
                       classname="nothing"
                       :message="$core.t( 'message-no-competence-content' )"
                       :active=true
        />
    </div>
    <PreheatingIndicator v-else/>
</template>
<script>
/*eslint-disable*/
import MixinCachePreheater from "@/mixins/MixinCachePreheater.vue";
import PreheatingIndicator from "@/components/elements/indicators/PreheatingIndicator.vue";
import InlineMessage       from "@/components/messages/InlineMessage.vue";
import Opener              from "@/components/elements/defaults/Opener.vue";
import CompetenceCompletionResult
                           from "@/components/elements/viewItems/competenceCategory/CompetenceCompletionResult.vue";
import MixinEvents         from "@/mixins/MixinEvents.vue";

export default {
    name      : "CompetenceCategoryOverview",
    components: { CompetenceCompletionResult, Opener, InlineMessage, PreheatingIndicator },
    emits     : [ 'count' ],

    props: {
        reference : { type: Object, required: true },
        filterKey : { type: [ String, Boolean ], required: true },
        filterList: { type: Object, required: true }
    },

    mixins: [ MixinCachePreheater, MixinEvents ],

    data()
    {
        return {
            neededCaches       : [ 'list', 'competence', 'competenceCategory' ],
            calculation        : {},
            prepared           : {},
            referenceId        : false,
            elementList        : [],
            allStudents        : [],
            referenceCompetence: false,
            ready              : false,
            tree               : false
        }
    },

    created()
    {
        /*eslint-disable*/
        this.awaitNeededCaches()
            .then( () =>
            {

                this.$core.getCompetenceCategoryTree()
                    .awaitReadiness()
                    .then( () =>
                    {

                        this.addEvent( 'on-category-tree-updated', () => {
                            this.reset()
                        })

                        this.prepare()

                    } )

            } )

    },

    methods: {

        reset()
        {
            this.ready = false
            this.$core.getCompetenceCategoryTree()
                .awaitReadiness()
                .then( () =>
                {
                    this.prepare()
                })
        },

        hasSubs( elm )
        {
            return Array.isArray( elm.subs ) && 0 < elm.subs.length
        },

        prepare()
        {

            this.referenceId = this.$props.reference.localId
            this.referenceCompetence = this.$core.getBaseClassHelper()
                                           .getObjectById( this.referenceId )

            this.tree = this.$core.getCompetenceCategoryTree().tree.byRoot[ this.referenceId ]

            let calculation = this.$core.getCompetenceCategoryCalculator().calculateResults( this.$props.reference, this.tree )

            this.calculation = calculation.calculation
            this.elementList = calculation.elementList
            this.allStudents = calculation.allStudents

            this.ready = true

        }

    }

}
</script>