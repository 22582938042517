export default class FieldHistoryHelper
{

    constructor( core )
    {

        if( !FieldHistoryHelper.instance )
        {

            this.logger = core.getLogger()
            this.logSign = 'FieldHistoryHelper::'
            FieldHistoryHelper.instance = this

        }

        return FieldHistoryHelper.instance

    }

    /*eslint-disable*/
    _getHistoryByValue( fieldHistory )
    {

        let historyByValue = {}
        for( let key in fieldHistory )
        {

            let entry = fieldHistory[ key ]
            for( let valueKey in entry.history )
            {
                if( undefined === historyByValue[ valueKey ]
                    || entry.timestamp > historyByValue[ valueKey ].timestamp )
                {
                    historyByValue[ valueKey ] = {
                        value    : entry.history[ valueKey ],
                        id_author: entry.id_author,
                        timestamp: entry.timestamp
                    }
                }
            }

        }

        return historyByValue

    }

    reduce( fieldHistory )
    {

        this.logger.clog( this.logSign + 'reduce', 'reducing fieldHistory to latest version...' )

        let historyByValue = this._getHistoryByValue( fieldHistory ),
            reducedHistory = {}

        for( let valueKey in historyByValue )
        {

            let entry = historyByValue[ valueKey ]
            reducedHistory[ entry.id_author + '-' + entry.timestamp ] = reducedHistory[ entry.id_author + '-' + entry.timestamp ] || {
                id_author: entry.id_author,
                timestamp: entry.timestamp,
                history  : {}
            }

            reducedHistory[ entry.id_author + '-' + entry.timestamp ].history[ valueKey ] = entry.value

        }

        return reducedHistory

    }


}