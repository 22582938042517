<!--
    Calendar.vue
    * calendar for simple and fast date selection
-->
<template>
    <transition appear :name="$core.settings().getTransition( 'next' )">
        <div class="big-calendar-wrapper" :id="id">
            <div class="big-calendar">
                <div class="header">
                    <div class="head-button last">
                        <Button type="navigate last" @clicked="changeDate( 'last' )"/>
                    </div>
                    {{ calendarTitle }}
                    <div class="head-button next">
                        <Button type="navigate next" @clicked="changeDate( 'next' )"/>
                    </div>
                    <div class="head-button close">
                        <Button type="close" @clicked="handleCalendarClose"/>
                    </div>
                </div>
                <transition-group :name="$core.settings().getTransition( 'slide-left' )">
                    <div v-if="ready" key="dates">
                        <div class="days">
                            <div v-for="day in days"
                                 v-bind:key="'key_calday_'+day.title"
                                 class="day" v-bind:class="day.type">
                                {{ day.title }}
                            </div>
                        </div>
                        <div class="dates">
                            <div v-for="day in dates"
                                 v-bind:key="'key_caldate_'+day.id"
                                 class="date-elem" v-bind:class="day.type">
                                <div class="date-inner" v-bind:class="day.type" @click="handleCalendarClick( day )">
                                    {{ day.title }}
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    name: 'Calendar',
    
    props: {
        objects          : { type: Object, required: false, default: undefined },
        title            : { type: String, required: false, default: undefined },
        allowFutureCreate: { type: Boolean, required: false, default: false },
        noClone          : { type: Boolean, required: false, default: false },
    },
    
    emits: [ 'calendarAction', 'clicked' ],
    
    data()
    {
        return {
            id           : false,
            year         : false,
            month        : false,
            day          : false,
            ready        : false,
            calendarTitle: false,
            days         : [
                {
                    day  : 1,
                    title: 'MO',
                    type : 'regular'
                },
                {
                    day  : 2,
                    title: 'DI',
                    type : 'regular'
                },
                {
                    day  : 3,
                    title: 'MI',
                    type : 'regular'
                },
                {
                    day  : 4,
                    title: 'DO',
                    type : 'regular'
                },
                {
                    day  : 5,
                    title: 'FR',
                    type : 'regular'
                },
                {
                    day  : 6,
                    title: 'SA',
                    type : 'weekend'
                },
                {
                    day  : 0,
                    title: 'SO',
                    type : 'weekend'
                } ],
            items        : [],
            dates        : [],
            elements     : {}
        }
    },
    
    created()
    {
        
        this.id = 'calendar-' + this.$core.getUuid().generate()
        this.initialize()
        this.hookKeys()
        
    },
    
    beforeUnmount()
    {
        this.hookKeys( false )
    },
    
    watch: {
        month( oldValue, newValue )
        {
            if( oldValue !== newValue )
            {
                this.setFriendlyMonth()
                this.prepareDateGrid()
                this.calendarTitle = this.getTitle() + ' ' + this.friendlyMonth + ' ' + this.year
            }
        }
    },
    
    methods: {
        
        cloneAllowed()
        {
            for( let o in this.$props.objects )
            {
                if( this.$core.r().isAllowed( this.$props.objects[ o ], 'clone' ) )
                {
                    return true
                }
            }
            return false
        },
        
        getTitle()
        {
            if( undefined !== this.$props.title )
            {
                return this.$props.title + ':'
            }
            if( undefined !== this.$props.objects )
            {
                return this.$props.objects[ 0 ].listname + ' für'
            }
            return 'Datum wählen'
        },
        
        initialize()
        {
            
            let date = new Date()
            this.year = date.getFullYear()
            this.month = ( date.getMonth() + 1 )
            this.realMonth = date.getMonth()
            this.day = date.getDate()
            
            this.setFriendlyMonth()
            this.calendarTitle = this.getTitle() + ' ' + this.friendlyMonth + ' ' + this.year
            this.prepareDateGrid()
            
        },
        
        setFriendlyMonth()
        {
            this.friendlyMonth = this.$core.getFriendlyTimestamp().friendlyMonth( this.realMonth )
        },
        
        isToday( now, date )
        {
            return now.getDate() == date.getDate()
                   && now.getFullYear() == date.getFullYear()
                   && now.getMonth() == date.getMonth()
        },
        
        prepareDateGrid()
        {
            
            this.ready = false
            
            if( undefined !== this.$props.objects )
            {
                
                for( let i in this.$props.objects )
                {
                    
                    let object = this.$props.objects[ i ]
                    if( undefined !== object.timestamp )
                    {
                        let timestamp = object.timestamp
                        let date = new Date( timestamp )
                        let key = date.getFullYear() + ':' + date.getMonth() + ':' + date.getDate()
                        this.elements[ key ] = object
                        
                        if( -1 == this.dates.indexOf( key ) )
                        {
                            this.dates.push( key )
                        }
                    }
                    else
                    {
                        if( undefined !== object.lists )
                        {
                            for( let l in object.lists )
                            {
                                let list = object.lists[ l ]
                                let timestamp = list.timestamp
                                let date = new Date( timestamp )
                                let key = date.getFullYear() + ':' + date.getMonth() + ':' + date.getDate()
                                
                                if( -1 == this.dates.indexOf( key ) )
                                {
                                    this.dates.push( key )
                                }
                            }
                        }
                    }
                }
            }
            
            let now = new Date()
            
            let dates = []
            let date = new Date( this.year, this.realMonth, 1, 12, 0, 0 )
            let emptyStart = date.getDay() - 1
            
            emptyStart = -1 == emptyStart ? 6 : emptyStart
            
            for( let i = 0; i < emptyStart; i++ )
            {
                dates.push( {
                    type : 'empty ' + ( i < 5 ? 'regular' : 'weekend' ),
                    title: '',
                    id   : 'filler_' + this.$core.getUuid().generate()
                } )
            }
            
            let month = date.getMonth()
            let day = 1
            while( month == date.getMonth() )
            {
                
                let key = date.getFullYear() + ':' + date.getMonth() + ':' + date.getDate()
                
                dates.push( {
                    timestamp: date.getTime(),
                    key      : key,
                    object   : this.elements[ key ],
                    type     : 'day '
                               + ( ( 0 != date.getDay() && 6 != date.getDay() ) ? 'regular' : 'weekend' )
                               + ( this.isToday( now, date ) ? ' today' : '' )
                               + ( -1 < this.dates.indexOf( key ) ? ' has-elements' : '' ),
                    title    : day,
                    id       : date.getTime()
                } )
                
                day++
                date = new Date( date.getTime() + 86400000 )
                
            }
            
            let remaining = 42 - dates.length
            let c = 0
            while( c < remaining )
            {
                
                dates.push( {
                    type : 'empty ' + ( ( 0 != date.getDay() && 6 != date.getDay() ) ? 'regular' : 'weekend' ),
                    title: '',
                    id   : 'filler_' + this.$core.getUuid().generate()
                } )
                
                date = new Date( date.getTime() + 86400000 )
                c++
            }
            
            this.dates = dates
            this.ready = true
            
        },
        
        changeDate( direction )
        {
            
            switch( direction )
            {
                case 'last':
                    this.realMonth -= 1
                    break
                case 'next':
                    this.realMonth++
                    break
            }
            
            if( this.realMonth == 12 )
            {
                this.realMonth = 0
                this.year++
            }
            if( this.realMonth == -1 )
            {
                this.realMonth = 11
                this.year -= 1
            }
            
            this.month = this.realMonth + 1
            
        },
        
        handleCalendarClick( day )
        {
            if( day.object !== undefined )
            {
                this.$emit( 'calendarAction', 'show', day.object )
            }
            else
            {
                if( this.$props.noClone || this.cloneAllowed() )
                {
                    this.$emit( 'calendarAction', 'create', day.timestamp )
                }
            }
            
        },
        
        handleCalendarClose()
        {
            
            let calendar = document.querySelector( '#' + this.id )
            
            if( null !== calendar )
            {
                calendar.style.opacity = 0
                setTimeout( () =>
                {
                    this.$emit( 'calendarAction', 'close' )
                }, 400 )
            }
            else
            {
                this.$emit( 'calendarAction', 'close' )
            }
            
        },
        
        handleClick()
        {
            this.$emit( 'clicked' )
        },
        
        hookKeys( state )
        {
            
            switch( state )
            {
                case undefined:
                    document.onkeydown = ( event ) =>
                    {
                        
                        if( 'ArrowLeft' === event.key )
                        {
                            this.changeDate( 'last' )
                        }
                        if( 'ArrowRight' === event.key )
                        {
                            this.changeDate( 'next' )
                        }
                        
                    }
                    break
                case false:
                    document.onkeydown = null
                    break
            }
            
        }
        
    }
}
</script>