<template>
    <div style="text-align: left;" v-if="initialized">
        <span v-html="caption"/>
    </div>
</template>

<script>

export default {
    name      : 'StudentData',
    props     : [
        'value',
        'id',
        'refName',
        'validator',
        'reformatter',
        'display',
        'editor',
        'rowAdder'
    ],
    components: {},
    data()
    {
        return {
            initialized: false,
            caption    : ''
        }
    },
    watch: {},
    created()
    {

        if( this.$core.f().valid( this.$props.refName ) )
        {

            let keys = this.$props.refName.split( '___' ),
                studentId = keys[ 0 ],
                field = keys[ 1 ]

            this.getReferencedField( studentId, field )
                .then( fieldData =>
                {
                    fieldData = 'undefined' === fieldData ? undefined : fieldData
                    this.caption = undefined !== fieldData ? this.$core.getTranslation().translate( fieldData, undefined, true ) : ''
                    this.initialized = true
                } )

        }
        else
        {
            this.caption = ''
            this.initialized = true
        }

    },
    mounted()
    {
    },
    methods: {
        
        getReferencedField( studentId, field )
        {
            
            return new Promise( resolve =>
            {

                let data = this.$core.getBaseClassHelper()
                               .get( 'student' )
                               .getById( studentId )

                return resolve( data[field] )

            } )
            
        }
        
    }
    
}
</script>
