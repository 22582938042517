export default class SyncHolidays
{

    constructor( parent )
    {

        this.parent = parent
        this.holidays = []
        this.allSynced = false

    }

    ready()
    {
        return this.allSynced === true
    }

    cleanHolidays( result )
    {
        for( let r in result )
        {
            if( r > 0 )
            {
                this.parent.database.deleteObject( result[ r ].id )
            }
        }
    }

    syncSchoolHolidays( syncResult )
    {

        return new Promise( ( resolve, reject ) =>
        {

            this.parent.database.readAllObjectsFiltered( 'schoolHolidays' )
                .then( result =>
                {

                    if( 0 < result.length )
                    {
                        if( 1 < result.length )
                        {
                            this.cleanHolidays( result )
                        }
                        this.parent.database.writeObject( syncResult.schoolHolidays, result[ 0 ].id, 'schoolHolidays' )
                        return resolve()
                    }
                    else
                    {
                        this.parent.database.writeObject( syncResult.schoolHolidays, undefined, 'schoolHolidays' )
                        return resolve()
                    }

                } )
                .catch( () =>
                {
                    return reject()
                } )

        } )

    }

    sync()
    {

        return new Promise( ( resolve, reject ) =>
        {

            let profile = this.parent.store.getters.metadata
            if( null !== profile )
            {

                this.parent.cryptoHelper.decrypt( profile )
                    .then( meta =>
                    {

                        if( false === meta )
                        {
                            return reject()
                        }

                        let temp = this.parent.f.isset( meta.school_zipcity ) ? meta.school_zipcity.split( ' ' ) : false
                        if( false !== temp && 0 < parseInt( temp[ 0 ] ) )
                        {

                            let zip = temp[ 0 ]
                            if( false !== this.parent.store.getters.idSession
                                && true === this.parent.store.getters.online )
                            {

                                let request = {
                                    method : 'objects.getHolidays',
                                    zipCode: zip
                                }

                                this.parent.client.request( request )
                                    .then( syncResult =>
                                    {

                                        this.parent.database.readAllObjectsFiltered( 'holidays' )
                                            .then( result =>
                                            {

                                                if( 0 < result.length )
                                                {
                                                    if( 1 < result.length )
                                                    {
                                                        this.cleanHolidays( result )
                                                    }
                                                    this.parent.database.writeObject( syncResult.holidays, result[ 0 ].id, 'holidays' )
                                                    return resolve( this.syncSchoolHolidays( syncResult ) )
                                                }
                                                else
                                                {
                                                    this.parent.database.writeObject( syncResult.holidays, undefined, 'holidays' )
                                                    return resolve( this.syncSchoolHolidays( syncResult ) )
                                                }

                                            } )
                                            .catch( () => {
                                                return reject()
                                            })

                                    } )
                                    .catch( () => {
                                        return reject()
                                    })

                            }
                            else
                            {
                                return reject()
                            }

                        }
                        else
                        {
                            return reject()
                        }

                    } )

            }
            else
            {
                return reject()
            }

        } )

    }

}