import { Constants }                from '@/classes/Core/Constants'
import Logger                       from '@/classes/Core/Logger'
import Config                       from '@/classes/Core/Config'
import EventManager                 from '@/classes/Core/EventManager'
import Ui                           from '@/classes/Core/Ui'
import UUID                         from '@/classes/Helpers/UUID'
import FriendlyTimestamp            from '@/classes/Helpers/FriendlyTimestamp'
import CoreTimer                    from '@/classes/Core/CoreTimer'
import Database                     from '@/classes/Core/Database/Database'
import CryptoCore                   from '@/classes/Core/CryptoCore'
import CryptoHelper                 from '@/classes/Helpers/Crypto'
import WebSocketClient              from '@/classes/Core/WebSocketClient/WebSocketClient'
import Translation                  from '@/classes/Helpers/Translation'
import Validator                    from '@/classes/Helpers/Validator'
import Login                        from '@/classes/Core/Authentication/Login'
import SyncWorker                   from '@/classes/Core/Workers/SyncWorker'
import License                      from '@/classes/Core/License'
import Functions                    from '@/classes/Helpers/Functions'
import SettingsWorker               from '@/classes/Core/Workers/SettingsWorker'
import Sorter                       from '@/classes/Helpers/Sorter'
import Reformatter                  from '@/classes/Helpers/Reformatter'
import QueueWorker                  from '@/classes/Core/Workers/QueueWorker'
import TableCalculation             from '@/classes/TableCalculation/TableCalculation'
import Sanitizers                   from '@/classes/Helpers/Sanitizers'
import Share                        from '@/classes/Core/Share/Share'
import Statistics                   from '@/classes/Helpers/Statistics'
import TemplateClass                from '@/classes/Core/TemplateClass'
import TestScoreCalculation         from '@/classes/Helpers/TestScoreCalculation'
import __Printing                   from '@/classes/Helpers/Printing'
import Performance                  from '@/classes/Core/Performance'
import Flags                        from '@/classes/Core/Flags'
import Rights                       from '@/classes/Core/Rights'
import TimerControl                 from '@/classes/Core/TimerControl'
import Excel                        from '@/classes/Helpers/Excel'
import Bubbles                      from '@/classes/Helpers/Bubbles'
import ColleagueCache               from '@/classes/Helpers/ColleagueCache'
import TwoFactor                    from '@/classes/Core/Authentication/TwoFactor'
import Session                      from '@/classes/Core/Session'
import OrganizerHelper              from '@/classes/Helpers/OrganizerHelper'
import BackupHelper                 from '@/classes/Helpers/Backup'
import MultiEdit                    from '@/classes/Helpers/MultiEdit'
import WebWorkerFactory             from '@/classes/Core/WebWorkerFactory'
import RegisterStudentAccess        from '@/classes/Helpers/RegisterStudentAccess'
import SearchEngine                 from '@/classes/Helpers/SearchEngine/SearchEngine';
import FieldHistoryHelper           from "@/classes/Helpers/FieldHistoryHelper";
import BaseClass                    from "@/classes/Core/BaseClass";
import StateMergers                 from "@/objects/stateMergers/StateMergers";
import DemoObjects                  from "@/classes/Helpers/DemoObjects";
import LazyObjectHandlerClass       from "@/classes/Helpers/LazyObjectHandler";
import Media                        from "@/classes/Helpers/Media";
import CompressionHelper            from "@/classes/Helpers/Compressor";
import ListSnapshotsClass           from "@/classes/Helpers/ListSnapshots";
import DevHelper                    from "@/classes/Helpers/Dev";
import ListUpdateManager            from "@/classes/Helpers/ListUpdateManager";
import Proto                        from "@/classes/Helpers/Proto";
import { reactive }                 from 'vue';
import CompetenceCategoryTree       from "@/classes/Helpers/CompetenceCategoryTree";
import CompetenceCategoryCalculator from "@/classes/Helpers/CompetenceCategoryCalculator";
import StudentAccessesHelper        from "@/classes/Helpers/StudentAccesses";

export default class Core
{

    constructor( store, router )
    {

        if( !Core.instance )
        {

            this.store = store
            this.router = router
            this.c = Constants
            this.setup()

            Core.instance = this

        }

        return Core.instance

    }

    setup()
    {

        this.states = reactive( new Map() )
        this.setState( 'criticalErrors', false )

        this.translation = new Translation()
        this.proto = new Proto()

        this.config = new Config()
        this.logger = new Logger( this )
        this.functions = new Functions( this )

        this.uuid = new UUID()

        this.eventManager = new EventManager( this )
        this.functions.injectEventManager( this.eventManager )
        this.translation.injectEventManager( this.eventManager )
        this.logger.injectEventManager( this.eventManager )

        this.database = new Database( this )
        this.performance = new Performance( this )
        this.statistics = new Statistics( this )

        this.store.commit( 'setDatabase', this.database )
        this.store.commit( 'setEventManager', this.eventManager )
        this.store.commit( 'initStates' )

        this.flags = new Flags( this )

        this.coreTimer = new CoreTimer( this )
        this.eventManager.dispatchAndRemove( 'on-core-timer-ready' )

        this.timerControl = new TimerControl( this )
        this.friendlyTimestamp = new FriendlyTimestamp()
        this.sanitizers = new Sanitizers()

        this.reformatter = new Reformatter( this )
        this.validator = new Validator( this )

        this.webworkerFactory = new WebWorkerFactory( this )
        this.colleagueCache = new ColleagueCache( this )
        this.cryptoCore = new CryptoCore()
        this.cryptoHelper = new CryptoHelper( this )

        this.websocketClient = new WebSocketClient( this )

        this.eventManager.dispatch( 'on-objectregistry-instance' )

        this.settingsWorker = new SettingsWorker( this )
        this.ui = new Ui( this )
        this.sorter = new Sorter( this.settingsWorker, this.eventManager )
        this.fieldHistory = new FieldHistoryHelper( this )
        this.organizerHelper = new OrganizerHelper( this )
        this.printing = new __Printing( this )
        this.excel = new Excel( this )
        this.queueWorker = new QueueWorker( this )
        this.syncWorker = new SyncWorker( this )

        this.lazyObjectHandlerHelper = new LazyObjectHandlerClass( this )

        /* two step initialization neccessary */
        this.baseClassHelper = new BaseClass( this )
        this.tableCalculation = new TableCalculation( this )
        this.stateMergers = new StateMergers( this )

        this.baseClassHelper.init( this )
        this.colleagueCache.injectBaseClass( this.baseClassHelper )

        this.bubbles = new Bubbles( this )
        this.license = new License( this )

        this.authenticator = new Login( this )
        this.twoFactor = new TwoFactor( this )
        this.templates = new TemplateClass( this )

        this.rights = new Rights( this )
        this.media = new Media( this )

        this.eventManager.dispatchAndRemove( 'on-media-ready' )

        this.testScoreCalculation = new TestScoreCalculation( this )

        this.backupHelper = new BackupHelper( this )
        this.multiEditHelper = new MultiEdit( this )

        this.registerStudentAccessHelper = new RegisterStudentAccess( this )
        this.studentAccessesHelper = new StudentAccessesHelper( this )
        this.cpxRegistry = false
        this.listSnapshotHelper = new ListSnapshotsClass( this )
        this.compressionHelper = new CompressionHelper( this )

        this.demoObjects = new DemoObjects( this )
        /* at last: the share helper */
        this.share = new Share( this )

        this.logger.inject( this.websocketClient, this.settingsWorker, this.store, this.coreTimer )
        this.logger.cconstructed( 'Core:constructor', 'entzettelt core ' + this.config.version + ' constructed and initialized.' )

        this.tdebug = {
            start: 0,
            end  : 0
        }

        this.session = new Session( this )
        this.searchEngine = new SearchEngine( this )
        this.devHelper = new DevHelper( this )
        this.listUpdateManager = new ListUpdateManager( this )
        this.competenceCategoryTree = new CompetenceCategoryTree( this )
        this.competenceCategoryCalculator = new CompetenceCategoryCalculator( this )

        this.eventManager.append( 'cpx-registry-ready', ( registry ) =>
        {

            this.cpxRegistry = registry

        } )

        this.eventManager.add( 'reset-all', () =>
        {
            this.reset( true )
        } )

    }

    /*eslint-disable*/
    reset( resetOnly, noReload )
    {

        if( !resetOnly )
        {

            this.timerControl.destruct()   // core-component-resettable
            this.functions.destruct()
            this.config.destruct()
            this.logger.destruct()
            this.eventManager.destruct()
            this.bubbles.destruct()        // core-component-resettable
            this.performance.destruct()    // core-component-resettable
            this.coreTimer.destruct()      // core-component-resettable
            this.ui.destruct()
            this.cryptoCore.destruct()
            this.cryptoHelper.destruct()
            this.websocketClient.destruct() // core-component-resettable
            this.syncWorker.destruct()      // core-component-resettable
            this.templates.destruct()
            this.queueWorker.destruct()     // should-work through timer, which is core-component-resettable
            this.settingsWorker.destruct()
            this.license.destruct()        // core-component-resettable
            this.share.destruct()
            this.authenticator.destruct()
            this.twoFactor.destruct()
            this.sorter.destruct()
            this.statistics.destruct()
            this.reformatter.destruct()
            this.validator.destruct()
            this.tableCalculation.destruct()
            this.testScoreCalculation.destruct()
            this.printing.destruct()
            this.excel.destruct()
            this.flags.destruct()
            this.rights.destruct()
            this.session.destruct()

            delete this.organizerHelper
            delete this.colleagueCache
            delete this.timerControl
            delete this.functions
            delete this.config
            delete this.logger
            delete this.database
            delete this.eventManager
            delete this.bubbles
            delete this.performance
            delete this.coreTimer
            delete this.friendlyTimestamp
            delete this.uuid
            delete this.sanitizers
            delete this.templates
            delete this.translation
            delete this.validator
            delete this.ui
            delete this.cryptoCore
            delete this.cryptoHelper
            delete this.websocketClient
            delete this.syncWorker
            delete this.queueWorker
            delete this.settingsWorker
            delete this.license
            delete this.share
            delete this.authenticator
            delete this.twoFactor
            delete this.sorter
            delete this.statistics
            delete this.reformatter
            delete this.tableCalculation
            delete this.testScoreCalculation
            delete this.printing
            delete this.flags
            delete this.rights
            delete this.media
            delete this.session

        }

        if( undefined === noReload )
        {

            this.store.commit( 'setCoreResetTriggering', true )

            setTimeout( () =>
            {
                window.location.reload()
            }, ( resetOnly ? 500 : 2000 ) )

        }

    }

    /**
     * short for getFunctions
     * @returns {Functions}
     */
    f()
    {
        return this.functions
    }

    s()
    {
        return this.statistics
    }

    r()
    {
        return this.rights
    }

    cc()
    {
        return this.colleagueCache
    }

    ts()
    {
        return this.friendlyTimestamp
    }

    ww()
    {
        return this.webworkerFactory
    }

    t( key, params )
    {
        return this.translation.translate( key, params )
    }

    reg()
    {
        return this.cpxRegistry
    }

    lz()
    {
        return this.compressionHelper
    }

    studentAccesses()
    {
        return this.studentAccessesHelper
    }

    lazyObjects()
    {
        return this.lazyObjectHandlerHelper
    }

    getListSnapshotHelper()
    {
        return this.listSnapshotHelper
    }

    getStateMergers()
    {
        return this.stateMergers
    }

    getBaseClassHelper()
    {
        return this.baseClassHelper
    }

    getListUpdateManager()
    {
        return this.listUpdateManager
    }

    getCompetenceCategoryTree()
    {
        return this.competenceCategoryTree
    }

    getCompetenceCategoryCalculator()
    {
        return this.competenceCategoryCalculator
    }

    baseClass( type, subType )
    {
        return this.baseClassHelper.get( type, subType )
    }

    search()
    {
        return this.searchEngine
    }

    timeDebug( mode, label )
    {
        switch( mode )
        {
            case 'start':
                this.tdebug.start = Date.now()
                break
            case 'end':
                label = label || 'unlabeled'
                this.tdebug.end = Date.now()
                this.logger.cdebug( 'Core::timeDebug', '>>>> DIFF', ( this.tdebug.end - this.tdebug.start ), 'ms', '>>>>', label )
                this.tdebug.start = 0
                this.tdebug.end = 0
                break
        }
    }

    setState( key, value )
    {

        let state  = this.states.get( key ),
            change = undefined !== state && value !== state

        this.states.set( key, value )

        if( change )
        {
            this.eventManager.dispatch( 'on-core-state-change-' + key, value )
        }

    }

    getState( key )
    {
        return this.states.get( key )
    }

    deleteState( key )
    {
        delete this.states.delete( key )
    }

    /*eslint-disable*/

    deleteStates( key )
    {
        for( let k in this.states.keys() )
        {
            if( -1 < k.indexOf( key ) )
            {
                this.deleteState( k )
            }
        }
    }

    getFlags()
    {
        return this.flags
    }

    /**
     * short for getSorter
     * @returns {Sorter}
     */
    sort()
    {
        return this.sorter
    }

    /**
     * short for getSettingsWorker
     */
    settings()
    {
        return this.settingsWorker
    }

    getAuthenticator()
    {
        return this.authenticator
    }

    getTwoFactor()
    {
        return this.twoFactor
    }

    getClient()
    {
        return this.websocketClient
    }

    getConfig()
    {
        return this.config
    }

    getCoreTimer()
    {
        return this.coreTimer
    }

    getCryptoCore()
    {
        return this.cryptoCore
    }

    getCryptoHelper()
    {
        return this.cryptoHelper
    }

    getDatabase()
    {
        return this.database
    }

    getEventManager()
    {
        return this.eventManager
    }

    getFieldHistoryHelper()
    {
        return this.fieldHistory
    }

    getFriendlyTimestamp()
    {
        return this.friendlyTimestamp
    }

    getLicense()
    {
        return this.license
    }

    getLogger()
    {
        return this.logger
    }

    getObjectRegistry()
    {
        return false //this.objectRegistry
    }

    getRouter()
    {
        return this.router
    }

    getSanitizers()
    {
        return this.sanitizers
    }

    getBubbles()
    {
        return this.bubbles
    }

    getTemplates()
    {
        return this.templates
    }

    getMediaHelper()
    {
        return this.media
    }

    getSettingsWorker()
    {
        return this.settingsWorker
    }

    getSorter()
    {
        return this.sorter
    }

    getStore()
    {
        return this.store
    }

    getUi()
    {
        return this.ui
    }

    getUuid()
    {
        return this.uuid
    }

    perfIndex()
    {
        return this.performance.index.performanceIndex
    }

    getPerformance()
    {
        return this.performance
    }

    getExcel()
    {
        return this.excel
    }

    getPrinting()
    {
        return this.printing
    }

    getShare()
    {
        return this.share
    }

    getSyncWorker()
    {
        return this.syncWorker
    }

    getTranslation()
    {
        return this.translation
    }

    getValidator()
    {
        return this.validator
    }

    getReformatter()
    {
        return this.reformatter
    }

    getQueueWorker()
    {
        return this.queueWorker
    }

    getTableCalculation()
    {
        return this.tableCalculation
    }

    getTestScoreCalculation()
    {
        return this.testScoreCalculation
    }

    getOrganizerHelper()
    {
        return this.organizerHelper
    }

    getBackupHelper()
    {
        return this.backupHelper
    }

    getMultiEditHelper()
    {
        return this.multiEditHelper
    }

    getRegisterStudentAccessHelper()
    {
        return this.registerStudentAccessHelper
    }

    awaitMxRegistry()
    {
        return new Promise( resolve =>
        {

            if( this.getState( 'mxRegistry.state' ) === true )
            {
                return resolve()
            }
            else
            {
                setTimeout( () =>
                {
                    return resolve( this.awaitMxRegistry() )
                }, 300 )
            }

        } )
    }

    awaitSyncedState()
    {
        return new Promise( resolve =>
        {

            this.syncWorker.awaitFirstSync()
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    awaitStableState()
    {
        return new Promise( resolve =>
        {

            this.syncWorker.awaitFirstSync()
                .then( () =>
                {
                    return resolve()
                } )

        } )
    }

    objectRegistryQueueForType()
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate()
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-queue-for-type', request )

        } )

    }

    objectRegistryTypeForQueue( queue )
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate(),
                queue    : queue
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-type-for-queue', request )

        } )

    }

    objectRegistryGet( which, archived )
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate(),
                which    : which,
                archived : archived
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get', request )

        } )

    }

    objectRegistryGetArchiveLimited( which, timestamp )
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate(),
                which    : which,
                timestamp: timestamp
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get-archive-limited', request )

        } )

    }

    objectRegistryGetElementCount()
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate()
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get-element-count', request )

        } )

    }

    objectRegistryGetAll()
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate()
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get-all', request )

        } )

    }

    objectRegistryGetById( which )
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate(),
                which    : which
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get-byId', request )

        } )

    }

    objectRegistryGetListById( referenceKey, localId, scope )
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId   : this.uuid.generate(),
                referenceKey: referenceKey,
                localId     : localId,
                scope       : scope
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get-list-byId', request )

        } )

    }

    objectRegistryGetColleagueById( which )
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate(),
                which    : which
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get-colleague-byId', request )

        } )

    }

    objectRegistryGetColleagueByUuid( which )
    {

        return new Promise( resolve =>
        {

            let request = {
                requestId: this.uuid.generate(),
                which    : which
            }

            this.eventManager.add( 'mx-response-' + request.requestId, ( response ) =>
            {
                return resolve( response )
            } )

            this.eventManager.dispatch( 'mx-get-colleague-byUuid', request )

        } )
    }
}