/*eslint-disable*/
export default class afterAllShare
{

    constructor( core )
    {
        this.eventManager = core.getEventManager()
    }

    afterFetch( shares, skipArchived )
    {
        return new Promise( resolve =>
        {

            this.eventManager
                .dispatchIndexed( 'on-share-refresh-component' )

            return resolve( shares )

        } )
    }

    afterUpdate( shares, cache, skipArchived )
    {

        return new Promise( resolve =>
        {

            this.afterFetch( shares, skipArchived )
                .then( result =>
                {

                    return resolve( result )

                } )

        } )

    }

}