import AbstractObjectClass from "@/objects/abstract/ObjectClass";

export default class ShadowCopyObjectClass extends AbstractObjectClass
{

    constructor( core )
    {

        if( !ShadowCopyObjectClass.instance )
        {

            let refList = [ 'elementType',
                            'referenceLocalId',
                            'referenceKey',
                            'timestamp',
                            'update',
                            'studentEditable',
                            'studentLocalId' ]

            let personalAttributesStatesList = []

            super( core, 'shadowCopy', refList, personalAttributesStatesList )

            this.hasDetailView = false
            this.baseClassHelper = core.getBaseClassHelper()
            this.t = ( key, params ) =>
            {
                return core.t( key, params )
            }

            this.objectType = 'shadowCopy'

            ShadowCopyObjectClass.instance = this

        }

        return ShadowCopyObjectClass.instance

    }

    getShadowCopyByReferenceLocalId( referenceLocalId )
    {

        let scopes = [ 'cache', 'archive' ]

        for( let s in scopes )
        {
            let scope   = scopes[ s ]

            for( let [ key, copy ] of this.registry[ scope ] )
            {

                if( key !== undefined
                    && copy.referenceLocalId === referenceLocalId )
                {
                    return copy
                }

            }

        }

        return undefined

    }

    getTabs()
    {
        return []
    }

    getFunctions()
    {
        return []
    }

}