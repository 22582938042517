<template>
    <transition appear :name="$core.settings().getTransition( 'list' )" mode="out-in">
        <div v-if="visible" class="default-form-overlay organizer-contents">
            <div class="default-form-container">
                <div class="default-form-wrapper">
                    <Button type="close"
                            @clicked="handleClose()"
                            addClass="absolute top right inverted"/>
                    <h2>{{ $core.t( 'organizer-contents' ) }}</h2>
                    <div class="form">
                        <OrganizerContentsList v-if="'list' === mode"
                                               :viewKey="viewKey"
                                               @update="handleUpdate()"
                                               @clicked="handleClick"/>
                        <OrganizerContentsForm v-if="'form' === mode"
                                               @update="handleUpdate()"
                                               @close="mode = 'list'"/>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
/*eslint-disable*/
import Pagination            from "@/components/elements/defaults/Pagination";
import PreheatingIndicator   from "@/components/elements/indicators/PreheatingIndicator";
import InlineMessage         from "@/components/messages/InlineMessage";
import OrganizerContentsList from "@/components/elements/viewItems/plannerView/modes/organizer/OrganizerContentsList";
import OrganizerContentsForm from "@/components/elements/viewItems/plannerView/modes/organizer/OrganizerContentsForm";

export default {

    name      : 'OrganizerContentsOverlay',
    components: { OrganizerContentsForm, OrganizerContentsList, InlineMessage, PreheatingIndicator, Pagination },
    emits     : [ 'close' ],

    props: {
        setup: { Type: Object, required: true }
    },

    data()
    {
        return {
            visible   : true,
            viewKey   : this.$core.getUuid().generate(),
            processing: false,
            mode      : 'list',
            eventIndex: false
        }
    },

    mounted()
    {
        this.eventIndex = this.$core.getEventManager().addIndexed( 'on-organizer-update', () =>
        {
            this.handleUpdate()
        } )
    },

    created()
    {
    },

    beforeUnmount()
    {
        this.$core.getEventManager().removeIndexedCallback( 'on-organizer-update', this.eventIndex )
    },

    methods: {

        handleClick( which )
        {
            switch( which )
            {
                case 'add':
                    this.mode = 'form'
                    break
            }
        },

        handleUpdate()
        {
            this.viewKey = this.$core.getUuid().generate()
        },

        handleClose()
        {
            this.visible = false
            this.$core.getUi()
                .delay( () =>
                {
                    this.$emit( 'close' )
                }, 400 )
        }

    }
}
</script>